import "whatwg-fetch";
import _ from "lodash";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import { HttpError } from "@microsoft/signalr";
import { maxMobileWidth } from "../constants/mobileConstants";

export interface CycleCounterDetection {
  telemetryId: string;
  detectedItem: string;
  timeStamp: string;
  deviceId: string;
}

export interface DetectionCount {
  day: Date;
  total: number;
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CycleCounterDetectionsState {
  cycleCounterDetections: {
    [key: string]: CycleCounterDetection;
  };
  initialLoadStarted: boolean;
  selectedCycleCounterDetectionId?: string;
  sidebarExpanded: boolean;
  CycleCounterDetectionStatusOperationLoading: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | RequestCycleCounterDetectionsAction
  | ReceiveCycleCounterDetectionsAction
  | ErrorReceivingCycleCounterDetectionsAction;

// -----------------
// SELECT
export class DetectionSummary {
  constructor(public day: string, public count?: number) {}
}

export class CycleCounterDetectionSelectors {
  static getCycleCounterDetectionSummary(state: CycleCounterDetectionsState, deviceId: string): CycleCounterDetection[] {
    // const filtered = Object.values(state.cycleCounterDetections).filter(x=>x.deviceId===deviceId)
    const filtered = Object.values(state.cycleCounterDetections);

    _.groupBy(filtered, "deviceId");

    return filtered;
  }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.


interface RequestCycleCounterDetectionsAction extends Action {
  type: "REQUEST_CYCLE_COUNTER_DETECTIONS";
}

export interface ReceiveCycleCounterDetectionsAction extends Action {
  type: "RECEIVE_CYCLE_COUNTER_DETECTIONS";
  cycleCounterDetections: CycleCounterDetection[];
}

interface ErrorReceivingCycleCounterDetectionsAction extends Action {
  type: "ERROR_RECEIVING_CYCLE_COUNTER_DETECTIONS";
}

export const actionCreators = {
  requestAllCycleCounterDetections: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    const {
      cycleCounterDetections: { initialLoadStarted },
      authentication: { accessToken },
    } = getState();

    if (initialLoadStarted || !accessToken) {
      return;
    }

    enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
      fetch(`/api/cyclecounter/detections`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json() as Promise<CycleCounterDetection[]>;
          } else {
            throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
          }
        })
        .then((data) => {
          dispatch({ type: "RECEIVE_CYCLE_COUNTER_DETECTIONS", cycleCounterDetections: data });
          ClearBackendErrorToast(ToastErrorTypes.CYCLE_COUNTER_DETECTIONS);
        })
        .catch((error) => {
          dispatch({ type: "ERROR_RECEIVING_CYCLE_COUNTER_DETECTIONS" });

          console.error(error);

          BackendErrorToast(ToastErrorTypes.CYCLE_COUNTER_DETECTIONS, "Error retrieving Detection signs");
        });

      dispatch({ type: "REQUEST_CYCLE_COUNTER_DETECTIONS" });
    });
  },
  receiveUpdatedCycleCounterDetections:
    (incomingCycleCounterDetections: CycleCounterDetection[]): AppThunkAction =>
    (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
      enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
        dispatch({ type: "RECEIVE_CYCLE_COUNTER_DETECTIONS", cycleCounterDetections: incomingCycleCounterDetections });
      });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CycleCounterDetectionsState = {
  cycleCounterDetections: {},
  initialLoadStarted: false,
  sidebarExpanded: window.innerWidth >= maxMobileWidth,
  selectedCycleCounterDetectionId: undefined,
  CycleCounterDetectionStatusOperationLoading: false,
};

export const reducer: Reducer<CycleCounterDetectionsState, AnyAction> = (state: CycleCounterDetectionsState | undefined, incomingAction: AnyAction) => {
  if (!state) {
    //Redux throws initialises the state with a dummy action on load. Return an initial state.
    state = unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "REQUEST_CYCLE_COUNTER_DETECTIONS":
      return <CycleCounterDetectionsState>{
        ...state,
        initialLoadStarted: true,
      };

    case "RECEIVE_CYCLE_COUNTER_DETECTIONS": {
      let autoId = 1;
      const incomingCycleCounterDetections = action.cycleCounterDetections.reduce<Record<string, CycleCounterDetection>>(function (
        cycleCounterDetectionMap,
        current
      ) {
        cycleCounterDetectionMap[`${current.deviceId}_${autoId++}`] = current;
        return cycleCounterDetectionMap;
      },
      {});

      return <CycleCounterDetectionsState>{
        ...state,
        initialLoadStarted: false,
        cycleCounterDetections: incomingCycleCounterDetections,
      };
    }

    case "ERROR_RECEIVING_CYCLE_COUNTER_DETECTIONS":
      return <CycleCounterDetectionsState>{
        ...state,
        initialLoadStarted: false,
      };

    default: {
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = action as never;
    }
  }
  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  return state;
};
