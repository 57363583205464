import React from "react";
import { useDispatch } from "react-redux";
import L, { LatLng } from "leaflet";
import { Marker } from "react-leaflet";
import "./cycleCounterDeviceMarker.scss";
import { CycleCounterDevice } from "store/cycleCounterDevices";
import { actionCreators } from "store/cycleCounterDevices";
import ReactDOMServer from "react-dom/server";

// marker props

export interface CycleCounterDeviceMarkerProps {
    device: CycleCounterDevice;
    zoom: number;
    isSelected: boolean;
}

// helper functions

class DeviceMarkerFunctions {
    scaleFunc = (zoomLevel: number) => {
        const iconSize = 50;
        return Math.floor(iconSize * (1 / Math.max(1, 17 - zoomLevel)));
    };

    getIconClass = (device: CycleCounterDevice, isSelected: boolean) => {
        let className = device.id;

        if (isSelected) className += " selected-site";
        if (device.state === "Online") className += " Online";
        if (device.state === "OffLine") className += " Offline";
        if (device.state === "LastSeen7DaysAgo") className += " LastSeen7DaysAgo";
        if (device.state === "Unknown") className += " Unknown";

        if (device.isLowBattery) className += " isLowBattery";
        return className;
    };

    // divIcon

    getBikeIcon = (device: CycleCounterDevice, zoom: number, isSelected: boolean) => {
        const size = deviceMarkerFunctions.scaleFunc(zoom);

        return L.divIcon({
            html: ReactDOMServer.renderToStaticMarkup(this.iconHtml(deviceMarkerFunctions.getIconClass(device, isSelected))),
            className: "map-counter-icon " + deviceMarkerFunctions.getIconClass(device, isSelected),
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 2],
        });
    };

    iconHtml = (className?: string) => <div className={className}>
        <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFF">
                <path d="m253.85 920.3c-114.53 0-207.69-93.176-207.69-207.69s93.164-207.69 207.69-207.69 207.69 93.176 207.69 207.69-93.164 207.69-207.69 207.69zm0-369.23c-89.062 0-161.54 72.465-161.54 161.54s72.477 161.54 161.54 161.54 161.54-72.465 161.54-161.54c-0.003907-89.074-72.477-161.54-161.54-161.54z" />
                <path d="m946.15 920.3c-114.53 0-207.69-93.176-207.69-207.69s93.164-207.69 207.69-207.69 207.69 93.176 207.69 207.69c0.003906 114.52-93.16 207.69-207.69 207.69zm0-369.23c-89.062 0-161.54 72.465-161.54 161.54s72.477 161.54 161.54 161.54 161.54-72.465 161.54-161.54-72.477-161.54-161.54-161.54z" />
                <path d="m923.1 735.7c-8.8125 0-17.219-5.0703-21.07-13.613l-184.62-409.81c-5.2266-11.617-0.046875-25.285 11.562-30.516 11.695-5.2969 25.285-0.035156 30.535 11.562l184.62 409.81c5.2266 11.617 0.046875 25.285-11.562 30.516-3.0859 1.3867-6.3086 2.0508-9.4648 2.0508z" />
                <path d="m576.92 764.34h-323.07c-8.5195 0-16.34-4.6875-20.352-12.191-4.0117-7.5039-3.582-16.609 1.1484-23.684l184.62-276.92 38.402 25.602-160.7 241.05h269.53l236.51-269.09 34.66 30.469-243.41 276.92c-4.3711 4.9805-10.707 7.8438-17.332 7.8438z" />
                <path d="m482.66 584.12 288.4-208.95 27.09 37.387-288.4 208.95z" />
                <path d="m761.54 325.88h-92.309c-12.754 0-23.078-10.332-23.078-23.078 0-12.746 10.32-23.078 23.078-23.078h92.309c12.754 0 23.078 10.332 23.078 23.078 0 12.746-10.324 23.078-23.078 23.078z" />
                <path d="m371.66 382.36 41.285-20.645 184.62 369.23-41.285 20.645z" />
                <path d="m461.54 395.11h-161.54c-12.754 0-23.078-10.332-23.078-23.078s10.32-23.078 23.078-23.078h161.54c12.754 0 23.078 10.332 23.078 23.078s-10.324 23.078-23.078 23.078z" />
            </g>
        </svg>
    </div>;


}
export const deviceMarkerFunctions = new DeviceMarkerFunctions();

export const CycleCounterDeviceMarker = (props: CycleCounterDeviceMarkerProps) => {
    const dispatch = useDispatch();

    return (
        <Marker
            position={new LatLng(+props.device.latitude, +props.device.longitude)}
            icon={deviceMarkerFunctions.getBikeIcon(props.device, props.zoom, props.isSelected)}
            onclick={(e) => dispatch(actionCreators.selectCycleCounter(props.device.id, false))}
        />
    );
};

export default CycleCounterDeviceMarker;
