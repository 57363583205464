import * as React from "react";

export interface IconGuideTableRowProps {
    icon: string | JSX.Element;
    altText: string;
    description: string | JSX.Element;
}

export class IconGuideTableRow extends React.Component<IconGuideTableRowProps> {
    public render() {
        return (
            <tr>
                <td className="col-2">
                    {typeof this.props.icon === "string" ? (
                        <img src={this.props.icon} alt={this.props.altText} />
                    ) : (
                        this.props.icon
                    )}
                </td>
                <td className="col-10">{this.props.description}</td>
            </tr>
        );
    }
}

export default IconGuideTableRow;
