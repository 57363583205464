import * as Alerts from "./alarm";
import * as Logs from "./logs";
import * as Sites from "./sites";
import * as Comments from "./comments";
import * as AddInsight from "./addInsight";
import * as Buses from "./buses";
import * as Map from "./map";
import * as SignalR from "./middleware/signalR";
import * as timerUpdates from "./middleware/timerUpdates";
import * as msal from "./middleware/msal";
import * as Authentication from "./authentication";
import { ThunkDispatch } from "redux-thunk";
import * as UserSettings from "./userSettings";
import * as AppSettings from "./appSettings";
import * as AdminSettings from "./adminSettings";
import * as SchoolSign from "./schoolSigns";
import * as CycleCounterDevices from "./cycleCounterDevices";
import * as CycleCounterDetections from "./cycleCounterDetections";
import * as LightwireDevices from "./lightwireDevicesStore";
import * as PumpDevices from "./Pumps/pumpsDataApi";
import { RouterRootState, LocationChangeAction, CallHistoryMethodAction } from "connected-react-router";
import { MiddlewareAPI, Dispatch, AnyAction } from "redux";
import * as PumpDeviceDataTypes from "./Pumps/pumpsDatatypes";
import * as Health from "./health";

// The top-level state object
export interface ApplicationState extends RouterRootState {
  alerts: Alerts.AlertState;
  logs: Logs.LogState;
  sites: Sites.SiteState;
  comments: Comments.CommentState;
  addInsight: AddInsight.AddInsightState;
  buses: Buses.BusState;
  userSettings: UserSettings.UserSettingsState;
  appSettings: AppSettings.AppSettingsState;
  map: Map.MapState;
  authentication: Authentication.AuthState;
  adminSettings: AdminSettings.AdminSettingsState;
  schoolSigns: SchoolSign.SchoolSignState;
  cycleCounterDevices: CycleCounterDevices.CycleCounterDevicesState;
  cycleCounterDetections: CycleCounterDetections.CycleCounterDetectionsState;
  lightwireDevices: LightwireDevices.LightwireDevicesState;
  pumpDevices: PumpDeviceDataTypes.PumpDevicesState;
  health: Health.HealthState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  alerts: Alerts.reducer,
  logs: Logs.reducer,
  sites: Sites.reducer,
  comments: Comments.reducer,
  addInsight: AddInsight.reducer,
  buses: Buses.reducer,
  userSettings: UserSettings.reducer,
  appSettings: AppSettings.reducer,
  map: Map.reducer,
  authentication: Authentication.reducer,
  adminSettings: AdminSettings.reducer,
  schoolSigns: SchoolSign.reducer,
  cycleCounterDevices: CycleCounterDevices.reducer,
  cycleCounterDetections: CycleCounterDetections.reducer,
  lightwireDevices: LightwireDevices.reducer,
  pumpDevices: PumpDevices.reducer,
  health: Health.reducer
};

export const middleware = [msal.middleware, SignalR.middleware, timerUpdates.middleware];

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type StoreActions =
  | Alerts.KnownAction
  | Logs.KnownAction
  | Sites.KnownAction
  | Map.KnownAction
  | Comments.KnownAction
  | AddInsight.KnownAction
  | Buses.KnownAction
  | UserSettings.KnownAction
  | AppSettings.KnownAction
  | Authentication.KnownAction
  | AdminSettings.KnownAction
  | LocationChangeAction
  | CallHistoryMethodAction
  | SchoolSign.KnownAction
  | CycleCounterDevices.KnownAction
  | CycleCounterDetections.KnownAction
  | LightwireDevices.KnownAction
  | PumpDeviceDataTypes.KnownAction
  | Health.KnownAction;

// This type can be used as a hint on action creators to ensure dispatching and state is typesafe
export type AppThunkDispatch = ThunkDispatch<ApplicationState, undefined, StoreActions>;
export type AppMiddlewareAPI = MiddlewareAPI<Dispatch<AnyAction>, ApplicationState>;

export interface AppThunkAction {
  (dispatch: AppThunkDispatch, getState: () => ApplicationState): void;
}

export interface AppThunkActionAsync<TResult> {
  (dispatch: (action: StoreActions) => void, getState: () => ApplicationState): Promise<TResult>;
}
