import * as React from 'react';

interface AdminSettingExplanationProps {
    explanation: string;
    className?: string;
}

export class AdminSettingExplanation extends React.Component<AdminSettingExplanationProps> {
    public render() {
        return (
            <div className={`explanation ${this.props.className || ""}`}>
                {this.props.explanation}
            </div>
        );
    }
}

export default AdminSettingExplanation;