import { createStore, applyMiddleware, combineReducers, ReducersMapObject } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";

import { reducers, middleware } from "store";
import { History } from "history";
import { Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(history: History) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const middlewares: Middleware[] = [routerMiddleware(history), thunk, ...middleware];

    // Combine all reducers and instantiate the app-wide store instance
    const rootReducer = buildRootReducer(reducers, history);
    const composedEnhancers = composeWithDevTools(applyMiddleware(...middlewares));

    //Create the Redux Store
    const store = createStore(rootReducer, composedEnhancers);

    /* Enable Webpack hot module replacement for reducers
    // TODO: https://github.com/facebook/create-react-app/issues/7227 & https://github.com/cdharris/react-app-rewire-hot-loader
    //import * as StoreModule from "store";
    //if (module.hot) {
    //    module.hot.accept("store", () => {
    //const newStore = require<typeof StoreModule>("store");
    //store.replaceReducer(buildRootReducer(newStore.reducers, history));
    // });
    } 
    */

    return store;
}

//The following function builds the reducer.
export function buildRootReducer(allReducers: ReducersMapObject, history: History) {
    return combineReducers({
        router: connectRouter(history),
        ...allReducers
    });
}
