import * as React from 'react';
import { iconClose } from "../../images";
import './sidebarFilters.scss';
import { AlarmCode, AlertSource } from 'store/alarm';
import { Filters } from 'store/sites';
import { AuthenticationRole, AuthenticationSelectors } from 'store/authentication';
import FilterGroup from '../../sidebar/filters/filterGroup';
import AlarmFilterGroup from '../../sidebar/filters/alarmFilterGroup';

interface SidebarFilterProps {
    closePanel: () => void;
    alarmCodes: AlarmCode[];
    filters: Filters;
    setFilters: (filters: Filters) => void;
    userRole: AuthenticationRole;
}

export enum ToggleType {
    TOGGLE,
    ALL_ON,
    ALL_OFF
}

const SidebarFilter = (props: SidebarFilterProps) => {

    const handleToggleAlertInFilterList = (alarmCodes: AlarmCode[], toggleType: ToggleType) => {
        const t = { ...props.filters };
        for (let i = 0; i < alarmCodes.length; i++) {
            if ((t.alarms[alarmCodes[i].code] != null && toggleType === ToggleType.TOGGLE) || toggleType === ToggleType.ALL_OFF) {
                delete t.alarms[alarmCodes[i].code];
            }
            else if ((t.alarms[alarmCodes[i].code] == null && toggleType === ToggleType.TOGGLE) || toggleType === ToggleType.ALL_ON) {
                t.alarms[alarmCodes[i].code] = alarmCodes[i];
            }
        }

        props.setFilters({ ...props.filters, ...t });
    };

    const hasPermission = AuthenticationSelectors.checkAuthenticationUsingRole(AuthenticationRole.Operator, props.userRole);

    return (
        <div className="nav-blade filter-sidebar">
            <div className="blade-header">
                <div className="blade-header-title">
                    <h1>Site Filters</h1>
                </div>
                <div className="blade-header-close">
                    <button
                        type="button"
                        className="btn p-0 m-0"
                        onClick={() => props.closePanel()}
                    >
                        <img
                            src={iconClose}
                            className="align-top"
                            width={15}
                            height={15}
                            alt="Close icon"
                        />
                    </button>
                </div>
            </div>
            <div className="blade-body">

                <AlarmFilterGroup
                    title="SCATS"
                    alarmCodes={props.alarmCodes.filter(x => x.alarmSourceId === AlertSource.ScatsSiteAlarm || x.alarmSourceId === AlertSource.ScatsSiteDetectorAlarm || x.alarmSourceId === AlertSource.ScatsSiteDetectorThresholdAlarm)}
                    selectedAlarmCodes={props.filters.alarms}
                    handleToggleAlertInFilterList={handleToggleAlertInFilterList}
                />

                <AlarmFilterGroup
                    title="AddInsights"
                    alarmCodes={props.alarmCodes.filter(x => x.alarmSourceId === AlertSource.AddInsightSiteAlarm)}
                    selectedAlarmCodes={props.filters.alarms}
                    handleToggleAlertInFilterList={handleToggleAlertInFilterList}
                />

                <AlarmFilterGroup
                    title="SharePoint"
                    alarmCodes={props.alarmCodes.filter(x => x.alarmSourceId === AlertSource.SharePoint)}
                    selectedAlarmCodes={props.filters.alarms}
                    handleToggleAlertInFilterList={handleToggleAlertInFilterList}
                />

                <AlarmFilterGroup
                    title="PowerSupply"
                    alarmCodes={props.alarmCodes.filter(x => x.alarmSourceId === AlertSource.PowerSupply)}
                    selectedAlarmCodes={props.filters.alarms}
                    handleToggleAlertInFilterList={handleToggleAlertInFilterList}
                />

                <FilterGroup
                    title="Logs"
                >
                    <button
                        className={"filter-button btn" + (props.filters.openLogs ? " active" : "")}
                        title="Filter to sites with open logs"
                        onClick={() => props.setFilters({ ...props.filters, openLogs: !props.filters.openLogs })}
                    >Open Logs</button>
                    {
                        hasPermission &&
                        <button
                            className={"filter-button btn" + (props.filters.unreadLogs ? " active" : "")}
                            title="Filter to sites with unread logs"
                            onClick={() => props.setFilters({ ...props.filters, unreadLogs: !props.filters.unreadLogs })}
                        >Unread Logs</button>
                    }

                </FilterGroup>

                <FilterGroup
                    title="Mute"
                >
                    <button
                        className={"filter-button btn" + (props.filters.mutedAlarms ? " active" : "")}
                        title="Filter to sites with muted alarm sources"
                        onClick={() => props.setFilters({ ...props.filters, mutedAlarms: !props.filters.mutedAlarms })}
                    >Muted Alarm Sources</button>
                </FilterGroup>

                <FilterGroup
                    title="School Signs"
                >
                    <button
                        className={"filter-button btn" + (props.filters.schoolSignOn === true ? " active" : "")}
                        title="Filter to sites with 'on' status"
                        onClick={() => props.setFilters({ ...props.filters, schoolSignOn: !props.filters.schoolSignOn })}
                    >On</button>

                    <button
                        className={"filter-button btn" + (props.filters.schoolSignOff === true ? " active" : "")}
                        title="Filter to sites with 'off' status"
                        onClick={() => props.setFilters({ ...props.filters, schoolSignOff: !props.filters.schoolSignOff })}
                    >Off</button>

                    <button
                        className={"filter-button btn" + (props.filters.schoolSignNotTalking === true ? " active" : "")}
                        title="Filter to sites with 'not_talking' status"
                        onClick={() => props.setFilters({ ...props.filters, schoolSignNotTalking: !props.filters.schoolSignNotTalking })}
                    >Faulty</button>

                </FilterGroup>

            </div>
        </div >
    );
};

export default SidebarFilter;