import * as React from "react";
import "./controls.scss";
import { MobileAlertGroup } from "store/adminSettings";
import { iconDelete, iconAdd } from "../../images";
import { FC } from "react";

interface MobileGroupsTableSettingProps {
    title?: string;
    updateMobileAlertGroups: (groups: MobileAlertGroup[], deletedGroup: MobileAlertGroup | null) => void;
    groups: MobileAlertGroup[];
}

export const MobileGroupsTableSetting: FC<MobileGroupsTableSettingProps> = (props) => {

    const defaultNewGroup: MobileAlertGroup = {
        id: -10000000 + props.groups.length, //set temporary id as negative - this will be replaced on save
        name: `Group ${props.groups.length + 1}`,
        lastUpdated: new Date()
    };

    const handleUpdateGroup = (group: MobileAlertGroup) => {
        const r: MobileAlertGroup[] = [...props.groups];
        const index = r.findIndex((obj => obj.id === group.id));
        r[index] = group;
        props.updateMobileAlertGroups(r, null);
    };

    const handleDeleteGroup = (group: MobileAlertGroup) => {
        const r: MobileAlertGroup[] = [...props.groups];
        const index = r.findIndex((obj => obj.id === group.id));
        r.splice(index, 1);
        props.updateMobileAlertGroups(r, group);
    };

    const handleAddGroup = () => {
        const r: MobileAlertGroup[] = [...props.groups];
        r.push(defaultNewGroup);
        props.updateMobileAlertGroups(r, null);
    };

    return (
        <div className="setting recipient-table">
            {props.groups.length > 0 &&
                <>
                    <div className="settings-table-title">{props.title}</div>
                    <div className="settings-table container">
                        <div className="settings-header-row pl-4 pr-4 d-none d-md-flex">
                            <div className="col-5">Name</div>
                            <div className="col-1">Actions</div>
                        </div>
                        {props.groups.map(
                            (r, i) =>
                                <div key={i} className={"row settings-row ml-1 mr-1" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                                    <div className="col-12 col-sm-6 col-md-5 mb-2">
                                        <div className="custom-value-input">
                                            <span className="d-flex d-md-none">Name</span>
                                            <input className="mr-3" type="text" value={r.name}
                                                onChange={
                                                    (event: React.ChangeEvent<HTMLInputElement>) => handleUpdateGroup({ ...r, name: event.target.value, lastUpdated: new Date() })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-1">
                                        <div className="delete-icon-container d-flex d-md-block justify-content-end" >
                                            <div className="d-inline-block" onClick={() => handleDeleteGroup(r)}>
                                                <img className="delete-icon" src={iconDelete} alt="Delete Group" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div >
                </>
            }
            <div className="add-icon-container" onClick={() => handleAddGroup()}>
                <span className="d-inline-block">
                    <img className="add-icon" src={iconAdd} alt="Add Group" />
                </span>Add Group</div>
        </div>
    );
};

export default MobileGroupsTableSetting;
