import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { DashBoard } from "../components/dashboard/dashboard";
import { ApplicationState } from "store/index";
import { DashboardViewModel } from "components/dashboard/dashboardModel";
import { Site } from "store/sites";
import { Alert } from "store/alarm";
import { Bus } from "store/buses";
import { Log } from "store/logs";
import { SchoolSign } from "store/schoolSigns";
import { dashboardCommon } from "components/dashboard/dashboardCommon";
import { AddInsightLink } from "store/addInsight";
import { AdminSettingsState } from "store/adminSettings";
import { useEffect } from "react";

import { actionCreators as adminSettingActionCreators } from "store/adminSettings";
import { actionCreators as userSettingsActionCreators } from "store/userSettings";
import { actionCreators as sitesActionCreators } from "store/sites";
import { actionCreators as addInsightActionCreators } from "store/addInsight";
import { actionCreators as logsActionCreators } from "store/logs";
import { actionCreators as schoolSignActionCreators } from "store/schoolSigns";
import { actionCreators as busActionCreators } from "store/buses";
import { actionCreators as alertActionCreators } from "store/alarm";
import { actionCreators as healthActionCreators, HealthState } from "store/health";

interface Props {
    id: string;
}


function loadData(dispatch: any) {
    // TODO: These should probably be moved out to their respective containers, especially when we move them to functional components.
    // We should also check if the data already exists, and if so (unless a boolean value is set for the SignalR middleware) don't re-reload it.
    dispatch(adminSettingActionCreators.getAdminSettings());
    dispatch(userSettingsActionCreators.getUserSettings());
    dispatch(userSettingsActionCreators.getUserSettings());
    dispatch(sitesActionCreators.requestAllSites());
    dispatch(addInsightActionCreators.requestAllLinks());
    dispatch(logsActionCreators.requestAllLogs());
    dispatch(schoolSignActionCreators.requestAllSchoolSigns());
    dispatch(busActionCreators.requestAllBuses());
    dispatch(alertActionCreators.requestAllAlerts());
    dispatch(healthActionCreators.requestStatus());
}


const DashBoardContainer = (props: Props) => {
    const dispatch = useDispatch();
    const authApiReady = useSelector<ApplicationState, boolean>((state) => state.authentication.authApiReady);

    useEffect(() => {
        if (authApiReady) {
            // Load data initially
            loadData(dispatch);

            // Set up interval to load data every minute
            const intervalId = setInterval(() => {
                loadData(dispatch);
            }, 60000);

            // Clean up interval on unmount
            return () => clearInterval(intervalId);
        }
    }, [authApiReady, dispatch]);


    

    const dataModel = new DashboardViewModel();

    const logs = useSelector<ApplicationState, Log[]>((state) => Object.values(state.logs.logs));
    const alerts = useSelector<ApplicationState, Alert[]>((state) => Object.values(state.alerts.alerts));
    const schoolSigns = useSelector<ApplicationState, SchoolSign[]>((state) => Object.values(state.schoolSigns.schoolSigns));
    const buses = useSelector<ApplicationState, Bus[]>((state) => Object.values(state.buses.buses));
    const sites = useSelector<ApplicationState, Site[]>((state) => Object.values(state.sites.sites));
    const addInsightLinks = useSelector<ApplicationState, AddInsightLink[]>((state) => Object.values(state.addInsight.links));
    const adminSettings = useSelector<ApplicationState, AdminSettingsState>((state) => state.adminSettings);
    const health = useSelector<ApplicationState, HealthState>((state) => state.health);

    dataModel.alertsCritical = dashboardCommon.getCriticalAlerts(adminSettings.adminSettings?.backendSettings, sites).length;
    dataModel.alertsModerate = dashboardCommon.getModerateAlerts(adminSettings.adminSettings?.backendSettings, sites).length;
    dataModel.logsOpen = dashboardCommon.getOpenLogs(logs).length;
    dataModel.logsUnread = dashboardCommon.getUnreadLogs(logs).length;
    dataModel.srmsOpen = dashboardCommon.getOpenSRMs(alerts).length;
    dataModel.mutedDataSources = dashboardCommon.getMutedDataSources(sites);

    dataModel.schoolSignsOffLineValue = dashboardCommon.getOfflineSchoolSigns(schoolSigns).length;
    dataModel.schoolSignDataUpdateMinutesAgo = dashboardCommon.getSchoolSignDataUpdatedTimeAgo(schoolSigns);
    dataModel.schoolSignsDisplayingMessagesPercentValue = dashboardCommon.getSchoolSignsDisplayingMessagePercent(schoolSigns);
    dataModel.schoolSignDataWarningOn = dashboardCommon.getSchoolSignDataWarningOn(schoolSigns);

    dataModel.busDataUpdateMinutesAgo = dashboardCommon.getBusDataUpdateMinutesAgo(buses);
    dataModel.busesRunningEarlyPercentValue = dashboardCommon.getBusRunningEarlyPercent(buses, adminSettings);
    dataModel.busesRunningLatePercentValue = dashboardCommon.getBusRunningLatePercent(buses, adminSettings);
    dataModel.busesDataWarningOn = dashboardCommon.getBusesDataWarningOn(buses);
    dataModel.busStatusEarly = dashboardCommon.getBusRunningEarlyStatus(buses, adminSettings);
    dataModel.busStatusLate = dashboardCommon.getBusRunningLateStatus(buses, adminSettings);

    dataModel.getScatsSitesInFallback = dashboardCommon.getScatsSitesInFallback(alerts).length;
    dataModel.scatsDataUpdateMinutesAgo = dashboardCommon.getScatsDataUpdateMinutesAgo(health.status);
    dataModel.scatsDataWarningOn = dashboardCommon.getScatsDataWarningOn(health.status);
    dataModel.scatsAlarmsST = dashboardCommon.getScatsAlarmST(alerts).length;
    dataModel.scatsAlarmsVOL = dashboardCommon.getScatsAlarmVOL(alerts).length;
    dataModel.scatsAlarmsWD = dashboardCommon.getScatsAlarmWD(alerts).length;
    dataModel.scatsAlarmsFY = dashboardCommon.getScatsAlarmFY(alerts).length;
    dataModel.scatsAlarmsCK = dashboardCommon.getScatsAlarmCK(alerts).length;
    dataModel.scatsAlarmsBO = dashboardCommon.getScatsAlarmBO(alerts).length;
    dataModel.scatsAlarmsDA = dashboardCommon.getScatsAlarmDA(alerts).length;

    dataModel.powerSupplyAlarms = dashboardCommon.getPowerSupplyAlarm(alerts).length;

    dataModel.addInsightsDataUpdateMinutesAgo = dashboardCommon.getAddInsightsDataUpdateMinutesAgo(addInsightLinks);
    dataModel.addInsightsDataWarningOn = dashboardCommon.getAddInsightDataWarningOn(addInsightLinks);
    dataModel.addInsightNumberOfCongestionLinks = dashboardCommon.getAddInsightsCongestionLinks(addInsightLinks).length;

    return (
        <DashBoard
            alertsCritical={dataModel.alertsCritical}
            alertsModerate={dataModel.alertsModerate}
            logsOpen={dataModel.logsOpen}
            logsUnread={dataModel.logsUnread}
            srmsOpen={dataModel.srmsOpen}
            mutedDataSources={dataModel.mutedDataSources}
            schoolSignsOffLineValue={dataModel.schoolSignsOffLineValue}
            schoolSignsDisplayingMessagesPercentValue={dataModel.schoolSignsDisplayingMessagesPercentValue}
            schoolSignsDisplayingMessagesPercentString={dataModel.schoolSignsDisplayingMessagesPercentString}
            schoolSignDataUpdateMinutesAgo={dataModel.schoolSignDataUpdateMinutesAgo}
            schoolSignDataWarningOn={dataModel.schoolSignDataWarningOn}
            busesRunningLatePercentValue={dataModel.busesRunningLatePercentValue}
            busesRunningLatePercentString={dataModel.busesRunningLatePercentString}
            busesRunningEarlyPercentValue={dataModel.busesRunningEarlyPercentValue}
            busesRunningEarlyPercentString={dataModel.busesRunningEarlyPercentString}
            busStatusEarly={dataModel.busStatusEarly}
            busStatusLate={dataModel.busStatusLate}
            busDataUpdateMinutesAgo={dataModel.busDataUpdateMinutesAgo}
            busDataUpdateSecondsAgo={dataModel.busDataUpdateSecondsAgo}
            busesDataWarningOn={dataModel.busesDataWarningOn}
            getScatsSitesInFallback={dataModel.getScatsSitesInFallback}
            scatsAlarmsST={dataModel.scatsAlarmsST}
            scatsAlarmsVOL={dataModel.scatsAlarmsVOL}
            scatsAlarmsDA={dataModel.scatsAlarmsDA}
            scatsAlarmsWD={dataModel.scatsAlarmsWD}
            scatsAlarmsFY={dataModel.scatsAlarmsFY}
            scatsAlarmsCK={dataModel.scatsAlarmsCK}
            scatsAlarmsBO={dataModel.scatsAlarmsBO}
            scatsDataUpdateMinutesAgo={dataModel.scatsDataUpdateMinutesAgo}
            scatsDataWarningOn={dataModel.scatsDataWarningOn}
            addInsightNumberOfCongestionLinks={dataModel.addInsightNumberOfCongestionLinks}
            addInsightsDataUpdateMinutesAgo={dataModel.addInsightsDataUpdateMinutesAgo}
            addInsightsDataWarningOn={dataModel.addInsightsDataWarningOn}
            dashboardWarningThresholdSeconds={dataModel.dashboardWarningThresholdSeconds}
            powerSupplyAlarms={dataModel.powerSupplyAlarms}
        />
    );
};

export default DashBoardContainer;
