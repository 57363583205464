import * as React from "react";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import { CheckBox } from "../../controls/checkBox";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface TextSettingControlProps {
    settingTitle: string;
    settingValue: boolean;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    handleSettingUpdate: any;
    explanation?: string;
}

export class BoolSettingControl extends React.Component<TextSettingControlProps> {
    public render() {
        const {
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            explanation,
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <CheckBox
                    checked={settingValue}
                    onChange={() => handleSettingUpdate(settingPropName, !settingValue)}
                />
            </SettingControlWrapper>
        );
    }
}

export default BoolSettingControl;
