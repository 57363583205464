import * as React from 'react';
import './controls.scss'
import { scaleVolumeSeverity } from '../../../utils/volume';

interface VolumeSettingExampleProps {
    volumeAlarmBaseSeverity: number;
    minVolumeMultiplierThreshold: number;
    scorePerVolumeIncrease: number;
    maximumVolumeScore: number;
}

const scaleVolumeSeverityText = (volumeAlarmBaseSeverity: number, volumeMultiplier: number, minVolumeMultiplierThreshold: number, scorePerVolumeIncrease: number, maximumVolumeScore: number) => {
    return `${volumeAlarmBaseSeverity} + ((${volumeMultiplier} -  ${minVolumeMultiplierThreshold}) x ${scorePerVolumeIncrease}) = ${scaleVolumeSeverity(volumeAlarmBaseSeverity, volumeMultiplier, minVolumeMultiplierThreshold, scorePerVolumeIncrease, maximumVolumeScore)}`;
}

export class VolumeSettingExample extends React.Component<VolumeSettingExampleProps> {
    public render() {
        const { maximumVolumeScore, minVolumeMultiplierThreshold, scorePerVolumeIncrease, volumeAlarmBaseSeverity } = this.props;

        const volumeExampleLowMultiplier = +(minVolumeMultiplierThreshold + 0.2).toFixed(4); //tofixed stops weird rounding issue
        const volumeExampleHighMultiplier = +(minVolumeMultiplierThreshold + 2).toFixed(4); //tofixed stops weird rounding issue

        return (
            <div className="volume-settings-example">
                <div className="sample-title">Examples</div>
                <div>
                    <span className="example-text">The formula for volume severity is as follows:</span>
                    <span className="example-result">Volume Alarm Severity + ((Minimum Threshold Multiplier - Volume Multiplier) x Score Per 100% Increase) = Scaled Volume Alarm Severity
                    </span>
                </div>
                <div>
                    <span className="example-text">A Volume alarm with a multiplier of {volumeExampleLowMultiplier} would calculate as:</span>
                    <span className="example-result">{scaleVolumeSeverityText(volumeAlarmBaseSeverity, volumeExampleLowMultiplier, minVolumeMultiplierThreshold, scorePerVolumeIncrease, maximumVolumeScore)}</span>
                </div>
                <div>
                    <span className="example-text">A Volume alarm with a multiplier of {volumeExampleHighMultiplier} would calculate as:</span>
                    <span className="example-result">{scaleVolumeSeverityText(volumeAlarmBaseSeverity, volumeExampleHighMultiplier, minVolumeMultiplierThreshold, scorePerVolumeIncrease, maximumVolumeScore)}</span>
                </div>
                <div>
                    <span className="example-text"><i>Note: The Scaled Volume Alarm Severity is constrained by the Maximum Volume Severity.</i></span>
                </div>
            </div>
        );
    }
}

export default VolumeSettingExample;