import { Status } from "../../store/health";

export class DashboardViewModel {
    constructor(
        public alertsCritical: number = 0,
        public alertsModerate: number = 0,
        public logsOpen: number = 0,
        public logsUnread: number = 0,
        public srmsOpen: number = 0,
        public mutedDataSources: number = 0,
        public schoolSignsOffLineValue: number = 0,
        public schoolSignsDisplayingMessagesPercentValue: number = 0,
        public schoolSignDataUpdateMinutesAgo: string = "0",
        public schoolSignDataWarningOn: boolean = false,
        public busesRunningLatePercentValue: number = 0,
        public busesRunningEarlyPercentValue: number = 0,
        public busDataUpdateMinutesAgo = "1",
        public busDataUpdateSecondsAgo = 0,
        public busesDataWarningOn = false,
        public busStatusEarly = "1 of 20",
        public busStatusLate = "1 of 10",
        public getScatsSitesInFallback: number = 0,
        public scatsAlarmsST: number = 0,
        public scatsAlarmsVOL: number = 0,
        public scatsAlarmsDA: number = 0,
        public scatsAlarmsWD: number = 0,
        public scatsAlarmsFY: number = 0,
        public scatsAlarmsCK: number = 0,
        public scatsAlarmsBO: number = 0,
        public powerSupplyAlarms: number = 0,
        public scatsDataUpdateMinutesAgo: string = "0",
        public scatsDataWarningOn: boolean = false,
        public addInsightNumberOfCongestionLinks: number = 0,
        public addInsightsDataUpdateMinutesAgo: string = "0",
        public addInsightsDataWarningOn: boolean = false,
        public dashboardWarningThresholdSeconds: number = 0
    ) { }

    get schoolSignsDisplayingMessagesPercentString(): string {
        return `${this.schoolSignsDisplayingMessagesPercentValue}%`;
    }
    get busesRunningLatePercentString(): string {
        return `${this.busesRunningLatePercentValue}%`;
    }
    get busesRunningEarlyPercentString(): string {
        return `${this.busesRunningEarlyPercentValue}%`;
    }
}
