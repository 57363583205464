import * as React from "react";
import "./iconGuide.scss";

export interface IconGuideTableProps {
    title: string;
    iconHeading: string;
    descriptionHeading: string;
}

export class IconGuideTable extends React.Component<IconGuideTableProps> {
    public render() {
        return (
            <div className="table-section">
                <h3 className="mb-3 mt-3">{this.props.title}</h3>
                <table className="table table-hover table-dark table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th className="col-2">{this.props.iconHeading}</th>
                            <th className="col-10">{this.props.descriptionHeading}</th>
                        </tr>
                    </thead>
                    <tbody>{this.props.children}</tbody>
                </table>
            </div>
        );
    }
}

export default IconGuideTable;
