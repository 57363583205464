import moment from "moment";
import * as React from "react";
import ReactMoment from "react-moment";
import { Alert } from "store/alarm";
import { AuthenticationRole, AuthenticationSelectors } from "store/authentication";
import { SiteAlertSourceState } from "store/sites";
import { iconPause, iconPaused } from "../images";

interface MuteRowProps {
  title: string;
  subText?: JSX.Element | JSX.Element[];
  userRole: AuthenticationRole;
  alertSeverityIcon: string;
  alertSourceState?: SiteAlertSourceState;
  mostRecentAlert?: Alert;
  mute: () => void;
  toggleMuteControls: () => void;
}

const getMuteTitleText = (s: SiteAlertSourceState) => {
  const isPermanentlyMuted = moment(s.muteUntil).local().format("YYYY/MM/DD") === moment("9999/12/31", "YYYY/MM/DD").local().format("YYYY/MM/DD");
  return isPermanentlyMuted ? "Permanently Muted" : "Unmutes " + moment(s.muteUntil).fromNow();
};

const MuteRow = (props: MuteRowProps) => {
  const { alertSeverityIcon, alertSourceState, mostRecentAlert, title, userRole, subText, mute, toggleMuteControls } = props;
  const hasPermission = AuthenticationSelectors.checkAuthenticationUsingRole(AuthenticationRole.Operator, userRole);

  return (
    <tr className="blade-table-body">
      <td className="text-center">
        <img src={alertSeverityIcon} width={24} height={24} alt="alert severity icon" />
      </td>
      <td>
        <span>
          <h2>{title}</h2>
          {subText && subText}
        </span>
      </td>
      <td>{mostRecentAlert && <ReactMoment from={mostRecentAlert.triggerTime} ago to={new Date()} />}</td>
      <td className="text-center pause-container">
        {alertSourceState && alertSourceState.muteUntil && moment() < moment(alertSourceState.muteUntil) ? (
          <button type="button" disabled={!hasPermission} className="btn p-0 m-0 border-0 shadow-none" onClick={mute}>
            <img src={iconPaused} title={getMuteTitleText(alertSourceState)} width={24} height={24} alt="Paused icon" />
          </button>
        ) : (
          <button type="button" disabled={!hasPermission} className="btn p-0 m-0 border-0 shadow-none" onClick={toggleMuteControls}>
            <img src={iconPause} width={24} height={24} alt="Pause icon" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default MuteRow;
