import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, AppThunkDispatch, ApplicationState } from '.';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { AlarmCode } from './alarm';
import moment from 'moment';
import { HttpError } from '@microsoft/signalr';
import { push } from 'connected-react-router';
import { Site, saveSites, SiteState } from './sites';

export interface MobileAlertRule {
    id: number;
    amount: number;
    alarmCode: string;
    alarmSourceId: number;
    priorityGroup: number;
    name: string;
    active: boolean;
    lastUpdated: Date;
    groupId: number | null;
}

export interface MobileAlertRecipient {
    id: number;
    mobile: string;
    email: string;
    active: boolean;
    lastUpdated: Date;
    groupId: number | null;
}

export interface MobileAlertGroup {
    id: number;
    name: string;
    lastUpdated: Date;
}


export interface SitePrioritySetting {
    priority: number;
    name: string;
    mediumAlertThreshold: number;
    criticalAlertThreshold: number;
}

// This must match the backend contracts 
export enum SitePriorityGroup {
    AnyPriority = 0,
    MediumAndAbove = 1,
    HighAndAbove = 2,
    Critical = 3
}

export interface AdminSettingsState {
    isLoading: boolean;
    hasLoaded: boolean;
    adminSettings?: AdminSettingsPayload;
}

export interface AdminSettingsPayload {
    portalSettings: PortalSettings;
    backendSettings: BackendSettings;
}

export interface PortalSettings {
    earlyBusColour: string;
    onTimeBusColour: string;
    lateBusColour: string;
    atStopBusColour: string;

    earlyBusScale: number;
    onTimeBusScale: number;
    lateBusScale: number;
    atStopBusScale: number;

    earlyBusThreshold: number;
    lateBusThreshold: number;

    earlyBusToggle: boolean;
    onTimeBusToggle: boolean;
    lateBusToggle: boolean;
    atStopBusToggle: boolean;
    cancelledBusToggle: boolean;

    scaleBasedOnAdherence: boolean;
    showBusDirection: boolean;
    filterBusesToBusyLinks: boolean;
    filterBusesToBusyLinksDistance: number;
    filterBusesToBusyLinksAngle: number;

    mapStartingLongitude: number;
    mapStartingLatitude: number;
    mapAdaptiveZoomRadius: number;
    lastUpdated: Date;
}

export interface BackendSettings {
    alarmCodes: AlarmCode[];
    criticalAlertThreshold: number;
    mediumAlertThreshold: number;
    lastUpdated: Date;
    minVolumeCarThreshold: number;
    minVolumeMultiplierThreshold: number;
    scorePerVolumeIncrease: number;
    maximumVolumeScore: number;
    minTimeForMobileAlertToTrigger: number;
    minDayTimeHoursInMinutes: number;
    maxDayTimeHoursInMinutes: number;
    sitePrioritySettings: SitePrioritySetting[];
    mobileAlertRules: MobileAlertRule[];
    mobileAlertRecipients: MobileAlertRecipient[];
    mobileAlertGroups: MobileAlertGroup[];
    isSysDownNotificationEnabled: boolean;
    sysDownGroupId?: number;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getAdminSettings: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { adminSettings: { isLoading }, authentication: { accessToken } } = getState();
        if (isLoading || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/AdminSettings`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data: AdminSettingsPayload) => {
                    dispatch({ type: 'RECEIVE_ADMIN_SETTINGS', adminSettings: data });
                    ClearBackendErrorToast(ToastErrorTypes.ADMIN_SETTINGS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_ADMIN_SETTINGS' });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.ADMIN_SETTINGS, "Error retrieving Admin Settings");
                });
            dispatch({ type: 'REQUEST_ADMIN_SETTINGS' });
        });
    },
    updateAdminSettings: (updatedAdminSettings: AdminSettingsPayload, updatedSites: Site[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { adminSettings: { isLoading, adminSettings }, authentication: { accessToken }, sites } = getState();
        if (isLoading || !accessToken || adminSettings === undefined) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Administrator, () => {
            const now = moment().toDate();
            const immutableAlarmCodeUpdate = updatedAdminSettings.backendSettings.alarmCodes.map(e => ({ ...e, lastUpdated: now }));
            const sitePrioritySettingsUpdate = updatedAdminSettings.backendSettings.sitePrioritySettings.map(e => ({ ...e, lastUpdated: now }));
            saveAdminSettings({
                //update last updated time
                ...updatedAdminSettings,
                portalSettings: {
                    ...updatedAdminSettings.portalSettings,
                    lastUpdated: now
                },
                backendSettings: {
                    ...updatedAdminSettings.backendSettings,
                    lastUpdated: now,
                    alarmCodes: immutableAlarmCodeUpdate,
                    sitePrioritySettings: sitePrioritySettingsUpdate
                }
            }, accessToken, dispatch, updatedSites, sites)
                .catch(e => dispatch({ type: 'ERROR_RECEIVING_ADMIN_SETTINGS' }));
        });
    }
};

const saveAdminSettings: (adminSettings: AdminSettingsPayload, jwtIdToken: string, dispatch: AppThunkDispatch, updatedSites: Site[], siteState: SiteState) => Promise<AdminSettingsPayload> =
    (adminSettings: AdminSettingsPayload, jwtIdToken: string, dispatch: AppThunkDispatch, updatedSites: Site[], siteState: SiteState) => new Promise((resolve, reject) => {
        fetch(`/api/AdminSettings`, {
            method: 'PUT',
            body: JSON.stringify(adminSettings),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtIdToken}`
            })
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json() as Promise<AdminSettingsPayload>;
                } else {
                    throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                }
            })
            .then(data => {
                dispatch({ type: 'RECEIVE_ADMIN_SETTINGS', adminSettings: data });
                ClearBackendErrorToast(ToastErrorTypes.ADMIN_SETTINGS_SAVE);
                saveSites(jwtIdToken, siteState, updatedSites)
                    .then(updatedSites => dispatch({ type: 'UPSERT_SITES', sites: updatedSites }));
                dispatch(push("/"));
            })
            .catch(error => {
                BackendErrorToast(ToastErrorTypes.ADMIN_SETTINGS_SAVE, "Error saving Admin Settings");
                console.error(error);
                reject(error);
            });
        dispatch({ type: 'SAVING_ADMIN_SETTINGS' });
    });

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface ReceiveSettingsAction extends Action {
    type: 'RECEIVE_ADMIN_SETTINGS';
    adminSettings: AdminSettingsPayload;
}

interface RequestSettingsAction extends Action {
    type: 'REQUEST_ADMIN_SETTINGS';
}
interface SavingSettingsAction extends Action {
    type: 'SAVING_ADMIN_SETTINGS';
}
interface ErrorAdminSettingsAction extends Action {
    type: 'ERROR_RECEIVING_ADMIN_SETTINGS';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReceiveSettingsAction | RequestSettingsAction | ErrorAdminSettingsAction | SavingSettingsAction;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: AdminSettingsState = {
    isLoading: false, hasLoaded: false
};

export const reducer: Reducer<AdminSettingsState, AnyAction> = (state: AdminSettingsState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_ADMIN_SETTINGS': {
            //new settings
            let newBackendSettings: BackendSettings;
            let newPortalSettings: PortalSettings;
            const updatedBackendSettings = action.adminSettings.backendSettings;
            const updatedPortalSettings = action.adminSettings.portalSettings;

            if (state.adminSettings) {
                const currentBackendSettings = { ...state.adminSettings.backendSettings };
                const currentPortalSettings = { ...state.adminSettings.portalSettings };
                //Update alarms
                const updatedAlarms = updatedBackendSettings.alarmCodes.map(e => {
                    const currentAlarm = currentBackendSettings.alarmCodes.filter(x => x.code === e.code && x.alarmSourceId === e.alarmSourceId)[0];
                    //new alarm that doesn't currently exist
                    if (currentAlarm == null) {
                        return ({ ...e });
                    }
                    //is newer
                    else if (e.lastUpdated > currentAlarm.lastUpdated) {
                        return ({ ...e });
                    }
                    //otherwise use current alarm value
                    else {
                        return { ...currentAlarm };
                    }
                });

                //is newer
                if (updatedBackendSettings.lastUpdated > currentBackendSettings.lastUpdated) {
                    newBackendSettings = { ...currentBackendSettings, ...updatedBackendSettings, alarmCodes: updatedAlarms };
                }
                else {
                    newBackendSettings = { ...currentBackendSettings, alarmCodes: updatedAlarms };
                }

                //is newer
                if (updatedPortalSettings.lastUpdated > currentPortalSettings.lastUpdated) {
                    newPortalSettings = { ...currentPortalSettings, ...updatedPortalSettings };
                }
                else {
                    newPortalSettings = { ...currentPortalSettings };
                }
            }
            else {
                newBackendSettings = updatedBackendSettings;
                newPortalSettings = updatedPortalSettings;
            }

            return <AdminSettingsState>{
                ...state,
                hasLoaded: true,
                isLoading: false,
                adminSettings:
                {
                    backendSettings: newBackendSettings,
                    portalSettings: newPortalSettings
                }
            };
        }
        case 'REQUEST_ADMIN_SETTINGS':
            return <AdminSettingsState>{
                ...state,
                hasLoaded: false,
                isLoading: true,
            };
        case 'SAVING_ADMIN_SETTINGS':
            return <AdminSettingsState>{
                ...state,
                hasLoaded: false,
                isLoading: true,
            };
        case 'ERROR_RECEIVING_ADMIN_SETTINGS':
            return <AdminSettingsState>{
                ...state,
                hasLoaded: true,
                isLoading: false
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};