import * as React from "react";
import { Link } from "react-router-dom";
import "./navMenu.scss";
import {
    iconUser,
    iconScatsLogo,
    iconAddInsightsLogo,
    iconBusLogo,
    iconLogout,
    iconQuestionMark,
    iconHome,
    iconSettings,
    iconSchoolSignsLogo,
    iconKmz,
    iconBicycle,
    iconDashboard,
    iconPump
} from "./images";
import { Toggle } from "./toggle/toggle";

export interface NavMenuProps {
    logout: (() => void) | undefined;
    toggleSchoolSigns: (() => void) | undefined;
    toggleKmz: (() => void) | undefined;
    toggleAddInsight: (() => void) | undefined;
    toggleSCATS: (() => void) | undefined;
    toggleCycleCounters: (() => void) | undefined;
    toggleBusNetwork: (() => void) | undefined;
    togglePumps: (() => void) | undefined;
    userName: string | null;
    roleName: string | null;
    pumpsEnabled?: boolean;
    scatsEnabled?: boolean;
    addInsightEnabled?: boolean;
    cycleCountersEnabled?: boolean;
    busNetworkEnabled?: boolean;
    schoolSignsEnabled?: boolean;
    kmzEnabled?: boolean;
    windowPathname: string;
    canViewAdminPanel: boolean;
}

export class NavMenu extends React.Component<NavMenuProps> {
    public render() {
        const isOnHomePage = this.props.windowPathname === "/";

        const homePageLink =
            <Link className="navbar-btn" to={"/"}>
                <span>
                    <img src={iconHome} width={28} height={28} alt="Home icon" />
                </span>
                Home
            </Link>;

        const dashboardLink =
            <Link className="navbar-btn" to={"/dashboard"}>
                <span>
                    <img src={iconDashboard} width={28} height={28} alt="Dashboard icon" />
                </span>
                Dashboard
            </Link>;

        const iconGuideLink =
            <Link className="navbar-btn" to={"/icon-guide"}>
                <span>
                    <img src={iconQuestionMark} width={28} height={28} alt="Guide icon" />
                </span>
                Icon Guide
            </Link>;

        const adminLink =
            <Link className="navbar-btn" to={"/admin"}>
                <span>
                    <img src={iconSettings} width={28} height={28} alt="Settings icon" />
                </span>
                Settings
            </Link>;

        return (
            <nav className="navbar navbar-dark">
                <div className="mr-auto">
                    <Link className="navbar-brand d-none d-md-inline-block " to={"/"}>
                        {"Transport Data\nAnalytics Platform"}
                    </Link>
                </div>
                {
                    isOnHomePage &&
                    <>
                        <Toggle className="navbar-btn" active={this.props.pumpsEnabled} toggle={this.props.togglePumps}>
                            <span>
                                <img src={iconPump} height={28} alt="Pumps logo" />
                            </span>
                            Pumps
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.kmzEnabled} toggle={this.props.toggleKmz}>
                            <span>
                                <img src={iconKmz} height={28} alt="KMZ logo" />
                            </span>
                            KMZ
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.cycleCountersEnabled} toggle={this.props.toggleCycleCounters}>
                            <span>
                                <img src={iconBicycle} width={28} alt="Cycle Counter logo" />
                            </span>
                            Bicycles
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.schoolSignsEnabled} toggle={this.props.toggleSchoolSigns}>
                            <span>
                                <img src={iconSchoolSignsLogo} height={28} alt="School Signs logo" />
                            </span>
                            School Signs
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.scatsEnabled} toggle={this.props.toggleSCATS}>
                            <span>
                                <img src={iconScatsLogo} height={28} alt="SCATS logo" />
                            </span>
                            SCATS
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.addInsightEnabled} toggle={this.props.toggleAddInsight}>
                            <span>
                                <img src={iconAddInsightsLogo} width={28} height={28} alt="AddInsights logo" />
                            </span>
                            AddInsight
                        </Toggle>
                        <Toggle className="navbar-btn" active={this.props.busNetworkEnabled} toggle={this.props.toggleBusNetwork}>
                            <span>
                                <img src={iconBusLogo} width={28} height={28} alt="Bus logo" />
                            </span>
                            Bus Network
                        </Toggle>
                    </>
                }
                {!isOnHomePage && homePageLink}
                {iconGuideLink}
                {dashboardLink}
                {this.props.canViewAdminPanel && adminLink}
                {this.props.logout && (
                    <button type="button" className="navbar-btn navbar-btn-override" tabIndex={3} onClick={this.props.logout}>
                        <span>
                            <img src={iconLogout} width={28} height={28} alt="Logout icon" />
                        </span>
                        Logout
                    </button>
                )}
                {this.props.userName && (
                    <div className="navbar-user navbar-element-override px-3">
                        <span className="navbar-user-details mr-3">
                            <span className="navbar-username">{this.props.userName}</span>
                            <span className="navbar-usertitle">{this.props.roleName}</span>
                        </span>
                        <img height={35} src={iconUser} alt="User icon" />
                    </div>
                )}
            </nav>
        );
    }
}
