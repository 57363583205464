import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";

const GoogleTheme: L.gridLayer.GoogleMutantStyle[] = [
    {
        featureType: "all",
        elementType: "geometry",
        stylers: [
            {
                saturation: 0
            },
            {
                lightness: 0
            },
            {
                visibility: "on"
            },
            {
                gamma: 1
            }
        ]
    },
    {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: 36
            },
            {
                color: "#e0e9f2"
            },
            {
                lightness: 40
            }
        ]
    },
    {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "off"
            },
            {
                color: "#000000"
            },
            {
                lightness: 0
            }
        ]
    },
    {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                lightness: 20
            },
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                lightness: 17
            },
            {
                weight: 1.2
            },
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#454e57"
            },
            {
                lightness: 0
            },
            {
                visibility: "on"
            },
            {
                weight: 1.0
            },
            {
                gamma: 1
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#515a63"
            },
            {
                lightness: 0
            }
        ]
    },
    {
        featureType: "poi.attraction",
        elementType: "geometry",
        stylers: [
            {
                visibility: "on"
            }
        ]
    },
    {
        featureType: "poi.attraction",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi.attraction",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi.business",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "on"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                lightness: 0
            },
            {
                color: "#384147"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#000000"
            },
            {
                lightness: 0
            },
            {
                weight: 1.2
            },
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#9c2020"
            },
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                lightness: 0
            },
            {
                visibility: "on"
            },
            {
                color: "#384147"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#384147"
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#373f48"
            },
            {
                lightness: 0
            }
        ]
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
            {
                color: "#6a737c"
            },
            {
                lightness: 0
            },
            {
                visibility: "on"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#1e2227"
            },
            {
                lightness: 0
            }
        ]
    }
];

export default GoogleTheme;
