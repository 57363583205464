/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { useDispatch } from "react-redux";
import L, { DivIcon, LatLng } from "leaflet";
import { Marker } from "react-leaflet";
import "./pumpMarker.scss";
import { PumpDevice } from "store/Pumps/pumpsDatatypes";
import { actionCreators } from "store/Pumps/pumpsDataApi";
import { iconPump } from "../../images";
import ReactDOMServer from "react-dom/server";

// marker props

export interface PumpDeviceMarkerProps {
    device: PumpDevice;
    zoom: number;
    isSelected: boolean;
}

// helper functions

class DeviceMarkerFunctions {
    scaleFunc = (zoomLevel: number): number => {
        const iconSize = 50;
        return Math.floor(iconSize * (1 / Math.max(1, 17 - zoomLevel)));
    };

    getIconClass = (device: PumpDevice, isSelected: boolean): string => {
        let className = "pump-icon";

        if (isSelected) className += " selected-site";

        if (device.fault === 0) {
            className += " ok";
        } else {
            className += " fault";
        }

        return className;
    };

    iconHtml = (className?: string) => <img src={iconPump} alt="Pump" className={className}/>

    getIcon = (device: PumpDevice, zoom: number, isSelected: boolean): DivIcon => {
        const size = deviceMarkerFunctions.scaleFunc(zoom);
        return L.divIcon({
            html: ReactDOMServer.renderToStaticMarkup(this.iconHtml(this.getIconClass(device, isSelected))),
            className: deviceMarkerFunctions.getIconClass(device, isSelected),
            iconSize: [size, size],
            iconAnchor: [size / 2, size / 2],
        });
    };
}
export const deviceMarkerFunctions = new DeviceMarkerFunctions();

export const PumpDeviceMarker = (props: PumpDeviceMarkerProps) => {
    const dispatch = useDispatch();

    return (
        <Marker
            position={new LatLng(+props.device.lat, +props.device.lng)}
            icon={deviceMarkerFunctions.getIcon(props.device, props.zoom, props.isSelected)}
            onclick={(e) => dispatch(actionCreators.selectPumpDevice(props.device.pumpId, false))}
        />
    );
};

export default PumpDeviceMarker;
