import {
    iconPinAlertHigh, iconPinAlertMedium, iconPinAlertNormal,
    iconSchoolSignOff, iconSchoolSignOn, iconSchoolSignStatic,
    iconSchoolSignIdle,
    iconSchoolSignNotTalking,
    iconBicycle
} from "../../images";

import { baseIconSize } from '../../../constants/mapConstants';

export interface TDAPIconOptions {
    iconUrl: string;
    iconRetinaUrl: string;
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor?: [number, number];
    tooltipAnchor?: [number, number];
    className?: string;
    html?: string;
}
export interface DivIconOptions {
    html: string;
    scale: number;
    color: string;
    iconSize: [number, number];
    iconAnchor: [number, number];
    popupAnchor?: [number, number];
    tooltipAnchor?: [number, number];
    className?: string;
    shouldScaleByTime?: boolean;
}

const defaultIcon: TDAPIconOptions = {
    iconUrl: iconPinAlertNormal,
    iconRetinaUrl: iconPinAlertNormal,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize]
};

const lowAlertIcon: TDAPIconOptions = {
    iconUrl: iconPinAlertNormal,
    iconRetinaUrl: iconPinAlertNormal,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize]
};

const mediumAlertIcon: TDAPIconOptions = {
    iconUrl: iconPinAlertMedium,
    iconRetinaUrl: iconPinAlertMedium,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize]
};

const highAlertIcon: TDAPIconOptions = {
    iconUrl: iconPinAlertHigh,
    iconRetinaUrl: iconPinAlertHigh,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize]
};

const defaultBusIcon: DivIconOptions = {
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    html: "",
    color: "#000",
    scale: 1,
    shouldScaleByTime: true
};

const schoolSignOffIcon: TDAPIconOptions = {
    iconUrl: iconSchoolSignOff,
    iconRetinaUrl: iconSchoolSignOff,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "school-sign-icon school-sign-off"
};

const schoolSignOnIcon: TDAPIconOptions = {
    iconUrl: iconSchoolSignOn,
    iconRetinaUrl: iconSchoolSignOn,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "school-sign-icon school-sign-on"
};

const schoolSignStaticIcon: TDAPIconOptions = {
    iconUrl: iconSchoolSignStatic,
    iconRetinaUrl: iconSchoolSignStatic,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "school-sign-icon school-sign-static"
};

const schoolSignIdleIcon: TDAPIconOptions = {
    iconUrl: iconSchoolSignIdle,
    iconRetinaUrl: iconSchoolSignIdle,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "school-sign-icon school-sign-idle"
};

const schoolSignNotTalkingIcon: TDAPIconOptions = {
    iconUrl: iconSchoolSignNotTalking,
    iconRetinaUrl: iconSchoolSignNotTalking,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "school-sign-icon school-sign-not-talking"
};


const cycleCounterDevice: TDAPIconOptions = {
    iconUrl: iconBicycle,
    iconRetinaUrl: iconBicycle,
    iconAnchor: [20, 20],
    popupAnchor: [0, 0],
    iconSize: [baseIconSize, baseIconSize],
    className: "cycle-counter-device-icon"
};

export {
    defaultIcon, lowAlertIcon, mediumAlertIcon, highAlertIcon,
    defaultBusIcon, schoolSignOffIcon, schoolSignOnIcon,
    schoolSignStaticIcon, schoolSignIdleIcon, schoolSignNotTalkingIcon, cycleCounterDevice
};
