import moment from "moment";
import * as React from "react";
import Datetime from "react-datetime";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface TimeSettingControlProps {
    settingTitle: string;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    settingValue: number;
    handleSettingUpdate: any;
    explanation?: string;
}

export class TimeSettingControl extends React.Component<TimeSettingControlProps> {
    constructor(props: TimeSettingControlProps) {
        super(props);
        this.state = { showExplanation: true };
    }
    public render() {
        const {
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            explanation,
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <div className="time-setting-input">
                    <Datetime
                        open
                        value={moment().startOf("day").add(settingValue, "minutes")}
                        className="time-setting"
                        onChange={(e) =>
                            handleSettingUpdate(
                                settingPropName,
                                moment(e).diff(moment().startOf("day"), "minutes")
                            )
                        }
                        input={false}
                        dateFormat={false}
                        viewMode="time"
                    />
                </div>
            </SettingControlWrapper>
        );
    }
}

export default TimeSettingControl;
