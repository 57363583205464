import moment from "moment";

export function metersPerSecondToKilometersPerHour(metersPerSecond: number, decimals = 1) {
    if (!isFinite(metersPerSecond)) {
        return "";
    }
    const kilometersPerHour = metersPerSecond * 3.6;
    return kilometersPerHour.toFixed(decimals);
}

export function adherenceMillisecondsToDisplayTime(milliseconds: number) {
    if (!Number.isInteger(milliseconds)) {
        return "";
    }

    const seconds = Math.round(Math.abs(milliseconds) / 1000);
    if (seconds === 0) {
        return "0 seconds (on-time)";
    }

    const prefix = milliseconds < 0 ? "-" : "+";
    const suffix = milliseconds < 0 ? " (early)" : " (late)";

    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = seconds - minutes * 60;

    return prefix + (minutes + secondsRemainder / 60).toFixed(1) + " minutes" + suffix;
}

export function getHeadwayPercent(headwaySeconds: number, scheduledHeadwaySeconds: number) {
    const headwayDifference = headwaySeconds - scheduledHeadwaySeconds;
    const headwayDifferenceAsPercent = parseFloat(
        ((headwayDifference / scheduledHeadwaySeconds) * 100).toFixed(1)
    );
    return headwayDifferenceAsPercent;
}

export function getHeadwayStatus(headwayDifferenceAsPercent: number) {
    if (headwayDifferenceAsPercent >= 25) {
        return "Gapped";
    } else if (headwayDifferenceAsPercent <= -25) {
        return "Bunched";
    } else {
        return "Expected";
    }
}

export function getPercentChangedText(headwayPercent: number) {
    const polarityLabel = headwayPercent < 0 ? "less than expected" : "greater than expected";
    return " (" + Math.abs(headwayPercent) + "% " + polarityLabel + ")";
}

export function getLastGpsPing(time: number) {
    const secondsPassed = Math.ceil(Date.now() / 1000 - time);
    let formattedTime = "";
    if (secondsPassed < 60) {
        formattedTime = secondsPassed + " seconds";
    } else {
        formattedTime = moment.duration(secondsPassed, "seconds").humanize(false);
    }
    return formattedTime;
}
