import "whatwg-fetch";
import L, { LatLng } from "leaflet";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import { HttpError } from "@microsoft/signalr";
import { maxMobileWidth } from "../constants/mobileConstants";
import { CloseSidePanelsAction, FlyToAction, SetZoomAction, actionCreators as MapActionCreators } from "./map";
import { TDAPIconOptions } from "components/map/icons";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CycleCounterDevicesState {
    cycleCounterDevices: {
        [key: string]: CycleCounterDevice;
    };
    initialLoadStarted: boolean;
    selectedCycleCounterDeviceId?: string;
    sidebarExpanded: boolean;
    cycleCounterDeviceStatusOperationLoading: boolean;
}

export interface CycleCounterDevice {
    id: string;
    state: string;
    lastHeartBeat: string;
    isLowBattery: boolean;
    latitude: string;
    longitude: string;
    icon?: TDAPIconOptions;
}

export interface CycleCounterDeviceMarker {
    id: string;
    schoolName: string;
    signName: string;
    status: string;
    lastSeen: Date;
    position: LatLng;
    icon?: TDAPIconOptions;
    clickCallback: () => void;
}

export enum DeviceState {
    Unknown = 0,
    LastSeen7DaysAgo = 1,
    Online = 2,
    Offline = 3,
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestCycleCounterDevicesAction
    | ReceiveCycleCounterDevicesAction
    | ErrorReceivingCycleCounterDevicesAction
    | OpenCycleCounterDevicePanelAction
    | CloseCycleCounterDevicePanelAction
    | UpdateCycleCounterDeviceStatusAction
    | CycleCounterDeviceStatusOperationLoadingAction
    | ErrorUpdatingCycleCounterDeviceStatusAction
    | UpdateCycleCounterFiltersAction
    | CloseSidePanelsAction;

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class CycleCounterDeviceSelectors {
    static getCycleCounterDeviceById(state: CycleCounterDevicesState, cycleCounterDeviceId: string): CycleCounterDevice {
        return state.cycleCounterDevices[cycleCounterDeviceId];
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface UpdateCycleCounterFiltersAction extends Action {
    type: "UPDATE_CYCLE_COUNTER_FILTER";
}

interface RequestCycleCounterDevicesAction extends Action {
    type: "REQUEST_CYCLE_COUNTER_DEVICES";
}

export interface ReceiveCycleCounterDevicesAction extends Action {
    type: "RECEIVE_CYCLE_COUNTER_DEVICES";
    cycleCounterDevices: CycleCounterDevice[];
}

interface ErrorReceivingCycleCounterDevicesAction extends Action {
    type: "ERROR_RECEIVING_CYCLE_COUNTER_DEVICES";
}

interface OpenCycleCounterDevicePanelAction extends Action {
    type: "OPEN_CYCLE_COUNTER_PANEL";
    // The Device sign id that is selected
    cycleCounterDeviceId: string;
}

export interface CloseCycleCounterDevicePanelAction extends Action {
    type: "CLOSE_CYCLE_COUNTER_PANEL";
}

interface UpdateCycleCounterDeviceStatusAction extends Action {
    type: "UPDATE_CYCLE_COUNTER_STATUS";
    DeviceName: string;
}

interface ErrorUpdatingCycleCounterDeviceStatusAction extends Action {
    type: "ERROR_UPDATING_CYCLE_COUNTER_STATUS";
}

interface CycleCounterDeviceStatusOperationLoadingAction extends Action {
    type: "CYCLE_COUNTER_STATUS_OPERATION_LOADING";
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    selectCycleCounter:
        (selectedCycleCounterDeviceId: string, zoom: boolean): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    cycleCounterDevices: { cycleCounterDevices },
                } = getState();

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(MapActionCreators.closeAllSidePanelsAction());
                    dispatch(<OpenCycleCounterDevicePanelAction>{ type: "OPEN_CYCLE_COUNTER_PANEL", cycleCounterDeviceId: selectedCycleCounterDeviceId });

                    if (zoom) {
                        const selectedDevice = cycleCounterDevices[selectedCycleCounterDeviceId as string];
                        if (selectedDevice) {
                            dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: 15 });
                            dispatch(<FlyToAction>{ type: "FLY_TO", latLng: new LatLng(+selectedDevice.latitude, +selectedDevice.longitude) });
                        }
                    }
                });
            },

    requestAllCycleCounterDevices: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            cycleCounterDevices: { initialLoadStarted },
            authentication: { accessToken },
        } = getState();

        if (initialLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/cyclecounter/devices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<CycleCounterDevice[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVE_CYCLE_COUNTER_DEVICES", cycleCounterDevices: data });
                    ClearBackendErrorToast(ToastErrorTypes.CYCLE_COUNTER_DEVICES);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_CYCLE_COUNTER_DEVICES" });

                    console.error(error);

                    BackendErrorToast(ToastErrorTypes.CYCLE_COUNTER_DEVICES, "Error retrieving Device signs");
                });

            dispatch({ type: "REQUEST_CYCLE_COUNTER_DEVICES" });
        });
    },
    closeCycleCounterPanel: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({ type: "CLOSE_CYCLE_COUNTER_PANEL" });
        });
    },
    receiveUpdatedCycleCounterDevices:
        (incomingCycleCounterDevices: CycleCounterDevice[]): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch({ type: "RECEIVE_CYCLE_COUNTER_DEVICES", cycleCounterDevices: incomingCycleCounterDevices });
                });
            },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CycleCounterDevicesState = {
    cycleCounterDevices: {},
    initialLoadStarted: false,
    sidebarExpanded: window.innerWidth >= maxMobileWidth,
    selectedCycleCounterDeviceId: undefined,
    cycleCounterDeviceStatusOperationLoading: false,
};

export const reducer: Reducer<CycleCounterDevicesState, AnyAction> = (state: CycleCounterDevicesState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_CYCLE_COUNTER_DEVICES":
            return <CycleCounterDevicesState>{
                ...state,
                initialLoadStarted: true,
            };

        case "RECEIVE_CYCLE_COUNTER_DEVICES": {
            const incomingCycleCounterDevices = action.cycleCounterDevices.reduce<Record<string, CycleCounterDevice>>(function (cycleCounterDeviceMap, current) {
                cycleCounterDeviceMap[current.id] = current;
                return cycleCounterDeviceMap;
            }, {});

            return <CycleCounterDevicesState>{
                ...state,
                initialLoadStarted: false,
                cycleCounterDevices: incomingCycleCounterDevices,
            };
        }

        case "ERROR_RECEIVING_CYCLE_COUNTER_DEVICES":
            return <CycleCounterDevicesState>{
                ...state,
                initialLoadStarted: false,
            };

        case "OPEN_CYCLE_COUNTER_PANEL":
            return <CycleCounterDevicesState>{
                ...state,
                selectedCycleCounterDeviceId: action.cycleCounterDeviceId,
            };

        case "CLOSE_CYCLE_COUNTER_PANEL":
            return <CycleCounterDevicesState>{
                ...state,
                selectedCycleCounterDeviceId: undefined,
            };

        case "UPDATE_CYCLE_COUNTER_STATUS":
            return <CycleCounterDevicesState>{
                ...state,
                CycleCounterDeviceStatusOperationLoading: false,
            };

        case "ERROR_UPDATING_CYCLE_COUNTER_STATUS":
            return <CycleCounterDevicesState>{
                ...state,
                CycleCounterDeviceStatusOperationLoading: false,
            };

        case "CYCLE_COUNTER_STATUS_OPERATION_LOADING":
            return <CycleCounterDevicesState>{
                ...state,
                CycleCounterDeviceStatusOperationLoading: true,
            };

        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
