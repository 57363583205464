import * as React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { dashboardCommon } from "../dashboard/dashboardCommon";
import "./progressCircle.scss";

class ProgressCircleProps {
  constructor(public color: string = "red", public value: number = 0, public displayText: string = "0%") {}
}

export const ProgressCircle: React.FC<ProgressCircleProps> = (props) => {
  return (
    <>
      {/* https://github.com/kevinsqi/react-circular-progressbar */}
      <CircularProgressbar
        value={props.value}
        text={props.displayText}
        styles={buildStyles({
          //   // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.62,

          //   // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          //   strokeLinecap: "butt",

          //   // Text size
          textSize: "2em",

          //   // How long animation takes to go from one percentage to another, in seconds
          //   pathTransitionDuration: 0.5,

          //   // Can specify path transition in more detail, or remove it entirely
          //   // pathTransition: 'none',

          //   // Colors
          pathColor: dashboardCommon.getColorHex(props.color),
          //   textColor: "#f88",
          // eslint-disable-next-line react/prop-types
          trailColor: dashboardCommon.getColorHex("steel-light"),
          // backgroundColor: getColorHex("steel"),
        })}
      />
    </>
  );
};
