import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { SiteState, Site, SiteSelectors } from "store/sites";
import { AdminSettingsPayload } from "store/adminSettings";
import LogViewer from "../components/logviewer/logViewer";
import { LogState, Log, LogSelectors, actionCreators as LogActionCreators } from "store/logs";
import { CommentState, CommentSelectors, actionCreators as CommentActionCreators} from "store/comments";
import { AlertsSelectors, AlertState } from "store/alarm";
import { AuthState } from "store/authentication";

const LogViewerContainer: React.FC = () => {
    const dispatch = useDispatch();
    const adminSettings = useSelector<ApplicationState, AdminSettingsPayload | undefined>(state => state.adminSettings.adminSettings);
    const commentState = useSelector<ApplicationState, CommentState>(state => state.comments);
    const authState = useSelector<ApplicationState, AuthState>(state => state.authentication);
    const siteState = useSelector<ApplicationState, SiteState>(state => state.sites);
    const alertState = useSelector<ApplicationState, AlertState>(state => state.alerts);
    const logState = useSelector<ApplicationState, LogState>(state => state.logs);

    const [selectedSite, setSelectedSite] = React.useState<Site | null>()
    const [currentLog, setCurrentLog] = React.useState<Log | null>()

    React.useEffect(() => {
        setSelectedSite(siteState.selectedSiteId ? SiteSelectors.GetSiteByIdentifier(siteState, siteState.selectedSiteId) : null);
    }, [siteState, siteState.selectedSiteId]);

    React.useEffect(() => {
        setCurrentLog(logState.selectedLogId ? LogSelectors.GetLogByIdentifier(logState, logState.selectedLogId) : null);
    }, [logState, logState.selectedLogId]);


    if (selectedSite && currentLog && currentLog !== null && adminSettings) {
        return (
            <LogViewer
                isLoading={logState.logOperationLoading}
                openCommentEditor={(commentId: number, log: Log) => dispatch(CommentActionCreators.openCommentEditor(commentId, log))}
                saveLog={(log?: Log, close: boolean = true) => dispatch(LogActionCreators.saveLog(log, close))}
                setSelectedLogModified={() => dispatch(LogActionCreators.setSelectedLogModifiedTrue())}
                closeLogViewer={() => dispatch(LogActionCreators.closeLogViewer())}
                rejectLog={() => dispatch(LogActionCreators.rejectLog())}
                reopenLog={() => dispatch(LogActionCreators.reopenLog())}
                currentLog={currentLog}
                selectedLogModified={logState.selectedLogModified}
                backendSettings={adminSettings.backendSettings}
                selectedSite={selectedSite}
                userRole={authState.authRole}
                comments={CommentSelectors.GetCommentsByLogId(
                    commentState,
                    currentLog.id
                )}
                currentAlerts={
                    currentLog.id === -1
                        ? AlertsSelectors.GetAlertsBySiteId(
                            alertState.alerts,
                            selectedSite.id
                        )
                        : currentLog.alarms
                }
            />
        );
    }
    return null;
}

export default LogViewerContainer;