import * as React from "react";
import "./spinner.scss";

interface SpinnerProps {
    dim?: boolean;
}

//open source pure css spinners
//https://loading.io/css/
const Spinner: React.FC<SpinnerProps> = (props) => {
    return (
        <div className={"spinner" + (props.dim ? " dim" : "")}>
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    );
};

export default Spinner;
