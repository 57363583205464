import React, { useEffect, useRef } from "react";
import "./sidebarItem.scss";

export interface SiteSidebarItemProps {
    isSelected: boolean;
    onClick: () => void;
    className?: string;
}

const SidebarItem: React.FC<SiteSidebarItemProps> = (props) => {

    const ref = useRef<HTMLDivElement>(null);

    //scrolls the sidebar item into view
    useEffect(() => {
        if (props.isSelected === true) {
            const div = ref.current;
            if (div) {
                const bounding = div.getBoundingClientRect();

                //checks if the site is in view (even partially)
                if (!(bounding.top >= -div.offsetHeight
                    && bounding.left >= -div.offsetWidth
                    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + div.offsetWidth
                    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + div.offsetHeight)) {

                    //scroll the div into view
                    div.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center'
                    });
                }
            }
        }
    }, [props.isSelected, ref]);

    return (
        <div
            onClick={props.onClick}
            className={(props.className ? props.className : "") + " nav-alert" + (props.isSelected ? " active" : "")}
            ref={ref}
        >
            {props.children}
        </div>
    );
};

export default SidebarItem;