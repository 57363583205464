import * as React from "react";
import "./controls.scss";
import { AlarmCode } from "store/alarm";
import { iconPinAlertNormal, iconPinAlertMedium, iconPinAlertHigh } from "../../images";

interface AlarmTableSettingControlProps {
    title?: string;
    alarmCodes: AlarmCode[];
    mediumAlertThreshold: number;
    criticalAlertThreshold: number;
    filterBySource?: number[];
    updateAlarmCodes: (alarmCodes: AlarmCode[]) => void;
}

export class AlarmTableSetting extends React.Component<AlarmTableSettingControlProps> {
    public render() {
        const {
            title,
            filterBySource,
            updateAlarmCodes,
            alarmCodes
        } = this.props;

        const upsertAlarmCode = (alarmCode: AlarmCode) => {
            return alarmCodes.map(el => {
                if (el.code === alarmCode.code && el.alarmSourceId === alarmCode.alarmSourceId)
                    return Object.assign({}, el, alarmCode)
                return el
            });
        }

        const handleStateUpdate = (alarmCode: AlarmCode) => updateAlarmCodes(upsertAlarmCode(alarmCode));
        const codes = alarmCodes.reduce(
            (result: AlarmCode[], m: AlarmCode) => {
                if (filterBySource === undefined || filterBySource.includes(m.alarmSourceId)) {
                    result.push(m);
                }
                return result;
            }, []);

        return (
            <>
                {this.props.alarmCodes && codes.length > 0 &&
                    <>
                        <div className="settings-table-title">{title}</div>
                        <div className="settings-table container">
                            <div className="row settings-header-row">
                                <div className="col-3">Type</div>
                                <div className="col-2">Code</div>
                                <div className="col-7">Alert Severity</div>
                            </div>
                            {codes.map(
                                (code, i) =>
                                    <div key={i} className={"row settings-row" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                                        <div className="col-3">
                                            {code.description && code.description}
                                        </div>
                                        <div className="col-2">
                                            {code.code && code.code}
                                        </div>
                                        <div className="col-7">
                                            <div className="alarm-severity-row">
                                                <div className="alarm-severity-container">
                                                    <div className="select-type-text">
                                                        <span>Select Type</span>
                                                    </div>
                                                    <div className="select-type-severity">
                                                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={() => handleStateUpdate({ ...code, alertSeverity: 1 })}>
                                                            <img src={iconPinAlertNormal}
                                                                className={"preset-severity" + (code.alertSeverity >= 1 && code.alertSeverity < this.props.mediumAlertThreshold ? " selected-severity" : "")}
                                                                alt="Normal alert pin icon"
                                                            />
                                                        </button>
                                                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={() => handleStateUpdate({ ...code, alertSeverity: this.props.mediumAlertThreshold })}>
                                                            <img src={iconPinAlertMedium}
                                                                className={"preset-severity" + (code.alertSeverity >= this.props.mediumAlertThreshold && code.alertSeverity < this.props.criticalAlertThreshold ? " selected-severity" : "")}
                                                                alt="Medium alert pin icon"
                                                            />
                                                        </button>
                                                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={() => handleStateUpdate({ ...code, alertSeverity: this.props.criticalAlertThreshold })}>
                                                            <img src={iconPinAlertHigh}
                                                                className={"preset-severity" + (code.alertSeverity >= this.props.criticalAlertThreshold ? " selected-severity" : "")}
                                                                alt="High alert pin icon"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="alarm-severity-input">
                                                    <div className="custom-value-text">
                                                        Custom Value
                                                    </div>
                                                    <div className="custom-value-input">
                                                        <input type="text" value={code.alertSeverity}
                                                            onChange={
                                                                (event: React.ChangeEvent<HTMLInputElement>) => handleStateUpdate({ ...code, alertSeverity: parseInt(event.target.value) || 0 })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )}
                        </div >
                    </>
                }
            </>
        );
    }
}

export default AlarmTableSetting;
