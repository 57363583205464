import * as React from "react";
import "./controls.scss";

interface CheckBoxProps {
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const ignoreClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className="custom-checkbox-container" onClick={(e) => !props.onChange && ignoreClick(e)}>
      <label className="custom-checkbox">
        <input title="checkbox" type="checkbox" checked={props.checked} onChange={(e) => props.onChange && props.onChange()} />
        <span className={"checkmark" + (props.disabled ? " disabled" : "")}></span>
      </label>
    </div>
  );
};
