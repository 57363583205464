import React, { ChangeEvent } from "react";
import moment from "moment";
import ReactMoment from "react-moment";
import MaskedInput from "react-text-mask";
import { iconClose, iconAccept, iconDecline, iconAdd, iconPinAlertHigh, iconPinAlertMedium, iconPinAlertNormal, iconEdit } from "../images";
import { Log } from "store/logs";
import * as AlertStore from "store/alarm";
import { Comment, defaultComment } from "store/comments";
import "./logViewer.scss";
import EdiText from "react-editext";
import { CheckBox } from "../controls/checkBox";
import { BackendSettings } from "store/adminSettings";
import { Site } from "store/sites";
import { scaleVolumeSeverity } from "../../utils/volume";
import { UserLogType } from "../../constants/logConstants";
import { AuthenticationRole, AuthenticationSelectors } from "store/authentication";
import Spinner from "../spinner/spinner";
import { SendToast } from "../../utils/toast";

export interface LogViewerProps {
  currentLog: Log;
  currentAlerts: AlertStore.Alert[];
  closeLogViewer: () => void;
  rejectLog: () => void;
  saveLog: (log: Log, dismiss: boolean) => void;
  openCommentEditor: (commentId: number, log: Log) => void;
  comments: Comment[];
  setSelectedLogModified: () => void;
  selectedLogModified: boolean;
  reopenLog: () => void;
  isLoading: boolean;
  selectedSite: Site;
  backendSettings: BackendSettings;
  userRole: AuthenticationRole;
}

export interface LogViewerState {
  currentLog: Log;
  currentLogScatsWorkCompletedString: string;
  currentLogContractorWorkCompletedString: string;
  editingTitle: boolean;
}

export default class LogViewer extends React.Component<LogViewerProps, LogViewerState> {
  private editextRef: React.RefObject<any>;
  constructor(props: LogViewerProps) {
    super(props);
    this.editextRef = React.createRef<EdiText>();
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleOnEditingStart = this.handleOnEditingStart.bind(this);
    this.handleOnEditingCancel = this.handleOnEditingCancel.bind(this);
    this.handleToggleScatsWorkRequired = this.handleToggleScatsWorkRequired.bind(this);
    this.handleToggleContractorWorkRequired = this.handleToggleContractorWorkRequired.bind(this);
    this.handleToggleContractorWorkCompleted = this.handleToggleContractorWorkCompleted.bind(this);
    this.handleOnBlurScatsWorkComplete = this.handleOnBlurScatsWorkComplete.bind(this);
    this.handleOnBlurContractorWorkComplete = this.handleOnBlurContractorWorkComplete.bind(this);
    this.handleUpdateScatsWorkCompleted = this.handleUpdateScatsWorkCompleted.bind(this);
    this.handleUpdateContractorWorkCompleted = this.handleUpdateContractorWorkCompleted.bind(this);
    this.handleLogTitleSave = this.handleLogTitleSave.bind(this);
    this.saveLog = this.saveLog.bind(this);
    this.state = this.initialState;
  }

  get initialState() {
    const { currentLog } = this.props;
    const state: LogViewerState = {
      currentLog: { ...currentLog },
      currentLogScatsWorkCompletedString: currentLog.scatsWorkCompleted ? moment(currentLog.scatsWorkCompleted).format("DD/MM/YYYY HH:mm") : "",
      currentLogContractorWorkCompletedString: currentLog.contractorWorkCompleted ? moment(currentLog.contractorWorkCompleted).format("DD/MM/YYYY HH:mm") : "",
      editingTitle: currentLog.id === -1 ? true : false,
    };
    return state;
  }

  componentDidUpdate(prevProps: LogViewerProps) {
    // resets the state when the a new log is selected
    if (this.props.currentLog && prevProps.currentLog) {
      if (this.props.currentLog.id !== prevProps.currentLog.id) {
        this.setState(this.initialState);
      }
    }
  }

  private getAlertSeverity = (alerts: AlertStore.Alert[]) => {
    const severity: number = Math.max(...alerts.map((alert) => alert.alertSeverity));
    const sitePriority = this.props.backendSettings.sitePrioritySettings.filter((p) => p.priority === this.props.selectedSite.priority)[0];

    if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
      return iconPinAlertHigh;
    } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
      return iconPinAlertMedium;
    } else {
      return iconPinAlertNormal;
    }
  };

  private getDetectorAlertSeverity = (alerts: AlertStore.Alert[]) => {
    const severity: number = alerts.reduce((partialSum, a) => partialSum + a.alertSeverity, 0);
    const sitePriority = this.props.backendSettings.sitePrioritySettings.filter((p) => p.priority === this.props.selectedSite.priority)[0];

    if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
      return iconPinAlertHigh;
    } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
      return iconPinAlertMedium;
    } else {
      return iconPinAlertNormal;
    }
  };

  private getVolumeAlertSeverity = (alerts: AlertStore.Alert[]) => {
    const { backendSettings, selectedSite } = this.props;
    const severity: number = Math.max(
      ...alerts.map((alert) =>
        scaleVolumeSeverity(
          alert.alertSeverity,
          parseFloat(alert.additionalData),
          backendSettings.minVolumeMultiplierThreshold,
          backendSettings.scorePerVolumeIncrease,
          backendSettings.maximumVolumeScore
        )
      )
    );
    const sitePriority = backendSettings.sitePrioritySettings.filter((p) => p.priority === selectedSite.priority)[0];

    if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
      return iconPinAlertHigh;
    } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
      return iconPinAlertMedium;
    } else {
      return iconPinAlertNormal;
    }
  };

  private updateModifiedStatus() {
    if (!this.props.selectedLogModified) {
      this.props.setSelectedLogModified();
    }
  }

  private saveLog = (complete: boolean, dismiss: boolean = true) => {
    const dateClosed = complete ? new Date(moment.now()) : this.state.currentLog.dateClosed;
    if (this.state.editingTitle) {
      this.setState({ editingTitle: false });
      const ref: EdiText = this.editextRef.current;
      if (ref) {
        //ensures the log has a title
        if (ref.state.value) {
          this.props.saveLog({ ...this.state.currentLog, title: ref.state.value, dateClosed: dateClosed }, dismiss);
        } else {
          SendToast("Log not Saved", "User logs require a title to be saved...", { type: "warning" });
        }
      }
    } else {
      //ensures the log has a title
      if (this.state.currentLog.title) {
        this.props.saveLog({ ...this.state.currentLog, dateClosed: dateClosed }, dismiss);
      } else {
        SendToast("Log not Saved", "User logs require a title to be saved...", { type: "warning" });
      }
    }
  };

  private handleSaveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    this.saveLog(false);
  };

  private handleCompleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    this.saveLog(true);
  };

  private handleToggleScatsWorkRequired = () => {
    const clone = this.state.currentLog;
    clone.scatsWorkRequired = !clone.scatsWorkRequired;
    clone.scatsWorkCompleted = undefined;
    this.setState({ currentLog: clone, currentLogScatsWorkCompletedString: "" });
    this.updateModifiedStatus();
  };

  private handleToggleContractorWorkRequired = () => {
    const clone = this.state.currentLog;
    clone.contractorWorkRequired = !clone.contractorWorkRequired;
    clone.contractorWorkCompleted = undefined;
    this.setState({ currentLog: clone, currentLogContractorWorkCompletedString: "" });
    this.updateModifiedStatus();
  };

  private handleToggleScatsWorkCompleted = () => {
    const clone = this.state.currentLog;
    clone.scatsWorkCompleted = clone.scatsWorkCompleted ? undefined : new Date();
    const dateString = clone.scatsWorkCompleted ? moment(clone.scatsWorkCompleted).format("DD/MM/YYYY HH:mm") : "";
    this.setState({ currentLog: { ...clone, logType: UserLogType }, currentLogScatsWorkCompletedString: dateString });
    this.updateModifiedStatus();
  };

  private handleToggleContractorWorkCompleted = () => {
    const clone = this.state.currentLog;
    clone.contractorWorkCompleted = clone.contractorWorkCompleted ? undefined : new Date();
    const dateString = clone.contractorWorkCompleted ? moment(clone.contractorWorkCompleted).format("DD/MM/YYYY HH:mm") : "";
    this.setState({ currentLog: clone, currentLogContractorWorkCompletedString: dateString });
    this.updateModifiedStatus();
  };

  private handleOnBlurScatsWorkComplete = () => {
    const clone = this.state.currentLog;
    let date = moment(this.state.currentLogScatsWorkCompletedString, "DD/MM/YYYY HH:mm");
    if (!date.isValid()) {
      date = moment();
    }
    clone.scatsWorkCompleted = date.toDate();

    const dateString = clone.scatsWorkCompleted ? date.format("DD/MM/YYYY HH:mm") : "";
    this.setState({ currentLog: clone, currentLogScatsWorkCompletedString: dateString });
    this.updateModifiedStatus();
  };

  private handleOnBlurContractorWorkComplete = () => {
    const clone = this.state.currentLog;
    let date = moment(this.state.currentLogContractorWorkCompletedString, "DD/MM/YYYY HH:mm");
    if (!date.isValid()) {
      date = moment();
    }
    clone.contractorWorkCompleted = date.toDate();

    const dateString = clone.contractorWorkCompleted ? date.format("DD/MM/YYYY HH:mm") : "";
    this.setState({ currentLog: clone, currentLogContractorWorkCompletedString: dateString });
    this.updateModifiedStatus();
  };

  private handleUpdateScatsWorkCompleted = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentLogScatsWorkCompletedString: e.target.value });
    this.updateModifiedStatus();
  };

  private handleUpdateContractorWorkCompleted = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentLogContractorWorkCompletedString: e.target.value });
    this.updateModifiedStatus();
  };

  private handleLogTitleSave = (val: string) => {
    if (val) {
      this.setState({ currentLog: { ...this.state.currentLog, title: val }, editingTitle: false });
      this.saveLog(false, false);
    }
  };

  private handleOnEditingStart = () => {
    this.setState({ editingTitle: true });
  };

  private handleOnEditingCancel = () => {
    this.setState({ editingTitle: false });
  };

  public render() {
    const { currentAlerts, comments, isLoading, userRole } = this.props;
    const { editingTitle, currentLog, currentLogScatsWorkCompletedString, currentLogContractorWorkCompletedString } = this.state;
    const logClosed = currentLog.dateClosed !== undefined && currentLog.dateClosed !== null;
    let alerts = AlertStore.AlertsSelectors.SortedBySourceSeverityAndTime(currentAlerts);
    if (currentLog.id === -1) {
      alerts = alerts.filter((alert) => !alert.closedTime);
    }
    currentLog.alarms = alerts;
    const scatsEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteAlarm);
    const scatsEventsFirst = AlertStore.AlertsSelectors.ShortestRunningAlert(scatsEvents);
    const thresholdEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm);
    const thresholdEventsFirst = AlertStore.AlertsSelectors.ShortestRunningAlert(thresholdEvents);
    const thresholdEventWithLargestMultiplier = AlertStore.AlertsSelectors.LargestAlertByAdditionalData(thresholdEvents);
    const detectorEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorAlarm);
    const detectorEventsFirst = AlertStore.AlertsSelectors.ShortestRunningAlert(detectorEvents);
    const srmEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.SharePoint);
    const powerSupplyEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.PowerSupply);
    const addInsightEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.AddInsightSiteAlarm);
    const addInsightEventsFirst = AlertStore.AlertsSelectors.ShortestRunningAlert(addInsightEvents);
    const hasPermission = AuthenticationSelectors.checkAuthenticationUsingRole(AuthenticationRole.Operator, userRole);

    return (
      <div className="nav-blade logviewer">
        {isLoading && <Spinner dim />}
        <div className={"log-viewer-content " + (isLoading ? "is-loading" : "")}>
          <div className="blade-header alternate">
            <div className="blade-header-title px-3">
              <div className="log-title-container">
                <h2>Log Viewer</h2>
                {hasPermission ? (
                  <EdiText
                    ref={this.editextRef}
                    type="text"
                    buttonsAlign="before"
                    editButtonContent={<img src={iconEdit} height={18} alt="Edit icon" />}
                    cancelButtonContent={<img src={iconDecline} height={18} alt="Cancel icon" />}
                    saveButtonContent={<img src={iconAccept} height={18} alt="Save icon" />}
                    hideIcons={true}
                    editOnViewClick={true}
                    submitOnEnter={true}
                    submitOnUnfocus={true}
                    viewContainerClassName="editext-view"
                    mainContainerClassName="editext-main-container"
                    inputProps={{
                      placeholder: "Enter log title here...",
                    }}
                    validation={(val) => val.length < 250}
                    validationMessage="Please use less than 250 characters..."
                    value={currentLog.title ? currentLog.title : ""}
                    editing={editingTitle}
                    onEditingStart={this.handleOnEditingStart}
                    onCancel={this.handleOnEditingCancel}
                    onSave={this.handleLogTitleSave}
                  />
                ) : (
                  <div className="log-title">{currentLog?.title ?? ""}</div>
                )}
              </div>
            </div>

            <div className="blade-header-close">
              <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={this.props.closeLogViewer}>
                <img src={iconClose} className="align-top" width={15} height={15} alt="Close icon" />
              </button>
            </div>
          </div>

          <div className="blade-body pt-4">
            <h3 className="p-2">Details</h3>
            <div className="log-details">
              <table>
                <tbody>
                  <tr>
                    <td>Type</td>
                    <td>{currentLog.logType}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>
                      <ReactMoment format="DD/MM/YYYY hh:mm A" date={currentLog.dateCreated} />
                    </td>
                  </tr>
                  <tr>
                    <td>First Modified</td>
                    <td>{currentLog.firstModified && <ReactMoment format="DD/MM/YYYY hh:mm A" date={currentLog.firstModified} />}</td>
                  </tr>
                  <tr>
                    <td>Duration</td>
                    <td>
                      <ReactMoment from={currentLog.dateCreated} to={currentLog.dateClosed} ago />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 className="pt-4 px-2 pb-2">Alerts</h3>
            <table className="log-detail-table">
              <tbody>
                {scatsEvents && scatsEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getAlertSeverity(scatsEvents)} width={24} height={24} alt="SCATS alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>SCATS Alarms</h2>
                        {scatsEvents
                          .filter((obj, pos, arr) => {
                            return arr.map((mapObj) => mapObj.code).indexOf(obj.code) === pos; //removes duplicate SCATS alarm codes
                          })
                          .map((alert, i) => (
                            <small key={i} title={alert.description}>
                              {" "}
                              {alert.code}
                            </small>
                          ))}
                      </span>
                    </td>
                    <td className="text-right">{scatsEventsFirst && <ReactMoment from={scatsEventsFirst.triggerTime} ago to={new Date()} />}</td>
                  </tr>
                )}
                {detectorEvents && detectorEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getDetectorAlertSeverity(detectorEvents)} width={24} height={24} alt="SCATS detector alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>SCATS Detectors</h2>
                        {detectorEvents
                          .filter((obj, pos, arr) => {
                            return arr.map((mapObj) => mapObj.detectorNumber).indexOf(obj.detectorNumber) === pos; //removes duplicate detectors numbers
                          })
                          .map(
                            (alert, i) =>
                              alert.detectorNumber && (
                                <small key={i} title={alert.description}>
                                  {alert.additionalData === "PED" ? "PB" : ""}
                                  {alert.detectorNumber}
                                  {i !== detectorEvents.length - 1 && ", "}
                                </small>
                              )
                          )}
                      </span>
                    </td>
                    <td className="text-right">{detectorEventsFirst && <ReactMoment from={detectorEventsFirst.triggerTime} ago to={new Date()} />}</td>
                  </tr>
                )}
                {thresholdEvents && thresholdEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getVolumeAlertSeverity(thresholdEvents)} width={24} height={24} alt="Threshold alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>SCATS Volumes</h2>
                        <small>
                          {thresholdEventWithLargestMultiplier &&
                            `${Math.round(Number(thresholdEventWithLargestMultiplier.additionalData) * 100)}% (detector ${
                              thresholdEventWithLargestMultiplier.detectorNumber
                            })`}
                        </small>
                      </span>
                    </td>
                    <td className="text-right">{thresholdEventsFirst && <ReactMoment from={thresholdEventsFirst.triggerTime} ago to={new Date()} />}</td>
                  </tr>
                )}
                {srmEvents && srmEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getAlertSeverity(srmEvents)} width={24} height={24} alt="SRM alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>SRM</h2>
                      </span>
                    </td>
                    <td className="text-right">
                      {(() => {
                        const lastEvent: AlertStore.Alert | undefined = srmEvents.pop();
                        return lastEvent && <ReactMoment from={lastEvent.triggerTime} ago to={new Date()} />;
                      })()}
                    </td>
                  </tr>
                )}
                {powerSupplyEvents && powerSupplyEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getAlertSeverity(powerSupplyEvents)} width={24} height={24} alt="Power Supply alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>WEL</h2>
                      </span>
                    </td>
                    <td className="text-right">
                      {(() => {
                        const lastEvent: AlertStore.Alert | undefined = powerSupplyEvents.pop();
                        return lastEvent && <ReactMoment from={lastEvent.triggerTime} ago to={new Date()} />;
                      })()}
                    </td>
                  </tr>
                )}
                {addInsightEvents && addInsightEvents.length > 0 && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <img src={this.getAlertSeverity(addInsightEvents)} width={24} height={24} alt="AddInsight alert severity icon" />
                    </td>
                    <td>
                      <span>
                        <h2>AddInsight</h2>
                      </span>
                    </td>
                    <td className="text-right">{addInsightEventsFirst && <ReactMoment from={addInsightEventsFirst.triggerTime} ago to={new Date()} />}</td>
                  </tr>
                )}
                <tr className="blade-table-body alternate" onClick={(e) => hasPermission && this.handleToggleScatsWorkRequired()}>
                  <td className="text-center">
                    <CheckBox checked={currentLog.scatsWorkRequired} disabled={!hasPermission} />
                  </td>
                  <td>
                    <span>
                      <h2>SCATS Work Required</h2>
                    </span>
                  </td>
                  <td></td>
                </tr>
                {currentLog.scatsWorkRequired && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <div onClick={(e) => hasPermission && this.handleToggleScatsWorkCompleted()}>
                        <CheckBox checked={currentLog.scatsWorkCompleted != null} disabled={!hasPermission} />
                      </div>
                    </td>
                    <td>
                      <span>
                        <h2>SCATS Work Completed</h2>
                      </span>
                    </td>
                    <td className="text-right">
                      <MaskedInput
                        className="input-date-mask"
                        value={currentLogScatsWorkCompletedString}
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]}
                        showMask={true}
                        onChange={this.handleUpdateScatsWorkCompleted}
                        onBlur={this.handleOnBlurScatsWorkComplete}
                        disabled={!hasPermission}
                      />
                    </td>
                  </tr>
                )}
                <tr className="blade-table-body alternate" onClick={(e) => hasPermission && this.handleToggleContractorWorkRequired()}>
                  <td className="text-center">
                    <CheckBox checked={currentLog.contractorWorkRequired} disabled={!hasPermission} />
                  </td>
                  <td>
                    <span>
                      <h2>Contractor Work Required</h2>
                    </span>
                  </td>
                  <td></td>
                </tr>
                {currentLog.contractorWorkRequired && (
                  <tr className="blade-table-body alternate">
                    <td className="text-center">
                      <div onClick={(e) => hasPermission && this.handleToggleContractorWorkCompleted()}>
                        <CheckBox checked={currentLog.contractorWorkCompleted != null} disabled={!hasPermission} />
                      </div>
                    </td>
                    <td>
                      <span>
                        <h2>Contractor Work Completed</h2>
                      </span>
                    </td>
                    <td className="text-right">
                      <MaskedInput
                        className="input-date-mask"
                        value={currentLogContractorWorkCompletedString}
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]}
                        showMask={true}
                        onChange={this.handleUpdateContractorWorkCompleted}
                        onBlur={this.handleOnBlurContractorWorkComplete}
                        disabled={!hasPermission}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="blade-section-header pt-4 px-2 pb-2">
              <h3 className="px-2">Comments</h3>
              <button
                type="button"
                disabled={!hasPermission}
                className="section-icon px-2 btn m-0 shadow-none"
                onClick={(e) => this.props.openCommentEditor(defaultComment.id, currentLog)}
              >
                <img src={iconAdd} width={15} height={15} alt="Add comment icon" />
              </button>
            </div>
            <div className="log-comments">
              {comments &&
                comments
                  .sort(function compare(a: Comment, b: Comment) {
                    const dateA = new Date(a.dateCreated);
                    const dateB = new Date(b.dateCreated);
                    return dateB.getTime() - dateA.getTime();
                  })
                  .map((comment, index) => (
                    <div key={comment.id} className="log-comment" onClick={(e) => this.props.openCommentEditor(comment.id, currentLog)}>
                      <div className="log-comment-header">
                        <small>
                          <b>{comment.userName}</b>
                        </small>
                        <small>
                          <ReactMoment format="DD/MM/YYYY hh:mm A" date={comment.dateCreated} />
                        </small>
                      </div>
                      <div className="log-comment-content">{comment.content}</div>
                    </div>
                  ))}
            </div>
          </div>
          <div className="log-actions py-2">
            <button type="button" disabled={!hasPermission || logClosed} onClick={this.props.rejectLog} className="btn btn-reject">
              Reject
            </button>
            <button type="button" disabled={!hasPermission} onClick={this.handleSaveClick} className="btn btn-save">
              Save
            </button>
            {logClosed ? (
              <button type="button" disabled={!hasPermission} onClick={this.props.reopenLog} className="btn btn-complete">
                Reopen
              </button>
            ) : (
              <button type="button" disabled={!hasPermission} onClick={this.handleCompleteClick} className="btn btn-complete">
                Complete
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
