import * as React from "react";
import { RouterState } from "connected-react-router";
import { AuthenticationState, MsalAuthProvider } from "react-aad-msal";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../components/layout";
import Spinner from "../components/spinner/spinner";
import { ApplicationState } from "store";
import { AuthenticationRole, AuthenticationSelectors, AuthState } from "store/authentication";
import { MsalAuthFactory } from "store/middleware/msal";
import { actionCreators as UserSettingActionCreators, UserSettingsPayload } from "store/userSettings";

const LayoutContainer: React.FC = (props) => {
    const dispatch = useDispatch();
    const userSettingsPayload = useSelector<ApplicationState, UserSettingsPayload>((state) => state.userSettings.userSettings);
    const routerState = useSelector<ApplicationState, RouterState>((state) => state.router);
    const authState = useSelector<ApplicationState, AuthState>((state) => state.authentication);
    const [authProvider, setAuthProvider] = React.useState<MsalAuthProvider | null>();

    React.useEffect(() => {
        setAuthProvider(MsalAuthFactory.getAuthProvider());
    }, []);

    return authProvider ? (
        <Layout
            isLoggedIn={authState.authState === AuthenticationState.Authenticated}
            logOut={authProvider.logout}
            userName={authState.userDisplayName}
            toggleSCATS={() => dispatch(UserSettingActionCreators.toggleSCATS())}
            toggleAddInsight={() => dispatch(UserSettingActionCreators.toggleAddInsight())}
            toggleBusNetwork={() => dispatch(UserSettingActionCreators.toggleBusNetwork())}
            toggleKmz={() => dispatch(UserSettingActionCreators.toggleKmz())}
            toggleSchoolSigns={() => dispatch(UserSettingActionCreators.toggleSchoolSigns())}
            toggleCycleCounters={() => dispatch(UserSettingActionCreators.toggleCycleCounters())}
            togglePumps={() => dispatch(UserSettingActionCreators.togglePumps())}
            userSettings={userSettingsPayload}
            windowPathname={routerState.location && routerState.location.pathname}
            canViewAdminPanel={AuthenticationSelectors.checkAuthentication(
                AuthenticationRole.Administrator,
                authState
            )}
            roleName={AuthenticationSelectors.getRoleName(authState)}
        >
            {props.children}
        </Layout>
    ) : (
        <Spinner dim />
    );
};

export default LayoutContainer;
