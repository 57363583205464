import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AuthError } from 'msal';
import { AuthenticationState, IAccountInfo, AuthenticationActions, IdTokenResponse, AccessTokenResponse } from 'react-aad-msal';
import { BackendErrorToast, ToastErrorTypes, ClearBackendErrorToast } from '../utils/toast';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum AuthenticationRole {
    None = "NONE",
    Viewer = "VIEWER",
    Operator = "OPERATOR",
    Administrator = "ADMINISTRATOR"
}

export interface AuthState {
    accessToken: string | null;
    accessTokenExpiry: Date | null;
    userDisplayName: string | null;
    authState: AuthenticationState;
    authRole: AuthenticationRole;
    userOid?: string;
    error?: string;

    // Used for any downstream components that use the auth provider factory provided by the middleware.
    authProviderFactoryReady: boolean;

    // Used to denote the API is ready to be called and a current access token is present. Initial API calls should refer to this variable.
    authApiReady: boolean;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface InitializingAction extends Action {
    type: AuthenticationActions.Initializing;
}
interface InitializedAction extends Action {
    type: AuthenticationActions.Initialized;
}

interface AuthenticatedStateChangedAction extends Action {
    type: AuthenticationActions.AuthenticatedStateChanged;
    payload: AuthenticationState;
}

interface AcquiredIdTokenSuccessAction extends Action {
    type: AuthenticationActions.AcquiredIdTokenSuccess;
    payload: IdTokenResponse;
}

interface AcquiredIdTokenErrorAction extends Action {
    type: AuthenticationActions.AcquiredIdTokenError;
    payload: AuthError;
}

interface AcquiredAccessTokenSuccessAction extends Action {
    type: AuthenticationActions.AcquiredAccessTokenSuccess;
    payload: AccessTokenResponse;
}

interface AcquiredAccessTokenErrorAction extends Action {
    type: AuthenticationActions.AcquiredAccessTokenError;
    payload: AuthError;
}

interface LogInSuccessAction extends Action {
    type: AuthenticationActions.LoginSuccess;
    payload: IAccountInfo;
}

interface LoginFailed extends Action {
    type: AuthenticationActions.LoginFailed;
}
interface LoginErrorAction extends Action {
    type: AuthenticationActions.LoginError;
    payload: AuthError;
}

interface LogOutSuccessAction extends Action {
    type: AuthenticationActions.LogoutSuccess;
}

interface AcquiredAccessTokenSuccessAction extends Action {
    type: AuthenticationActions.AcquiredAccessTokenSuccess;
    payload: AccessTokenResponse;
}

interface RefreshedTokenErrorAction extends Action {
    type: AuthenticationActions.AcquiredAccessTokenError;
    payload: AuthError;
}

interface AuthProviderFactoryReady extends Action {
    type: "AUTH_PROVIDER_FACTORY_READY";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
export type KnownAction = LogInSuccessAction | LogOutSuccessAction | AcquiredIdTokenSuccessAction | AcquiredIdTokenErrorAction | InitializingAction | InitializedAction
    | AcquiredAccessTokenSuccessAction | RefreshedTokenErrorAction | AuthenticatedStateChangedAction | LoginErrorAction | AcquiredAccessTokenSuccessAction
    | AcquiredAccessTokenErrorAction | LoginFailed | AuthProviderFactoryReady
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    //These are handled by react-aad-msal library
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class AuthenticationSelectors {
    static checkAuthenticationUsingRole(minRole: AuthenticationRole, userRole: AuthenticationRole): boolean {
        switch (minRole) {
            case AuthenticationRole.Administrator:
                return userRole === AuthenticationRole.Administrator;
            case AuthenticationRole.Operator:
                return userRole === AuthenticationRole.Operator || userRole === AuthenticationRole.Administrator
            case AuthenticationRole.Viewer:
                return userRole === AuthenticationRole.Viewer || userRole === AuthenticationRole.Operator || userRole === AuthenticationRole.Administrator
            case AuthenticationRole.None:
                return true;
        }
    }

    static checkAuthentication(minRole: AuthenticationRole, authenticationState: AuthState): boolean {
        const userRole = authenticationState.authRole;
        return AuthenticationSelectors.checkAuthenticationUsingRole(minRole, userRole)
    }

    static getRoleName(authenticationState: AuthState): string | null {
        const userRole = authenticationState.authRole;
        switch (userRole) {
            case AuthenticationRole.Administrator:
                return "Portal Administrator";
            case AuthenticationRole.Operator:
                return "Portal Operator";
            case AuthenticationRole.Viewer:
                return "Portal Viewer";
            case AuthenticationRole.None:
                return null;
        }
    }
}

export const enforceRolePermissions = (authState: AuthState, requiredAuth: AuthenticationRole, callback: () => void) => {
    if (AuthenticationSelectors.checkAuthentication(requiredAuth, authState)) {
        callback();
    }
    else {
        ClearBackendErrorToast(ToastErrorTypes.INSUFFICIENT_PERMISSIONS)
        BackendErrorToast(ToastErrorTypes.INSUFFICIENT_PERMISSIONS, "Insufficient Permissions", `The role of '${AuthenticationSelectors.getRoleName(authState)}' does not have permission to complete that action...`);
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthState = { userDisplayName: null, accessToken: null, authProviderFactoryReady: false, authState: AuthenticationState.Unauthenticated, accessTokenExpiry: null, authApiReady: false, authRole: AuthenticationRole.None };

export const reducer: Reducer<AuthState, AnyAction> = (state: AuthState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case AuthenticationActions.LoginSuccess: {
            // This seems to be a bad assumption the MSAL library takes. Roles are returned in a JSON array.
            const roles = action.payload.account.idToken["roles"] as unknown as (string[] | undefined);
            let authRole = AuthenticationRole.Viewer;

            if (roles) {
                if (roles.includes("user.portaladmin")) {
                    authRole = AuthenticationRole.Administrator;
                }
                else if (roles.includes("user.portaloperator")) {
                    authRole = AuthenticationRole.Operator
                }
            }
            return {
                ...state,
                userDisplayName: action.payload.account.name,
                userOid: action.payload.account.accountIdentifier,
                authRole: authRole
            };
        }
        case AuthenticationActions.AuthenticatedStateChanged:
            return {
                ...state,
                authState: action.payload
            };
        case AuthenticationActions.LogoutSuccess:
            return { ...state, userDisplayName: null, accessToken: null, accessTokenExpiry: null, authApiReady: false, authRole: AuthenticationRole.None };
        case AuthenticationActions.AcquiredIdTokenError:
        case AuthenticationActions.AcquiredAccessTokenError:
        case AuthenticationActions.LoginError:
        case 'AAD_LOGIN_ERROR':
            return { ...state, error: action.payload.message };

        case AuthenticationActions.AcquiredAccessTokenSuccess:
            return { ...state, accessToken: action.payload.accessToken, accessTokenExpiry: action.payload.expiresOn, authApiReady: true };

        case "AUTH_PROVIDER_FACTORY_READY":
            return { ...state, authProviderFactoryReady: true }

        case AuthenticationActions.Initializing:
        case AuthenticationActions.Initialized:
        case AuthenticationActions.AcquiredIdTokenSuccess:
        case AuthenticationActions.LoginFailed:
            return state;

        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};