import "whatwg-fetch";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import { HttpError } from "@microsoft/signalr";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface HealthState {
    status?: Status;
    isLoadingStatus: boolean;
}

export interface Status {
    addInsightLinksLastUpdated: Date | string | undefined;
    scatsDetectorAlarmsLastUpdated: Date | string | undefined;
    scatsSiteAlarmsLastUpdated: Date | string | undefined;
    scatsVolumesLastUpdated: Date | string | undefined;
    cycleCountersLastDetection: Date | string | undefined;
    sumpMonitorsLastUpdated: Date | string | undefined;
    schoolSignsLastTalked: Date | string | undefined;
    schoolSignsLastUpdated: Date | string | undefined;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestStatus extends Action {
    type: "REQUEST_STATUS";
}
interface ReceiveStatusError extends Action {
    type: "ERROR_RECEIVING_STATUS";
}
export interface ReceiveStatus extends Action {
    type: "RECEIVE_STATUS";
    status: Status;
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestStatus
    | ReceiveStatusError
    | ReceiveStatus;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestStatus: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            health: { isLoadingStatus },
            authentication: { accessToken },
        } = getState();

        if (isLoadingStatus || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Health`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<Status>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVE_STATUS", status: data });
                    ClearBackendErrorToast(ToastErrorTypes.STATUS);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_STATUS" });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.STATUS, "Error retrieving Status");
                });

            dispatch({ type: "REQUEST_STATUS" });
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: HealthState = {
    isLoadingStatus: false
};

export const reducer: Reducer<HealthState, AnyAction> = (state: HealthState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_STATUS": {

            return <HealthState>{
                ...state,
                isLoadingStatus: true,
            };
        }
        case "RECEIVE_STATUS": {
            return <HealthState>{
                ...state,
                isLoadingStatus: false,
                status: action.status,
            };
        }
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
