import L, { LatLng } from "leaflet";
import * as React from "react";
import { Map, Marker, Rectangle, Pane } from "react-leaflet";
import { Site } from "store/sites";
import { iconPin } from "../../images";
import GoogleApiLoader from "../../map/google-mutant/GoogleApiLoader";
import GoogleLayer from "../../map/google-mutant/GoogleLayer";
import GoogleTheme from "../../map/google-mutant/GoogleTheme";
import { defaultIcon } from "../../map/icons";
import { getMapZoom, getScaledIcon } from "../../map/map";
import "./controls.scss";

interface TextSettingControlProps {
    handleSettingUpdate: any;
    googleApiKey?: string;
    sites: Site[];
    lat: number;
    lng: number;
    adaptiveZoomRadius: number;
}
interface TextSettingControlState {
    zoom: number;
    center: LatLng;
}

const getCenterPin = () => {
    const t = {
        ...defaultIcon,
        iconUrl: iconPin,
        iconRetinaUrl: iconPin,
        className:"center-pin"
    };
    return L.icon(t);
};

export class MapSettingControl extends React.Component<
    TextSettingControlProps,
    TextSettingControlState
    > {
    private mapRef: React.RefObject<any>;
    private initialCenter: LatLng;

    constructor(props: TextSettingControlProps) {
        super(props);
        this.mapRef = React.createRef<Map>();
        this.initialCenter = new LatLng(props.lat, props.lng);
        this.state = { zoom: 11, center: this.initialCenter };
    }

    public render() {
        const {
            handleSettingUpdate,
            googleApiKey,
            sites,
            lat,
            lng,
            adaptiveZoomRadius,
        } = this.props;
        const { zoom, center } = this.state;

        const getBounds = () => {
            if (!sites.length) {
                return null;
            }

            const center = new LatLng(lat, lng);
            const radius = adaptiveZoomRadius;
            const markersWithinRadius: LatLng[] = sites.reduce(function (
                result: LatLng[],
                m: Site
            ) {
                const distance = center.distanceTo([m.latitude, m.longitude]) / 1000;
                if (distance <= radius) {
                    result.push(new LatLng(m.latitude, m.longitude));
                }
                return result;
            },
                []);

            if (!markersWithinRadius.length) {
                return null;
            }

            const bounds = L.latLngBounds(markersWithinRadius);
            if (!bounds.isValid) {
                return null;
            }

            return bounds;
        };

        const bounds = getBounds();

        return (
            <div className="map-setting">
                {googleApiKey && (
                    <GoogleApiLoader apiKey={googleApiKey}>
                        <Map
                            ref={this.mapRef}
                            zoomSnap={1}
                            center={center}
                            zoom={zoom}
                            onZoomEnd={() => {
                                this.setState({ zoom: getMapZoom(this.mapRef.current, zoom) });
                            }}
                        >
                            <GoogleLayer type="roadmap" styles={GoogleTheme} />
                            <Pane>
                                {sites &&
                                    sites.length > 0 &&
                                    sites.map((m) => (
                                        <Marker
                                            key={m.id}
                                            position={[m.latitude, m.longitude]}
                                            icon={getScaledIcon(zoom, defaultIcon)}
                                        />
                                    ))}
                            </Pane>
                            <Pane>
                                {bounds && <Rectangle bounds={bounds} className="rectangle" />}
                            </Pane>
                            <Pane className="center-pin-pane">
                                <Marker
                                    position={[lat, lng]}
                                    draggable
                                    icon={getCenterPin()}
                                    zIndexOffset={1000}
                                    onDragend={(e: L.LeafletEvent) => {
                                        const latLng = e.target.getLatLng(); //get updated marker LatLng
                                        handleSettingUpdate("mapStartingLatitude", latLng.lat);
                                        handleSettingUpdate("mapStartingLongitude", latLng.lng);
                                    }}
                                />
                            </Pane>
                        </Map>
                    </GoogleApiLoader>
                )}
            </div>
        );
    }
}

export default MapSettingControl;
