import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    AdminSettingsPayload,
    BackendSettings,
    MobileAlertGroup,
    MobileAlertRecipient,
    MobileAlertRule,
    PortalSettings,
    SitePrioritySetting,
} from "store/adminSettings";
import { AlertSource } from "store/alarm";
import { Site } from "store/sites";
import { AlarmTableSetting } from "../adminSettings/controls/alarmTableSetting";
import { MobileRecipientTableSetting } from "../adminSettings/controls/mobileRecipientTableSetting";
import { MobileGroupsTableSetting } from "../adminSettings/controls/mobileGroupsTableSetting";
import { MobileRulesTableSetting } from "../adminSettings/controls/mobileRulesTableSetting";
import { SiteAlarmTableSetting } from "../adminSettings/controls/siteAlarmTableSetting";
import { SitePriorityTableSetting } from "../adminSettings/controls/sitePriorityTableSetting";
import { SliderIndicator } from "../adminSettings/controls/sliderIndicator";
import { TimeSettingControl } from "../adminSettings/controls/timeSettingControl";
import { VolumeSettingExample } from "../adminSettings/controls/volumeSettingExample";
import { iconClose, iconDistance, iconMagnify, iconSave, iconTime } from "../images";
import { busIconHtml } from "../map/bus/busMarker";
import Modal from "../modal/modal";
import { AdminSettingExplanation } from "./adminSettingExplanation";
import "./adminSettings.scss";
import { AdminSettingSection } from "./adminSettingSection";
import { AdminSettingSubSection } from "./adminSettingSubSection";
import BoolSettingControl from "./controls/boolSettingControl";
import ColorSettingControl from "./controls/colorSettingControl";
import MapSettingControl from "./controls/mapSettingControl";
import SliderSettingControl from "./controls/sliderSettingControl";
import TextSettingControl from "./controls/textSettingControl";
import Spinner from '../spinner/spinner';
import { addinsightAlarmCode, severeAddinsightAlarmCode, flowRestrictionAlarmCode, volumeAlarmCode, detectorAlarmCode } from "../../constants/alarmConstants";
import DropDownSettingControl, { DropDownKeyVaule } from "./controls/dropDownSettingControl";


interface AdminSettingsProps extends RouteComponentProps<any> {
    adminSettings: AdminSettingsPayload;
    updateAdminSettings: (updatedAdminSettings: AdminSettingsPayload, updatedSites: Site[]) => void;
    googleApiKey?: string;
    sites: Site[];
    isLoading: boolean;
}

interface AdminSettingsState {
    adminSettings: AdminSettingsPayload;
    sites: Site[];
    hasChanges: boolean;
    modalOpen: boolean;
}

const scaleIndicator = <SliderIndicator iconSrc={iconMagnify} altText="Scale icon" />;
const distanceIndicator = <SliderIndicator iconSrc={iconDistance} altText="Distance icon" />;
const timeIndicator = <SliderIndicator iconSrc={iconTime} altText="Time icon" />;

function firstOrNull<T>(array: T[], predicate: (test: T) => boolean): T | null {
    const matches = array.filter(predicate);

    if (matches.length >= 1) {
        return matches[0];
    }

    return null;
}

export class AdminSettings extends React.Component<AdminSettingsProps, AdminSettingsState> {
    constructor(props: AdminSettingsProps) {
        super(props);

        this.state = {
            adminSettings: props.adminSettings,
            sites: props.sites,
            hasChanges: false,
            modalOpen: false,
        };
    }

    //checks if settings page has been edited while they are using it
    componentDidUpdate(prevProps: AdminSettingsProps, prevState: AdminSettingsState) {
        const jsonCurrentProps = JSON.stringify(this.props.adminSettings);
        const jsonPrevProps = JSON.stringify(prevProps.adminSettings);

        const jsonCurrentState = JSON.stringify(this.state.adminSettings);
        const jsonPrevState = JSON.stringify(prevState.adminSettings);

        // Compares a current and previous list of sites to identify if there has been
        // a change to priority. Returns true if there is a significant change.
        const compareSitePriorities = (current: Site[], previous: Site[]) => {
            return current.some((site) => {
                const matchingSite = firstOrNull(previous, (other) => other.id === site.id);

                if (matchingSite == null) {
                    return true;
                }

                if (matchingSite.priority !== site.priority) {
                    return true;
                }

                return false;
            });
        };

        const sitePriorityPropsDiffersFromPrevious = compareSitePriorities(
            this.props.sites,
            prevProps.sites
        );
        const sitePriorityStateDiffersFromPrevious = compareSitePriorities(
            this.state.sites,
            prevState.sites
        );
        const sitePriorityStateDiffersFromProps = compareSitePriorities(
            this.state.sites,
            this.props.sites
        );

        // there are changes in the admin settings
        if (!this.state.hasChanges) {
            if (jsonCurrentState !== jsonPrevState) {
                this.setState({ hasChanges: true });
            } else if (sitePriorityStateDiffersFromPrevious) {
                this.setState({ hasChanges: true });
            }
        } else if (jsonCurrentState === jsonCurrentProps && !sitePriorityStateDiffersFromProps) {
            this.setState({ hasChanges: false });
        }

        //compare props for changes
        if (jsonPrevProps !== jsonCurrentProps && jsonCurrentProps !== jsonCurrentState) {
            //prompts the user to refresh the page
            this.setState({ modalOpen: true });
        } else if (sitePriorityPropsDiffersFromPrevious && sitePriorityStateDiffersFromPrevious) {
            //prompts the user to refresh the page
            this.setState({ modalOpen: true });
        }
    }

    saveSettings = () => {
        this.props.updateAdminSettings({ ...this.state.adminSettings }, [...this.state.sites]);
    };

    revertSettings = () => {
        this.setState({
            adminSettings: { ...this.props.adminSettings },
            sites: [...this.props.sites],
        });
    };

    handlePortalSettingUpdate = <T extends keyof PortalSettings, K extends PortalSettings[T]>(
        name: T,
        value: K
    ): void => {
        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                portalSettings: {
                    ...this.state.adminSettings.portalSettings,
                    [name]: value,
                },
            },
        });
    };

    handleSitePriorityThresholdUpdate = (sps: SitePrioritySetting): void => {
        const { backendSettings } = this.state.adminSettings;

        const newSitePrioritySettings = backendSettings.sitePrioritySettings.map((x) => {
            return x.priority === sps.priority ? sps : x;
        });

        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                backendSettings: Object.assign(
                    { ...backendSettings },
                    { sitePrioritySettings: newSitePrioritySettings }
                ),
            },
        });
    };

    handleBackendSettingUpdate = <T extends keyof BackendSettings, K extends BackendSettings[T]>(
        name: T,
        value: K
    ): void => {
        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                backendSettings: {
                    ...this.state.adminSettings.backendSettings,
                    [name]: value,
                },
            },
        });
    };

    handleMobileAlertRecipientUpdate = (recipients: MobileAlertRecipient[]): void => {
        const { backendSettings } = this.state.adminSettings;
        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                backendSettings: Object.assign(
                    { ...backendSettings },
                    { mobileAlertRecipients: recipients }
                ),
            },
        });
    };

    handleMobileAlertGroupUpdate = (groups: MobileAlertGroup[], deletedGroup: MobileAlertGroup| null): void => {
        const { backendSettings } = this.state.adminSettings;
        const rules = backendSettings.mobileAlertRules;
        const recipients = backendSettings.mobileAlertRecipients;
        if(deletedGroup !== null){
            rules.forEach(rule => {
                if(rule.groupId === deletedGroup.id){
                    rule.groupId = null
                    rule.lastUpdated = new Date()
                }
            })
            recipients.forEach(recipient => {
                if(recipient.groupId === deletedGroup.id){
                    recipient.groupId = null
                    recipient.lastUpdated = new Date()
                }
            })
        }
        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                backendSettings: Object.assign(
                    { ...backendSettings },
                    { mobileAlertGroups: groups, mobileAlertRules: rules, mobileAlertRecipients: recipients}
                ),
            },
        });
    };

    handleMobileAlertRulesUpdate = (rules: MobileAlertRule[]): void => {
        const { backendSettings } = this.state.adminSettings;
        this.setState({
            adminSettings: {
                ...this.state.adminSettings,
                backendSettings: Object.assign({ ...backendSettings }, { mobileAlertRules: rules }),
            },
        });
    };

    handleSiteUpdate = (site: Site): void => {
        const newStateUpdate = {
            sites: this.state.sites.map((s) => {
                // We return our new site in place of the old site if the
                // stable id matches
                return s.id === site.id ? site : s;
            }),
        };

        this.setState(newStateUpdate);
    };

    hideModal = () => this.setState({ modalOpen: false });

    cancel = () => {
        this.revertSettings();
        this.props.history.push("/");
    };

    refreshPage = () => {
        this.revertSettings();
        this.hideModal();
    };
    public render() {
        const { adminSettings, sites, hasChanges, modalOpen } = this.state;
        const { googleApiKey } = this.props;
        const portalSettingsState = adminSettings.portalSettings;
        const backendSettingsState = adminSettings.backendSettings;
        const volumeAlarmBaseSeverity = backendSettingsState.alarmCodes.filter(
            (x) =>
                x.alarmSourceId === AlertSource.ScatsSiteDetectorThresholdAlarm && x.code === volumeAlarmCode
        )[0].alertSeverity;
        const detectorAlarmBaseSeverity = backendSettingsState.alarmCodes.filter(
            (x) => x.alarmSourceId === AlertSource.ScatsSiteDetectorAlarm && x.code === detectorAlarmCode
        )[0].alertSeverity;
        return (
            <div className="admin-settings">
                {
                    this.props.isLoading &&
                    <Spinner dim />
                }
                {modalOpen && (
                    <Modal isLoading={false} title="Settings Outdated" closeModal={this.hideModal}>
                        <div className="modal-content">
                            It looks like someone has edited the settings while you were still on
                            this page.
                            <br />
                            Would you like to refresh to get the latest changes?
                        </div>
                        <div className="modal-actions">
                            <button
                                type="button"
                                className="btn btn-save"
                                onClick={this.refreshPage}
                            >
                                Refresh
                            </button>
                            <button
                                type="button"
                                className="btn btn-cancel"
                                onClick={this.hideModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                )}
                <div className="setting-controls">
                    <button className="close-button btn" onClick={this.cancel}>
                        <img src={iconClose} width={28} height={28} alt="Close icon" />
                    </button>
                    <button className="save-button btn" disabled={!hasChanges} onClick={this.saveSettings}>
                        <img src={iconSave} width={28} height={28} alt="Save icon" />
                    </button>
                </div>
                <div className="container">
                    <div className="section">
                        <h1 className="mt-5">Admin Settings</h1>
                    </div>
                    <AdminSettingSection
                        sectionTitle="Alarm Severity"
                        sectionSummary="Set the threshold and severity of various alarm types."
                    >
                        {backendSettingsState && (
                            <>
                                <AdminSettingSubSection sectionTitle="Site Alarm Thresholds">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the threshold for TDAP site priorities before alerts occur. Individual SCATS site priorities can be configured in the SCATS Site Alarms section, AddInsight sites will use the 'Default' priority"
                                    />
                                    <SitePriorityTableSetting
                                        sitePrioritySettings={
                                            adminSettings.backendSettings.sitePrioritySettings
                                        }
                                        handleUpdate={this.handleSitePriorityThresholdUpdate}
                                    />
                                </AdminSettingSubSection>

                                <AdminSettingSubSection sectionTitle="SCATS Site Priorities">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the priority of SCATS sites. Priorities enable sites to be linked to some common alerting thresholds, see Site Alarm Thresholds section for visibility over configured thresholds"
                                    />
                                    <SiteAlarmTableSetting
                                        sites={sites}
                                        sitePrioritySettings={
                                            adminSettings.backendSettings.sitePrioritySettings
                                        }
                                        handleSiteUpdate={this.handleSiteUpdate}
                                    />
                                </AdminSettingSubSection>

                                <AdminSettingSubSection sectionTitle="SCATS Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the alert severity of SCATS Alarms"
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[AlertSource.ScatsSiteAlarm]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="SCATS Volume Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the severity of SCATS Volume Alarms. SCATS Volume data is received in 5 minutes intervals."
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[
                                            AlertSource.ScatsSiteDetectorThresholdAlarm,
                                        ]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                    <TextSettingControl
                                        explanation="Sets the threshold for the minimum number of cars required to trigger a volume detector alert."
                                        settingValue={backendSettingsState.minVolumeCarThreshold.toString()}
                                        settingPropName={"minVolumeCarThreshold"}
                                        settingTitle="Minimum Car Threshold"
                                        handleSettingUpdate={this.handleBackendSettingUpdate}
                                        type={"number"}
                                    />
                                    <SliderSettingControl
                                        explanation="Sets the threshold for the minimum multiplier required to trigger a volume detector alert."
                                        min={1.0}
                                        max={5.0}
                                        step={0.1}
                                        settingValue={
                                            backendSettingsState.minVolumeMultiplierThreshold
                                        }
                                        settingPropName={"minVolumeMultiplierThreshold"}
                                        settingTitle="Minimum Threshold Multiplier"
                                        handleSettingUpdate={this.handleBackendSettingUpdate}
                                        unit={"x"}
                                    />
                                    <TextSettingControl
                                        explanation="Sets the severity increase per 100% more than the Minimum Threshold Multiplier."
                                        settingValue={backendSettingsState.scorePerVolumeIncrease.toString()}
                                        settingPropName={"scorePerVolumeIncrease"}
                                        settingTitle="Score Per 100% Increase"
                                        handleSettingUpdate={this.handleBackendSettingUpdate}
                                        type={"number"}
                                    />
                                    <TextSettingControl
                                        explanation="Sets the maximum possible severity for a volume alert."
                                        settingValue={backendSettingsState.maximumVolumeScore.toString()}
                                        settingPropName={"maximumVolumeScore"}
                                        settingTitle="Maximum Volume Severity"
                                        handleSettingUpdate={this.handleBackendSettingUpdate}
                                        type={"number"}
                                    />
                                    {volumeAlarmBaseSeverity && (
                                        <VolumeSettingExample
                                            volumeAlarmBaseSeverity={volumeAlarmBaseSeverity}
                                            maximumVolumeScore={
                                                backendSettingsState.maximumVolumeScore
                                            }
                                            minVolumeMultiplierThreshold={
                                                backendSettingsState.minVolumeMultiplierThreshold
                                            }
                                            scorePerVolumeIncrease={
                                                backendSettingsState.scorePerVolumeIncrease
                                            }
                                        />
                                    )}
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="SCATS Detector Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation={`Set the severity of SCATS Detector Alarms. SCATS Detector Alarms combine their severity E.g. 3 Detector alarms detector alarms on a single site would calculate as 3 * ${detectorAlarmBaseSeverity} = ${3 * detectorAlarmBaseSeverity}.`}
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[AlertSource.ScatsSiteDetectorAlarm]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="AddInsight Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the severity of AddInsight Alarms"
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[AlertSource.AddInsightSiteAlarm]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                    <div className="explanation-box">
                                        <div className="explanation-title">Note</div>
                                        <div className="explanation-text">
                                            AddInsight Flow Restriction ({flowRestrictionAlarmCode}) alarms apply to the site at the <strong>start</strong> of the link.<br />AddInsight ({addinsightAlarmCode.trim()}) & Severe AddInsight ({severeAddinsightAlarmCode}) alarms apply to the site at the <strong>end</strong> of the link.
                                        </div>
                                    </div>
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="SharePoint Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the severity of SharePoint Alarms"
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[AlertSource.SharePoint]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="Power Supply (WEL) Alarms">
                                    <AdminSettingExplanation
                                        className="pl-2"
                                        explanation="Set the severity of Power Supply Alarms"
                                    />
                                    <AlarmTableSetting
                                        criticalAlertThreshold={
                                            backendSettingsState.criticalAlertThreshold
                                        }
                                        mediumAlertThreshold={
                                            backendSettingsState.mediumAlertThreshold
                                        }
                                        alarmCodes={backendSettingsState.alarmCodes}
                                        filterBySource={[AlertSource.PowerSupply]}
                                        updateAlarmCodes={(codes) =>
                                            this.handleBackendSettingUpdate("alarmCodes", codes)
                                        }
                                    />
                                </AdminSettingSubSection>
                            </>
                        )}
                    </AdminSettingSection>
                    <AdminSettingSection
                        sectionTitle="Mobile Alert Settings"
                        sectionSummary="Set the rules for triggering mobile alerts, who the recipients are and when the alerts can be sent."
                    >
                        <AdminSettingSubSection sectionTitle="Groups">
                            <AdminSettingExplanation
                                className="pl-2"
                                explanation="Manage groups for mobile alerts."
                            />
                            <MobileGroupsTableSetting
                                groups={backendSettingsState.mobileAlertGroups}
                                updateMobileAlertGroups={(groups: MobileAlertGroup[], deletedGroup: MobileAlertGroup | null) =>
                                    this.handleMobileAlertGroupUpdate(groups, deletedGroup)
                                }
                            />
                        </AdminSettingSubSection>
                        <AdminSettingSubSection sectionTitle="Recipients">
                            <AdminSettingExplanation
                                className="pl-2"
                                explanation="Manage the recipients of mobile alerts."
                            />
                            <MobileRecipientTableSetting
                                recipients={backendSettingsState.mobileAlertRecipients}
                                groups={backendSettingsState.mobileAlertGroups}
                                updateMobileAlertRecipients={(recipients: MobileAlertRecipient[]) =>
                                    this.handleMobileAlertRecipientUpdate(recipients)
                                }
                            />
                        </AdminSettingSubSection>
                        <AdminSettingSubSection sectionTitle="Rules">
                            <AdminSettingExplanation
                                className="pl-2"
                                explanation="Manage rules for mobile alerts."
                            />
                            <MobileRulesTableSetting
                                rules={backendSettingsState.mobileAlertRules}
                                groups={backendSettingsState.mobileAlertGroups}
                                alarmCodes={backendSettingsState.alarmCodes}
                                updateMobileAlertRules={(rules: MobileAlertRule[]) =>
                                    this.handleMobileAlertRulesUpdate(rules)
                                }
                            />
                        </AdminSettingSubSection>
                        <AdminSettingSubSection sectionTitle="Time Constraints">
                            <TimeSettingControl
                                explanation="Sets the minimum daytime that mobile alerts can be sent."
                                settingValue={backendSettingsState.minDayTimeHoursInMinutes}
                                settingPropName={"minDayTimeHoursInMinutes"}
                                settingTitle="Minimum Daytime Hour"
                                handleSettingUpdate={this.handleBackendSettingUpdate}
                            />
                            <TimeSettingControl
                                explanation="Sets the maximum daytime that mobile alerts can be sent."
                                settingValue={backendSettingsState.maxDayTimeHoursInMinutes}
                                settingPropName={"maxDayTimeHoursInMinutes"}
                                settingTitle="Maximum Daytime Hour"
                                handleSettingUpdate={this.handleBackendSettingUpdate}
                            />
                            <TextSettingControl
                                explanation={`Sets the minimum required time in seconds for an alarm to be open for it to trigger a mobile alert. I.E. An open alarm that triggered more than ${backendSettingsState.minTimeForMobileAlertToTrigger} seconds ago is able to trigger a mobile alert, if the rule conditions are met.`}
                                settingValue={backendSettingsState.minTimeForMobileAlertToTrigger.toString()}
                                settingPropName={"minTimeForMobileAlertToTrigger"}
                                settingTitle="Minimum Open Time"
                                handleSettingUpdate={this.handleBackendSettingUpdate}
                                type={"number"}
                            />
                        </AdminSettingSubSection>
                        <AdminSettingSubSection sectionTitle="System Down Notifications">
                            <AdminSettingExplanation
                                className="pl-2"
                                explanation=""
                            />
                            <BoolSettingControl
                                explanation=""
                                settingValue={backendSettingsState.isSysDownNotificationEnabled}
                                settingPropName={"isSysDownNotificationEnabled"}
                                settingTitle="Notify recipients when SCATS events have not been received for three minutes"
                                handleSettingUpdate={this.handleBackendSettingUpdate}
                            />
                            <DropDownSettingControl
                                explanation={"Selects the Mobile Alert Group that System Down Alerts should be sent to."}
                                settingValue={backendSettingsState?.sysDownGroupId}
                                dropDownKeyValues={backendSettingsState.mobileAlertGroups.map<DropDownKeyVaule>(x => ({
                                    key: x.id.toString(),
                                    value: x.name
                                }))}
                                settingPropName={"sysDownGroupId"}
                                settingTitle="Mobile Alert Group Id"
                                handleSettingUpdate={this.handleBackendSettingUpdate}
                            />

                        </AdminSettingSubSection>
                    </AdminSettingSection>
                    <AdminSettingSection
                        sectionTitle="Map Settings"
                        sectionSummary="Set the initial position and adaptive zoom of the map."
                    >
                        {adminSettings && (
                            <AdminSettingSubSection sectionTitle="Map Starting Position">
                                <SliderSettingControl
                                    explanation="Sets the bounding box for what sites will be shown in the initial map load. The blue bounding box on the map below indicates what sites are included."
                                    unit="km"
                                    updateOnChange
                                    min={1}
                                    max={100}
                                    step={1}
                                    settingValue={portalSettingsState.mapAdaptiveZoomRadius}
                                    settingPropName={"mapAdaptiveZoomRadius"}
                                    settingTitle="Adaptive Zoom Radius"
                                    handleSettingUpdate={this.handlePortalSettingUpdate}
                                    indicator={distanceIndicator}
                                />
                                <TextSettingControl
                                    explanation="Sets the Latitude for the centre of the map. You can also set this by dragging the blue marker."
                                    settingValue={portalSettingsState.mapStartingLatitude.toString()}
                                    settingPropName={"mapStartingLatitude"}
                                    settingTitle="Latitude"
                                    handleSettingUpdate={this.handlePortalSettingUpdate}
                                    type={"float"}
                                />
                                <TextSettingControl
                                    explanation="Sets the Longitude for the centre of the map. You can also set this by dragging the blue marker."
                                    settingValue={portalSettingsState.mapStartingLongitude.toString()}
                                    settingPropName={"mapStartingLongitude"}
                                    settingTitle="Longitude"
                                    handleSettingUpdate={this.handlePortalSettingUpdate}
                                    type={"float"}
                                />
                                <MapSettingControl
                                    sites={sites}
                                    googleApiKey={googleApiKey}
                                    lat={portalSettingsState.mapStartingLatitude}
                                    lng={portalSettingsState.mapStartingLongitude}
                                    adaptiveZoomRadius={portalSettingsState.mapAdaptiveZoomRadius}
                                    handleSettingUpdate={this.handlePortalSettingUpdate}
                                />
                            </AdminSettingSubSection>
                        )}
                    </AdminSettingSection>
                    <AdminSettingSection
                        sectionTitle="Bus Settings"
                        sectionSummary="Update the colour, scale, threshold and visibility of buses."
                    >
                        {adminSettings && (
                            <div>
                                <AdminSettingSubSection sectionTitle="Colour">
                                    <ColorSettingControl
                                        explanation="Sets the icon colour for the buses that are running early."
                                        sampleIconHtml={busIconHtml}
                                        settingValue={portalSettingsState.earlyBusColour}
                                        settingPropName={"earlyBusColour"}
                                        settingTitle="Early Bus Colour"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <ColorSettingControl
                                        explanation="Sets the icon colour for the buses that are running on time."
                                        sampleIconHtml={busIconHtml}
                                        settingValue={portalSettingsState.onTimeBusColour}
                                        settingPropName={"onTimeBusColour"}
                                        settingTitle="On-Time Bus Colour"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <ColorSettingControl
                                        explanation="Sets the icon colour for the buses that are running late."
                                        sampleIconHtml={busIconHtml}
                                        settingValue={portalSettingsState.lateBusColour}
                                        settingPropName={"lateBusColour"}
                                        settingTitle="Late Bus Colour"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <ColorSettingControl
                                        explanation="Sets the icon colour for the buses that are at a stop."
                                        sampleIconHtml={busIconHtml}
                                        settingValue={portalSettingsState.atStopBusColour}
                                        settingPropName={"atStopBusColour"}
                                        settingTitle="At Stop Bus Colour"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="Scale">
                                    <SliderSettingControl
                                        explanation="Multiplies the size of the icon by the specified amount."
                                        min={0.5}
                                        max={2.5}
                                        step={0.1}
                                        settingValue={portalSettingsState.earlyBusScale}
                                        settingPropName={"earlyBusScale"}
                                        settingTitle="Early Bus Scale"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        sampleIcon={busIconHtml("#999")}
                                        updateOnChange
                                        unit={"x"}
                                        indicator={scaleIndicator}
                                    />
                                    <SliderSettingControl
                                        explanation="Multiplies the size of the icon by the specified amount."
                                        min={0.5}
                                        max={2.5}
                                        step={0.1}
                                        settingValue={portalSettingsState.onTimeBusScale}
                                        settingPropName={"onTimeBusScale"}
                                        settingTitle="On-Time Bus Scale"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        sampleIcon={busIconHtml("#999")}
                                        updateOnChange
                                        unit={"x"}
                                        indicator={scaleIndicator}
                                    />
                                    <SliderSettingControl
                                        explanation="Multiplies the size of the icon by the specified amount."
                                        min={0.5}
                                        max={2.5}
                                        step={0.1}
                                        settingValue={portalSettingsState.lateBusScale}
                                        settingPropName={"lateBusScale"}
                                        settingTitle="Late Bus Scale"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        sampleIcon={busIconHtml("#999")}
                                        updateOnChange
                                        unit={"x"}
                                        indicator={scaleIndicator}
                                    />
                                    <SliderSettingControl
                                        explanation="Multiplies the size of the icon by the specified amount."
                                        min={0.5}
                                        max={2.5}
                                        step={0.1}
                                        settingValue={portalSettingsState.atStopBusScale}
                                        settingPropName={"atStopBusScale"}
                                        settingTitle="At Stop Bus Scale"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        sampleIcon={busIconHtml("#999")}
                                        updateOnChange
                                        unit={"x"}
                                        indicator={scaleIndicator}
                                    />
                                    <BoolSettingControl
                                        explanation="Multiplies the size of the bus icon based on how early or late the bus is running."
                                        settingValue={portalSettingsState.scaleBasedOnAdherence}
                                        settingPropName={"scaleBasedOnAdherence"}
                                        settingTitle="Scale Based on Adherence"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="Threshold">
                                    <SliderSettingControl
                                        unit="Mins"
                                        explanation={
                                            "Defines what an early bus is. I.e Buses running earlier than " +
                                            portalSettingsState.earlyBusThreshold +
                                            " minutes are considered early."
                                        }
                                        min={1}
                                        max={10}
                                        step={0.1}
                                        settingValue={portalSettingsState.earlyBusThreshold}
                                        settingPropName={"earlyBusThreshold"}
                                        settingTitle="Early Bus Threshold"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        indicator={timeIndicator}
                                    />
                                    <SliderSettingControl
                                        unit="Mins"
                                        explanation={
                                            "Defines what a late bus is. I.e Buses running later than " +
                                            portalSettingsState.lateBusThreshold +
                                            " minutes are considered late."
                                        }
                                        min={1}
                                        max={10}
                                        step={0.1}
                                        settingValue={portalSettingsState.lateBusThreshold}
                                        settingPropName={"lateBusThreshold"}
                                        settingTitle="Late Bus Threshold"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                        indicator={timeIndicator}
                                    />
                                </AdminSettingSubSection>
                                <AdminSettingSubSection sectionTitle="Visibility">
                                    <BoolSettingControl
                                        explanation="Toggles the visibility of early buses."
                                        settingValue={portalSettingsState.earlyBusToggle}
                                        settingPropName={"earlyBusToggle"}
                                        settingTitle="Show Early Buses"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Toggles the visibility of on-time buses."
                                        settingValue={portalSettingsState.onTimeBusToggle}
                                        settingPropName={"onTimeBusToggle"}
                                        settingTitle="Show On-Time Buses"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Toggles the visibility of late buses."
                                        settingValue={portalSettingsState.lateBusToggle}
                                        settingPropName={"lateBusToggle"}
                                        settingTitle="Show Late Buses"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Toggles the visibility of buses at bus stops."
                                        settingValue={portalSettingsState.atStopBusToggle}
                                        settingPropName={"atStopBusToggle"}
                                        settingTitle="Show Buses at Stops"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Toggles the visibility of cancelled buses."
                                        settingValue={portalSettingsState.cancelledBusToggle}
                                        settingPropName={"cancelledBusToggle"}
                                        settingTitle="Show Cancelled Buses"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Adds an arrow showing the current heading of the bus."
                                        settingValue={portalSettingsState.showBusDirection}
                                        settingPropName={"showBusDirection"}
                                        settingTitle="Show Bus Direction"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    <BoolSettingControl
                                        explanation="Buses will only be visible if they are near a link that has a score greater than 1. Other bus visibility filters will still apply."
                                        settingValue={portalSettingsState.filterBusesToBusyLinks}
                                        settingPropName={"filterBusesToBusyLinks"}
                                        settingTitle="Only show buses in unusual congestion"
                                        handleSettingUpdate={this.handlePortalSettingUpdate}
                                    />
                                    {portalSettingsState.filterBusesToBusyLinks &&
                                        <SliderSettingControl
                                            explanation="The distance in meters away from a link to show a bus in unusual congestion."
                                            settingValue={portalSettingsState.filterBusesToBusyLinksDistance}
                                            settingPropName={"filterBusesToBusyLinksDistance"}
                                            settingTitle="Bus Link Filter Distance"
                                            handleSettingUpdate={this.handlePortalSettingUpdate}
                                            unit={"meters"}
                                            min={5}
                                            max={50}
                                            step={1}
                                        />
                                    }
                                    {portalSettingsState.filterBusesToBusyLinks &&
                                        <SliderSettingControl
                                            explanation="The absolute angle in degrees that determines if a bus and link are facing the same direction."
                                            settingValue={portalSettingsState.filterBusesToBusyLinksAngle}
                                            settingPropName={"filterBusesToBusyLinksAngle"}
                                            settingTitle="Bus Link Filter Angle"
                                            handleSettingUpdate={this.handlePortalSettingUpdate}
                                            unit={"degrees"}
                                            min={0}
                                            max={90}
                                            step={1}
                                        />
                                    }

                                </AdminSettingSubSection>
                            </div>
                        )}
                    </AdminSettingSection>
                    <div className="section">
                        <button
                            className="btn primary"
                            disabled={!hasChanges}
                            onClick={this.saveSettings}
                        >
                            Save Settings
                        </button>
                        <button className="btn secondary" onClick={this.cancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AdminSettings);
