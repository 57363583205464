import * as React from "react";
import "./controls.scss";
import { SitePrioritySetting } from "store/adminSettings";

export class SitePriorityTableProps {
    public sitePrioritySettings: SitePrioritySetting[]
    public handleUpdate: (sitePrioritySettings: SitePrioritySetting) => void

    constructor(sitePrioritySettings: SitePrioritySetting[], handleUpdate: (site: SitePrioritySetting) => void) {
        this.sitePrioritySettings = sitePrioritySettings
        this.handleUpdate = handleUpdate
    }
}

export class SitePriorityTableSetting extends React.Component<SitePriorityTableProps> {
    constructor(props: SitePriorityTableProps) {
        super(props)

        this.state = { filter: null }
    }

    public handleMediumThresholdChange(sps: SitePrioritySetting, e: React.ChangeEvent<HTMLInputElement>) {
        const v = e.target.valueAsNumber;

        this.props.handleUpdate(Object.assign({ ...sps }, { mediumAlertThreshold: v }))
    }

    public handleCriticalThresholdChange(sps: SitePrioritySetting, e: React.ChangeEvent<HTMLInputElement>) {
        const v = e.target.valueAsNumber;

        this.props.handleUpdate(Object.assign({ ...sps }, { criticalAlertThreshold: v }))
    }

    public render() {
        const sitePrioritySettings =
            this.props.sitePrioritySettings || [];

        return (
            <div className="settings-table container">
                <div className="row settings-header-row">
                    <div className="col-4">Priority</div>
                    <div className="col-4">Medium Threshold</div>
                    <div className="col-4">Critical Threshold</div>
                </div>
                {sitePrioritySettings && sitePrioritySettings.map((sps, i) =>
                    <div key={i} className={"row settings-row" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                        <div className="col-4">{sps.name}</div>
                        <div className="col-4">
                            <div className="custom-value-input">
                                <input type="number" value={sps.mediumAlertThreshold} onChange={e => this.handleMediumThresholdChange(sps, e)}></input>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="custom-value-input">
                                <input type="number" value={sps.criticalAlertThreshold} onChange={e => this.handleCriticalThresholdChange(sps, e)} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SitePriorityTableSetting;
