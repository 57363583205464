import { CycleCounterDetectionsState, CycleCounterDetection } from "store/cycleCounterDetections";
import _ from "lodash";
import moment from "moment"

export interface GraphData {
    x: string;
    y: number;
}

class CycleCounterHelper {
    getSummary = (detections: CycleCounterDetectionsState, deviceId: string | null | undefined) => {
        const detectionsFiltered: CycleCounterDetection[] = Object.values(detections.cycleCounterDetections).filter((x) => x.deviceId === deviceId);

        //get unique
        const uniqueRes = _.uniqBy(detectionsFiltered, 'telemetryId');

        // group by timestamp
        const grouping = _.groupBy(uniqueRes, (item) => {
            return moment(new Date(item.timeStamp)).format('yyyy-MM-DD')
        });

        // produce summary
        const summary: GraphData[] = Object.values(grouping).map((item) => {
            const x = moment(new Date(item[0].timeStamp)).format('yyyy-MM-DD');
            const y = item.length;
            return {
                x,
                y,
            };
        });

        return summary;
    };

    getTodaysTotalForDevice = (detections: CycleCounterDetectionsState, deviceId: string | null | undefined) => {
        const summary = this.getSummary(detections, deviceId);

        const todayDateString = new Date().toDateString();

        if (summary && summary.length) {
            const sorted = _.sortBy(summary, "x").reverse();
            const latestDateString = new Date(sorted[0].x).toDateString();
            const isToday = latestDateString === todayDateString;

            return isToday ? sorted[0].y : 0;
        }

        return 0;
    };
}

export const cycleCounterHelper = new CycleCounterHelper();
