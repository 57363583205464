import React from 'react';
import { CustomPicker } from 'react-color';
import { EditableInput } from 'react-color/lib/components/common'
import './colorPicker.scss'
import { baseIconSize } from '../../../constants/mapConstants';

interface MyColorPickerProps {
    hex: string;
    colors: string[];
    onChange?: () => void;
    setColor: (hex: string) => void;
    sampleIcon: (color: string) => JSX.Element;
}

class ColorPicker extends React.Component<MyColorPickerProps> {

    render() {
        return (
            <div className="custom-color-picker" >

                <div className="swatches-container">
                    <div className="swatches-title">
                        Colour Options
                    </div>
                    <div className="swatches">
                        {this.props.colors.map(x =>
                            <span key={x}><div className={"swatch" + (x.toUpperCase() === this.props.hex.toUpperCase() ? " selected" : "")} onClick={() => this.props.setColor(x)} title={x} style={{ background: x }} /></span>
                        )}
                    </div>
                </div>
                <div className="editable-input-container">
                    <div className="editable-input-title">
                        Custom Colour
                    </div>
                    <div className="editable-input">
                        <div className={"sample-colour"} style={{ background: this.props.hex }} />
                        <EditableInput
                            value={this.props.hex}
                            onChange={this.props.onChange}
                        />
                    </div>
                </div>
                {
                    this.props.sampleIcon &&
                    <div className="sampleIcon-container">
                        <div className="sampleIcon-title">
                            Icon
                        </div>
                        <div className="sampleIcon" style={{ height: `${baseIconSize}px`, width: `${baseIconSize}px` }}>
                            {this.props.sampleIcon(this.props.hex)}
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default CustomPicker(ColorPicker);