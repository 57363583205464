import React from "react";
import "./sideBarCycleCounterItem.scss";
import { CycleCounterDevice } from "store/cycleCounterDevices";
import SidebarItem from "./sidebarItem";

export interface SideBarCycleCounterItemProps {
    device: CycleCounterDevice;
    expand: boolean;
    selectDevice(id: string, zoom: boolean): void;
    icon: string | undefined;
    isSelected: boolean;
}

const SideBarCycleCounterItem: React.FC<SideBarCycleCounterItemProps> = (props) => {
    return (
        <SidebarItem
            isSelected={props.isSelected}
            onClick={() => props.selectDevice(props.device.id, true)}
            className="sidebarCycleCounterItem"
        >
            <div className="statusIcon">
                <img className="collapse-icon" src={props.icon} alt="Collapse All" />
            </div>
            { props.expand &&
                <div className="deviceName">{props.device.id}</div>
            }
        </SidebarItem>
    );
};

export default SideBarCycleCounterItem;