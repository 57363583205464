import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store";
import Map, { SiteMarker } from "../components/map/map";
import { LatLng } from "leaflet";
import * as SiteState from "store/sites";
import * as MapState from "store/map";
import * as AlertState from "store/alarm";
import * as LogStore from "store/logs";
import * as AddInsightStore from "store/addInsight";
import * as SettingsStore from "store/appSettings";
import * as UserSettingsStore from "store/userSettings";
import * as BusStore from "store/buses";
import * as SchoolSignStore from "store/schoolSigns";
import * as AdminSettingsStore from "store/adminSettings";
import * as CycleCounterDevicesStore from "store/cycleCounterDevices";
import * as CycleCounterDetectionsStore from "store/cycleCounterDetections";
import * as PumpDevicesStore from "store/Pumps/pumpsDataApi";
import * as PumpDevicesTypes from "store/Pumps/pumpsDatatypes";
import * as ptld from "@turf/point-to-line-distance";
import * as helpers from "@turf/helpers";
import {
    highAlertIcon,
    mediumAlertIcon,
    lowAlertIcon,
    defaultBusIcon,
    DivIconOptions,
    schoolSignOffIcon,
    schoolSignStaticIcon,
    schoolSignOnIcon,
    schoolSignIdleIcon,
    schoolSignNotTalkingIcon,
} from "../components/map/icons";
import {
    adherenceMillisecondsToDisplayTime,
    metersPerSecondToKilometersPerHour,
    getLastGpsPing,
} from "../components/map/bus/utils";
import { BusMarker, BusStatus } from "store/buses";
import { LineString, Point, Feature } from "@turf/helpers";
import { SchoolSignMarker, SchoolSignStatus } from "store/schoolSigns";
import { PumpDevice } from "store/Pumps/pumpsDatatypes";

// At runtime, Redux will merge together...
type MapTestContainerProps = MapState.MapState & // ... state we've requested from the Redux store
    SiteState.SiteState &
    AdminSettingsStore.AdminSettingsState &
    AlertState.AlertState &
    SchoolSignStore.SchoolSignState &
    AddInsightStore.AddInsightState & // ... plus state models
    SettingsStore.AppSettingsState &
    UserSettingsStore.UserSettingsState &
    LogStore.LogState &
    BusStore.BusState &
    SchoolSignStore.SchoolSignState &
    CycleCounterDetectionsStore.CycleCounterDetectionsState &
    CycleCounterDevicesStore.CycleCounterDevicesState &
    typeof MapState.actionCreators & // ... plus action creators we've requested
    typeof AddInsightStore.actionCreators &
    typeof SiteState.actionCreators &
    typeof AdminSettingsStore.actionCreators &
    typeof LogStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof SchoolSignStore.actionCreators &
    typeof CycleCounterDevicesStore.actionCreators &
    typeof CycleCounterDetectionsStore.actionCreators &
    typeof PumpDevicesStore.actionCreators &
    PumpDevicesTypes.PumpDevicesState;

export interface MapContainerState {
    refresh: NodeJS.Timer | undefined;
}

const buildPoint = (lat: number, lng: number) => {
    const point = helpers.point([lng, lat]);
    return point;
};

const checkDistance = (point: Feature<Point>, linestring: Feature<LineString>, meters: number) => {
    const distance = ptld.default(point, linestring, { method: "geodesic", units: "meters" });
    return distance < meters;
};

class MapContainer extends React.Component<MapTestContainerProps, MapContainerState> {
    constructor(props: MapTestContainerProps) {
        super(props);
        this.pinMarkers = this.pinMarkers.bind(this);
        this.getAddInsightLinks = this.getAddInsightLinks.bind(this);
        this.getBuses = this.getBuses.bind(this);
        this.getSchoolSigns = this.getSchoolSigns.bind(this);
        this.getCycleCounterDevices = this.getCycleCounterDevices.bind(this);
    }

    public pinMarkers(): SiteMarker[] {
        if (!this.props.userSettings) {
            return [];
        }
        const { adminSettings } = this.props;

        // TODO: Get alerts for each site initially, then implement the logic to use pinned markers
        const sites = Object.values(this.props.sites).filter(this.filterSitesBasedOnToggles);
        return sites.map<SiteMarker>((site) => {
            let icon = lowAlertIcon;
            const severity = site.currentStatus;
            const hasOpenLog = LogStore.LogSelectors.GetLogsBySiteId(this.props, site.id).filter((x) => x.dateClosed === null).length > 0;
            const unread = LogStore.LogSelectors.GetLogsBySiteId(this.props, site.id).filter((x) => x.unread === true).length > 0;
            const sitePriority = adminSettings
                ? adminSettings.backendSettings.sitePrioritySettings.filter((p) => p.priority === site.priority)[0]
                : {
                    priority: 0,
                    name: "Default",
                    mediumAlertThreshold: 5000,
                    criticalAlertThreshold: 10000,
                };

            if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
                icon = highAlertIcon;
            } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
                icon = mediumAlertIcon;
            }
            const className = (unread ? "unread" : "") + (hasOpenLog ? " open-log" : "") + (site.id === this.props.selectedSiteId ? " selected-site" : "");
            icon = { ...icon, className: className };

            return {
                id: site.id,
                icon: icon,
                description: site.description,
                position: new LatLng(site.latitude, site.longitude),
                clickCallback: () => {
                    this.props.closeLogViewer();
                    this.props.closeLinkPanel();
                    this.props.selectSite(site.id, false);
                },
            };
        });
    }

    private filterSitesBasedOnToggles = (site: SiteState.Site) => {
        const { userSettings } = this.props;
        return (userSettings.scatsEnabled && site.scatsSiteId != null) || (userSettings.addInsightEnabled && site.addInsightSiteId != null);
    };

    public getCycleCounterDevices = (): CycleCounterDevicesStore.CycleCounterDevice[] => {
        const { cycleCounterDevices, userSettings } = this.props;
        if (!this.props.userSettings || !userSettings.cycleCountersEnabled) {
            return [];
        }

        return Object.values(cycleCounterDevices);
    };

    public getCycleCounterDetections = (): CycleCounterDetectionsStore.CycleCounterDetection[] => {
        const { cycleCounterDetections, userSettings } = this.props;
        if (!this.props.userSettings || !userSettings.cycleCountersEnabled) {
            return [];
        }

        return Object.values(cycleCounterDetections);
    };

    public getPumpDevices = (): PumpDevice[] => {
        const { pumpDevices, userSettings } = this.props;
        if (!this.props.userSettings || !userSettings.pumpsEnabled) {
            return [];
        }

        return Object.values(pumpDevices);
    };

    public getAddInsightLinks(): AddInsightStore.AddInsightLink[] {
        const { userSettings, links } = this.props;
        return userSettings.addInsightEnabled ? (links ? Object.values(links) : []) : [];
    }

    public getSchoolSigns(): SchoolSignStore.SchoolSignMarker[] {
        const { userSettings } = this.props;

        if (!this.props.userSettings || !userSettings.schoolSignsEnabled) {
            return [];
        }

        const schoolSigns = Object.values(this.props.schoolSigns);

        return schoolSigns.map<SchoolSignMarker>((schoolSign) => {
            let icon = schoolSignOffIcon;
            const status = schoolSign.status;

            if (status === SchoolSignStatus.On) {
                icon = schoolSignOnIcon;
            } else if (status === SchoolSignStatus.Forty) {
                icon = schoolSignOnIcon;
            } else if (status === SchoolSignStatus.Off) {
                icon = schoolSignOffIcon;
            } else if (status === SchoolSignStatus.Static) {
                icon = schoolSignStaticIcon;
            } else if (status === SchoolSignStatus.Idle) {
                icon = schoolSignIdleIcon;
            } else if (status === SchoolSignStatus.NotTalking) {
                icon = schoolSignNotTalkingIcon;
            }

            const className = schoolSign.signId === this.props.selectedSchoolSignId ? " selected-site" : "";

            icon = { ...icon, className: className };

            return {
                id: schoolSign.signId,
                schoolName: schoolSign.schoolName,
                signName: schoolSign.signName,
                status: schoolSign.status,
                position: new LatLng(schoolSign.latitude, schoolSign.longitude),
                lastSeen: schoolSign.lastSeen,
                icon: icon,
                clickCallback: () => {
                    this.props.closeLogViewer();
                    this.props.closeLinkPanel();
                    this.props.selectSchoolSign(schoolSign.signId, false);
                },
            };
        });
    }

    public getBuses(links: AddInsightStore.AddInsightLink[]): BusMarker[] {
        const { userSettings, buses, adminSettings } = this.props;
        //only get buses if they are enabled and the admin settings are available
        if (!userSettings.busesEnabled || !adminSettings) {
            return [];
        }
        const aiLinks = links.filter((x) => (x.enoughData && x.score > 0) || x.flowRestrictionScore > 0);

        //quick escape if filtering to links and there are no active links
        if (adminSettings.portalSettings.filterBusesToBusyLinks && !aiLinks.length) {
            return [];
        }

        const busMarkers = Object.values(buses).reduce(function (result: BusMarker[], bus) {
            let iconOpt: DivIconOptions = {
                ...defaultBusIcon,
                shouldScaleByTime: adminSettings.portalSettings.scaleBasedOnAdherence,
            };

            let busStatus = BusStatus.Unknown;

            //determine bus status and style bus accordingly
            if (bus.schAdhSecs != null) {
                const seconds = Math.round(bus.schAdhSecs);
                const minutes = seconds / 60;
                const lateThreshold = adminSettings.portalSettings.lateBusThreshold;
                const earlyThreshold = -adminSettings.portalSettings.earlyBusThreshold;

                if (bus.isAtStop) {
                    iconOpt = { ...iconOpt, color: adminSettings.portalSettings.atStopBusColour, scale: adminSettings.portalSettings.atStopBusScale };
                    busStatus = BusStatus.AtStop;
                } else if (minutes <= lateThreshold && minutes >= earlyThreshold) {
                    iconOpt = { ...iconOpt, color: adminSettings.portalSettings.onTimeBusColour, scale: adminSettings.portalSettings.onTimeBusScale };
                    busStatus = BusStatus.OnTime;
                } else if (minutes < earlyThreshold) {
                    iconOpt = { ...iconOpt, color: adminSettings.portalSettings.earlyBusColour, scale: adminSettings.portalSettings.earlyBusScale };
                    busStatus = BusStatus.Early;
                } else if (minutes > lateThreshold) {
                    iconOpt = { ...iconOpt, color: adminSettings.portalSettings.lateBusColour, scale: adminSettings.portalSettings.lateBusScale };
                    busStatus = BusStatus.Late;
                }
            }

            //filter out any unwanted buses
            if (
                (!adminSettings.portalSettings.earlyBusToggle && busStatus === BusStatus.Early) ||
                (!adminSettings.portalSettings.onTimeBusToggle && busStatus === BusStatus.OnTime) ||
                (!adminSettings.portalSettings.lateBusToggle && busStatus === BusStatus.Late) ||
                (!adminSettings.portalSettings.atStopBusToggle && busStatus === BusStatus.AtStop) ||
                (!adminSettings.portalSettings.cancelledBusToggle && bus.isCanceled) ||
                busStatus === BusStatus.Unknown
            ) {
                return result;
            }

            if (adminSettings.portalSettings.filterBusesToBusyLinks) {
                let closeToBusyLink = false;
                for (let i = 0; i < aiLinks.length; i++) {
                    const aiLink = aiLinks[i];
                    const point = buildPoint(bus.loc.lat, bus.loc.lon);

                    //checks if the bus is close to a link that has a score. The distance is set using the admin setting filterBusesToBusyLinksDistance
                    if (checkDistance(point, aiLink.lineString, adminSettings.portalSettings.filterBusesToBusyLinksDistance)) {
                        // Calculates the minimum angle difference out of 360 degrees.
                        // Buses going in the same direction will have a difference near 0
                        // Buses going in the opposite direction will have a difference near 180
                        // We account for road turns on the same link by allowing a variance of 90
                        const headingDiff = Math.min(
                            360 - (Math.max(aiLink.heading, bus.loc.heading) - Math.min(aiLink.heading, bus.loc.heading)),
                            Math.max(aiLink.heading, bus.loc.heading) - Math.min(aiLink.heading, bus.loc.heading)
                        );
                        if (headingDiff <= adminSettings.portalSettings.filterBusesToBusyLinksAngle) {
                            closeToBusyLink = true;
                            break;
                        }
                    }
                }
                if (!closeToBusyLink) {
                    return result;
                }
            }

            //build bus object
            const busMarker: BusMarker = {
                adherenceTime: bus.schAdhSecs != null ? bus.schAdhSecs : null,
                iconOptions: iconOpt,
                busStatus: busStatus,
                loc: bus.loc,
                showHeading: adminSettings.portalSettings.showBusDirection,
                busInfo: {
                    id: bus.id,
                    routeName: bus.routeName,
                    nextStopName: bus.nextStopName,
                    headSign: bus.headsign,
                    tripId: bus.tripId,
                    blockId: bus.blockId,
                    adherence: bus.schAdhSecs != null ? adherenceMillisecondsToDisplayTime(bus.schAdhSecs * 1000) : null,
                    speed: bus.loc.speed != null ? metersPerSecondToKilometersPerHour(bus.loc.speed) + " km/h" : null,
                    lastGpsPing: getLastGpsPing(bus.loc.time),
                    distanceAlongTrip: bus.distanceAlongTrip,
                    isAtStop: bus.isAtStop,
                    isCanceled: bus.isCanceled,
                    isScheduledService: bus.isScheduledService,
                },
            };

            result.push(busMarker);

            return result;
        }, []);

        return busMarkers;
    }

    public getAddInsightGraph(): AddInsightStore.AddInsightGraphData | undefined {
        const { addInsightGraphData } = this.props;
        return addInsightGraphData;
    }

    public selectLink = (linkId: number) => {
        this.props.closeSitePanel();
        this.props.closeSchoolSignPanel();
        this.props.closeCycleCounterPanel();
        this.props.clearAddInsightGraphData();
        this.props.selectLinkOnMap(linkId);
        this.props.requestAllGraphData(linkId);
    };

    public render() {
        const links = this.getAddInsightLinks();
        const buses = this.getBuses(links);
        const schoolSigns = this.getSchoolSigns();
        const cycleCounterDevices = this.getCycleCounterDevices();
        const cycleCounterDetections = this.getCycleCounterDetections();
        const pumpDevices = this.getPumpDevices();

        return this.props.adminSettings ? (
            <Map
                appSettings={this.props.appSettings}
                addInsightLinks={links}
                buses={buses}
                schoolSigns={schoolSigns}
                cycleCounterDevices={cycleCounterDevices}
                cycleCounterDetections={cycleCounterDetections}
                pumpDevices={pumpDevices}
                selectedPumpId={this.props.selectedPumpId}
                selectedCycleCounterDeviceId={this.props.selectedCycleCounterDeviceId}
                zoom={this.props.zoom}
                maxZoom={this.props.maxZoom}
                center={
                    this.props.center || [this.props.adminSettings.portalSettings.mapStartingLatitude, this.props.adminSettings.portalSettings.mapStartingLongitude]
                }
                markers={this.pinMarkers()}
                toggleMapIndex={this.props.toggleMapIndex}
                mapIndex={this.props.userSettings.mapIndex}
                setZoom={this.props.setZoom}
                setCenter={this.props.setCenter}
                closeSitePanel={this.props.closeSitePanel}
                selectLinkOnMap={this.selectLink}
                adaptiveZoomRadius={this.props.adminSettings.portalSettings.mapAdaptiveZoomRadius}
                initialCenter={[this.props.adminSettings.portalSettings.mapStartingLatitude, this.props.adminSettings.portalSettings.mapStartingLongitude]}
                selectedLinkId={this.props.selectedLinkId}
                selectedSchoolSignId={this.props.selectedSchoolSignId}
                closeFilterPanel={this.props.closeFilterPanel}
                closeLinkPanel={this.props.closeLinkPanel}
                addInsightEnabled={this.props.userSettings.addInsightEnabled}
                kmzEnabled={this.props.userSettings.kmzEnabled}
                closeSchoolSignPanel={this.props.closeSchoolSignPanel}
                closeCycleCounterPanel={this.props.closeCycleCounterPanel}
            />
        ) : (
            <div></div>
        );
    }
}

// Most of the map containers job will be to fire down markers that can then create events to change the state.
// It might make sense to just use the url handler for most of this? Keep things consistent and tested.
// Although using URLs the route must only be set to be then read by the sidebar handler to process.
// Local cache of all the data so we don't need to wait for render? Should make time travel a bit easier too?
export default connect(
    (state: ApplicationState) => {
        return {
            ...state.sites,
            ...state.map,
            ...state.alerts,
            ...state.addInsight,
            ...state.appSettings,
            ...state.buses,
            ...state.userSettings,
            ...state.adminSettings,
            ...state.logs,
            ...state.schoolSigns,
            ...state.cycleCounterDevices,
            ...state.cycleCounterDetections,
            ...state.pumpDevices,
        };
    }, // Selects which state properties are merged into the component's props
    {
        ...SiteState.actionCreators,
        ...MapState.actionCreators,
        ...AlertState.actionCreators,
        ...AddInsightStore.actionCreators,
        ...LogStore.actionCreators,
        ...SettingsStore.actionCreators,
        ...BusStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...AdminSettingsStore.actionCreators,
        ...SchoolSignStore.actionCreators,
        ...CycleCounterDevicesStore.actionCreators,
        ...CycleCounterDetectionsStore.actionCreators,
        ...PumpDevicesStore.actionCreators,
    } // Selects which action creators are merged into the component's props
)(MapContainer as any);
