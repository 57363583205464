import * as React from "react";
import "./controls.scss";

import { SitePrioritySetting } from "store/adminSettings";
import { Site } from "store/sites";

export class SiteAlarmTableState {
    public filter: string | null = null
}

export class SiteAlarmTableProps {
    public sites: Site[]
    public sitePrioritySettings: SitePrioritySetting[]
    public handleSiteUpdate: (site: Site) => void

    constructor(sites: Site[], sitePrioritySettings: SitePrioritySetting[], handleSiteUpdate: (site: Site) => void) {
        this.sites = sites
        this.sitePrioritySettings = sitePrioritySettings
        this.handleSiteUpdate = handleSiteUpdate
    }
}

function isSearchMatch(site: Site, searchTerm: string): boolean {
    // We emulate a case insensitive search such that we are not being super picky about
    // the potential matches
    const lowerCaseDescription = site.description.toLowerCase()
    const lowerCaseSearchTerm = searchTerm.toLowerCase()

    if (lowerCaseDescription.toLowerCase().includes(lowerCaseSearchTerm))
        return true;

    if (site.scatsSiteId && site.scatsSiteId === Number.parseInt(searchTerm))
        return true;

    return false;
}

export class SiteAlarmTableSetting extends React.Component<SiteAlarmTableProps, SiteAlarmTableState> {
    constructor(props: SiteAlarmTableProps) {
        super(props)

        this.state = { filter: null }
    }

    public handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ filter: e.target.value })
    }

    public handlePriorityChange(site: Site, e: React.ChangeEvent<HTMLSelectElement>) {
        const i = e.target.selectedIndex;
        const v = Number.parseInt(e.target.options[i].value);

        this.props.handleSiteUpdate(Object.assign({ ...site }, { priority: v, lastUpdated: new Date() }))
    }

    public render() {
        const sites =
            this.props.sites || [];

        const sitePrioritySettings =
            this.props.sitePrioritySettings || [];

        const scatsSites = sites
            .filter(s => s.scatsSiteId)
            .filter(s => !this.state || !this.state.filter || isSearchMatch(s, this.state.filter))
            .sort((a, b) => (a?.scatsSiteId || 0) - (b?.scatsSiteId || 0));

        return (
            <>
                <div className="container table-heading">
                    <div className="row align-items-center">
                        <div className="col-6 col-md-8 table-heading-title">SCATS Sites</div>
                        <form className="col-6 col-md-4 text-right search-form">
                            <div className="search-container">
                                <input id="sites-filter" type="text" className="form-control search-control" placeholder="Search" onChange={e => this.handleFilterChange(e)} />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="settings-table">
                    <div className="container">
                        <div className="row settings-header-row">
                            <div className="col-1">ID</div>
                            <div className="col-5 col-md-7">Site Name</div>
                            <div className="col-5 col-md-4">Alert Priority</div>
                        </div>
                        {scatsSites && scatsSites.map((site, i) =>
                            <div key={i} className={"row settings-row" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                                <div className="col-1 mb-3">
                                    {site.scatsSiteId}
                                </div>
                                <div className="col-10 col-md-7 mb-3">
                                    {site.description}
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="setting">
                                        <select key={site.id} value={site.priority} className="text-setting-input w-100" onChange={e => this.handlePriorityChange(site, e)}>
                                            {sitePrioritySettings.map(sps => (
                                                <option key={sps.priority} value={sps.priority}>{sps.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default SiteAlarmTableSetting;
