import React from "react";
import L, { LatLng } from "leaflet";
import { BusMarker, BusStatus } from "store/buses";
import { DivIconOptions } from "../icons";
import { Marker, Popup } from "react-leaflet";
import { iconBusLogo } from "../../images";
import ReactDOMServer from "react-dom/server";
import "./busMarker.scss";

export interface BusMarkerProps {
    bus: BusMarker;
    zoom: number;
}

const MAX_ADHERENCE_SCALE = 4;
const MAX_ADHERENCE_TIME = 15;
const ICON_ANGLE_OFFSET = 90;

export const busIconHtml = (color: string, heading?: number) => <div style={{
    backgroundColor: color,
    borderRadius: "100%",
    width: "100%",
    height: "100%",
    position: "relative"
}}>
    {
        heading &&
        <div className="bus-direction-container" style={{
            transform: `rotate(${heading - ICON_ANGLE_OFFSET}deg)`
        }} >
            <svg className="bus-direction" x="0px" y="0px" viewBox="0 0 410.258 410.258">
                <polygon points="144.206,0 112.206,24 248.052,205.129 112.206,386.258 144.206,410.258 298.052,205.129" />
            </svg>
        </div>
    }

    <img
        style={{
            width: "65%",
            height: "65%",
            margin: "auto",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
        }}
        src={iconBusLogo}
        alt="Bus icon"
    />
</div>

export default class BusLeafletMarker extends React.Component<BusMarkerProps> {
    getScaledBusIcon(iconOpt: DivIconOptions, adherenceTime: number | null, busStatus: BusStatus): L.DivIcon {
        const currentZoomLevel = this.props.zoom;
        let adherenceScale = 1;
        let busStatusScale = 1;

        //scale based on admin scale setting
        if (iconOpt.scale != null) {
            busStatusScale = iconOpt.scale;
        }

        //scale based on adherence
        if (adherenceTime != null && busStatus !== BusStatus.OnTime && iconOpt.shouldScaleByTime) {
            let absoluteAdherenceTimeMinutes = Math.abs(adherenceTime / 60); // get absolute adherence as minutes
            absoluteAdherenceTimeMinutes = absoluteAdherenceTimeMinutes >= MAX_ADHERENCE_TIME ? MAX_ADHERENCE_TIME : absoluteAdherenceTimeMinutes; //limit to 15 mins max
            adherenceScale = ((absoluteAdherenceTimeMinutes / MAX_ADHERENCE_TIME) * MAX_ADHERENCE_SCALE);
            adherenceScale = adherenceScale < 1 ? adherenceScale + 1 : adherenceScale; //add 1 if less than 1
        }

        const scaleFunc = (iconSize: number) =>
            Math.floor(iconSize * (1 / Math.max(1, 17 - currentZoomLevel))) * adherenceScale * busStatusScale;

        iconOpt.iconAnchor = [
            scaleFunc(iconOpt.iconAnchor[0]), scaleFunc(iconOpt.iconAnchor[1])
        ];

        iconOpt.iconSize = [scaleFunc(iconOpt.iconSize[0]), scaleFunc(iconOpt.iconSize[1])];

        iconOpt.className = "bus-marker";

        iconOpt.html = ReactDOMServer.renderToStaticMarkup(busIconHtml(iconOpt.color, this.props.bus.showHeading ? this.props.bus.loc.heading : undefined));
        return L.divIcon(iconOpt);
    }

    public render() {
        const { bus } = this.props;

        return (<Marker
            position={new LatLng(bus.loc.lat, bus.loc.lon)}
            icon={this.getScaledBusIcon(bus.iconOptions, bus.adherenceTime, bus.busStatus)}
        >
            <Popup className="map-popup" autoPan={false}>
                <div className="map-popup-title-container">
                    {bus.busInfo.routeName != null && (
                        <span className="map-popup-title">
                            Route {bus.busInfo.routeName}
                        </span>
                    )}
                </div>
                <div className="map-popup-vehicle-info">
                    <table className="w-100">
                        <tbody>
                            {bus.busInfo.id != null && <tr><td>Vehicle</td><td>{bus.busInfo.id}</td></tr>}
                            {bus.busInfo.nextStopName != null && <tr><td>Next Stop</td><td>{bus.busInfo.nextStopName}</td></tr>}
                            {bus.busInfo.headSign != null && <tr><td>HeadSign</td><td>{bus.busInfo.headSign}</td></tr>}
                            {bus.busInfo.tripId != null && <tr><td>Trip</td><td>{bus.busInfo.tripId}</td></tr>}
                            {bus.busInfo.blockId != null && <tr><td>Block</td><td>{bus.busInfo.blockId}</td></tr>}
                            <tr><td colSpan={2}><hr className="w-100" /></td></tr>
                            {bus.busInfo.adherence != null && <tr><td>Adherence</td><td>{bus.busInfo.adherence}</td></tr>}
                            {bus.busInfo.speed != null && <tr><td>Speed</td><td>{bus.busInfo.speed}</td></tr>}
                            {bus.busInfo.isAtStop != null && <tr><td>Is at Stop</td><td>{bus.busInfo.isAtStop.toString()}</td></tr>}
                            {bus.busInfo.isCanceled != null && <tr><td>Is Cancelled</td><td>{bus.busInfo.isCanceled.toString()}</td></tr>}
                            {bus.busInfo.lastGpsPing != null && <tr><td>Last GPS Ping</td><td>{bus.busInfo.lastGpsPing}</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Popup>
        </Marker>
        );
    }
}
