import * as React from "react";
import { NavMenu } from "./navMenu";
import { UserSettingsPayload } from "store/userSettings";

export interface LayoutProps {
    isLoggedIn: boolean;
    userName: string | null;
    roleName: string | null;
    logOut: (() => void) | undefined;
    toggleCycleCounters: (() => void) | undefined;
    toggleSchoolSigns: (() => void) | undefined;
    toggleAddInsight: (() => void) | undefined;
    toggleSCATS: (() => void) | undefined;
    toggleBusNetwork: (() => void) | undefined;
    toggleKmz: (() => void) | undefined;
    togglePumps: (() => void) | undefined;
    userSettings: UserSettingsPayload;
    windowPathname: string;
    canViewAdminPanel: boolean;
}

export default class Layout extends React.Component<LayoutProps> {
    public render() {
        return (
            <>
                <NavMenu
                    logout={this.props.logOut}
                    userName={this.props.userName}
                    toggleCycleCounters={this.props.toggleCycleCounters}
                    toggleSchoolSigns={this.props.toggleSchoolSigns}
                    toggleAddInsight={this.props.toggleAddInsight}
                    toggleSCATS={this.props.toggleSCATS}
                    toggleBusNetwork={this.props.toggleBusNetwork}
                    toggleKmz={this.props.toggleKmz}
                    togglePumps={this.props.togglePumps}
                    addInsightEnabled={this.props.userSettings.addInsightEnabled}
                    cycleCountersEnabled={this.props.userSettings.cycleCountersEnabled}
                    scatsEnabled={this.props.userSettings.scatsEnabled}
                    busNetworkEnabled={this.props.userSettings.busesEnabled}
                    schoolSignsEnabled={this.props.userSettings.schoolSignsEnabled}
                    kmzEnabled={this.props.userSettings.kmzEnabled}
                    windowPathname={this.props.windowPathname}
                    canViewAdminPanel={this.props.canViewAdminPanel}
                    roleName={this.props.roleName}
                    pumpsEnabled={this.props.userSettings.pumpsEnabled}
                />
                <main className="main-body" role="main">
                    {this.props.children}
                </main>
            </>
        );
    }
}
