/* eslint-disable @typescript-eslint/explicit-function-return-type */
// Signal R middleware based on https://medium.com/@lucavgobbi/signalr-react-redux-5a100a226871
import { AppMiddlewareAPI, StoreActions, AppThunkDispatch, ApplicationState } from "..";
import { AnyAction, Dispatch } from "redux";
import * as signalR from "@microsoft/signalr";
import * as SiteStore from "../sites";
import * as AdminSettingsStore from "../adminSettings";
import * as LogStore from "../logs";
import * as AlertStore from "../alarm";
import * as CommentStore from "../comments";
import * as AddInsightStore from "../addInsight";
import * as SchoolSignStore from "../schoolSigns";
import * as CycleCounterDevicesStore from "../cycleCounterDevices";
import * as CycleCounterDetectionsStore from "../cycleCounterDetections";
import * as LightwireDevicesStore from "../lightwireDevicesStore";
import * as PumpDevicesStore from "store/Pumps/pumpsDataApi";
import * as BusStore from "../buses";
import { startTimerUpdates, stopTimerUpdates } from "./timerUpdates";
import * as signalRConstants from "../../constants/signalRConstants";
import debounce from "lodash/debounce";
import { SendToast, ClearBackendErrorToast, ToastErrorTypes, BackendErrorToast } from "../../utils/toast";
import { AuthenticationActions } from "react-aad-msal";
import { fetchAllData } from "./../../containers/home";

const retryInterval: number = 15000;
let timerFallBackActive: boolean = false;
let connection: signalR.HubConnection;

export const middleware = (api: AppMiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: StoreActions) => {
    switch (action.type) {
        case AuthenticationActions.AcquiredAccessTokenSuccess:
            initSignalR(api.dispatch, api.getState, action.payload.accessToken);
            break;
        case "AAD_LOGOUT_SUCCESS":
            stop();
            break;
        case "UPDATE_BUSNETWORK_FILTER":
            startBuses(api.dispatch, api.getState);
            break;
        case "UPDATE_ADDINSIGHT_FILTER":
            startAddInsightLinks(api.dispatch, api.getState);
            break;
        case "UPDATE_SCHOOLSIGN_FILTER":
            startSchoolSigns(api.dispatch, api.getState);
            break;
        case "UPDATE_CYCLE_COUNTER_FILTER":
            startCycleCounters(api.dispatch, api.getState);
            break;
        case "UPDATE_KMZ_FILTER":
            startKMZ(api.dispatch, api.getState);
            break;
        case "UPDATE_PUMP_FILTER":
            startPumps(api.dispatch, api.getState);
            break;
    }
    return next(action);
};

const initSignalR = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState, accessToken: string) => {
    stop();
    connection = new signalR.HubConnectionBuilder()
        .withUrl("/hub", {
            accessTokenFactory: () => accessToken,
            transport: signalR.HttpTransportType.WebSockets,
            skipNegotiation: true,
        })
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
                if (retryContext.elapsedMilliseconds < retryInterval * 6) {
                    console.log("[" + (retryContext.previousRetryCount + 1) + "] Attempting to reconnect...");

                    //only let user know if it's failed to reconnect a couple times
                    if (retryContext.previousRetryCount >= 2) {
                        BackendErrorToast(ToastErrorTypes.DISCONNECTED, "SignalR has been disconnected", "Attempting to reconnect...");
                    }

                    //update all data
                    fetchAllData(dispatch);

                    return retryInterval;
                } else {
                    //reached max reconnections, fallback to timer updates
                    SendToast("There was an issue connecting with SignalR", "Falling back to timer updates...", { type: "warning" });
                    fallbackToTimer(dispatch, stateFunction);
                    return null;
                }
            },
        })
        .build();
    startSignalR(dispatch, stateFunction);
};

export const startBuses = (dispatch: AppThunkDispatch, stateFunction: any) => {
    const state = stateFunction();
    if (state.userSettings.userSettings.busesEnabled && !timerFallBackActive) {
        const busHandler = debounce(() => {
            dispatch(BusStore.actionCreators.requestAllBuses());
        }, 1500);

        busHandler();

        connection.on(signalRConstants.BusesUpdated, () => {
            busHandler();
        });
    } else {
        connection.off(signalRConstants.BusesUpdated);
    }
};

export const startAddInsightLinks = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    const state = stateFunction();
    if (state.userSettings.userSettings.addInsightEnabled && !timerFallBackActive) {
        // Add Insight Link
        const linkHandler = debounce(() => {
            dispatch(AddInsightStore.actionCreators.requestAllLinks());
        }, 1500);

        linkHandler();

        connection.on(signalRConstants.AddInsightLinksUpdated, () => {
            linkHandler();
        });
    } else {
        connection.off(signalRConstants.AddInsightLinksUpdated);
    }
};

export const startSchoolSigns = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    const state = stateFunction();

    if (state.userSettings.userSettings.schoolSignsEnabled && !timerFallBackActive) {
        const schoolSignHandler = debounce(() => {
            dispatch(SchoolSignStore.actionCreators.requestAllSchoolSigns());
        }, 1500);

        schoolSignHandler();

        connection.on(signalRConstants.SchoolSignsUpdated, () => {
            schoolSignHandler();
        });
    } else {
        connection.off(signalRConstants.SchoolSignsUpdated);
    }
};

export const startCycleCounters = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    const state = stateFunction();
    if (state.userSettings.userSettings.cycleCountersEnabled && !timerFallBackActive) {
        const cycleCounterDeviceHandler = debounce(() => {
            dispatch(CycleCounterDevicesStore.actionCreators.requestAllCycleCounterDevices());
        }, 1500);

        const cycleCounterDetectionsHandler = debounce(() => {
            dispatch(CycleCounterDetectionsStore.actionCreators.requestAllCycleCounterDetections());
        }, 1500);

        cycleCounterDeviceHandler();
        cycleCounterDetectionsHandler();

        connection.on(signalRConstants.CycleCounterDevicesUpdated, () => {
            cycleCounterDeviceHandler();
        });
        connection.on(signalRConstants.CycleCounterDetectionsUpdated, () => {
            cycleCounterDetectionsHandler();
        });
    } else {
        connection.off(signalRConstants.CycleCounterDevicesUpdated);
        connection.off(signalRConstants.CycleCounterDetectionsUpdated);
    }
};

export const startlightwire = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    const state = stateFunction();
    if (state.userSettings.userSettings.scatsEnabled && !timerFallBackActive) {
        console.log("starting lightwire as scats enabled...");
        const lightwireHandler = debounce(() => {
            dispatch(LightwireDevicesStore.actionCreators.requestAllLightwireDevices());
        }, 1500);

        lightwireHandler();

        connection.on(signalRConstants.LightwireDevicesUpdated, () => {
            lightwireHandler();
        });
    } else {
        connection.off(signalRConstants.LightwireDevicesUpdated);
    }
};

export const startPumps = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    const state = stateFunction();
    if (state.userSettings.userSettings.scatsEnabled && !timerFallBackActive) {
        const pumpHandler = debounce(() => {
            dispatch(PumpDevicesStore.actionCreators.requestPumpDevices());
        }, 1500);

        pumpHandler();

        connection.on(signalRConstants.PumpsUpdated, () => {
            pumpHandler();
        });
    } else {
        connection.off(signalRConstants.PumpsUpdated);
    }
};

export const startKMZ = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState) => {
    return false;
};

export const startSignalR = (dispatch: AppThunkDispatch, stateFunction: () => ApplicationState, callback?: () => void) => {
    if (connection === null || connection.state !== signalR.HubConnectionState.Disconnected || timerFallBackActive) {
        return;
    }

    // Alarms
    const alertHandler = debounce(() => {
        dispatch(AlertStore.actionCreators.requestAllAlerts());
    }, 1500);

    connection.on(signalRConstants.AlarmsUpdated, () => {
        alertHandler();
    });

    // Alarms
    const adminSettingsHandler = debounce(() => {
        dispatch(AdminSettingsStore.actionCreators.getAdminSettings());
    }, 1500);

    connection.on(signalRConstants.AdminSettingsUpdated, () => {
        adminSettingsHandler();
    });

    //mute alarm source
    const muteHandler = debounce(() => {
        dispatch(SiteStore.actionCreators.requestAllSites());
    }, 1500);

    connection.on(signalRConstants.MuteStateUpdated, () => {
        muteHandler();
        alertHandler(); //to update icons on map
    });

    // Logs
    const logHandler = debounce(() => {
        dispatch(LogStore.actionCreators.requestAllLogs());
    }, 1500);

    connection.on(signalRConstants.LogsUpdated, () => {
        logHandler();
    });

    // Comments
    const commentHandler = debounce(() => {
        dispatch(CommentStore.actionCreators.requestAllComments());
    }, 1500);

    connection.on(signalRConstants.CommentsUpdated, () => {
        commentHandler();
    });

    // Sites
    const siteHandler = debounce(() => {
        dispatch(SiteStore.actionCreators.requestAllSites());
    }, 1500);

    connection.on(signalRConstants.SitesUpdated, () => {
        siteHandler();
    });

    //buses
    startBuses(dispatch, stateFunction);

    //addinsightlinks
    startAddInsightLinks(dispatch, stateFunction);

    // School signs
    startSchoolSigns(dispatch, stateFunction);

    // Start Cycle Counters/detections
    startCycleCounters(dispatch, stateFunction);
    startPumps(dispatch, stateFunction);

    // AdminUserSettings
    const adminUserSettingsHandler = debounce(() => {
        dispatch(AdminSettingsStore.actionCreators.getAdminSettings());
    }, 1500);

    connection.on(signalRConstants.AdminSettingsUpdated, () => {
        adminUserSettingsHandler();
    });

    connection.onreconnected(() => {
        ClearBackendErrorToast(ToastErrorTypes.DISCONNECTED);
        fetchAllData(dispatch);
    });

    //connects to signalR if not currently connected
    if (connection.state === signalR.HubConnectionState.Disconnected) {
        connection
            .start()
            .catch((err: any) => {
                console.log(err);
            })
            .then(() => {
                if (connection.state === signalR.HubConnectionState.Disconnected) {
                    fallbackToTimer(dispatch, stateFunction, callback);
                } else {
                    ClearBackendErrorToast(ToastErrorTypes.DISCONNECTED);
                    fetchAllData(dispatch);
                }
            });
    }
};

const fallbackToTimer = (dispatch: AppThunkDispatch, stateFunction: any, callback?: () => void) => {
    console.log("Falling back to timer updates...");
    stop();
    timerFallBackActive = true;
    startTimerUpdates(dispatch, stateFunction, callback);
};

export const stop = () => {
    if (connection && connection.state !== signalR.HubConnectionState.Disconnected) {
        connection.stop();
    }
    if (timerFallBackActive) {
        stopTimerUpdates();
        timerFallBackActive = false;
    }
};
