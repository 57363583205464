import * as React from "react";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface TextSettingControlProps {
    settingTitle: string;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    settingValue: string;
    handleSettingUpdate: any;
    explanation?: string;
    type?: keyof OutputType;
}

interface OutputType {
    string: string;
    number: number;
    float: number;
}

export class TextSettingControl extends React.Component<TextSettingControlProps> {
    constructor(props: TextSettingControlProps) {
        super(props);
        this.state = { showExplanation: true };
    }
    public render() {
        const {
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            explanation,
            type,
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <div className="text-setting-input">
                    <input
                        type={type === "float" ? "number" : "text"}
                        value={settingValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const val = event.target.value;
                            switch (type) {
                                case "number":
                                    return handleSettingUpdate(settingPropName, parseInt(val) || 0);
                                case "float":
                                    return handleSettingUpdate(
                                        settingPropName,
                                        parseFloat(val) || 0
                                    );
                                case "string":
                                    return handleSettingUpdate(settingPropName, val);
                                default:
                                    return handleSettingUpdate(settingPropName, val);
                            }
                        }}
                    />
                </div>
            </SettingControlWrapper>
        );
    }
}

export default TextSettingControl;
