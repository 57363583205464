import React from "react";
import { FC } from "react";
import ReactMoment from "react-moment";
import { LightwireDevice } from "store/lightwireDevicesStore";
import { iconPinAlertNormal, iconPinAlertMedium } from "../images";

interface LightwireRowProps {
    title: string;
    device: LightwireDevice;
}

const LightwireRow: FC<LightwireRowProps> = (props) => {
    const { device } = props;

    const getLightwireAlertSeverity = () => {
        const defaults = iconPinAlertMedium;
        if (device && device.status && device.status.toLocaleUpperCase() === "UP") {
            return iconPinAlertNormal;
        }

        return defaults;
    };

    return (
        <>
            <tr className="blade-table-body">
                <td className="text-center">
                    <img src={getLightwireAlertSeverity()} width={24} height={24} alt="alert severity icon" />
                </td>
                <td>
                    <span>
                        <h2>Lightwire</h2>
                    </span>
                </td>
                <td>{device && device.status === "DOWN" && device?.lastChanged && <ReactMoment fromNow interval={1000}>{Date.parse(device.lastChanged)}</ReactMoment>}</td>
                <td className="text-center pause-container">&nbsp;</td>
            </tr>
        </>
    );
};

export default LightwireRow;
