import React from "react";
import * as SchoolSignStore from "store/schoolSigns";
import ReactMoment from "react-moment";
import Modal from "../../modal/modal";
import {
  iconClose,
  iconSchoolSignIdle,
  iconSchoolSignOff,
  iconSchoolSignOn,
  iconSchoolSignStatic,
  iconSchoolSignNotTalking,
  iconSettings,
  iconSchoolSignOn100,
  iconSchoolSignOff100,
} from "../../images";
import "../sidebar.scss";
import "./sidebarSchoolSignInformation.scss";

export interface SchoolSignInformationProps {
  schoolSign: SchoolSignStore.SchoolSign;
  closeSchoolSignPanel: () => void;
  setSchoolSignStatus: (schoolName: string, activationDurationMinutes: number) => void;
  isSchoolSignStatusUpdateOperationLoading: boolean;
}

export interface SchoolSignInformationState {
  showStatusUpdateModal: boolean;
  activationDurationMinutes: number;
  turnSignsOn: boolean;
  selectedStatusOperation: StatusOperationSelection;
}

enum StatusOperationSelection {
  // The 0 value is reserved for "unknown" as per the frontend and backend
  // SchoolSignAction enums
  TurnOn = 1,
  TurnOff = 2,
}

export default class SidebarSchoolSignInformation extends React.Component<SchoolSignInformationProps, SchoolSignInformationState> {
  state = {
    showStatusUpdateModal: false,
    activationDurationMinutes: 1,
    turnSignsOn: false,
    selectedStatusOperation: StatusOperationSelection.TurnOff,
  };

  private getSchoolSignStatusIcon = (status: SchoolSignStore.SchoolSignStatus) => {
    if (status === SchoolSignStore.SchoolSignStatus.Off) {
      return iconSchoolSignOff;
    } else if (status === SchoolSignStore.SchoolSignStatus.Idle) {
      return iconSchoolSignIdle;
    // Should display on for either on value or integer
    } else if (status === SchoolSignStore.SchoolSignStatus.On || status === SchoolSignStore.SchoolSignStatus.Forty || status === SchoolSignStore.SchoolSignStatus.Fifty) {
      return iconSchoolSignOn;
    } else if (status === SchoolSignStore.SchoolSignStatus.Static) {
      return iconSchoolSignStatic;
    } else if (status === SchoolSignStore.SchoolSignStatus.NotTalking) {
      return iconSchoolSignNotTalking;
    }

    // Cannot return null otherwise tslint complains
    return undefined;
  };

  private showSetSchoolSignStatusModal = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    schoolSign: SchoolSignStore.SchoolSign
  ) => {
    event.preventDefault();

    this.setState({
      showStatusUpdateModal: true,
    });
  };

  private closeSetSchoolSignStatusModal = () => {
    this.resetSchoolSignStatusModalState();
  };

  private resetSchoolSignStatusModalState = () => {
    this.setState({
      showStatusUpdateModal: false,
      activationDurationMinutes: 1,
      selectedStatusOperation: StatusOperationSelection.TurnOff,
      turnSignsOn: false,
    });
  };

  private handleSetSignStatus = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>, schoolSign: SchoolSignStore.SchoolSign) => {
    // Stop default button behaviour
    event.preventDefault();

    const { activationDurationMinutes, selectedStatusOperation } = this.state;

    // 0 is a special value used to turn signs off
    const activationDurationMins = selectedStatusOperation === StatusOperationSelection.TurnOn ? activationDurationMinutes : 0;

    console.log(`Set sign status to ${selectedStatusOperation} for school ${schoolSign.schoolName} with a ${activationDurationMins} minute/s duration`);

    this.props.setSchoolSignStatus(schoolSign.schoolName, activationDurationMinutes);

    this.resetSchoolSignStatusModalState();
  };

  private handleActivationDurationChange(e: React.ChangeEvent<HTMLInputElement>) {
    const activationDurationMinutes = e.target.valueAsNumber;

    this.setState({
      activationDurationMinutes: activationDurationMinutes,
    });
  }

  private handleStatusOperationSelection(
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    statusOperationSelection: StatusOperationSelection
  ) {
    // Stop default button behaviour
    event.preventDefault();

    const wantsToTurnSignsOn = statusOperationSelection === StatusOperationSelection.TurnOn;

    const { activationDurationMinutes } = this.state;

    this.setState({
      turnSignsOn: wantsToTurnSignsOn,
      selectedStatusOperation: wantsToTurnSignsOn ? StatusOperationSelection.TurnOn : StatusOperationSelection.TurnOff,
      activationDurationMinutes: wantsToTurnSignsOn ? activationDurationMinutes : 0,
    });
  }

  public render() {
    const { schoolSign, isSchoolSignStatusUpdateOperationLoading } = this.props;

    const { showStatusUpdateModal, activationDurationMinutes, turnSignsOn } = this.state;

    const schoolSignTitle = schoolSign.signName;
    const schoolSignDescription = schoolSign.schoolName;

    return (
      <>
        <div className="nav-blade">
          <div className="blade-header">
            <div className="blade-header-icon">
              <img src={this.getSchoolSignStatusIcon(schoolSign.status)} alt="Current school sign status icon" />
            </div>
            <div className="blade-header-title">
              <h1>{schoolSignTitle}</h1>
              <h2>{schoolSignDescription}</h2>
            </div>
            <div className="blade-header-close">
              <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={() => this.props.closeSchoolSignPanel()}>
                <img src={iconClose} width={15} height={15} alt="Close icon" className="align-top" />
              </button>
            </div>
          </div>
          <div className="blade-body">
            <h3 className="blade-body-header">Information</h3>
            <table className="w-100">
              <thead>
                <tr className="blade-table-header">
                  <th>Details</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {schoolSign.status != null && (
                  <tr className="blade-table-body">
                    <td>Status</td>
                    <td>{schoolSign.status}</td>
                  </tr>
                )}
                {schoolSign.displayValue && (
                  <tr className="blade-table-body">
                    <td>Display Value</td>
                    <td>
                      {schoolSign.displayValue}
                    </td>
                  </tr>
                )}
                {schoolSign.latitude != null && (
                  <tr className="blade-table-body">
                    <td>Latitude</td>
                    <td>{schoolSign.latitude}</td>
                  </tr>
                )}
                {schoolSign.longitude != null && (
                  <tr className="blade-table-body">
                    <td>Latitude</td>
                    <td>{schoolSign.longitude}</td>
                  </tr>
                )}
                {schoolSign.lastSeen != null && (
                  <tr className="blade-table-body">
                    <td>
                      Last Seen
                      <br />
                      <ReactMoment date={schoolSign.lastSeen} fromNow />
                    </td>
                    <td>
                      <ReactMoment date={schoolSign.lastSeen} format="DD-MM-YYYY" />
                      <br />
                      <ReactMoment date={schoolSign.lastSeen} format="hh:mm:ss A" />
                    </td>
                  </tr>
                )}
                {
                  <tr className="blade-table-body">
                    <td>
                      Last Event
                      <br />
                      {schoolSign.lastEvent != null && <ReactMoment date={schoolSign.lastEvent} fromNow />}
                    </td>
                    <td>
                      {schoolSign.lastEvent != null ? (
                        <>
                          <ReactMoment date={schoolSign.lastEvent} format="DD-MM-YYYY" />
                          <br />
                          <ReactMoment date={schoolSign.lastEvent} format="hh:mm:ss A" />
                        </>
                      ) : (
                        <div>-</div>
                      )}
                    </td>
                  </tr>
                }
              </tbody>
            </table>

            <h3 className="blade-body-header">Actions</h3>

            <button type="button" className="px-2 btn m-0 shadow-none" onClick={(e) => this.showSetSchoolSignStatusModal(e, schoolSign)}>
              <img src={iconSettings} width={15} height={15} alt="Add log icon" />
              <span className="ml-2">Set sign status</span>
            </button>
          </div>

          {(showStatusUpdateModal || isSchoolSignStatusUpdateOperationLoading) && (
            <Modal
              closeModal={() => this.closeSetSchoolSignStatusModal()}
              title="Update School Sign Status"
              isLoading={isSchoolSignStatusUpdateOperationLoading}
            >
              <div className="modal-content fixed-height">
                <h3>
                  Turn <em>all</em> signs associated with a school:
                </h3>

                <div className="modal-status-update-selection-container">
                  <button className="operation-selection-button btn" onClick={(e) => this.handleStatusOperationSelection(e, StatusOperationSelection.TurnOn)}>
                    <img src={iconSchoolSignOn100} alt="School Sign on icon" className="mb-1" />

                    <br />

                    <span>On</span>
                  </button>

                  <button className="operation-selection-button btn" onClick={(e) => this.handleStatusOperationSelection(e, StatusOperationSelection.TurnOff)}>
                    <img src={iconSchoolSignOff100} alt="School Sign off icon" className="mb-1" />

                    <br />

                    <span>Off</span>
                  </button>
                </div>

                {turnSignsOn && (
                  <div className="modal-status-update-turn-signs-on-container p-2">
                    <div className="input-group mb-3 w-50">
                      <input
                        title="minutes"
                        id="tbAactivationDurationMinutes"
                        type="number"
                        className="custom-value-input activation-duration-control"
                        value={activationDurationMinutes}
                        step={0.25}
                        min={0}
                        max={1440}
                        onChange={(e) => this.handleActivationDurationChange(e)}
                      ></input>

                      <div className="input-group-append activation-duration-units">
                        <span className="input-group-text">minutes</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="modal-actions">
                <button type="button" className="btn btn-cancel" onClick={() => this.closeSetSchoolSignStatusModal()}>
                  Cancel
                </button>

                <button type="button" className="btn btn-save" onClick={(e) => this.handleSetSignStatus(e, schoolSign)}>
                  Save
                </button>
              </div>
            </Modal>
          )}
        </div>
      </>
    );
  }
}
