import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import AdminSettings from "../components/adminSettings/adminSettings";
import { actionCreators as adminSettingActionCreators, AdminSettingsPayload, AdminSettingsState } from "store/adminSettings";
import { actionCreators as sitesActionCreators, Site } from "store/sites";
import { useEffect } from "react";
import { AuthenticationRole } from "store/authentication";
import { Redirect } from "react-router";
import Spinner from "../components/spinner/spinner";

const AdminSettingsContainer: React.FC = () => {
    const dispatch = useDispatch();
    const authApiReady = useSelector<ApplicationState, boolean>(state => state.authentication.authApiReady);
    const adminSettings = useSelector<ApplicationState, AdminSettingsState | undefined>(state => state.adminSettings);
    const googleApiKey = useSelector<ApplicationState, string | undefined>(state => state.appSettings.appSettings?.googleApiKey);
    const sites = useSelector<ApplicationState, Site[]>(state => state.sites?.sites ? Object.values(state.sites.sites) : []);
    const authRole = useSelector<ApplicationState, AuthenticationRole>(state => state.authentication.authRole);

    useEffect(() => {
        if (authApiReady) {
            dispatch(adminSettingActionCreators.getAdminSettings());
            dispatch(sitesActionCreators.requestAllSites());
        }
    }, [authApiReady, dispatch]);

    //authApi isn't ready yet, so show loader
    if (!authApiReady) {
        return <Spinner dim />
    }
    else {

        //user does not have access, redirect to home page
        if (authRole !== AuthenticationRole.Administrator) {
            return <Redirect to="/" />
        }
        else {
            return (
                adminSettings && adminSettings.adminSettings && !!sites.length ?
                    <AdminSettings
                        adminSettings={adminSettings.adminSettings}
                        isLoading={adminSettings.isLoading}
                        googleApiKey={googleApiKey}
                        sites={sites}
                        updateAdminSettings={(updatedAdminSettings: AdminSettingsPayload, updatedSites: Site[]) => dispatch(adminSettingActionCreators.updateAdminSettings(updatedAdminSettings, updatedSites))}
                    /> :
                    <Spinner dim />
            );
        }
    }
}

export default AdminSettingsContainer;