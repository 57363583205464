import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, ApplicationState, AppThunkDispatch } from './';
import moment from 'moment';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import { Alert } from './alarm';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { HttpError } from '@microsoft/signalr';
import { actionCreators as CommentActionCreators, defaultComment,  } from './comments'
import { SystemLogType, UserLogType } from '../constants/logConstants';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LogState {
    initialLoadStarted: boolean;
    logs: {
        [key: number]: Log;
    };
    logViewerOpen: boolean;
    selectedLogId?: number;
    selectedLogModified: boolean;
    logOperationLoading: boolean;
}


export interface Log {
    id: number;
    siteId: number;
    logType: string;
    alarms: Alert[];
    dateClosed?: Date;
    dateCreated: Date;
    firstModified?: Date;
    scatsWorkRequired: boolean;
    scatsWorkCompleted?: Date;
    contractorWorkRequired: boolean;
    contractorWorkCompleted?: Date;
    commentIds: number[];
    lastUpdated: Date;
    title: string;
    unread: boolean;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class LogSelectors {
    static GetLogsBySiteId(state: LogState, siteId: number): Log[] {
        return Object.values(state.logs).filter(log => log.siteId === siteId);
    }

    //Gets a single log by the log id
    static GetLogByIdentifier(state: LogState, id: number): Log | undefined {
        return state.logs[id];
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface AddLogAction extends Action {
    type: 'OPEN_LOG_VIEWER';
    logId: number;
}
interface EditLogAction extends Action {
    type: 'EDIT_LOG';
    logs: Log[];
}
interface CloseLogAction extends Action {
    type: 'CLOSE_LOG_VIEWER';
    //index: number;
}
interface RejectLogAction extends Action {
    type: 'REJECT_LOG';
}
interface SaveLogAction extends Action {
    type: 'SAVE_LOG';
    logs: Log[];
    selectedLogId: number | undefined;
}
interface LogOperationLoadingAction extends Action {
    type: 'LOG_OPERATION_LOADING';
}
interface ReopenLogAction extends Action {
    type: 'REOPEN_LOG';
    logs: Log[];
}

interface ReceiveLogsAction extends Action {
    type: 'RECEIVE_LOGS';
    //The value of the component that will be rendered
    logs: Log[];
    //dateTime
}
interface UpsertLogsAction extends Action {
    type: 'UPSERT_LOGS';
    //The value of the component that will be rendered
    logs: Log[];
    //dateTime
}
interface DeleteLogsAction extends Action {
    type: 'DELETE_LOGS';
    //The value of the component that will be rendered
    logs: Log[];
    //dateTime
}
interface RequestLogsAction extends Action {
    type: 'REQUEST_LOGS';
    //dateTime
}
interface SelectedLogModifiedAction {
    type: 'SELECTED_LOG_MODIFIED';
}
interface ErrorLogsAction extends Action {
    type: 'ERROR_RECEIVING_LOGS';
}

interface ErrorLogOperationAction extends Action {
    type: 'ERROR_LOG_OPERATION';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = AddLogAction | EditLogAction | RejectLogAction | SaveLogAction | CloseLogAction | RequestLogsAction
    | ReceiveLogsAction | DeleteLogsAction | UpsertLogsAction | SelectedLogModifiedAction | ErrorLogsAction | ReopenLogAction | LogOperationLoadingAction
    | ErrorLogOperationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAllLogs: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not loading data, and we don't have any loaded yet.
        const { logs: { initialLoadStarted }, authentication: { accessToken } } = getState();
        if (initialLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Log`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<Log[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_LOGS', logs: data });
                    ClearBackendErrorToast(ToastErrorTypes.LOGS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_LOGS' });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.LOGS, "Error retrieving logs");
                });
            dispatch({ type: 'REQUEST_LOGS' });
        });
    },
    updateLogs: (log: Log): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({ type: 'UPSERT_LOGS', logs: [log] });
        });
    },
    removeLog: (logId: number): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { logs } = getState().logs;
            dispatch({ type: 'DELETE_LOGS', logs: [logs[logId]] });
        });
    },
    removeLogs: (logIds: number[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { logs } = getState().logs;
            const logsToDelete = logIds.map(id => logs[id]);

            dispatch({ type: 'DELETE_LOGS', logs: logsToDelete });
        });
    },
    openLogViewer: (logId: number): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logs: { logs }, authentication: { accessToken } } = getState();
        if (!accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            //creates a new log
            if (logId === -1) {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
                    const log: Log = { ...defaultLog, dateCreated: new Date() };
                    dispatch({ type: 'UPSERT_LOGS', logs: [log] });
                });
            }

            dispatch(<AddLogAction>{ type: 'OPEN_LOG_VIEWER', logId: logId })

            //set as log as read
            const log = logs[logId];
            if (log && log.unread === true) {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
                    log.unread = false;
                    setLogViewAsRead(logId, accessToken);
                    dispatch({ type: 'UPSERT_LOGS', logs: [log] });
                });
            }
        });
    },
    rejectLog: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logs: { selectedLogId, logs }, authentication: { accessToken } } = getState();
        if (!selectedLogId || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
            fetch(`/api/Log/${selectedLogId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }

            })
                .then(resp => {
                    if (resp.ok) {
                        return resp;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(() => {
                    dispatch({ type: 'REJECT_LOG' });
                    dispatch({ type: 'DELETE_LOGS', logs: [logs[selectedLogId]] });
                    dispatch(<CloseLogAction>{ type: 'CLOSE_LOG_VIEWER' });
                    ClearBackendErrorToast(ToastErrorTypes.LOGS_DELETE);
                }).catch(error => {
                    dispatch(<ErrorLogOperationAction>{ type: 'ERROR_LOG_OPERATION' });
                    BackendErrorToast(ToastErrorTypes.LOGS_DELETE, "Error deleting Log", "Please check your internet connection...");
                    console.error(error);
                });
            dispatch(<LogOperationLoadingAction>{ type: 'LOG_OPERATION_LOADING' });
        });
    },
    saveLog: (log?: Log, close: boolean = true): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logs: { selectedLogId, logs }, sites: { selectedSiteId }, authentication: { accessToken, userDisplayName } } = getState();

        if (!selectedLogId || !selectedSiteId || !accessToken || !log) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
            _saveInternal(parseServerLog({ ...log, siteId: selectedSiteId, logType: UserLogType }), accessToken).then(data => {
                if (selectedLogId === -1) {
                    dispatch({ type: 'DELETE_LOGS', logs: [logs[selectedLogId]] });
                }
                dispatch({ type: 'SAVE_LOG', selectedLogId: data.id, logs: [data] });
                if (close) {
                    dispatch(<CloseLogAction>{ type: 'CLOSE_LOG_VIEWER' });
                }

                // insert initial commit when new log or existing system log are saved
                if (selectedLogId === -1 || log.logType === SystemLogType) {
                    let content = `${UserLogType} log created by ${userDisplayName}`;

                    if (log.logType === SystemLogType) {
                        content = `${SystemLogType} log converted to ${UserLogType} log by ${userDisplayName}`;
                    }

                    dispatch(CommentActionCreators.saveComment({
                        ...defaultComment,
                        logId: data.id,
                        userName: "SYSTEM",
                        content: content
                    }))
                }

                clearOtherUsersLogViews(data.id, accessToken);
                setLogViewAsRead(data.id, accessToken);
                ClearBackendErrorToast(ToastErrorTypes.LOGS_SAVE);
            }).catch(reason => {
                dispatch(<ErrorLogOperationAction>{ type: 'ERROR_LOG_OPERATION' });
                BackendErrorToast(ToastErrorTypes.LOGS_SAVE, "Error saving Log", "Please check your internet connection...");
                console.log(reason)
            });
            dispatch(<LogOperationLoadingAction>{ type: 'LOG_OPERATION_LOADING' });
        });
    },
    reopenLog: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logs: { selectedLogId, logs }, authentication: { accessToken } } = getState();
        if (!selectedLogId || !accessToken) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
            const currentLog = logs[selectedLogId];
            if (!currentLog) {
                return;
            }

            _saveInternal({
                ...currentLog,
                dateClosed: undefined
            }, accessToken).then(data => {
                dispatch(<CloseLogAction>{ type: 'CLOSE_LOG_VIEWER' });
                dispatch(<ReopenLogAction>{ type: 'REOPEN_LOG', logs: [data] })
                clearOtherUsersLogViews(data.id, accessToken);
                ClearBackendErrorToast(ToastErrorTypes.LOGS_REOPEN);
            }).catch(reason => {
                dispatch(<ErrorLogOperationAction>{ type: 'ERROR_LOG_OPERATION' });
                BackendErrorToast(ToastErrorTypes.LOGS_REOPEN, "Error Reopening Log", "");
                console.log(reason)
            });
            dispatch(<LogOperationLoadingAction>{ type: 'LOG_OPERATION_LOADING' });
        });
    },
    setSelectedLogModifiedTrue: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
            dispatch(<SelectedLogModifiedAction>{ type: 'SELECTED_LOG_MODIFIED' });
        });
    },
    closeLogViewer: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logViewerOpen } = getState().logs;
        //checks if log view is open and allows next action
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            if (!logViewerOpen) {
                //no point closing the viewer as it is already closed
                return;
            }
            dispatch(<CloseLogAction>{ type: 'CLOSE_LOG_VIEWER' });  //closes viewer
        });

    }
};

const _saveInternal: (log: Log, jwtIdToken: string) => Promise<Log> = (log: Log, jwtIdToken: string) => new Promise((resolve, reject) => {
    fetch(`/api/Log`, {
        method: 'PUT',
        body: JSON.stringify(log),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtIdToken}`
        })
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<Log>;
            } else {
                throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
            }
        })
        .then(data => resolve(parseServerLog(data)))
        .catch(error => {
            console.error(error);
            reject(error)
        });
});

const setLogViewAsRead: (logId: number, jwtIdToken: string) => Promise<any> = (logId: number, jwtIdToken: string) => new Promise((resolve, reject) => {
    fetch(`/api/Log/LogView`, {
        method: 'PUT',
        body: JSON.stringify(logId),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtIdToken}`
        })
    })
        .then(resp => {
            if (resp.ok) {
                return resp;
            } else {
                throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
            }
        })
        .then(data => resolve(data))
        .catch(error => {
            console.error(error);
            reject(error)
        });
});

export const clearOtherUsersLogViews: (logId: number, jwtIdToken: string) => Promise<any> = (logId: number, jwtIdToken: string) => new Promise((resolve, reject) => {
    fetch(`/api/Log/LogView/${logId}`, {
        method: 'DELETE',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtIdToken}`
        })
    })
        .then(resp => {
            if (resp.ok) {
                return resp;
            } else {
                throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
            }
        })
        .then(data => resolve(data))
        .catch(error => {
            console.error(error);
            reject(error)
        });
});

export const parseServerLog = (log: Log) => {
    const scatsWorkCompleted = moment(log.scatsWorkCompleted, 'YYYY-MM-DDTHH:mm:ssZ');
    if (!scatsWorkCompleted.isValid()) {
        delete log.scatsWorkCompleted;
    } else {
        log = Object.assign({}, log, {
            scatsWorkCompleted: scatsWorkCompleted.local().format()
        });
    }
    const contractorWorkCompleted = moment(log.contractorWorkCompleted, 'YYYY-MM-DDTHH:mm:ssZ');
    if (!contractorWorkCompleted.isValid()) {
        delete log.contractorWorkCompleted;
    } else {
        log = Object.assign({}, log, {
            contractorWorkCompleted: contractorWorkCompleted.local().format()
        });
    }
    return log;
}

export const defaultLog: Log = {
    logType: 'USER',
    siteId: 0,
    dateCreated: new Date(),
    firstModified: undefined,
    alarms: [],
    id: -1,
    lastUpdated: new Date(),
    contractorWorkRequired: false,
    contractorWorkCompleted: undefined,
    scatsWorkCompleted: undefined,
    scatsWorkRequired: false,
    title: '',
    commentIds: [],
    unread: false
} as Log;


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: LogState = { logs: {}, initialLoadStarted: false, logViewerOpen: false, selectedLogModified: false, logOperationLoading: false };

export const reducer: Reducer<LogState, AnyAction> = (state: LogState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }

    const action = incomingAction as KnownAction;
    const logState = { ...state.logs };

    switch (action.type) {
        case 'LOG_OPERATION_LOADING':
            return <LogState>{
                ...state,
                logOperationLoading: true
            };
        case 'OPEN_LOG_VIEWER':
            return <LogState>{
                ...state,
                selectedLogId: action.logId,
                logViewerOpen: true
            };
        case 'UPSERT_LOGS':
            action.logs.forEach((upsertLog) => {
                const existingLog = logState[upsertLog.id];
                if (!existingLog || upsertLog.lastUpdated >= existingLog.lastUpdated) {
                    logState[upsertLog.id] = upsertLog;
                }
            });

            return <LogState>{
                ...state,
                logs: logState
            };
        case 'EDIT_LOG':
            action.logs.forEach((upsertLog) => {
                const existingLog = logState[upsertLog.id];
                if (!existingLog || upsertLog.lastUpdated >= existingLog.lastUpdated) {
                    logState[upsertLog.id] = upsertLog;
                }
            });
            return <LogState>{
                ...state,
                logs: logState
            };
        case 'REJECT_LOG':
            return <LogState>{
                ...state,
                logOperationLoading: false
            };
        case 'DELETE_LOGS':
            action.logs.forEach((log) => {
                delete logState[log.id];
            });
            return <LogState>{
                ...state,
                logs: logState
            };
        case 'SAVE_LOG':
            action.logs.forEach((upsertLog) => {
                const existingLog = logState[upsertLog.id];
                if (!existingLog || upsertLog.lastUpdated >= existingLog.lastUpdated) {
                    logState[upsertLog.id] = upsertLog;
                }
            });
            return <LogState>{
                ...state,
                logs: logState,
                selectedLogId: action.selectedLogId,
                logOperationLoading: false
            };
        case 'REOPEN_LOG':
            action.logs.forEach((upsertLog) => {
                const existingLog = logState[upsertLog.id];
                if (!existingLog || upsertLog.lastUpdated >= existingLog.lastUpdated) {
                    logState[upsertLog.id] = upsertLog;
                }
            });
            return <LogState>{
                ...state,
                logs: logState,
                logOperationLoading: false
            };
        case 'CLOSE_LOG_VIEWER':
            return <LogState>{
                ...state,
                selectedLogId: undefined,
                logViewerOpen: false,
                selectedLogModified: false
            };
        case 'RECEIVE_LOGS': {
            const incomingLogs = action.logs.reduce<Record<number, Log>>(function (logMap, log) {
                const existingLog = logMap[log.id];
                if (!existingLog || log.lastUpdated >= existingLog.lastUpdated) {
                    logMap[log.id] = log;
                }
                return logMap;
            }, {});
            return <LogState>{
                ...state,
                initialLoadStarted: false,
                logs: incomingLogs
            };
        }
        case 'REQUEST_LOGS':
            return <LogState>{
                ...state,
                initialLoadStarted: true
            };
        case 'SELECTED_LOG_MODIFIED':
            return <LogState>{
                ...state,
                selectedLogModified: true
            };
        case 'ERROR_RECEIVING_LOGS':
            return <LogState>{
                ...state,
                initialLoadStarted: false
            };
        case 'ERROR_LOG_OPERATION':
            return <LogState>{
                ...state,
                logOperationLoading: false
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};