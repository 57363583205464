import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import { AuthState } from "store/authentication";
import { CommentState, CommentSelectors, actionCreators as CommentActionCreators } from "store/comments";
import ConmmentEditor from "../components/commenteditor/commentEditor";

const CommentEditorContainer: React.FC = () => {
    const dispatch = useDispatch();
    const authState = useSelector<ApplicationState, AuthState>(state => state.authentication);
    const commentState = useSelector<ApplicationState, CommentState>(state => state.comments);
    const { selectedCommentId, commentOperationLoading } = commentState;
    const comment = selectedCommentId ? CommentSelectors.GetCommentByIdentifier(commentState, selectedCommentId) : null;

    if (comment) {
        return (
            <ConmmentEditor
                closeCommentEditor={() => dispatch(CommentActionCreators.closeCommentEditor())}
                deleteComment={() => dispatch(CommentActionCreators.deleteComment())}
                saveComment={(content: string) => dispatch(CommentActionCreators.saveComment(undefined, content))}
                isLoading={commentOperationLoading}
                commentToEdit={comment}
                authentication={authState}
            />
        );
    }
    return null;
};

export default CommentEditorContainer;