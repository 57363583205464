import React from "react";
import { Site } from "store/sites";
import { Log } from "store/logs";
import * as AlertStore from "store/alarm";
import {
    iconAlertNormal, iconAlertHigh, iconAlertMedium, iconPinAlertHigh, iconPinAlertMedium, iconWrench,
    iconAlertMediumCircle, iconAlertHighCircle, iconAlertNormalUnmodified, iconAlertNormalModified, iconPaused
} from "../../images";
import ReactMoment from "react-moment";
import "./sidebarItem.scss";
import SidebarItem from "./sidebarItem";
import { BackendSettings } from "store/adminSettings";
import Moment from "moment";


const alertSources = [
    { key: "SRM", value: AlertStore.AlertSource.SharePoint }, { key: "AddInsight", value: AlertStore.AlertSource.AddInsightSiteAlarm }
];

export interface SiteSidebarAlertProps {
    expand: boolean;
    isSelected: boolean;
    selectSite: (scatsSiteId: number, zoom: boolean) => void;
    site: Site;
    alerts: AlertStore.Alert[];
    logs: Log[];
    backendSettings: BackendSettings;
}

const SiteSideBarAlert: React.FC<SiteSidebarAlertProps> = (props) => {

    const getAlertSeverity = () => {
        const { logs, site, backendSettings } = props;
        const unmodified = logs.some(log => !log.firstModified && !log.dateClosed);
        const modified = logs.some(log => log.firstModified && !log.dateClosed);

        const severity: number = site.currentStatus;
        const sitePriority = backendSettings.sitePrioritySettings.filter(p => p.priority === site.priority)[0];

        if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
            if (unmodified) {
                return iconPinAlertHigh;
            } else if (modified) {
                return iconAlertHighCircle;
            } else {
                return iconAlertHigh;
            }
        } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
            if (unmodified) {
                return iconPinAlertMedium;
            } else if (modified) {
                return iconAlertMediumCircle;
            } else {
                return iconAlertMedium;
            }
        } else {
            if (unmodified) {
                return iconAlertNormalUnmodified;
            } else if (modified) {
                return iconAlertNormalModified;
            } else {
                return iconAlertNormal;
            }
        }
    };

    const alerts = AlertStore.AlertsSelectors.SortedBySourceSeverityAndTime(props.alerts);
    const shortestRunningAlert = AlertStore.AlertsSelectors.ShortestRunningAlert(
        props.alerts
    );
    const alertsToShow = new Set(
        alerts
            .filter(
                x =>
                    x.alarmSourceId === AlertStore.AlertSource.ScatsSiteAlarm ||
                    x.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorAlarm ||
                    x.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm
            )
            .filter((obj, pos, arr) => {
                return arr.map(mapObj => mapObj.code).indexOf(obj.code) === pos;
            })
            .map(alert => ({ description: alert.description, code: alert.code }))
    );
    const currentTime = Moment(new Date());
    const hasMutedAlertSource = !!props.site.alertSources?.filter(x => x.muteUntil && Moment(x.muteUntil) >= currentTime).length;

    let alertTitle;
    if (props.site.addInsightSiteId != null && props.site.scatsSiteId != null) {
        alertTitle = (
            <span className="nav-alert-title">
                Site {props.site.scatsSiteId} - {props.site.description}
            </span>
        );
    } else if (
        props.site.addInsightSiteId == null &&
        props.site.scatsSiteId != null
    ) {
        alertTitle = (
            <span className="nav-alert-title">
                Site {props.site.scatsSiteId} - {props.site.description}
            </span>
        );
    } else if (
        props.site.addInsightSiteId != null &&
        props.site.scatsSiteId == null
    ) {
        alertTitle = (
            <span className="nav-alert-title">
                AddInsight {props.site.addInsightSiteId} - {props.site.description}
            </span>
        );
    }

    return (
        <SidebarItem
            onClick={() => props.selectSite(props.site.id, true)}
            isSelected={props.isSelected}
        >
            <div className={"unread-indicator" + (props.logs.filter(x => x.unread).length > 0 ? " unread" : "")}></div>
            <button type="button" className="btn border-0 shadow-none">
                {props.site && props.backendSettings &&
                    <img src={getAlertSeverity()} width={24} height={24} alt="Alert severity icon" />
                }
                {props.logs &&
                    props.logs.some(
                        x =>
                            ((x.contractorWorkRequired && !x.contractorWorkCompleted) ||
                                (x.scatsWorkRequired && !x.scatsWorkCompleted)) &&
                            !x.dateClosed
                    ) && <img className="contract-work-required" src={iconWrench} alt="Wrench icon" />}
                {hasMutedAlertSource &&
                    <img className="alert-source-muted" src={iconPaused} alt="Pause icon" />}
            </button>
            {props.expand && (
                <div className="nav-alert-detail">
                    {alertTitle}
                    <span className="nav-alert-information">
                        {alerts.length > 0 && (
                            <small className="nav-alert-codes alert-type">
                                {[...alertsToShow.values()].map((alert, i) => {
                                    return (
                                        <span
                                            className="active-alert"
                                            key={i}
                                            title={alert.description}
                                        >
                                            {i > 0 && ", "}
                                            {alert.code.trim()}
                                        </span>
                                    );
                                })}
                            </small>
                        )}
                        {alertSources.map(alert => {
                            const source = (
                                <small
                                    key={alert.key}
                                    className={"alert-type" +
                                        (alerts.some(a => a.alarmSourceId === alert.value) ? " active" : "")
                                    }
                                >
                                    {alert.key}
                                </small>
                            );
                            if (alert.key === "AddInsight") {
                                return props.site.addInsightSiteId != null && source;
                            } else if (alert.key === "SRM") {
                                return props.site.scatsSiteId != null && source;
                            } else {
                                return source;
                            }
                        })}
                        {shortestRunningAlert != null && (
                            <small className="alert-type active">
                                {shortestRunningAlert && (
                                    <ReactMoment
                                        from={shortestRunningAlert.triggerTime}
                                        toNow
                                        ago
                                    />
                                )}
                            </small>
                        )}
                    </span>
                </div>
            )}
        </SidebarItem >
    );
};

export default SiteSideBarAlert;