import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, AppThunkDispatch, ApplicationState } from './index';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { HttpError } from '@microsoft/signalr';
import { CloseFilterPanelAction, CloseSitePanelAction } from './sites';
import { bearing} from '@turf/turf'
import { lineString, LineString, Feature } from '@turf/helpers'
import { CloseSchoolSignPanelAction } from './schoolSigns';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// These Alerts are to be used both in combination with the log viewer, and within sites.

export interface AddInsightState {
    initialLoadStarted: boolean;
    links: { [key: number]: AddInsightLink };
    addInsightGraphData?: AddInsightGraphData;
    selectedLinkId?: number;
}

export interface LatLong {
    lat: number;
    long: number;
}

export interface AddInsightLink {
    linkId: number;
    coordinates: LatLong[];
    score: number;
    name: string;
    originAddInsightSiteId: number;
    destinationAddInsightSiteId: number;
    travelTime: number;
    delay: number;
    speed: number;
    excessDelay: number;
    congestion: number;
    flowRestrictionScore: number;
    averageDensity: number;
    density: number;
    lastUpdated: Date;
    enoughData: boolean;
    heading: number;
    lineString: Feature<LineString>;
}

export interface AddInsightGraphData {
    linkId: number;
    actual: GraphDataActual[];
    expected: GraphDataExpected[];
    bt: GraphDataBt[];
}

export interface GraphDataBt {
    source_id_type: string;
    tracked_vehicle_type_id: number;
    destination_time: Date;
    tt: number;
}

export interface GraphDataActual {
    interval_start: Date;
    travel_time: number;
    congestion: number;
}

export interface GraphDataExpected {
    interval_start: Date;
    mean_travel_time: number;
    st_dev_travel_time: number;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class AddInsightLinkSelectors {
    static GetLinkById(state: AddInsightState, linkId: number): AddInsightLink {
        return state.links[linkId];
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

//addinsight links
interface ReceiveAddInsightLinksAction extends Action {
    type: 'RECEIVE_ADDINSIGHT_LINKS';
    //The value of the component that will be rendered
    incomingLinks: AddInsightLink[];
}

interface RequestAddInsightLinksAction extends Action {
    type: 'REQUEST_ADDINSIGHT_LINKS';
}

interface UpsertAddInsightLinksAction extends Action {
    type: 'UPSERT_ADDINSIGHT_LINKS';
    incomingLinks: AddInsightLink[];
}

interface ErrorAddInsightLinksAction extends Action {
    type: 'ERROR_RECEIVING_ADDINSIGHT_LINKS';
}

interface RemoveAddInsightLinksAction extends Action {
    type: 'REMOVE_ADDINSIGHT_LINKS';
    linkIds: number[];
}

interface OpenLinkPanelAction extends Action {
    type: 'OPEN_LINK_PANEL';
    // The site id that is selected
    linkId: number;
}

export interface CloseLinkPanelAction extends Action {
    type: 'CLOSE_LINK_PANEL';
}

//addinsight graph
interface ReceiveAddInsightGraphAction extends Action {
    type: 'RECEIVE_ADDINSIGHT_GRAPH';
    addInsightGraphData: AddInsightGraphData;
}

interface RequestAddInsightGraphAction extends Action {
    type: 'REQUEST_ADDINSIGHT_GRAPH';
}
interface ClearAddInsightGraphAction extends Action {
    type: 'CLEAR_ADDINSIGHT_GRAPH';
}

interface ErrorAddInsightGraphAction extends Action {
    type: 'ERROR_RECEIVING_ADDINSIGHT_GRAPH';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReceiveAddInsightLinksAction | RequestAddInsightLinksAction | UpsertAddInsightLinksAction | RemoveAddInsightLinksAction | ErrorAddInsightLinksAction
    | ReceiveAddInsightGraphAction | RequestAddInsightGraphAction | ErrorAddInsightGraphAction | OpenLinkPanelAction | CloseLinkPanelAction | ClearAddInsightGraphAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAllGraphData: (linkId: number): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not loading data, and we don't have any loaded yet.
        const { authentication: { accessToken } } = getState();

        if (!accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/AddInsightGraph?linkId=${linkId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<AddInsightGraphData>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_ADDINSIGHT_GRAPH', addInsightGraphData: data });
                    ClearBackendErrorToast(ToastErrorTypes.ADDINSIGHT_GRAPH);
                })
                .catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_ADDINSIGHT_GRAPH' });
                    console.error(error)
                    BackendErrorToast(ToastErrorTypes.ADDINSIGHT_GRAPH, "Error retrieving AddInsight Graph Data", "Check that the AddInsight service is running...");
                });

            dispatch({ type: 'REQUEST_ADDINSIGHT_GRAPH' });
        });
    },
    requestAllLinks: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not loading data, and we don't have any loaded yet.
        const { addInsight: { initialLoadStarted }, authentication: { accessToken } } = getState();

        if (initialLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/AddInsightLink`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<AddInsightLink[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {

                    //calculate and store the heading of each link
                    data.forEach((el) => {
                        const ls = lineString(el.coordinates.map((lngLat) => [lngLat.long, lngLat.lat]));
                        const startPoint = ls.geometry.coordinates[0];
                        const endPoint = ls.geometry.coordinates[ls.geometry.coordinates.length - 1];
                        const heading = bearing(startPoint, endPoint);
                        el.heading = heading < 0 ? (360 - Math.abs(heading)) : heading;
                        el.lineString = ls;
                    });

                    dispatch({ type: 'RECEIVE_ADDINSIGHT_LINKS', incomingLinks: data });
                    ClearBackendErrorToast(ToastErrorTypes.ADDINSIGHT_LINKS);
                })
                .catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_ADDINSIGHT_LINKS' });
                    console.error(error)
                    BackendErrorToast(ToastErrorTypes.ADDINSIGHT_LINKS, "Error retrieving AddInsight Links");
                });

            dispatch({ type: 'REQUEST_ADDINSIGHT_LINKS' });
        });
    },
    clearAddInsightGraphData: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<ClearAddInsightGraphAction>{ type: 'CLEAR_ADDINSIGHT_GRAPH' });
        }),
    selectLinkOnMap: (selectedLinkId: number): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            sites: { selectedSiteId, showFilterSidebar },
            schoolSigns: { selectedSchoolSignId }
        } = getState();

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<OpenLinkPanelAction>{ type: 'OPEN_LINK_PANEL', linkId: selectedLinkId });

            if (selectedSiteId) {
                dispatch(<CloseSitePanelAction>{ type: 'CLOSE_SITE_PANEL' });
            }
            if (showFilterSidebar) {
                dispatch(<CloseFilterPanelAction>{ type: 'CLOSE_FILTER_PANEL' });
            }
            if (selectedSchoolSignId) {
                dispatch(<CloseSchoolSignPanelAction>{ type: 'CLOSE_SCHOOL_SIGN_PANEL' });
            }
        });
    },
    updateLink: (link: AddInsightLink): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<UpsertAddInsightLinksAction>{ type: 'UPSERT_ADDINSIGHT_LINKS', incomingLinks: [link] });
        });
    },
    upsertLinks: (incomingLinks: AddInsightLink[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<UpsertAddInsightLinksAction>{ type: 'UPSERT_ADDINSIGHT_LINKS', incomingLinks: incomingLinks });
        });
    },
    removeLink: (linkId: number): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<RemoveAddInsightLinksAction>{ type: 'REMOVE_ADDINSIGHT_LINKS', linkIds: [linkId] });
        });
    },
    removeLinks: (linkIds: number[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<RemoveAddInsightLinksAction>{ type: 'REMOVE_ADDINSIGHT_LINKS', linkIds: linkIds });
        });
    },
    closeLinkPanel: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<CloseLinkPanelAction>{ type: 'CLOSE_LINK_PANEL' });
        })
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AddInsightState = { links: {}, initialLoadStarted: false };

export const reducer: Reducer<AddInsightState, AnyAction> = (state: AddInsightState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }

    const action = incomingAction as KnownAction;
    const newLinks = { ...state.links };

    switch (action.type) {
        case 'RECEIVE_ADDINSIGHT_LINKS': {
            const incomingLinks = action.incomingLinks.reduce<Record<number, AddInsightLink>>(function (linkMap, link) {
                linkMap[link.linkId] = link;
                return linkMap;
            }, {});

            return <AddInsightState>{
                ...state,
                initialLoadStarted: false,
                links: incomingLinks
            };
        }
        case 'REQUEST_ADDINSIGHT_LINKS':
            return <AddInsightState>{
                ...state,
                initialLoadStarted: true,
            };
        case 'UPSERT_ADDINSIGHT_LINKS':
            action.incomingLinks.forEach((upsertLink) => {
                const existingLink = newLinks[upsertLink.linkId];
                if (!existingLink || upsertLink.lastUpdated >= existingLink.lastUpdated) {
                    newLinks[upsertLink.linkId] = upsertLink;
                }
            });
            return <AddInsightState>{
                ...state,
                links: newLinks,
                initialLoadStarted: true,
            };
        case 'REMOVE_ADDINSIGHT_LINKS':
            action.linkIds.forEach((linkId) => {
                delete newLinks[linkId];
            });
            return <AddInsightState>{
                ...state,
                links: newLinks
            };
        case 'ERROR_RECEIVING_ADDINSIGHT_LINKS':
            return <AddInsightState>{
                ...state,
                initialLoadStarted: false,
            };
        case 'RECEIVE_ADDINSIGHT_GRAPH':
            return <AddInsightState>{
                ...state,
                addInsightGraphData: action.addInsightGraphData,
            };
        case 'REQUEST_ADDINSIGHT_GRAPH':
            return <AddInsightState>{
                ...state,
            };
        case 'CLEAR_ADDINSIGHT_GRAPH':
            return <AddInsightState>{
                ...state,
                addInsightGraphData: undefined
            };
        case 'ERROR_RECEIVING_ADDINSIGHT_GRAPH':
            return <AddInsightState>{
                ...state,
            };
        case 'OPEN_LINK_PANEL':
            return <AddInsightState>{
                ...state,
                selectedLinkId: action.linkId
            };
        case 'CLOSE_LINK_PANEL':
            return <AddInsightState>{
                ...state,
                selectedLinkId: undefined
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }

    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};