import * as React from "react";
import { FC } from "react";

import { ProgressCircle } from "../controls/progressCircle";
import "./dashboard.scss";
import { iconScatsDash, schoolSign } from "./../../components/images";
import { addInsightLogoLarge } from "./../images";
import { DashboardViewModel } from "./dashboardModel";

export const DashBoard: FC<DashboardViewModel> = (props) => {
  return (
    <>
      <div className="dashboard-container">
        <div className="row row1">
          <div className="overview">
            <p>Overview</p>
            <div className="displayContainer">
              <div className="labelNumberDisplay">
                <p>Critical Alerts</p>
                <div className="number">{props.alertsCritical}</div>
              </div>
              <div className="labelNumberDisplay">
                <p>Moderate Alerts</p>
                <div className="number">{props.alertsModerate}</div>
              </div>
              <div className="labelNumberDisplay">
                <p>Open Logs</p>
                <div className="number">{props.logsOpen}</div>
              </div>
              <div className="labelNumberDisplay">
                <p>Unread Logs</p>
                <div className="number">{props.logsUnread}</div>
              </div>
              <div className="labelNumberDisplay">
                <p>Open SRMss</p>
                <div className="number">{props.srmsOpen}</div>
              </div>
              <div className="labelNumberDisplay">
                <p>Muted Data Sources</p>
                <div className="number">{props.mutedDataSources}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row2">
          <div className="displayContainer">
            <div className={`schoolSigns ${props.schoolSignDataWarningOn ? "warning" : ""}`}>
              <div className="header">
                <div className="title">School Signs</div>
                <div className={`rightText ${props.schoolSignDataWarningOn ? "warning" : ""}`}>{props.schoolSignDataUpdateMinutesAgo}</div>
              </div>

              <div className="displayBoxSmallSquare">
                <div className="title">Signs Offline</div>
                <div className="graphRow">
                  <div className="graphContainer">
                    <div className="bgDiv">
                      <img src={schoolSign} alt="School Sign" />
                    </div>
                    <div className="overlay">{props.schoolSignsOffLineValue}</div>
                  </div>
                </div>
              </div>

              <div className="displayBoxSmallSquare">
                <div className="title">Signs Displaying Messages</div>
                <div className="graphRow">
                  <div className="graphContainer">
                    <ProgressCircle
                      color="purpleglow"
                      value={props.schoolSignsDisplayingMessagesPercentValue}
                      displayText={props.schoolSignsDisplayingMessagesPercentString}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={`busnetwork ${props.busesDataWarningOn ? "warning" : ""}`}>
              <div className="header">
                <div className="title">Bus Network</div>
                <div className={`rightText ${props.busesDataWarningOn ? "warning" : ""}`}>{props.busDataUpdateMinutesAgo}</div>
              </div>
              <div className="displayBoxSmallSquare">
                <div className="title">Buses Running Early {props.busStatusEarly}</div>
                <div className="graphRow">
                  <div className="graphContainer">
                    <ProgressCircle color="green" value={props.busesRunningEarlyPercentValue} displayText={props.busesRunningEarlyPercentString} />
                  </div>
                </div>
              </div>

              <div className="displayBoxSmallSquare">
                <div className="title">Buses Running Late {props.busStatusLate}</div>
                <div className="graphRow">
                  <div className="graphContainer">
                    <ProgressCircle color="maroon" value={props.busesRunningLatePercentValue} displayText={props.busesRunningLatePercentString} />
                  </div>
                </div>
              </div>
            </div>

            <div className={`scats ${props.scatsDataWarningOn ? "warning" : ""}`}>
              <div className="header">
                <div className="title">
                  <img src={iconScatsDash} alt="AddInsight Logo"></img>
                </div>
                <div className={`rightText ${props.scatsDataWarningOn ? "warning" : ""}`}>{props.scatsDataUpdateMinutesAgo}</div>
              </div>

              <div className="displayContainer">
                <div className="labelNumberDisplay">
                  <p>Sites in Fallback</p>
                  <div className="number">{props.getScatsSitesInFallback}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Stopped Comms Alarms (ST)</p>
                  <div className="number">{props.scatsAlarmsST}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Unusual Volume Records (VOL)</p>
                  <div className="number">{props.scatsAlarmsVOL}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Sites with Detector Alarms (DA)</p>
                  <div className="number">{props.scatsAlarmsDA}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>WatchDog (WD)</p>
                  <div className="number">{props.scatsAlarmsWD}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Flashing Yellow (FY)</p>
                  <div className="number">{props.scatsAlarmsFY}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Check Sum (CK)</p>
                  <div className="number">{props.scatsAlarmsCK}</div>
                </div>
                <div className="labelNumberDisplay">
                  <p>Black Out (BO)</p>
                  <div className="number">{props.scatsAlarmsBO}</div>
                </div>
              </div>
            </div>

            <div className={`addInsights ${props.addInsightsDataWarningOn ? "warning" : ""}`}>
              <div className="header">
                <div className="title">
                  <img className="addInsightLogo" src={addInsightLogoLarge} alt="AddInsight Logo"></img>
                </div>
                <div className={`rightText ${props.addInsightsDataWarningOn ? "warning" : ""}`}>{props.addInsightsDataUpdateMinutesAgo}</div>
              </div>

              <div className="displayContainer">
                <div className="labelNumberDisplay">
                  <p>Number of Congestion Links</p>
                  <div className="number wider">{props.addInsightNumberOfCongestionLinks}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
