import { AddInsightLink } from "store/addInsight";
import { Alert, AlertSource } from "store/alarm";
import { Bus } from "store/buses";
import { Log } from "store/logs";
import { SchoolSign, SchoolSignStatus } from "store/schoolSigns";
import { Site } from "store/sites";
import moment from "moment";
import { AdminSettingsState, BackendSettings } from "store/adminSettings";
import { Status } from "../../store/health";

class DashboardCommon {
    // utility functions /////////////////////////////////////////////////////////////////////////////////////

    getPercentDifference = (v1: number, v2: number) => {
        if (!v1 || !v2) return 0;
        const res = ((v1 - v2) / ((v1 + v2) / 2)) * 100;
        return +res.toFixed(0);
    };

    getPercentage = (v1: number, v2: number) => {
        if (!v1 || !v2) return 0;
        const res = (v1 / v2) * 100;
        return +res.toFixed(0);
    };

    fixHumaniseTimeCasing = (original: string) => {
        return original.replace("Minutes", "mins").replace("a few seconds", "less than a minute").concat(" ago");
    };

    getNow = () => {
        return moment.now();
    };

    getColorHex = (color: string) => {
        return this.colors.get(color);
    };

    colors = new Map([
        ["red", "#ff0000"],
        ["purpleglow", "#9017fc"],
        ["green", "#00da7d"],
        ["maroon", "#fd0154"],
        ["steel-light", "#2A353E"],
        ["blue-light", "#00AEEF"],
    ]);

    get DefaultDate(): moment.Moment {
        return moment("1970-01-01T00:00:00");
    }

    get thresholdForNetworkWarningSeconds(): number {
        return 600;
    }

    // logs /////////////////////////////////////////////////////////////////////////////////////

    getOpenLogs = (logs: Log[]): Log[] => logs.filter((log) => !log.dateClosed);
    getUnreadLogs = (logs: Log[]): Log[] => logs.filter((log) => log.unread);
    getOpenSRMs = (alerts: Alert[]): Alert[] =>
        alerts.filter((a) => a.code?.trim() === "SRM" && !a.closedTime && a.additionalData?.toLocaleLowerCase().includes("open"));

    // Alerts /////////////////////////////////////////////////////////////////////////////////////

    private isCriticalAlert = (site: Site, backendSettings: BackendSettings | undefined) => {
        if (!backendSettings) return false;
        const sitePriority = backendSettings.sitePrioritySettings.filter((p) => p.priority === site.priority)[0];
        return site.currentStatus >= sitePriority.criticalAlertThreshold;
    };
    private isModerateAlert = (site: Site, backendSettings: BackendSettings | undefined) => {
        if (!backendSettings) return false;
        const sitePriority = backendSettings.sitePrioritySettings.filter((p) => p.priority === site.priority)[0];
        return site.currentStatus >= sitePriority.mediumAlertThreshold && site.currentStatus < sitePriority.criticalAlertThreshold;
    };

    getCriticalAlerts = (backendSettings: BackendSettings | undefined, sites: Site[]) => {
        if (!backendSettings) return [];
        return sites?.filter((s) => this.isCriticalAlert(s, backendSettings)) ?? [];
    };

    getModerateAlerts = (backendSettings: BackendSettings | undefined, sites: Site[]) => {
        if (!backendSettings) return [];
        return sites?.filter((s) => this.isModerateAlert(s, backendSettings)) ?? [];
    };

    getMutedDataSources = (sites: Site[]): number => {
        const muted = sites.flatMap((s) => s.alertSources.filter((x) => x.muteUntil != null && moment(x.muteUntil) >= moment(new Date())));
        return muted.length;
    };

    // schoolSigns /////////////////////////////////////////////////////////////////////////////////////

    private isSchoolSignOffline = (s: SchoolSign) => s.status === SchoolSignStatus.NotTalking;
    private isSchoolSignDisplayingMessage = (s: SchoolSign) => s.status === SchoolSignStatus.On;
    private getSchoolSignsDisplayingMessage = (ss: SchoolSign[]) => ss.filter(this.isSchoolSignDisplayingMessage);

    getOfflineSchoolSigns = (ss: SchoolSign[]) => ss.filter(this.isSchoolSignOffline);

    getSchoolSignsDisplayingMessagePercent = (ss: SchoolSign[]): number => {
        if (ss && ss.length) {
            const percent = (this.getSchoolSignsDisplayingMessage(ss).length / ss.length) * 100;
            return Math.round(percent);
        }
        return 0;
    };

    getSchoolSignDataUpdatedTimeAgo = (ss: SchoolSign[] | undefined): string => {
        if (!ss) return "Unknown";

        if (!ss.length) {
            return "Unknown";
        }

        const lastUpdate = this.getSchoolSignsLatestTime(ss);
        const duration = moment.duration(moment().diff(lastUpdate));
        const humanised = duration.humanize();
        return this.fixHumaniseTimeCasing(humanised);
    };

    private getSchoolSignsLatestTime(ss: SchoolSign[] | undefined): moment.Moment {
        if (!ss || !ss.length) return this.DefaultDate;

        const latest =
            ss
                .filter((s) => s.lastSeen)
                .map((s) => s.lastSeen)
                .sort()
                .reverse()[0] || null;
        return moment(latest);
    }

    getSchoolSignDataUpdateSecondsAgo = (ss: SchoolSign[] | undefined): number => {
        if (!ss || !ss.length) return 60 * 24 * 365;
        const lastUpdate = this.getSchoolSignsLatestTime(ss);
        const duration = moment.duration(moment().diff(lastUpdate));
        return duration.asSeconds() + 5;
    };

    getSchoolSignDataWarningOn = (ss: SchoolSign[] | undefined): boolean => {
        const seconds = this.getSchoolSignDataUpdateSecondsAgo(ss);
        return seconds > this.thresholdForNetworkWarningSeconds;
    };

    // buses /////////////////////////////////////////////////////////////////////////////////////

    getBusStatuses = (bb: Bus[] | undefined, settings: AdminSettingsState | undefined): any[] => {
        if (!bb || !settings) return [];

        const lateThreshold = settings?.adminSettings?.portalSettings?.lateBusThreshold || 1;
        const earlyThreshold = settings?.adminSettings?.portalSettings?.earlyBusThreshold || 1;

        const statuses = bb
            ?.filter((bus) => bus?.schAdhSecs)
            .map((bus) => {
                const seconds = Math.round(bus.schAdhSecs || 0);
                const minutes = seconds / 60;
                const isLate = minutes > lateThreshold;
                const isEarly = minutes < earlyThreshold;
                return { isLate, isEarly };
            });

        return statuses;
    };

    getBusRunningEarlyStatus = (bb: Bus[] | undefined, settings: AdminSettingsState | undefined) => {
        const early = this.getBusStatuses(bb, settings).filter((b) => b.isEarly);
        return `(${early.length} of ${bb?.length})`;
    };

    getBusRunningLateStatus = (bb: Bus[] | undefined, settings: AdminSettingsState | undefined) => {
        const late = this.getBusStatuses(bb, settings).filter((b) => b.isLate);
        return `(${late.length} of ${bb?.length})`;
    };

    getBusRunningLatePercent = (bb: Bus[] | undefined, settings: AdminSettingsState | undefined) => {
        bb = bb || [];
        const late = this.getBusStatuses(bb, settings).filter((b) => b.isLate);
        return this.getPercentage(late.length, bb?.length);
    };

    getBusRunningEarlyPercent = (bb: Bus[] | undefined, settings: AdminSettingsState | undefined) => {
        bb = bb || [];
        const early = this.getBusStatuses(bb, settings).filter((b) => b.isEarly);
        return this.getPercentage(early.length, bb?.length);
    };

    getBusLatestDataTime = (bb: Bus[]): moment.Moment => {
        if (!bb || !bb.length) return this.DefaultDate;

        const latest = bb
            .filter((s) => s.loc.time)
            .map((s) => s.loc.time)
            .sort()
            .reverse()[0];

        return moment.unix(latest);
    };

    getBusDataUpdateSecondsAgo = (bb: Bus[] | undefined): number => {
        if (!bb || !bb.length) return 60 * 24 * 365;
        const lastUpdate = this.getBusLatestDataTime(bb);
        const duration = moment.duration(moment().diff(lastUpdate));
        return duration.asSeconds() + 10;
    };

    getBusDataUpdateMinutesAgo = (bb: Bus[]) => {
        if (!bb || !bb.length) return "Unknown";

        const lastUpdate = this.getBusLatestDataTime(bb);
        const duration = moment.duration(moment().diff(lastUpdate));
        const seconds = duration.asSeconds() + 10;

        return `${seconds.toFixed(1)} seconds ago`;
    };

    getBusesDataWarningOn = (bb: Bus[]) => {
        const seconds = this.getBusDataUpdateSecondsAgo(bb);
        return seconds > this.thresholdForNetworkWarningSeconds;
    };

    // sites /////////////////////////////////////////////////////////////////////////////////////

    getScatsSitesInFallback = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "FB");
    getScatsAlarmST = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "ST");
    getScatsAlarmVOL = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "VOL");
    getScatsAlarmDA = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "DA");
    getScatsAlarmWD = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "WD");
    getScatsAlarmFY = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "FY");
    getScatsAlarmCK = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "CK");
    getScatsAlarmBO = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "BO");
    getPowerSupplyAlarm = (alerts: Alert[]) => alerts.filter((a) => a.code?.trim() === "WEL");

    getScatsDataUpdateMinutesAgo = (aa: Status | undefined) => {
        if (!aa) return "Loading";

        const latest = this.getScatsDataLatestDate(aa);
        if (!latest) return "Unknown";

        const lastUpdate = moment(latest);
        const duration = moment.duration(moment().diff(lastUpdate));
        const humanised = duration.humanize();
        return this.fixHumaniseTimeCasing(humanised);
    };

    getScatsDataLatestDate = (aa: Status): moment.Moment => {

        const times = [
            aa.scatsSiteAlarmsLastUpdated,
            aa.scatsDetectorAlarmsLastUpdated,
            aa.scatsVolumesLastUpdated
        ]
            .filter(time => time !== undefined)
            .map(x => moment(x));

        const lastUpdate = moment.max(times);
        return lastUpdate;
    };

    getScatsDataUpdateSecondsAgo = (aa: Status | undefined): number => {
        if (!aa) return 60 * 24 * 365;
        const lastUpdate = this.getScatsDataLatestDate(aa);
        const duration = moment.duration(moment().diff(lastUpdate));
        return duration.asSeconds();
    };

    getScatsDataWarningOn = (aa: Status | undefined): boolean => {
        const seconds = this.getScatsDataUpdateSecondsAgo(aa);
        return seconds > this.thresholdForNetworkWarningSeconds;
    };

    // addinsights /////////////////////////////////////////////////////////////////////////////////////

    getAddInsightsCongestionLinks = (links: AddInsightLink[]) => links.filter((a) => a.score && a.score > 2);

    getAddInsightsDataUpdateMinutesAgo = (links: AddInsightLink[]): string => {
        if (!links) return "Unknown";
        if (!links.length) return "Unknown";

        const lastUpdate = this.getAddInsightDataLatestDate(links);
        const duration = moment.duration(moment().diff(lastUpdate));
        const humanised = duration.humanize();
        return this.fixHumaniseTimeCasing(humanised);
    };

    getAddInsightDataLatestDate = (links: AddInsightLink[]): moment.Moment => {
        if (!links || !links.length) return this.DefaultDate;
        const latest =
            links
                .filter((k) => k.lastUpdated)
                .map((k) => k.lastUpdated)
                .sort()
                .reverse()[0] || null;

        return moment(latest);
    };

    getAddInsightsDataUpdateSecondsAgo = (links: AddInsightLink[]): number => {
        const lastUpdate = this.getAddInsightDataLatestDate(links);
        const duration = moment.duration(moment().diff(lastUpdate));
        return duration.asSeconds() + 5;
    };

    getAddInsightDataWarningOn = (links: AddInsightLink[]): boolean => {
        const seconds = this.getAddInsightsDataUpdateSecondsAgo(links);
        return seconds > this.thresholdForNetworkWarningSeconds;
    };
}

export const dashboardCommon = new DashboardCommon();
