import React from "react";
import "./modal.scss";
import { iconClose } from "../images";
import Spinner from "../spinner/spinner";

export interface ModalProps {
    closeModal: () => void;
    title: string;
    isLoading: boolean;
}

export default class Modal extends React.Component<ModalProps> {
    public render() {
        const { isLoading } = this.props;

        return (
            <div className="modal-overlay">
                <div className="modal-dialog">
                    {isLoading && (
                        <Spinner dim />
                    )}
                    <div className={"modal-content-all " + (isLoading ? "is-loading" : "")}>
                        <div className="modal-header">
                            <div className="modal-header-content">
                                <h2>{this.props.title}</h2>
                                <div className="modal-header-close" onClick={this.props.closeModal}>
                                    <img src={iconClose} width={24} height={24} alt="Close icon" />
                                </div>
                            </div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
