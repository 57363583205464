import * as React from "react";
import {
    VictoryChart, VictoryLegend, VictoryAxis, VictoryScatter, VictoryLine, VictoryLabel, VictoryTheme, VictoryVoronoiContainer
} from "victory";
import {
    GraphDataActual, GraphDataExpected, GraphDataBt
} from "store/addInsight";

type DataPoint = {
    x: number | Date;
    y: number;
};

type Line = {
    index: number;
    name: string;
    color?: string;
    dataPoints: DataPoint[];
};

interface GraphProps {
    actual: GraphDataActual[];
    expected: GraphDataExpected[];
    bt: GraphDataBt[];
}

const toVictoryData = (line: Line) => {
    return line.dataPoints.map(dp => ({
        name: line.name, x: dp.x, y: dp.y
    }));
};

const toVictoryLegend = (line: Line) => {
    return line.color
        ? {
            name: line.name, symbol: {
                fill: line.color
            }
        }
        : { name: line.name };
};

type State = {
    chartHeight: number;
    chartWidth: number;
    showActual: boolean;
    showExpected: boolean;
    showBt: boolean;
};

export default class GraphWithLegend extends React.Component<GraphProps, State> {
    private refreshHeightTimer?: NodeJS.Timer;
    private divRef: React.RefObject<any>;
    private expectedTravelTimeLabel = "Expected Travel Time";
    private actualTravelTimeLabel = "Travel Time";
    private btTravelTimeLabel = "Travel Time Records: bt";

    series: Line[];

    constructor(props: GraphProps) {
        super(props);
        this.state = {
            chartHeight: 100, chartWidth: 0, showActual: true, showExpected: true, showBt: true
        };

        this.divRef = React.createRef<HTMLDivElement>();
        this.series = this.buildSeries(this.props.expected, this.props.actual, this.props.bt);
    }

    private buildSeries(
        expected: GraphDataExpected[], actual: GraphDataActual[], bt: GraphDataBt[]
    ) {
        const mtt = (actual.length > 0 ?
            actual.reduce(function (prev, current) {
                return (prev.travel_time > current.travel_time) ? prev : current
            }).travel_time : 3600) + 300;
        return [
            {
                name: this.expectedTravelTimeLabel, color: "#ff0000", dataPoints: expected.map(d => {
                    return { x: new Date(d.interval_start), y: d.mean_travel_time / 60 };
                }), index: 0
            }, {
                name: this.actualTravelTimeLabel, color: "#00bfff", dataPoints: actual.map(d => {
                    return { x: new Date(d.interval_start), y: d.travel_time / 60 };
                }), index: 1
            }, {
                name: this.btTravelTimeLabel, color: "#7895ff", dataPoints: bt
                    .filter(x => x.source_id_type.toLocaleLowerCase() === "bt" && x.tt < mtt)
                    .map(d => {
                        return { x: new Date(d.destination_time), y: d.tt / 60 };
                    }), index: 2
            }
        ];
    }

    componentDidMount() {
        const current: HTMLDivElement = this.divRef.current;
        if (current) {
            this.refreshHeightTimer = setTimeout(() => {
                const height = current.clientHeight;
                this.setState({ chartHeight: height });
                const width = current.clientWidth;
                this.setState({ chartWidth: width });
            }, 200);
        }
    }
    componentWillUnmount() {
        if (this.refreshHeightTimer) {
            clearTimeout(this.refreshHeightTimer);
        }
    }

    private buildEvents(): any {
        const t = this.series.map(_ => {
            return {
                childName: ["legend"], target: ["data", "labels"], eventKey: String(_.index), eventHandlers: {
                    onClick: () => {
                        return [
                            {
                                childName: [_.name], target: "data", eventKey: "all", mutation: () => {
                                    if (_.name === this.expectedTravelTimeLabel) {
                                        this.setState({ showExpected: !this.state.showExpected });
                                    } else if (_.name === this.actualTravelTimeLabel) {
                                        this.setState({ showActual: !this.state.showActual });
                                    } else if (_.name === this.btTravelTimeLabel) {
                                        this.setState({ showBt: !this.state.showBt });
                                    }
                                    return null;
                                }
                            }
                        ];
                    }, onMouseOver: () => {
                        return [
                            {
                                childName: [_.name], target: "data", eventKey: "all", mutation: (props: any) => {
                                    return {
                                        style: { ...props.style, strokeWidth: 5 }, size: 4
                                    };
                                }
                            }
                        ];
                    }, onMouseOut: () => {
                        return [
                            {
                                childName: [_.name], target: "data", eventKey: "all", mutation: () => {
                                    return null;
                                }
                            }
                        ];
                    }
                }
            };
        });
        return t;
    }

    render() {
        const { showActual, showBt, showExpected, chartHeight, chartWidth } = this.state;
        const { actual, expected, bt } = this.props;

        this.series = this.buildSeries(expected, actual, bt);

        return (
            <div ref={this.divRef}>
                <VictoryChart
                    domainPadding={{ y: [20, 20], x: [2, 2] }}
                    theme={VictoryTheme.material}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={({ datum }) => `${Math.round(datum.y * 100) / 100}`}
                        />
                    }
                    events={this.buildEvents()}
                    padding={{ bottom: 110, left: 55, right: 20, top: 20 }}
                    width={chartWidth}
                >
                    {/*Y axis*/}
                    <VictoryAxis
                        dependentAxis
                        orientation="left"
                        standalone={false}
                        style={{
                            axisLabel: { padding: 40, fontSize: "15px", fill: "#E8E8E8" }, tickLabels: { fill: "#FFF" }
                        }}
                        label={"Travel Time (mins)"}
                    />

                    {/*X axis*/}
                    <VictoryAxis
                        standalone={false}
                        tickValues={this.series[0].dataPoints.map(d => new Date(d.x))}
                        tickFormat={(x: string) =>
                            `${new Date(x).getHours()}:${("0" + new Date(x).getMinutes()).slice(
                                -2
                            )}`
                        }
                        tickLabelComponent={
                            <VictoryLabel angle={310} verticalAnchor="start" textAnchor="middle" />
                        }
                        style={{ tickLabels: { fill: "#FFF" } }}
                        fixLabelOverlap
                    />
                    {/*Travel Time Records: bt*/}
                    {showBt && bt && (
                        <VictoryScatter
                            key={this.series[2].name}
                            name={this.series[2].name}
                            style={{
                                data: { fill: this.series[2].color }, labels: { fill: this.series[2].color }
                            }}
                            standalone={false}
                            data={toVictoryData(this.series[2])}
                            size={1.8}
                        />
                    )}

                    {/*Expected Travel Time*/}
                    {showExpected && expected && (
                        <VictoryLine
                            key={this.series[0].name}
                            name={this.series[0].name}
                            data={toVictoryData(this.series[0])}
                            interpolation="monotoneX"
                            standalone={false}
                            style={{
                                data: { stroke: this.series[0].color, strokeWidth: 3 }, labels: { fill: this.series[0].color }
                            }}
                        />
                    )}

                    {/*Actual Travel Time*/}
                    {showActual && actual && (
                        <VictoryLine
                            key={this.series[1].name}
                            name={this.series[1].name}
                            data={toVictoryData(this.series[1])}
                            interpolation="monotoneX"
                            standalone={false}
                            style={{
                                data: { stroke: this.series[1].color, strokeWidth: 3 }, labels: { fill: this.series[1].color }
                            }}
                        />
                    )}

                    <VictoryLegend
                        name={"legend"}
                        data={this.series.map(s => {
                            const item = toVictoryLegend(s);

                            const deselectedStyle = {
                                ...item, symbol: { fill: "#999" }, labels: { fill: "#999" }
                            };

                            if (s.name === this.expectedTravelTimeLabel && !showExpected) {
                                return deselectedStyle;
                            } else if (s.name === this.actualTravelTimeLabel && !showActual) {
                                return deselectedStyle;
                            } else if (s.name === this.btTravelTimeLabel && !showBt) {
                                return deselectedStyle;
                            }
                            return item;
                        })}
                        style={{
                            labels: { cursor: "pointer", fill: "#E8E8E8" }, data: { cursor: "pointer" }
                        }}
                        orientation="vertical"
                        y={chartHeight - 70}
                    />
                </VictoryChart>
            </div>
        );
    }
}
