import * as React from "react";
import "./controls.scss";
import { MobileAlertGroup, MobileAlertRecipient } from "store/adminSettings";
import { CheckBox } from "../../controls/checkBox";
import { iconDelete, iconAdd } from "../../images";

interface MobileRecipientTableSettingProps {
    title?: string;
    updateMobileAlertRecipients: (recipients: MobileAlertRecipient[]) => void;
    recipients: MobileAlertRecipient[];
    groups: MobileAlertGroup[];
}

export class MobileRecipientTableSetting extends React.Component<MobileRecipientTableSettingProps> {
    private defaultNewRecipient: MobileAlertRecipient = {
        id: 0,
        active: true,
        email: "",
        mobile: "",
        lastUpdated: new Date(),
        groupId: null
    };

    public render() {
        const {
            title,
            updateMobileAlertRecipients,
            recipients,
            groups
        } = this.props;

        const handleUpdateRecipient = (recipient: MobileAlertRecipient) => {
            const r: MobileAlertRecipient[] = [...this.props.recipients];
            const index = r.findIndex((obj => obj.id === recipient.id));
            r[index] = recipient;
            updateMobileAlertRecipients(r);
        };

        const handleDeleteRecipient = (recipient: MobileAlertRecipient) => {
            const r: MobileAlertRecipient[] = [...this.props.recipients];
            const index = r.findIndex((obj => obj.id === recipient.id));
            r.splice(index, 1);
            updateMobileAlertRecipients(r);
        };

        const handleAddRecipient = () => {
            const r: MobileAlertRecipient[] = [...this.props.recipients];
            this.defaultNewRecipient.id = -10000000 + this.props.recipients.length; //set temporary id as negative - this will be replaced on save
            r.push(this.defaultNewRecipient);
            updateMobileAlertRecipients(r);
        };

        return (
            <div className="setting recipient-table">
                {recipients.length > 0 &&
                    <>
                        <div className="settings-table-title">{title}</div>
                        <div className="settings-table container">
                            <div className="settings-header-row pl-4 pr-4 d-none d-md-flex">
                                <div className="col-4">Email</div>
                                <div className="col-3">Mobile</div>
                                <div className="col-2 col-sm-1">Active</div>
                                <div className="col-3 col-sm-3">Group</div>
                                <div className="col-1">Actions</div>
                            </div>
                            {recipients.map(
                                (r, i) =>
                                    <div key={i} className={"row settings-row ml-1 mr-1" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                                        <div className="col-12 col-sm-6 col-md-4 mb-2">
                                            <div className="custom-value-input">
                                                <span className="d-flex d-md-none">Email</span>
                                                <input className="mr-3" type="text" value={r.email}
                                                    onChange={
                                                        (event: React.ChangeEvent<HTMLInputElement>) => handleUpdateRecipient({ ...r, email: event.target.value, lastUpdated: new Date() })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-3 mb-2">
                                            <div className="custom-value-input">
                                                <span className="d-flex d-md-none">Mobile</span>
                                                <input className="mr-3" type="text" value={r.mobile}
                                                    onChange={
                                                        (event: React.ChangeEvent<HTMLInputElement>) => handleUpdateRecipient({ ...r, mobile: event.target.value, lastUpdated: new Date() })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                            <div className="d-flex">
                                                <span className="d-flex d-md-none align-items-center mr-3">Active</span>
                                                <CheckBox checked={r.active} onChange={() => handleUpdateRecipient({ ...r, active: !r.active, lastUpdated: new Date() })} />
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <div className="d-flex">
                                                <span className="d-flex d-md-none align-items-center mr-3">Group</span>
                                                <select name="groups" value={r.groupId ?? -1} onChange={(e) => handleUpdateRecipient({ ...r, groupId: Number(e.target.value) === -1 ? null : Number(e.target.value), lastUpdated: new Date() })} className="text-setting-input">
                                                    <option value={-1}>None</option>
                                                    {
                                                        !!groups.length && groups.map(g => {
                                                            return (
                                                                <option key={g.id} value={g.id}>{g.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                            <div className="delete-icon-container d-flex d-md-block justify-content-end" >
                                                <div className="d-inline-block" onClick={() => handleDeleteRecipient(r)}>
                                                    <img className="delete-icon" src={iconDelete} alt="Delete recipient" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            )}
                        </div >
                    </>
                }
                <div className="add-icon-container" onClick={() => handleAddRecipient()}>
                    <span className="d-inline-block">
                        <img className="add-icon" src={iconAdd} alt="Add recipient" />
                    </span>Add Recipient</div>
            </div>
        );
    }
}

export default MobileRecipientTableSetting;
