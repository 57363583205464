import * as React from "react";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface DropDownSettingControlProps {
    settingTitle: string;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    settingValue?: number;
    dropDownKeyValues: DropDownKeyVaule[];
    handleSettingUpdate: any;
    explanation?: string;
}

export interface DropDownKeyVaule {
    key: string;
    value: string;
}

export class DropDownSettingControl extends React.Component<DropDownSettingControlProps> {
    constructor(props: DropDownSettingControlProps) {
        super(props);
        this.state = { showExplanation: true };
    }
    public render() {
        const {
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            explanation,
            dropDownKeyValues
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <select name="dropDownKeyValues" value={settingValue ?? -1} onChange={(e) =>
                    handleSettingUpdate(settingPropName, Number(e.target.value) === -1 ? null : Number(e.target.value))} className="text-setting-input">
                    <option value={-1}>None</option>
                    {
                        !!dropDownKeyValues.length && dropDownKeyValues.map(g => {
                            return (
                                <option key={g.key} value={g.key}>{g.value}</option>
                            );
                        })
                    }
                </select>
            </SettingControlWrapper>
        );
    }
}

export default DropDownSettingControl;
