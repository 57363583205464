//Adapted from https://github.com/produtoreativo/react-leaflet-googlemutant to support React 16
//https://github.com/produtoreativo/react-leaflet-googlemutant/blob/master/LICENSE

import React from "react";

declare global {
  interface Window {
    [key: string]: unknown; 
  }
}

export type Library = "drawing" | "geometry" | "places" | "visualization" | "directions";

export type GoogleApiLoaderProps = {
    apiKey: string;
    libraries?: Library[];
};

export type GoogleApiLoaderState = {
    googleLoaded: boolean;
    url: string;
    version: string;
    client?: string;
    language?: string;
    region?: string;
    channel?: string;
    libraries: Library[];
    callback: string;
    signature?: string;
    apiKey?: string;
};

class GoogleApiLoader extends React.Component<GoogleApiLoaderProps, GoogleApiLoaderState> {
    constructor(props: GoogleApiLoaderProps) {
        super(props);
        this.state = {
            googleLoaded: false,
            url: "https://maps.googleapis.com/maps/api/js",
            version: "3.27",
            libraries: this.props.libraries || [],
            callback: "__google_maps_api_provider_initializator__",
            apiKey: this.props.apiKey
        };
    }

    componentDidMount() {
        if (!this.state.googleLoaded) {
            this.load();
        }
    }

    afterLoad = () => {
        this.setState({
            ...this.state,
            googleLoaded: true
        });
    };

    createUrl = () => {
        const {
            url,
            callback,
            libraries,
            client,
            version,
            channel,
            language,
            region,
            signature,
            apiKey
        } = this.state;
        const chave = apiKey ? `&key=${apiKey}` : "";
        const assinatura = signature ? `&signature=${signature}` : "";
        const libs = libraries.length ? `&libraries=${libraries.join(",")}` : "";
        const cliente = client ? `&client=${client}&v=${version}` : "";
        const canal = channel ? `&channel=${channel}` : "";
        const lang = language ? `&language=${language}` : "";
        const regiao = region ? `&region=${region}` : "";
        return `${url}?callback=${callback}${assinatura}${chave}${libs}${cliente}${canal}${lang}${regiao}`;
    };

    load = () => {
        const src = this.createUrl();

        //checks if the script tag already exists
        if (document.querySelectorAll(`script[src="${src}"]`).length > 0) {
            this.afterLoad();
            return;
        }

        const { callback } = this.state;
        
        if (!(window[callback])) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            window[callback] = () => {};
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        script.onload = this.afterLoad;
        document.body.appendChild(script);
    };

    render() {
        const { googleLoaded } = this.state;
        const { children } = this.props;
        if (!googleLoaded) {
            return null;
        }
        return children;
    }
}

export default GoogleApiLoader;
