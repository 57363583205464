import * as React from "react";
import Map from "../containers/map";
import Sidebar from "../containers/sidebar";
import CommentEditor from "../containers/commentEditor";
import LogViewer from "../containers/logViewer";
import SiteInformation from "../containers/siteInformation";

export class Home extends React.Component {
    public render() {
        return (
            <>
                <Sidebar />
                <div id="pushToRight">
                    <div className="map-overlay">
                        <div className="nav-blade-container">
                            <SiteInformation />
                            <LogViewer />
                        </div>
                    </div>
                </div>
                <CommentEditor />
                <Map />
            </>
        );
    }
}

export default Home;
