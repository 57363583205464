import * as React from 'react';
import { iconOpen, iconCollapse } from '../images/index';

interface AdminSettingSubSectionProps {
    sectionTitle: string;
}

interface AdminSettingSubSectionState {
    showSection: boolean;
}

export class AdminSettingSubSection extends React.Component<AdminSettingSubSectionProps, AdminSettingSubSectionState> {
    constructor(props: AdminSettingSubSectionProps) {
        super(props);
        this.state = { showSection: false }
    }

    public render() {
        const { sectionTitle } = this.props;
        const { showSection } = this.state;
        const handleToggleSection = () => this.setState((e) => ({
            showSection: !e.showSection
        }));

        return (
            <div className="settings-subsection">
                <div className="toggle-sub-section">
                    <div onClick={handleToggleSection}>
                        <h4 className="settings-section-subtitle">{sectionTitle}</h4>
                    </div>
                    <div onClick={handleToggleSection}>
                        {
                            showSection
                                ? <img className="collapse-icon" src={iconCollapse} alt="Collapse Section" />
                                : <img className="open-icon" src={iconOpen} alt="Open Section" />
                        }
                    </div>
                </div>
                {showSection && this.props.children}
            </div >
        );
    }
}

export default AdminSettingSubSection;