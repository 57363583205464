import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store";
import * as AlertStore from "store/alarm";
import * as AuthStore from "store/authentication";
import * as SitesStore from "store/sites";
import * as LogStore from "store/logs";
import * as AddInsightStore from "store/addInsight";
import * as AdminSettingsStore from "store/adminSettings";
import * as SchoolSignStore from "store/schoolSigns";
import * as CycleCounterStore from "store/cycleCounterDevices";
import * as LightwireDevicesStore from "store/lightwireDevicesStore";
import * as PumpsApi from "store/Pumps/pumpsDataApi";
import * as PumpsStore from "store/Pumps/pumpsDatatypes";
import SidebarPumpInformation from "../components/sidebar/infoPanels/sidebarPumpInformation";
import SidebarSiteInformation from "../components/sidebar/infoPanels/sidebarSiteInformation";
import SidebarLinkInformation from "../components/sidebar/infoPanels/sidebarLinkInformation";
import SidebarFilter from "../components/sidebar/infoPanels/sidebarFilters";
import SidebarSchoolSignInformation from "../components/sidebar/infoPanels/sidebarSchoolSignInformation";
import { SideBarCycleCounterInformation } from "../components/sidebar/infoPanels/sidebarCycleCounterInformation";

export type SidebarInfoViewerProps = LogStore.LogState &
    AuthStore.AuthState &
    AlertStore.AlertState &
    SitesStore.SiteState &
    AdminSettingsStore.AdminSettingsState &
    AddInsightStore.AddInsightState &
    SchoolSignStore.SchoolSignState &
    CycleCounterStore.CycleCounterDevicesState &
    LightwireDevicesStore.LightwireDevicesState &
    PumpsStore.PumpDevicesState &
    typeof LogStore.actionCreators &
    typeof SitesStore.actionCreators &
    typeof AddInsightStore.actionCreators &
    typeof SchoolSignStore.actionCreators &
    typeof CycleCounterStore.actionCreators &
    typeof LightwireDevicesStore.actionCreators &
    typeof PumpsApi.actionCreators;

class SidebarInfoViewerContainer extends React.Component<SidebarInfoViewerProps> {
    public componentDidMount() {
        this.props.requestAllLogs();
    }
    public render() {
        const {
            selectedSiteId,
            selectedLinkId,
            siteOperationLoading,
            muteAlertSource,
            adminSettings,
            addInsightGraphData,
            showFilterSidebar,
            requestAllGraphData,
            closeFilterPanel,
            filters,
            setFilters,
            selectedLogId,
            authRole,
            selectedSchoolSignId,
            setSchoolSignStatus,
            schoolSignStatusOperationLoading,
            selectedCycleCounterDeviceId,
            selectedPumpId
        } = this.props;

        const selectedSite = selectedSiteId ? SitesStore.SiteSelectors.GetSiteByIdentifier(this.props, selectedSiteId) : null;
        const selectedLink = selectedLinkId ? AddInsightStore.AddInsightLinkSelectors.GetLinkById(this.props, selectedLinkId) : null;
        const selectedSchoolSign = selectedSchoolSignId ? SchoolSignStore.SchoolSignSelectors.GetSchoolSignById(this.props, selectedSchoolSignId) : null;
        const selectedCycleCounterDevice = selectedCycleCounterDeviceId
            ? CycleCounterStore.CycleCounterDeviceSelectors.getCycleCounterDeviceById(this.props, selectedCycleCounterDeviceId)
            : null;

        const selectedPump = selectedPumpId ? PumpsApi.PumpSelectors.GetPumpDeviceByIdentifier(this.props, selectedPumpId) : null;

        const lightwireDevice = LightwireDevicesStore.LightwireDeviceSelectors.getLightwireDeviceBySiteId(this.props, selectedSite?.scatsSiteId);

        if (showFilterSidebar && adminSettings) {
            return (
                <SidebarFilter
                    closePanel={() => closeFilterPanel()}
                    alarmCodes={adminSettings.backendSettings.alarmCodes}
                    filters={filters}
                    setFilters={setFilters}
                    userRole={authRole}
                />
            );
        } else if (selectedSite && adminSettings) {
            const alerts = AlertStore.AlertsSelectors.GetAlertsBySiteId(this.props.alerts, selectedSite.id, selectedSite.alertSources) || [];

            return (
                <SidebarSiteInformation
                    {...this.props}
                    mute={muteAlertSource}
                    site={selectedSite}
                    lightwireDevice={lightwireDevice}
                    currentAlerts={alerts}
                    currentLogs={LogStore.LogSelectors.GetLogsBySiteId(this.props, selectedSite.id)}
                    isSiteOperationLoading={siteOperationLoading}
                    backendSettings={adminSettings.backendSettings}
                    selectedLogId={selectedLogId}
                    userRole={authRole}
                />
            );
        } else if (selectedLink) {
            const graphData = addInsightGraphData;
            return <SidebarLinkInformation {...this.props} link={selectedLink} graphData={graphData} refreshGraphData={requestAllGraphData} />;
        } else if (selectedSchoolSign) {
            return (
                <SidebarSchoolSignInformation
                    {...this.props}
                    schoolSign={selectedSchoolSign}
                    setSchoolSignStatus={setSchoolSignStatus}
                    isSchoolSignStatusUpdateOperationLoading={schoolSignStatusOperationLoading}
                />
            );
        } else if (selectedCycleCounterDeviceId) {
            return <SideBarCycleCounterInformation {...this.props} device={selectedCycleCounterDevice} closeCycleCounterPanel={this.props.closeCycleCounterPanel} />;
        } else if (selectedPumpId) {
            return <SidebarPumpInformation {...this.props} device={selectedPump} closePanel={this.props.closePumpPanel} />;
        }

        return null;
    }
}
export default connect(
    (state: ApplicationState) => {
        return {
            ...state.sites,
            ...state.addInsight,
            ...state.alerts,
            ...state.logs,
            ...state.adminSettings,
            ...state.authentication,
            ...state.schoolSigns,
            ...state.cycleCounterDevices,
            ...state.cycleCounterDetections,
            ...state.lightwireDevices,
            ...state.pumpDevices,
        };
    },
    {
        ...LogStore.actionCreators,
        ...SitesStore.actionCreators,
        ...AddInsightStore.actionCreators,
        ...SchoolSignStore.actionCreators,
        ...CycleCounterStore.actionCreators,
        ...LightwireDevicesStore.actionCreators,
        ...PumpsApi.actionCreators,
    }
)(SidebarInfoViewerContainer as any);
