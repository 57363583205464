import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState, AppThunkDispatch } from "store";
import Home from "../components/home";

import { actionCreators as adminSettingActionCreators } from "store/adminSettings";
import { actionCreators as userSettingsActionCreators } from "store/userSettings";
import { actionCreators as alarmActionCreators } from "store/alarm";
import { actionCreators as sitesActionCreators } from "store/sites";
import { actionCreators as addInsightActionCreators } from "store/addInsight";
import { actionCreators as logsActionCreators } from "store/logs";
import { actionCreators as commentsActionCreators } from "store/comments";
import { actionCreators as schoolSignActionCreators } from "store/schoolSigns";
import { actionCreators as cycleCounterDevicesActionCreators } from "store/cycleCounterDevices";
import { actionCreators as cycleCounterDetectionsActionCreators } from "store/cycleCounterDetections";
import { actionCreators as lightwireDevicesActionCreators } from "store/lightwireDevicesStore";
import { actionCreators as pumpDeviceActionCreators } from "store/Pumps/pumpsDataApi";
import { useEffect } from "react";

export const fetchAllData = (dispatch: AppThunkDispatch): void => {
    // TODO: These should probably be moved out to their respective containers, especially when we move them to functional components.
    // We should also check if the data already exists, and if so (unless a boolean value is set for the SignalR middleware) don't re-reload it.
    console.log("Fetching all data from home container...");
    dispatch(adminSettingActionCreators.getAdminSettings());
    dispatch(userSettingsActionCreators.getUserSettings());
    dispatch(sitesActionCreators.requestAllSites());
    dispatch(addInsightActionCreators.requestAllLinks());
    dispatch(commentsActionCreators.requestAllComments());
    dispatch(logsActionCreators.requestAllLogs());
    dispatch(schoolSignActionCreators.requestAllSchoolSigns());
    dispatch(cycleCounterDevicesActionCreators.requestAllCycleCounterDevices());
    dispatch(cycleCounterDetectionsActionCreators.requestAllCycleCounterDetections());
    dispatch(lightwireDevicesActionCreators.requestAllLightwireDevices());
    dispatch(pumpDeviceActionCreators.requestPumpDevices());

};

const HomeContainer: React.FC = (props) => {
    const dispatch = useDispatch();
    const authApiReady = useSelector<ApplicationState, boolean>((state) => state.authentication.authApiReady);
    const adminSettingsReady = useSelector<ApplicationState, boolean>((state) => state.adminSettings.hasLoaded);

    useEffect(() => {
        if (authApiReady) {
            fetchAllData(dispatch);
        }
    }, [authApiReady, dispatch]);

    useEffect(() => {
        if (adminSettingsReady) {
            dispatch(alarmActionCreators.requestAllAlerts());
        }
    }, [adminSettingsReady, dispatch]);

    return <Home />;
};

export default HomeContainer;
