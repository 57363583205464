import * as React from "react";
import { ColorResult } from "react-color";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import ColorPicker from "./colorPicker";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface ColorSettingControlProps {
    settingTitle: string;
    settingValue: string;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    handleSettingUpdate: any;
    sampleIconHtml: (color: string) => JSX.Element;
    explanation?: string;
}

export class ColorSettingControl extends React.Component<ColorSettingControlProps> {
    public render() {
        const {
            explanation,
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            sampleIconHtml,
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <ColorPicker
                    sampleIcon={sampleIconHtml}
                    setColor={(hex: string) => handleSettingUpdate(settingPropName, hex)}
                    hex={settingValue}
                    onChangeComplete={(color: ColorResult) =>
                        handleSettingUpdate(settingPropName, color.hex)
                    }
                    color={settingValue}
                    colors={["#AAAAAA", "#8AC37E", "#E5AD07", "#3D94DB", "#8E3DDA", "#FD0154"]}
                />
            </SettingControlWrapper>
        );
    }
}

export default ColorSettingControl;
