import React from "react";
import "./sideBarSchoolSignItem.scss";
import { SchoolSign } from "store/schoolSigns";
import SidebarItem from "./sidebarItem";

export interface SiteSidebarSchoolSignProps {
    expand: boolean;
    schoolSign: SchoolSign;
    selectSchoolsSign(signId: string, zoom: boolean): void;
    icon: string | undefined;
    isSelected: boolean;
}

const SiteSideBarSchoolSignListItem: React.FC<SiteSidebarSchoolSignProps> = (props) => {
    return (
        <SidebarItem
            isSelected={props.isSelected}
            onClick={() => props.selectSchoolsSign(props.schoolSign.signId, true)}
            className="sidebarSchoolSign"
        >
            <div className="statusIcon">
                <img className="collapse-icon" src={props.icon} alt="Collapse All" />
            </div>
            { props.expand &&
                <div className="signName">
                    {props.schoolSign.schoolName} / {props.schoolSign.signName}
                </div>
            }
        </SidebarItem >
    );
};

export default SiteSideBarSchoolSignListItem;