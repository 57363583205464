// Required for IE11 Support. TODO: Deprecate IE 11.
import 'core-js';
import "./css/main.scss";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import configureStore from "./configureStore";
import Routes from "./routes";
import { default as SecureApp } from "./containers/secureApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from './serviceWorker';

// Create browser history to use in the Redux store
export const history = createBrowserHistory();
const store = configureStore(history);

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    //TODO : Change the AzureAD functions to map login and logout functions to redux
    ReactDOM.render(
        <Provider store={store}>
            <ToastContainer position="bottom-right" />
            <SecureApp>
                <ConnectedRouter history={history}>
                    <Routes />
                </ConnectedRouter>
            </SecureApp>
        </Provider>,
        document.getElementById("root")
    );
}


// MSAL loads an iframe of the current site to grab the url token. Don't render the application to prevent loops.
try {
    if (window.self === window.top) {
        renderApp();
    }
} catch (e) {
    // window.top may be blocked due to Same Origin Policies when in a iframe
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

