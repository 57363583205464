import Slider, { Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import * as React from "react";
import { baseIconSize } from "../../../constants/mapConstants";
import { BackendSettings, PortalSettings } from "store/adminSettings";
import "./controls.scss";
import SettingControlWrapper from "./settingControlWrapper";

interface SliderSettingControlProps {
    settingTitle: string;
    settingPropName: keyof PortalSettings | keyof BackendSettings;
    handleSettingUpdate: any;
    settingValue: number;
    min: number;
    max: number;
    step: number;
    updateOnChange?: boolean;
    unit?: string;
    explanation?: string;
    sampleIcon?: JSX.Element;
    indicator?: JSX.Element;
}

const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

export class SliderSettingControl extends React.Component<SliderSettingControlProps> {
    public render() {
        const {
            indicator,
            sampleIcon,
            settingPropName,
            settingTitle,
            settingValue,
            handleSettingUpdate,
            max,
            min,
            step,
            updateOnChange,
            unit,
            explanation,
        } = this.props;
        return (
            <SettingControlWrapper settingTitle={settingTitle} explanation={explanation}>
                <div className="slider">
                    <div className="slider-bar">
                        {indicator && <div className="indicator small-indicator">{indicator}</div>}
                        <Slider
                            step={step}
                            min={min}
                            max={max}
                            defaultValue={settingValue}
                            handle={handle}
                            onAfterChange={(val: number) =>
                                handleSettingUpdate(settingPropName, val)
                            }
                            onChange={(val: number) =>
                                updateOnChange && handleSettingUpdate(settingPropName, val)
                            }
                        />
                        {indicator && <div className="indicator big-indicator">{indicator}</div>}
                    </div>
                    {sampleIcon && (
                        <div className="slider-sample-container">
                            <div className="slider-value">
                                {`${settingValue}${unit && `${unit}`}`}
                            </div>
                            <div
                                className="sample-icon"
                                style={{
                                    width: `${baseIconSize * settingValue}px`,
                                    height: `${baseIconSize * settingValue}px`,
                                }}
                            >
                                {sampleIcon}
                            </div>
                        </div>
                    )}
                    {!sampleIcon && (
                        <div className="slider-value-container">
                            <div className="slider-unit">{unit && `${unit}`}</div>
                            <div className="slider-value">{`${settingValue}`}</div>
                        </div>
                    )}
                </div>
            </SettingControlWrapper>
        );
    }
}

export default SliderSettingControl;
