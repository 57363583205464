import * as React from "react";
import "./login.scss";

export interface LoginProps {
    showFooter: boolean;
    showLogin: boolean;
    login?: () => void;
}

// Clears all state, can be useful in some situations where MSAL hurts itself in its confusion.
export const reset = (postReset: () => void) => () => {
    try {
        localStorage.clear();
        sessionStorage.clear();

        const cookies = document.cookie;
        for (let i = 0; i < cookies.split(";").length; ++i) {
            const myCookie = cookies[i];
            const pos = myCookie.indexOf("=");
            const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    finally {
        postReset();
    }
}

export class Login extends React.Component<LoginProps> {
    public render() {
        return (
            <div className="login-viewport">
                <div onClick={this.props.login && reset(this.props.login)} className="navbar-brand">
                    Transport Data Analytics Platform
                </div>
                {this.props.showLogin &&
                    <button className="btn btn-purple" onClick={this.props.login}>
                        Login
                    </button>
                }
                {this.props.showFooter &&
                    <footer className="footer">
                        <div className="container">
                            {
                                this.props.login
                                ? <span className="text-muted">Please sign in, you may need to allow pop-ups. If you have issues logging in <button type="button" className="btn btn-link btn-link-custom text-muted shadow-none" onClick={reset(this.props.login)}>click here</button>.</span>
                                : <span className="text-muted">Please wait, if you have issues logging in <button type="button" className="btn btn-link btn-link-custom text-muted shadow-none" onClick={reset(window.location.reload)}>click here</button> to try again.</span>
                            }
                        </div>
                    </footer>}
            </div>)
    }
}

export default Login;