import "whatwg-fetch";
import { LatLng } from "leaflet";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, SendToast, ToastErrorTypes } from "../utils/toast";
import { HttpError } from "@microsoft/signalr";
import { TDAPIconOptions } from "../components/map/icons";
import { FlyToAction, SetZoomAction, actionCreators as MapActionCreators } from "./map";
import _ from "lodash";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface SchoolSignState {
    schoolSigns: {
        [key: string]: SchoolSign;
    };
    initialLoadStarted: boolean;
    selectedSchoolSignId?: string;
    schoolSignStatusOperationLoading: boolean;
}

export interface SchoolSign {
    // DB id
    id: number;
    signId: string;
    schoolName: string;
    signName: string;
    status: SchoolSignStatus;
    displayValue: string;
    prevDisplayValue: string;
    longitude: number;
    latitude: number;
    lastSeen: Date;
    lastEvent: Date;
    initialLoadStarted: boolean;
}

export interface SchoolSignMarker {
    id: string;
    schoolName: string;
    signName: string;
    status: string;
    lastSeen: Date;
    position: LatLng;
    icon?: TDAPIconOptions;
    clickCallback: () => void;
}

export interface SchoolSignActionRequestResponse {
    schoolName: string;
    activationDurationMinutes: number;
    actionId: number;
}

export enum SchoolSignStatus {
    Unknown = "unknown",
    On = "on",
    Off = "off",
    Static = "static",
    Idle = "idle",
    NotTalking = "not_talking",
    Forty = "40",
    Fifty = "50"
}

export enum SchoolSignAction {
    Unknown = 0,
    TurnOn = 1,
    TurnOff = 2,
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestSchoolSignsAction
    | ReceiveSchoolSignsAction
    | ErrorReceivingSchoolSignsAction
    | OpenSchoolSignPanelAction
    | CloseSchoolSignPanelAction
    | UpdateSchoolSignStatusAction
    | SchoolSignStatusOperationLoadingAction
    | ErrorUpdatingSchoolSignStatusAction;

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class SchoolSignSelectors {
    static GetSchoolSignById(state: SchoolSignState, schoolSignId: string): SchoolSign {
        return state.schoolSigns[schoolSignId];
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSchoolSignsAction extends Action {
    type: "REQUEST_SCHOOL_SIGNS";
}
export interface ReceiveSchoolSignsAction extends Action {
    type: "RECEIVE_SCHOOL_SIGNS";
    schoolSigns: SchoolSign[];
}
interface ErrorReceivingSchoolSignsAction extends Action {
    type: "ERROR_RECEIVING_SCHOOL_SIGNS";
}
interface OpenSchoolSignPanelAction extends Action {
    type: "OPEN_SCHOOL_SIGN_PANEL";
    // The school sign id that is selected
    schoolSignId: string;
}
export interface CloseSchoolSignPanelAction extends Action {
    type: "CLOSE_SCHOOL_SIGN_PANEL";
}
interface UpdateSchoolSignStatusAction extends Action {
    type: "UPDATE_SCHOOL_SIGN_STATUS";
    schoolName: string;
}
interface ErrorUpdatingSchoolSignStatusAction extends Action {
    type: "ERROR_UPDATING_SCHOOL_SIGN_STATUS";
}
interface SchoolSignStatusOperationLoadingAction extends Action {
    type: "SCHOOL_SIGN_STATUS_OPERATION_LOADING";
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAllSchoolSigns: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            schoolSigns: { initialLoadStarted },
            authentication: { accessToken },
        } = getState();

        if (initialLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/SchoolSign`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<SchoolSign[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVE_SCHOOL_SIGNS", schoolSigns: data });

                    ClearBackendErrorToast(ToastErrorTypes.SCHOOL_SIGNS);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_SCHOOL_SIGNS" });

                    console.error(error);

                    BackendErrorToast(ToastErrorTypes.SCHOOL_SIGNS, "Error retrieving school signs");
                });

            dispatch({ type: "REQUEST_SCHOOL_SIGNS" });
        });
    },
    receiveUpdatedSchoolSigns:
        (incomingSchoolSigns: SchoolSign[]): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch({ type: "RECEIVE_SCHOOL_SIGNS", schoolSigns: incomingSchoolSigns });
                });
            },
    selectSchoolSign:
        (selectedSchoolSignId: string, zoom: boolean): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    schoolSigns: { schoolSigns }
                } = getState();

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(MapActionCreators.closeAllSidePanelsAction());
                    dispatch(<OpenSchoolSignPanelAction>{ type: "OPEN_SCHOOL_SIGN_PANEL", schoolSignId: selectedSchoolSignId });

                    if (zoom) {
                        const selectedSite = schoolSigns[selectedSchoolSignId as string];
                        if (selectedSite) {
                            dispatch(<FlyToAction>{ type: "FLY_TO", latLng: new LatLng(selectedSite.latitude, selectedSite.longitude) });
                            dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: 16 });
                        }
                    }
                });
            },
    closeSchoolSignPanel: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<CloseSchoolSignPanelAction>{ type: "CLOSE_SCHOOL_SIGN_PANEL" });
        });
    },
    setSchoolSignStatus:
        (schoolName: string, activationDurationMinutes: number): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    schoolSigns: { schoolSigns, initialLoadStarted, selectedSchoolSignId },
                    authentication: { accessToken },
                } = getState();

                if (initialLoadStarted || !selectedSchoolSignId || !accessToken) {
                    return;
                }

                const school = schoolSigns[selectedSchoolSignId];

                if (school === undefined) {
                    return;
                }

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    fetch(`/api/SchoolSign/PerformAction`, {
                        method: "PUT",
                        body: JSON.stringify({
                            schoolSignId: school.signId,
                            schoolName: schoolName,
                            activationDurationMinutes: activationDurationMinutes,
                            actionId: activationDurationMinutes === 0 ? SchoolSignAction.TurnOff : SchoolSignAction.TurnOn,
                        }),
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    })
                        .then((resp) => {
                            if (resp.ok) {
                                return resp.json() as Promise<SchoolSignActionRequestResponse>;
                            } else {
                                throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                            }
                        })
                        .then((data) => {
                            dispatch(<UpdateSchoolSignStatusAction>{ type: "UPDATE_SCHOOL_SIGN_STATUS", schoolName: data.schoolName });

                            ClearBackendErrorToast(ToastErrorTypes.SCHOOL_SIGNS);

                            SendToast("Sent a school sign status update request", `For all school signs assocated with ${schoolName}...`, { type: "success" });
                        })
                        .catch((error) => {
                            dispatch(<ErrorUpdatingSchoolSignStatusAction>{ type: "ERROR_UPDATING_SCHOOL_SIGN_STATUS" });

                            console.error(error);

                            BackendErrorToast(ToastErrorTypes.SCHOOL_SIGNS, "Error updating school sign status");
                        });

                    dispatch(<SchoolSignStatusOperationLoadingAction>{ type: "SCHOOL_SIGN_STATUS_OPERATION_LOADING" });
                });
            },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SchoolSignState = {
    schoolSigns: {},
    initialLoadStarted: false,
    selectedSchoolSignId: undefined,
    schoolSignStatusOperationLoading: false,
};

export const reducer: Reducer<SchoolSignState, AnyAction> = (state: SchoolSignState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_SCHOOL_SIGNS":
            return <SchoolSignState>{
                ...state,
                initialLoadStarted: true,
            };
        case "RECEIVE_SCHOOL_SIGNS": {
            const getDisplayValue = (currentStatusMessage: string, incomingStatusMessage: string) => {
                let resultMessage = "40";

                // no incoming message although called
                if (!incomingStatusMessage) {
                    resultMessage = "off";
                    return resultMessage;
                }

                // they are the same or no current value so return incoming
                if (!currentStatusMessage || currentStatusMessage === incomingStatusMessage) {
                    resultMessage = incomingStatusMessage;
                    return resultMessage;
                }

                // they are different, keep the current if it displaying number as first char (coerse)
                const incomingfirstChar = +currentStatusMessage.substring(0, 1);

                // use the number if there is one
                if (_.isNumber(incomingfirstChar)) {
                    resultMessage = incomingStatusMessage;
                    return resultMessage;
                } else {
                    // dont change the message
                    resultMessage = currentStatusMessage;
                    return resultMessage;
                }
            };

            const incomingSchoolSigns = action.schoolSigns.reduce<Record<string, SchoolSign>>(function (schoolSignMap, schoolSign) {
                // grab the current data
                const currentDisplay = schoolSignMap && schoolSignMap[schoolSign.signId] && schoolSignMap[schoolSign.signId].status;
                const incomingDisplay = schoolSign && schoolSign.status;
                const prevDisplayValue = currentDisplay;
                const displayValue = getDisplayValue(currentDisplay, incomingDisplay);

                const current = schoolSignMap[schoolSign.signId];

                if (current == null) {
                    schoolSignMap[schoolSign.signId] = schoolSign;
                } else if (current && schoolSign.lastSeen >= current.lastSeen) {
                    schoolSignMap[schoolSign.signId] = schoolSign;
                }

                schoolSignMap[schoolSign.signId].displayValue = displayValue;
                schoolSignMap[schoolSign.signId].prevDisplayValue = prevDisplayValue;
                return schoolSignMap;
            }, {});

            return <SchoolSignState>{
                ...state,
                initialLoadStarted: false,
                schoolSigns: incomingSchoolSigns,
            };
        }
        case "ERROR_RECEIVING_SCHOOL_SIGNS":
            return <SchoolSignState>{
                ...state,
                initialLoadStarted: false,
            };
        case "OPEN_SCHOOL_SIGN_PANEL":
            return <SchoolSignState>{
                ...state,
                selectedSchoolSignId: action.schoolSignId,
            };

        case "CLOSE_SCHOOL_SIGN_PANEL":
            return <SchoolSignState>{
                ...state,
                selectedSchoolSignId: undefined,
            };
        case "UPDATE_SCHOOL_SIGN_STATUS":
            return <SchoolSignState>{
                ...state,
                schoolSignStatusOperationLoading: false,
            };
        case "ERROR_UPDATING_SCHOOL_SIGN_STATUS":
            return <SchoolSignState>{
                ...state,
                schoolSignStatusOperationLoading: false,
            };
        case "SCHOOL_SIGN_STATUS_OPERATION_LOADING":
            return <SchoolSignState>{
                ...state,
                schoolSignStatusOperationLoading: true,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
