import { HttpError } from "@microsoft/signalr";
import { LatLng } from "leaflet";
import { AnyAction, Reducer } from "redux";
import { ApplicationState, AppThunkAction, AppThunkDispatch } from "store";
import { AuthenticationRole, enforceRolePermissions } from "store/authentication";
import { actionCreators as MapActionCreators, FlyToAction, SetZoomAction } from "store/map";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "utils/toast";
import { ClosePumpPanel, KnownAction, OpenPumpPanel, PumpDevice, PumpDevicesState } from "./pumpsDatatypes";

export const actionCreators = {
    requestPumpDevices: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            authentication: { accessToken },
        } = getState();

        if (!accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/pumps/devices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<PumpDevice[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVED_PUMPS", pumpDevices: data });
                    ClearBackendErrorToast(ToastErrorTypes.PUMP_DEVICES);
                })
                .catch((error) => {
                    dispatch({ type: "GET_PUMPS_ERROR" });

                    console.error(error);

                    BackendErrorToast(ToastErrorTypes.PUMP_DEVICES, "Error retrieving Pumps");
                });

            dispatch({ type: "GET_PUMPS" });
        });
    },
    selectPumpDevice:
        (pumpDeviceId: string, zoom: boolean): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    pumpDevices: { pumpDevices },
                } = getState();
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(MapActionCreators.closeAllSidePanelsAction());
                    dispatch(<OpenPumpPanel>{ type: "OPEN_PUMP_PANEL", pumpId: pumpDeviceId });

                    if (zoom) {
                        const selectedDevice = pumpDevices[pumpDeviceId];
                        if (selectedDevice) {
                            dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: 15 });
                            dispatch(<FlyToAction>{ type: "FLY_TO", latLng: new LatLng(+selectedDevice.lat, +selectedDevice.lng) });
                        }
                    }
                });
            },
    closePumpPanel:
        (): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(<ClosePumpPanel>{ type: "CLOSE_PUMP_PANEL" });
                });
            },
};


// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class PumpSelectors {
    //Gets a single site by the site id
    static GetPumpDeviceByIdentifier(state: PumpDevicesState, id: string | undefined): PumpDevice | null {
        if (id === undefined) {
            return null;
        }
        return (
            state.pumpDevices[id]
        );
    }
}


// intial state;
const unloadedState: PumpDevicesState = {
    pumpDevices: {},
    pumpsLoading: false,
    selectedPumpId: undefined
};

//Reducers

export const reducer: Reducer<PumpDevicesState, AnyAction> = (state: PumpDevicesState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "RECEIVED_PUMPS": {
            const incomingPumpDevices = action.pumpDevices.reduce<Record<string, PumpDevice>>((map, current: PumpDevice) => {
                map[current.pumpId.toString()] = current;
                return map;
            }, {});

            return <PumpDevicesState>{
                ...state,
                pumpsLoading: false,
                pumpDevices: incomingPumpDevices,
            };
        }

        case "GET_PUMPS":
            return <PumpDevicesState>{
                ...state,
                pumpsLoading: true,
            };

        case "GET_PUMPS_ERROR":
            return <PumpDevicesState>{
                ...state,
                pumpsLoading: false,
            };

        case "OPEN_PUMP_PANEL":
            return <PumpDevicesState>{
                ...state,
                selectedPumpId: action.pumpId
            };

        case "CLOSE_PUMP_PANEL":
            return <PumpDevicesState>{
                ...state,
                selectedPumpId: undefined
            };

        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
