import * as React from 'react';
import { AlarmCode } from '../../../store/alarm';
import './filterGroup.scss';
import { ToggleType } from '../infoPanels/sidebarFilters';

interface AlarmFilterGroupProps {
    title: string;
    alarmCodes: AlarmCode[];
    selectedAlarmCodes: Record<string, AlarmCode>;
    handleToggleAlertInFilterList: (alarmCodes: AlarmCode[], toggleType: ToggleType) => void;
}

const AlarmFilterGroup = (props: AlarmFilterGroupProps) => {

    const allSelected = () => {
        const v = (props.alarmCodes.filter(x => props.selectedAlarmCodes[x.code]).length);
        return v === props.alarmCodes.length;
    };

    const someSelected = () => {
        const v = (props.alarmCodes.filter(x => props.selectedAlarmCodes[x.code]).length);
        return v > 0;
    };

    return (
        <div className="filter-group">
            <div className="filter-group-title-container">
                <h3 className="filter-group-title">{props.title}</h3>
                <button className={"btn filter-all" + (allSelected() ? " all" : (someSelected() ? " some" : ""))} onClick={() => props.handleToggleAlertInFilterList(props.alarmCodes, allSelected() ? ToggleType.ALL_OFF : ToggleType.ALL_ON)}>ALL</button>
            </div>
            {props.alarmCodes.map(x =>
                <button
                    key={`${x.alarmSourceId}_${x.code}`}
                    className={"filter-button btn" + (props.selectedAlarmCodes[x.code] ? " active" : "")}
                    title={x.description}
                    onClick={() => props.handleToggleAlertInFilterList([x], ToggleType.TOGGLE)}>{x.code}</button>
            )}
        </div>
    );
};

export default AlarmFilterGroup;