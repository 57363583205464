import "whatwg-fetch";
import L, { LatLng } from "leaflet";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import { HttpError } from "@microsoft/signalr";
import { maxMobileWidth } from "../constants/mobileConstants";
import { CloseSidePanelsAction, FlyToAction, SetZoomAction, actionCreators as MapActionCreators } from "./map";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LightwireDevicesState {
    lightwireDevices: {
        [key: string]: LightwireDevice;
    };
    initialLoadStarted: boolean;
    selectedLightwireDeviceId?: string;
    sidebarExpanded: boolean;
    lightwireDeviceStatusOperationLoading: boolean;
}
export interface LightwireDevice {
    customerId: number;
    lastUpdated: string;
    lastChanged: string;
    latitude: number;
    longitude: number;
    status: string;
    siteId: string;
}

export enum DeviceState {
    Unknown = 0,
    ON = 1,
    OFF = 2,
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestLightwireDevicesAction
    | ReceiveLightwireDevicesAction
    | ErrorReceivingLightwireDevicesAction
    | OpenLightwireDevicePanelAction
    | CloseLightwireDevicePanelAction
    | UpdateLightwireDeviceStatusAction
    | LightwireDeviceStatusOperationLoadingAction
    | ErrorUpdatingLightwireDeviceStatusAction
    | UpdateLightwireDeviceFiltersAction
    | CloseSidePanelsAction;

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class LightwireDeviceSelectors {
    static getLightwireDeviceBySiteId(lightwireDevices: LightwireDevicesState, lightwireDeviceId: number | undefined): LightwireDevice | null {
        if (!lightwireDevices?.lightwireDevices || !lightwireDeviceId) return null;

        const lookup = lightwireDeviceId.toString();
        const result = lightwireDevices.lightwireDevices[+lookup];
        if (!result) return null;

        result.lastUpdated = new Date().toUTCString();
        return result;
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface UpdateLightwireDeviceFiltersAction extends Action {
    type: "UPDATE_LIGHTWIRE_DEVICE_FILTER";
}

interface RequestLightwireDevicesAction extends Action {
    type: "REQUEST_LIGHTWIRE_DEVICE";
}

export interface ReceiveLightwireDevicesAction extends Action {
    type: "RECEIVE_LIGHTWIRE_DEVICE";
    lightwireDevices: LightwireDevice[];
}

interface ErrorReceivingLightwireDevicesAction extends Action {
    type: "ERROR_RECEIVING_LIGHTWIRE_DEVICE";
}

interface OpenLightwireDevicePanelAction extends Action {
    type: "OPEN_LIGHTWIRE_DEVICE_PANEL";
    // The Device sign id that is selected
    lightwireDeviceId: string;
}

export interface CloseLightwireDevicePanelAction extends Action {
    type: "CLOSE_LIGHTWIRE_DEVICE_PANEL";
}

interface UpdateLightwireDeviceStatusAction extends Action {
    type: "UPDATE_LIGHTWIRE_DEVICE_STATUS";
    DeviceName: string;
}

interface ErrorUpdatingLightwireDeviceStatusAction extends Action {
    type: "ERROR_UPDATING_LIGHTWIRE_DEVICE_STATUS";
}

interface LightwireDeviceStatusOperationLoadingAction extends Action {
    type: "LIGHTWIRE_DEVICE_STATUS_OPERATION_LOADING";
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    selectLightwireDeviceOnList:
        (selectedLightwireDeviceId: string): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    lightwireDevices: { lightwireDevices },
                } = getState();

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(MapActionCreators.closeAllSidePanelsAction());
                    dispatch(<OpenLightwireDevicePanelAction>{ type: "OPEN_LIGHTWIRE_DEVICE_PANEL", lightwireDeviceId: selectedLightwireDeviceId });

                    const selectedDevice = lightwireDevices[selectedLightwireDeviceId as string];
                    if (selectedDevice) {
                        dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: 15 });
                        dispatch(<FlyToAction>{ type: "FLY_TO", latLng: new LatLng(+selectedDevice.latitude, +selectedDevice.longitude) });
                    }
                });
            },

    requestAllLightwireDevices: (): AppThunkAction => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            authentication: { accessToken },
        } = getState();

        if (!accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/lightwire/devices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<LightwireDevice[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVE_LIGHTWIRE_DEVICE", lightwireDevices: data });
                    ClearBackendErrorToast(ToastErrorTypes.LIGHTWIRE_DEVICES);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_LIGHTWIRE_DEVICE" });

                    console.error(error);

                    BackendErrorToast(ToastErrorTypes.LIGHTWIRE_DEVICES, "Error retrieving lightwire device signs");
                });

            dispatch({ type: "REQUEST_LIGHTWIRE_DEVICE" });
        });
    },
    closeLightwireDevicePanel: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({ type: "CLOSE_LIGHTWIRE_DEVICE_PANEL" });
        });
    },
    receiveUpdatedLightwireDevices:
        (incomingLightwireDevices: LightwireDevice[]): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch({ type: "RECEIVE_LIGHTWIRE_DEVICE", lightwireDevices: incomingLightwireDevices });
                });
            },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: LightwireDevicesState = {
    lightwireDevices: {},
    initialLoadStarted: false,
    sidebarExpanded: window.innerWidth >= maxMobileWidth,
    selectedLightwireDeviceId: undefined,
    lightwireDeviceStatusOperationLoading: false,
};

export const reducer: Reducer<LightwireDevicesState, AnyAction> = (state: LightwireDevicesState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_LIGHTWIRE_DEVICE":
            return <LightwireDevicesState>{
                ...state,
                initialLoadStarted: true,
            };

        case "RECEIVE_LIGHTWIRE_DEVICE": {
            const incomingLightwireDevices = action.lightwireDevices.reduce<Record<string, LightwireDevice>>((lightwireDeviceMap, current: LightwireDevice) => {
                lightwireDeviceMap[current.siteId?.toString()] = current;
                return lightwireDeviceMap;
            }, {});

            return <LightwireDevicesState>{
                ...state,
                initialLoadStarted: false,
                lightwireDevices: incomingLightwireDevices,
            };
        }

        case "ERROR_RECEIVING_LIGHTWIRE_DEVICE":
            return <LightwireDevicesState>{
                ...state,
                initialLoadStarted: false,
            };

        case "OPEN_LIGHTWIRE_DEVICE_PANEL":
            return <LightwireDevicesState>{
                ...state,
                selectedLightwireDeviceId: action.lightwireDeviceId,
            };

        case "CLOSE_LIGHTWIRE_DEVICE_PANEL":
            return <LightwireDevicesState>{
                ...state,
                selectedLightwireDeviceId: undefined,
            };

        case "UPDATE_LIGHTWIRE_DEVICE_STATUS":
            return <LightwireDevicesState>{
                ...state,
                LightwireDeviceStatusOperationLoading: false,
            };

        case "ERROR_UPDATING_LIGHTWIRE_DEVICE_STATUS":
            return <LightwireDevicesState>{
                ...state,
                LightwireDeviceStatusOperationLoading: false,
            };

        case "LIGHTWIRE_DEVICE_STATUS_OPERATION_LOADING":
            return <LightwireDevicesState>{
                ...state,
                LightwireDeviceStatusOperationLoading: true,
            };

        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
