import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import Sidebar from "../components/sidebar/sidebar";
import * as AlertStore from "store/alarm";
import * as SitesStore from "store/sites";
import * as LogStore from "store/logs";
import * as PumpsStore from "store/Pumps/pumpsDatatypes";
import * as PumpsApi from "store/Pumps/pumpsDataApi";
import * as UserSettingsStore from "store/userSettings";
import * as AdminSettingsStore from "store/adminSettings";
import * as CommentsStore from "store/comments";
import * as AuthStore from "store/authentication";
import * as SchoolSignStore from "store/schoolSigns";
import * as CycleCounterDeviceStore from "store/cycleCounterDevices";
import Moment from "moment";
import { iconSchoolSignIdle, iconSchoolSignOff, iconSchoolSignOn, iconSchoolSignStatic, iconSchoolSignNotTalking, iconBicycle, iconPump } from "./../components/images";
import { CycleCounterDevice } from "store/cycleCounterDevices";
import { useState } from "react";
import { PumpDevice } from "../store/Pumps/pumpsDatatypes";

export interface SearchState {
    siteFilter: string;
}

const SidebarContainer: React.FC = () => {
    const dispatch = useDispatch();
    const siteState = useSelector<ApplicationState, SitesStore.SiteState>((state) => state.sites);
    const authState = useSelector<ApplicationState, AuthStore.AuthState>((state) => state.authentication);
    const alertState = useSelector<ApplicationState, AlertStore.AlertState>((state) => state.alerts);
    const logState = useSelector<ApplicationState, LogStore.LogState>((state) => state.logs);
    const adminSettingsState = useSelector<ApplicationState, AdminSettingsStore.AdminSettingsState>((state) => state.adminSettings);
    const userSettingsState = useSelector<ApplicationState, UserSettingsStore.UserSettingsState>((state) => state.userSettings);
    const commentState = useSelector<ApplicationState, CommentsStore.CommentState>((state) => state.comments);
    const schoolSignState = useSelector<ApplicationState, SchoolSignStore.SchoolSignState>((state) => state.schoolSigns);
    const cycleCounterDevicesState = useSelector<ApplicationState, CycleCounterDeviceStore.CycleCounterDevicesState>((state) => state.cycleCounterDevices);
    const PumpDevicesState = useSelector<ApplicationState, PumpsStore.PumpDevicesState>((state) => state.pumpDevices);

    const [siteFilter, setSiteFilter] = useState("");

    const updateSiteSearch = (searchVal: string) => {
        setSiteFilter(searchVal);
    };

    const getSiteListFilter = (site: SitesStore.Site) => {
        let alertTitle = "";
        if (site.addInsightSiteId != null && site.scatsSiteId != null) {
            alertTitle = `Site ${site.scatsSiteId} - ${site.description}`;
        } else if (site.addInsightSiteId == null && site.scatsSiteId != null) {
            alertTitle = `Site ${site.scatsSiteId} - ${site.description}`;
        } else if (site.addInsightSiteId != null && site.scatsSiteId == null) {
            alertTitle = `AddInsight ${site.addInsightSiteId} - ${site.description}`;
        }

        return alertTitle.toLowerCase().indexOf(siteFilter.toLowerCase()) > -1;
    };

    const getSchoolSignListFilter = (schoolSign: SchoolSignStore.SchoolSign) => {
        return [schoolSign.schoolName, schoolSign.signName].join().toLowerCase().indexOf(siteFilter.toLowerCase()) > -1;
    };

    const filterSchoolSignsBasedOnToggles = (schoolSign: SchoolSignStore.SchoolSign) => {
        return userSettingsState.userSettings.schoolSignsEnabled;
    };

    const filterSitesBasedOnToggles = (site: SitesStore.Site) => {
        const {
            userSettings: { scatsEnabled, addInsightEnabled },
        } = userSettingsState;

        return (scatsEnabled && site.scatsSiteId != null) || (addInsightEnabled && site.addInsightSiteId != null);
    };

    const filterSitesBasedOnSiteFilters = (site: SitesStore.Site) => {
        const {
            filters: { alarms, mutedAlarms, openLogs, unreadLogs },
        } = siteState;

        const logs = LogStore.LogSelectors.GetLogsBySiteId(logState, site.id);
        const alerts = AlertStore.AlertsSelectors.GetAlertsBySiteId(alertState.alerts, site.id, site.alertSources);

        return (
            (Object.keys(alarms).length === 0 || alerts.filter((f) => f.closedTime === null && alarms[f.code]).length > 0) && // filter by alarm type
            (mutedAlarms === false || site.alertSources.filter((x) => x.muteUntil && Moment(x.muteUntil) >= Moment(new Date())).length > 0) && // filter by muted
            (openLogs === false || logs.filter((x) => x.dateClosed === null).length > 0) && // filter by open logs
            (unreadLogs === false || logs.filter((x) => x.unread === true).length > 0)
        );
    };

    const filterSchoolSignsBasedOnSiteFilters = (schoolSign: SchoolSignStore.SchoolSign) => {
        const {
            filters: { schoolSignNotTalking, schoolSignOn, schoolSignOff },
        } = siteState;

        if (schoolSignNotTalking || schoolSignOn || schoolSignOff) {
            const statuses: SchoolSignStore.SchoolSignStatus[] = [];

            if (schoolSignNotTalking === true) statuses.push(SchoolSignStore.SchoolSignStatus.NotTalking);
            if (schoolSignOn === true) statuses.push(SchoolSignStore.SchoolSignStatus.On);
            if (schoolSignOff === true) statuses.push(SchoolSignStore.SchoolSignStatus.Off);

            return statuses.includes(schoolSign.status);
        }

        return true;
    };

    const getSchoolSignStatusIcon = (status: SchoolSignStore.SchoolSignStatus) => {
        if (status === SchoolSignStore.SchoolSignStatus.Off) {
            return iconSchoolSignOff;
        } else if (status === SchoolSignStore.SchoolSignStatus.Idle) {
            return iconSchoolSignIdle;
        // Should display on for either on value or integer
        } else if (status === SchoolSignStore.SchoolSignStatus.On || status === SchoolSignStore.SchoolSignStatus.Forty || status === SchoolSignStore.SchoolSignStatus.Fifty) {
            return iconSchoolSignOn;
        } else if (status === SchoolSignStore.SchoolSignStatus.Static) {
            return iconSchoolSignStatic;
        } else if (status === SchoolSignStore.SchoolSignStatus.NotTalking) {
            return iconSchoolSignNotTalking;
        }

        // Cannot return null otherwise tslint complains
        return undefined;
    };

    // cycle counter functions
    const filterCycleCountersBasedOnToggles = () => {
        const {
            userSettings: { cycleCountersEnabled },
        } = userSettingsState;
        return cycleCountersEnabled;
    };

    const getCycleCountersListFilter = (device: CycleCounterDevice) => {
        return device.id.toLowerCase().indexOf(siteFilter.toLowerCase()) > -1;
    };

    const getCycleCounterDeviceStatusIcon = () => {
        return iconBicycle;
    };
    // end cycleCounter functions

    // pump functions
    const filterPumpBasedOnToggles = () => {
        const {
            userSettings: { pumpsEnabled },
        } = userSettingsState;
        return pumpsEnabled;
    };

    const getPumpListFilter = (device: PumpDevice) => {
        return device.pumpId.toLowerCase().indexOf(siteFilter.toLowerCase()) > -1;
    };

    const getPumpDeviceStatusIcon = () => {
        return iconPump;
    };
    // end pump functions

    const sitesList = Object.values(siteState.sites)
        .filter(filterSitesBasedOnSiteFilters)
        .filter(filterSitesBasedOnToggles)
        .filter(getSiteListFilter);

    const schoolSignData = Object.values(schoolSignState.schoolSigns)
        .filter(filterSchoolSignsBasedOnSiteFilters)
        .filter(filterSchoolSignsBasedOnToggles)
        .filter(getSchoolSignListFilter);

    const cycleCounterDevices = Object.values(cycleCounterDevicesState.cycleCounterDevices)
        .filter(getCycleCountersListFilter)
        .filter(filterCycleCountersBasedOnToggles);

    const pumps = Object.values(PumpDevicesState.pumpDevices)
        .filter(getPumpListFilter)
        .filter(filterPumpBasedOnToggles);

    const alarms = sitesList?.flatMap((e) => AlertStore.AlertsSelectors.GetAlertsBySiteId(alertState.alerts, e.id, e.alertSources));

    const logSiteMap = new Map(sitesList?.map((e) => [e.id, LogStore.LogSelectors.GetLogsBySiteId(logState, e.id)] as [number, LogStore.Log[]]));

    const comments = [...commentState.comments.values()];

    return (
        <>
            <Sidebar
                userRole={authState.authRole}
                updateSiteFilter={updateSiteSearch}
                siteFilterString={siteFilter}
                siteList={sitesList}
                schoolSignsData={schoolSignData}
                logSiteMap={logSiteMap}
                alarms={alarms}
                selectSite={(scatsSiteId, zoom) => dispatch(SitesStore.actionCreators.selectSite(scatsSiteId, zoom))}
                selectSchoolSign={(schoolsSignId, zoom) => dispatch(SchoolSignStore.actionCreators.selectSchoolSign(schoolsSignId, zoom))}
                toggleSidebarExpanded={() => dispatch(SitesStore.actionCreators.toggleSidebarExpanded())}
                expanded={siteState.sidebarExpanded}
                backendSettings={adminSettingsState.adminSettings && adminSettingsState.adminSettings.backendSettings}
                openFilterSidebar={() => dispatch(SitesStore.actionCreators.openFilterPanel())}
                closeFilterSidebar={() => dispatch(SitesStore.actionCreators.closeFilterPanel())}
                showFilterSidebar={siteState.showFilterSidebar}
                filters={siteState.filters}
                setFilters={(filters) => dispatch(SitesStore.actionCreators.setFilters(filters))}
                comments={comments}
                pumps={pumps}
                getSchoolSignStatusIcon={getSchoolSignStatusIcon}
                cycleCounterDevices={cycleCounterDevices}
                getCycleCounterDeviceStatusIcon={getCycleCounterDeviceStatusIcon}
                selectCycleCounter={(deviceId) => dispatch(CycleCounterDeviceStore.actionCreators.selectCycleCounter(deviceId, true))}
                getPumpStatusIcon={getPumpDeviceStatusIcon}
                selectPump={(pumpId) => dispatch(PumpsApi.actionCreators.selectPumpDevice(pumpId, true))}
                selectedSite={siteState.selectedSiteId ? SitesStore.SiteSelectors.GetSiteByIdentifier(siteState, siteState.selectedSiteId) : null}
                selectedPump={PumpDevicesState.selectedPumpId ? PumpsApi.PumpSelectors.GetPumpDeviceByIdentifier(PumpDevicesState, PumpDevicesState.selectedPumpId) : null}
                selectedSchoolSign={
                    schoolSignState.selectedSchoolSignId ? SchoolSignStore.SchoolSignSelectors.GetSchoolSignById(schoolSignState, schoolSignState.selectedSchoolSignId) : null
                }
                selectedCycleCounterDevice={
                    cycleCounterDevicesState.selectedCycleCounterDeviceId
                        ? CycleCounterDeviceStore.CycleCounterDeviceSelectors.getCycleCounterDeviceById(cycleCounterDevicesState, cycleCounterDevicesState.selectedCycleCounterDeviceId)
                        : null
                }
            />

        </>
    );
};

export default SidebarContainer;
