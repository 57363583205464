export const AddInsightLinksUpdated: string = "AddInsightLinksUpdated";
export const AdminSettingsUpdated: string = "AdminSettingsUpdated";
export const AlarmsUpdated: string = "AlarmsUpdated";
export const SitesUpdated: string = "SitesUpdated";
export const LogsUpdated: string = "LogsUpdated";
export const CommentsUpdated: string = "CommentsUpdated";
export const MuteStateUpdated: string = "MuteStateUpdated";
export const BusesUpdated: string = "BusesUpdated";
export const SchoolSignsUpdated: string = "SchoolSignsUpdated";
export const CycleCounterDevicesUpdated = "CycleCounterDevicesUpdated";
export const CycleCounterDetectionsUpdated = "CycleCounterDetectionsUpdated";
export const LightwireDevicesUpdated = "LightwireDevicesUpdated";
export const PumpsUpdated = "PumpsUpdated";
