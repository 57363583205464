import * as React from "react";
import { Helmet } from 'react-helmet';

//based on https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app----3l0j
interface TitleProps {
    title: string;
}

interface Component {
    Component: React.ComponentType;
    title: string;
}

const TitleComponent: React.FC<TitleProps> = (props) => {
    const defaultTitle = 'TDAP - Home Page';
    return (
        <Helmet>
            <title>{props.title ? props.title : defaultTitle}</title>
        </Helmet>
    );
};

export const withTitle: (Component: Component) => React.ComponentType = ({ Component, title }) => {
    return class Title extends React.Component {
        render() {
            return (
                <>
                    <TitleComponent title={title} />
                    <Component {...this.props} />
                </>
            );
        }
    };
};