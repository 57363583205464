import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, AppThunkDispatch, ApplicationState } from '.';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import * as SiteStore from './sites';
import moment from 'moment';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { HttpError } from '@microsoft/signalr';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// These Alerts are to be used both in combination with the log viewer, and within sites.

export interface AlertState {
    initialAlertsLoadStarted: boolean;
    alerts: Alert[];
}

export enum AlertSource {
    Unknown = 0,
    ScatsSiteAlarm = 1,
    ScatsSiteDetectorAlarm = 2,
    ScatsSiteDetectorThresholdAlarm = 3,
    AddInsightSiteAlarm = 4,
    SharePoint = 5,
    AddInsightLinkAlarm = 6,
    SumpMonitorAlarm = 7,
    PowerSupply = 8
}

export interface AlarmCode {
    alarmSourceId: AlertSource;
    alertSeverity: number;
    code: string;
    description: string;
    lastUpdated: Date;
}

export interface Alert {
    initialLoadStarted: boolean;
    alarmId: number;
    siteId: number;
    code: string;
    description: string;
    triggerTime: Date;
    lastUpdated: Date;
    closedTime?: Date;
    alarmSourceId: AlertSource;
    detectorNumber?: number;
    alertSeverity: number;
    additionalData: string;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class AlertsSelectors {

    static GetAlertsBySiteId(alerts: Alert[], siteId: number, alertSources?: SiteStore.SiteAlertSourceState[]): Alert[] {
        return alerts.filter(alert =>
            (!alertSources || !alertSources.find(src => src.alarmSourceId === alert.alarmSourceId &&
                moment(src.muteUntil).isAfter(new Date()))) && alert.siteId === siteId);
    }

    static SortBySourceSeverityAndTime(firstAlert: Alert, secondAlert: Alert): number {
        if (
            firstAlert.alarmSourceId < secondAlert.alarmSourceId ||
            (firstAlert.alarmSourceId > secondAlert.alarmSourceId && firstAlert.alertSeverity > secondAlert.alertSeverity) ||
            (firstAlert.alarmSourceId > secondAlert.alarmSourceId && firstAlert.alertSeverity === secondAlert.alertSeverity && firstAlert.triggerTime < secondAlert.triggerTime)
        ) {
            return -1;
        }
        else if (
            (firstAlert.alarmSourceId > secondAlert.alarmSourceId && firstAlert.alertSeverity < secondAlert.alertSeverity) ||
            (firstAlert.alarmSourceId > secondAlert.alarmSourceId && firstAlert.alertSeverity === secondAlert.alertSeverity && firstAlert.triggerTime > secondAlert.triggerTime)
        ) {
            return 1;
        }
        else
            return 0;
    }

    static SortedBySourceSeverityAndTime(alerts: Alert[]): Alert[] {
        return alerts.sort((a, b) => AlertsSelectors.SortBySourceSeverityAndTime(a, b));
    }

    static SortBySeverityAndTime(firstAlert: Alert, secondAlert: Alert): number {
        if (
            firstAlert.alertSeverity < secondAlert.alertSeverity ||
            (firstAlert.alertSeverity === secondAlert.alertSeverity && firstAlert.triggerTime < secondAlert.triggerTime)
        ) {
            return -1;
        }
        else if (
            (firstAlert.alertSeverity > secondAlert.alertSeverity) ||
            (firstAlert.alertSeverity === secondAlert.alertSeverity && firstAlert.triggerTime > secondAlert.triggerTime)
        ) {
            return 1;
        }
        else {
            return 0;
        }
    }

    static ShortestRunningAlertBySeverity(alerts: Alert[]): Alert {
        return alerts.sort((a, b) => AlertsSelectors.SortBySeverityAndTime(a, b)).slice(-1)[0];
    }
    static ShortestRunningAlert(alerts: Alert[]): Alert {
        return alerts.sort((a, b) => new Date(a.triggerTime) < new Date(b.triggerTime) ? -1 : 1).slice(-1)[0];
    }
    static LargestAlertByAdditionalData(alerts: Alert[]): Alert {
        return alerts.sort((a, b) => (Number(b.additionalData) < Number(a.additionalData)) ? 1 : -1).slice(-1)[0];
    }

    static GetAdditionalItem(addtionalData: string, itemNumber: number): string {
        if (addtionalData) {
            const ary = addtionalData.split('|');
            if (ary.length && ary.length >= itemNumber) {
                return ary[itemNumber - 1];
            }
        }
        return "";
    }

    static GetCSRNumberAdditionalItemString(addtionalData: string) {
        const csrNumber = this.GetAdditionalItem(addtionalData, 2);
        return csrNumber ? `(${csrNumber})` : "";
    }

    static ExcludeInProgressSrmAlarms(alerts: Alert[]): Alert[] {
        return alerts.filter(x =>
            x.alarmSourceId !== AlertSource.SharePoint || (x.alarmSourceId === AlertSource.SharePoint && (AlertsSelectors.GetAdditionalItem(x.additionalData, 1) !== "InProgress")));
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ReceiveAlertsAction extends Action {
    type: 'RECEIVE_ALERTS';
    alerts: Alert[];
}

interface RequestAlertsAction extends Action {
    type: 'REQUEST_ALERTS';
}

interface ErrorAlertsAction extends Action {
    type: 'ERROR_RECEIVING_ALERTS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReceiveAlertsAction | RequestAlertsAction | ErrorAlertsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAllAlerts: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not loading data, and we don't have any loaded yet.
        const { alerts: { initialAlertsLoadStarted }, authentication: { accessToken } } = getState();
        if (initialAlertsLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Alarm`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<Alert[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    const incomingAlerts = AlertsSelectors.ExcludeInProgressSrmAlarms(data);
                    dispatch({ type: 'RECEIVE_ALERTS', alerts: incomingAlerts });
                    ClearBackendErrorToast(ToastErrorTypes.ALERTS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_ALERTS' });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.ALERTS, "Error retrieving Alerts");
                });

            dispatch({ type: 'REQUEST_ALERTS' });
        });
    },
    createAlert: (alarm: Alert): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({
                type: 'RECEIVE_ALERTS', alerts: [alarm]
            });
        });
    },
    receiveUpdatedAlerts: (alarms: Alert[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({ type: 'RECEIVE_ALERTS', alerts: alarms });
        });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AlertState = {
    alerts: [], initialAlertsLoadStarted: false
};

export const reducer: Reducer<AlertState, AnyAction> = (state: AlertState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'RECEIVE_ALERTS': {
            return <AlertState>{
                ...state,
                initialAlertsLoadStarted: false,
                alerts: action.alerts
            };
        }
        case 'REQUEST_ALERTS':
            return <AlertState>{
                ...state,
                initialAlertsLoadStarted: true,
            };
        case 'ERROR_RECEIVING_ALERTS':
            return <AlertState>{
                ...state,
                initialAlertsLoadStarted: false,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }

    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};