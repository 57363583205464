import * as React from 'react';
import {
    iconPinAlertNormal, iconPinAlertHigh, iconPinAlertMedium, iconAlertHighCircle, iconAlertHigh,
    iconAlertMediumCircle, iconAlertMedium, iconAlertNormal, iconClose, iconWrench, iconAlertNormalModified,
    iconAlertNormalUnmodified, iconLocation, iconLayers, iconPaused, iconSchoolSignIdle, iconSchoolSignNotTalking,
    iconSchoolSignOff, iconSchoolSignOn, iconSchoolSignStatic
} from '../images';
import './iconGuide.scss';
import { Link } from 'react-router-dom';
import {
    addInsightLinkColor0, addInsightLinkColor1, addInsightLinkColor2, addInsightLinkColor3,
    addInsightLinkColor4, addInsightLinkColor5, addInsightLinkColor6, addInsightLinkColor7
} from '../../constants/addInsightLinkConstants';
import IconGuideTable from './iconGuideTable';
import IconGuideTableRow from './iconGuideTableRow';
import IconGuideTableRowNote from './iconGuideTableRowNote';
import { busIconHtml } from '../map/bus/busMarker';
import { deviceMarkerFunctions as cycleMarkerFunctions } from '../map/cycleCounters/cycleCounterDeviceMarker';
import { deviceMarkerFunctions as sumpMarkerFunctions  } from '../map/pump/pumpMarker';
import { PortalSettings } from '../../store/adminSettings';
import { CycleCounterDevice } from "../../store/cycleCounterDevices";

interface IconGuideProps {
    portalSettings: PortalSettings;
}


export class IconGuide extends React.Component<IconGuideProps> {

    private defaultCycleCounterDevice: CycleCounterDevice = {
        id: "",
        isLowBattery: false,
        lastHeartBeat: "",
        latitude: "",
        longitude: "",
        state: ""
    };

    public render() {
        const { portalSettings } = this.props;
        const busEarlyThreshold = portalSettings.earlyBusThreshold;
        const busLateThreshold = portalSettings.lateBusThreshold;

        const onTimeBusDescription = `The bus is running on time (less than ${busEarlyThreshold === 1 ? busEarlyThreshold + " minute" : busEarlyThreshold + " minutes"} early & less than ${busLateThreshold === 1 ? busLateThreshold + " minute" : busLateThreshold + " minutes"} late)`;
        const lateBusDescription = `The bus is running late (more than ${busLateThreshold === 1 ? busLateThreshold + " minute" : busLateThreshold + " minutes"} late)`;
        const earlyBusDescription = `The bus is running early (more than ${busEarlyThreshold === 1 ? busEarlyThreshold + " minute" : busEarlyThreshold + " minutes"} early)`;

        return <div className="key">
            <div className="setting-controls">
                <Link className="close-button" to={"/"}><img src={iconClose} width={28} height={28} alt="Close icon" /></Link>
            </div>
            <div className="container">
                <IconGuideTable
                    title="Map Controls"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={iconLayers} description="Map Control to change the map layer" altText="Map layers icon" />
                    <IconGuideTableRow icon={iconLocation} description="Map Control to reset the view" altText="Reset map view icon" />
                </IconGuideTable>
                <IconGuideTable
                    title="Map Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={iconPinAlertNormal} description="The location of a SCATS or AddInsight site" altText="Site normal alert pin icon" />
                    <IconGuideTableRow icon={iconPinAlertMedium} description="A site with a medium level alert" altText="Site medium alert pin icon" />
                    <IconGuideTableRow icon={iconPinAlertHigh} description="A site with a high level alert" altText="Site high alert pin icon" />
                    <IconGuideTableRow icon={<div className="icon-circle-container"><div className="icon-circle leaflet-marker-icon open-log"></div></div>} description="This site has at least one open log" altText="Open log indicator" />
                    <IconGuideTableRow icon={<div className="icon-circle-container"><div className="icon-circle leaflet-marker-icon unread"></div></div>} description="This site has an open log that has been updated since you last viewed it, updating a comment will also trigger this" altText="Unread log indicator" />
                    <IconGuideTableRow icon={<div className="icon-circle-container"><div className="icon-circle leaflet-marker-icon selected-site"></div></div>} description="This site has been selected" altText="Selected site indicator" />
                </IconGuideTable>

                <IconGuideTable
                    title="Side Bar Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={iconWrench} description="This site requires SCATS or Contractor work" altText="Site work required icon" />
                    <IconGuideTableRow icon={iconPaused} description="This site has at least one alert source that is muted" altText="Muted alert source" />
                    <IconGuideTableRow icon={iconAlertNormal} description="A site that has no alerts" altText="Site no alerts icon" />
                    <IconGuideTableRow icon={iconAlertNormalModified} description={<span>A site that has no alerts <b>AND</b> an open log that has been modified</span>} altText="Site no alerts, open, modified log icon" />
                    <IconGuideTableRow icon={iconAlertNormalUnmodified} description={<span>A site that has no alerts <b>AND</b> an open system generated log that has not been modified</span>} altText="Site no alerts, open log icon" />
                    <IconGuideTableRow icon={iconAlertMedium} description="A site that has a medium level alert" altText="Site medium alert icon" />
                    <IconGuideTableRow icon={iconAlertMediumCircle} description={<span>A site that has a medium level alert <b>AND</b> an open log that has been modified</span>} altText="Site medium alert circular icon" />
                    <IconGuideTableRow icon={iconPinAlertMedium} description={<span>A site that has a medium level alert <b>AND</b> an open system generated log that has not been modified</span>} altText="Site medium alert, open log icon" />
                    <IconGuideTableRow icon={iconAlertHigh} description="A site that has a high level alert" altText="Site high alert icon" />
                    <IconGuideTableRow icon={iconAlertHighCircle} description={<span>A site that has a high level alert <b>AND</b> an open log that has been modified</span>} altText="Site high alert circular icon" />
                    <IconGuideTableRow icon={iconPinAlertHigh} description={<span>A site that has a high level alert <b>AND</b> an open system generated log that has not been modified</span>} altText="Site high alert, open alert pin icon" />
                </IconGuideTable>

                <IconGuideTable
                    title="Bus Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={<div className="busIcon">{busIconHtml(portalSettings.onTimeBusColour)}</div>} description={<span>{onTimeBusDescription}</span>} altText="On time bus icon" />
                    <IconGuideTableRow icon={<div className="busIcon">{busIconHtml(portalSettings.lateBusColour)}</div>} description={<span>{lateBusDescription}</span>} altText="Late bus icon" />
                    <IconGuideTableRow icon={<div className="busIcon">{busIconHtml(portalSettings.earlyBusColour)}</div>} description={<span>{earlyBusDescription}</span>} altText="Early bus icon" />
                    <IconGuideTableRowNote text="NOTE: You can click on any of the bus icons to get realtime statistics for that bus." />
                </IconGuideTable>

                <IconGuideTable
                    title="AddInsight Links"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor0 }}></div>} description="AddInsight Link score of 0" altText="AddInsights score zero icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor1 }}></div>} description="AddInsight Link score of 1" altText="AddInsights score one icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor2 }}></div>} description="AddInsight Link score of 2" altText="AddInsights score two icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor3 }}></div>} description="AddInsight Link score of 3" altText="AddInsights score three icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor4 }}></div>} description="AddInsight Link score of 4" altText="AddInsights score four icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor5 }}></div>} description="AddInsight Link score of 5" altText="AddInsights score five icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor6 }}></div>} description="AddInsight Link score of 6" altText="AddInsights score six icon" />
                    <IconGuideTableRow icon={<div className="link" style={{ "backgroundColor": addInsightLinkColor7 }}></div>} description="AddInsight Link score of 7" altText="AddInsights score seven icon" />
                    <IconGuideTableRowNote text="NOTE: You can click on any of the AddInsight links to get realtime statistics for that link." />
                </IconGuideTable>

                <IconGuideTable
                    title="School Sign Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={iconSchoolSignOn} description="This school sign is currently on" altText="School sign on icon" />
                    <IconGuideTableRow icon={iconSchoolSignOff} description="This school sign is currently off" altText="School sign off icon" />
                    <IconGuideTableRow icon={iconSchoolSignIdle} description="This school sign is on and displaying vehicle speeds" altText="School sign idle icon" />
                    <IconGuideTableRow icon={iconSchoolSignStatic} description="This school sign is displaying a fixed speed value" altText="School sign static icon" />
                    <IconGuideTableRow icon={iconSchoolSignNotTalking} description="This school sign has not transmitted data recently, and should be investigated" altText="Site work required icon" />
                    <IconGuideTableRowNote text="NOTE: You can click on any of the school sign icons to get realtime statistics for that sign or turn a sign on/off." />
                </IconGuideTable>
                <IconGuideTable
                    title="Cycle Counter Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={<div className="center-cell">{cycleMarkerFunctions.iconHtml("map-counter-icon icon-guide Online")}</div>} description="The device is online." altText="Cycle Counter is online." />
                    <IconGuideTableRow icon={<div className="center-cell">{cycleMarkerFunctions.iconHtml("map-counter-icon icon-guide")}</div>} description="The device is offline or has a fault." altText="Cycle Counter is offline or has fault" />
                </IconGuideTable>
                <IconGuideTable
                    title="Sump Monitor Icons"
                    iconHeading="Icon"
                    descriptionHeading="Description"
                >
                    <IconGuideTableRow icon={<div className="center-cell">{sumpMarkerFunctions.iconHtml("pump-icon icon-guide ok")}</div>} description="The device is online." altText="Sump Monitor is online." />
                    <IconGuideTableRow icon={<div className="center-cell">{sumpMarkerFunctions.iconHtml("pump-icon icon-guide")}</div>} description="The device is offline or has a fault." altText="Sump Monitor has a fault" />
                </IconGuideTable>
            </div>
        </div>;
    }
}

export default IconGuide;