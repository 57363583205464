import React, { ChangeEvent } from "react";
import { BackendSettings } from "store/adminSettings";
import { Alert, AlertsSelectors } from "store/alarm";
import { Log } from "store/logs";
import { Site, Filters } from "store/sites";
import { iconExpand, iconFilter, iconSearch, iconSimplify } from "../images";
import "./sidebar.scss";
import SiteSideBarSchoolSignListItem from "./sidebarItems/sideBarSchoolSignItem";
import SideBarCycleCounterItem from "./sidebarItems/sideBarCycleCounterItem";
import SiteSideBarAlert from "./sidebarItems/sidebarSiteItem";
import Moment from "moment";
import { Comment } from "store/comments";
import _ from "lodash";
import { UserLogType } from "../../constants/logConstants";
import { AuthenticationRole, AuthenticationSelectors } from "store/authentication";
import { SchoolSign, SchoolSignStatus } from "store/schoolSigns";
import { CycleCounterDevice } from "store/cycleCounterDevices";
import { PumpDevice } from "../../store/Pumps/pumpsDatatypes";
import SidebarPumpItem from "./sidebarItems/sidebarPumpItem";

export interface SidebarProps {
    siteList: Site[];
    schoolSignsData: SchoolSign[];
    alarms: Alert[];
    logSiteMap: Map<number, Log[]>;
    updateSiteFilter(searchVal: string): void;
    siteFilterString: string;
    expanded: boolean;
    toggleSidebarExpanded: () => void;
    backendSettings: BackendSettings | undefined;
    openFilterSidebar: () => void;
    closeFilterSidebar: () => void;
    showFilterSidebar: boolean;
    filters: Filters;
    setFilters: (filters: Filters) => void;
    comments: Comment[];
    userRole: AuthenticationRole;
    pumps: PumpDevice[];
    getSchoolSignStatusIcon(status: SchoolSignStatus): string | undefined;
    getCycleCounterDeviceStatusIcon(device: CycleCounterDevice): string | undefined;
    getPumpStatusIcon(device: PumpDevice): string | undefined;
    cycleCounterDevices: CycleCounterDevice[];
    selectedSite: Site | null;
    selectedSchoolSign: SchoolSign | null;
    selectedCycleCounterDevice: CycleCounterDevice | null;
    selectedPump: PumpDevice | null;
    selectSite: (scatsSiteId: number, zoom: boolean) => void;
    selectSchoolSign: (signId: string, zoom: boolean) => void;
    selectCycleCounter: (id: string, zoom: boolean) => void;
    selectPump: (id: string, zoom: boolean) => void;
}

interface SidebarState {
    showOpenLogs: boolean;
}

export default class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);
        this.state = { showOpenLogs: false };
    }

    private toggleSidebarExpansion = () => {
        this.props.toggleSidebarExpanded();
    };

    private handleSiteFilter = (event: ChangeEvent<HTMLInputElement>) => {
        this.props.updateSiteFilter(event.target.value.toLowerCase());
    };

    private getCurrentSiteSeverity = (a: Site, b: Site) => {
        const { alarms, logSiteMap, filters, comments } = this.props;
        const alertsForSiteA = AlertsSelectors.GetAlertsBySiteId(alarms, a.id) || [];
        const alertsForSiteB = AlertsSelectors.GetAlertsBySiteId(alarms, b.id) || [];
        const logsForSiteA: Log[] = logSiteMap.get(a.id) || [];
        const logsForSiteB: Log[] = logSiteMap.get(b.id) || [];
        const hasUnmodifiedLogsA: boolean = logsForSiteA.some((log) => !log.firstModified && !log.dateClosed);
        const hasUnmodifiedLogsB: boolean = logsForSiteB.some((log) => !log.firstModified && !log.dateClosed);
        const hasModifiedLogsA: boolean = logsForSiteA.some((log) => log.firstModified && !log.dateClosed);
        const hasModifiedLogsB: boolean = logsForSiteB.some((log) => log.firstModified && !log.dateClosed);
        const shortestRunningAlertA = {
            ...AlertsSelectors.ShortestRunningAlertBySeverity(alertsForSiteA),
        };
        const shortestRunningAlertB = {
            ...AlertsSelectors.ShortestRunningAlertBySeverity(alertsForSiteB),
        };

        const severityA = a.currentStatus;
        const severityB = b.currentStatus;

        //custom sorting order for unread logs
        //The site with the most recent log/comment update at the top
        if (filters.unreadLogs) {
            const closedUserLogsA = logsForSiteA.filter((x) => x.logType === UserLogType && x.dateClosed === null);
            const closedUserLogsB = logsForSiteB.filter((x) => x.logType === UserLogType && x.dateClosed === null);

            const commentsA = comments.reduce((result: Comment[], m: Comment) => {
                if (closedUserLogsA.filter((x) => x.id === m.logId).length > 0) {
                    result.push(m);
                }
                return result;
            }, []);

            const commentsB = comments.reduce((result: Comment[], m: Comment) => {
                if (closedUserLogsB.filter((x) => x.id === m.logId).length > 0) {
                    result.push(m);
                }
                return result;
            }, []);

            const maxLastUpdatedLogA = Moment(_.maxBy(closedUserLogsA, "lastUpdated")?.lastUpdated);
            const maxLastUpdatedLogB = Moment(_.maxBy(closedUserLogsB, "lastUpdated")?.lastUpdated);
            const maxLastUpdatedCommentA = Moment.max(commentsA.map((e) => Moment(e.lastUpdated)));
            const maxLastUpdatedCommentB = Moment.max(commentsB.map((e) => Moment(e.lastUpdated)));

            return Moment.max(maxLastUpdatedLogA, maxLastUpdatedCommentA) > Moment.max(maxLastUpdatedLogB, maxLastUpdatedCommentB) ? -1 : 1;
        }

        if (severityA > severityB) return -1;
        if (severityA < severityB) return 1;
        if (alertsForSiteA.length === 0 && alertsForSiteB.length > 0) return 1;
        if (alertsForSiteA.length > 0 && alertsForSiteB.length === 0) return -1;
        if (alertsForSiteA.length > 0 && alertsForSiteB.length > 0) {
            if (shortestRunningAlertA.triggerTime > shortestRunningAlertB.triggerTime) {
                return -1;
            } else if (shortestRunningAlertA.triggerTime < shortestRunningAlertB.triggerTime) {
                return 1;
            } else {
                if (a.scatsSiteId && b.scatsSiteId) {
                    return a.scatsSiteId > b.scatsSiteId ? 1 : -1;
                } else if (a.addInsightSiteId && b.addInsightSiteId) {
                    return a.addInsightSiteId > b.addInsightSiteId ? 1 : -1;
                } else {
                    return a.id > b.id ? 1 : -1;
                }
            }
        }

        //sites that reach here have no alerts
        if (hasUnmodifiedLogsA && !hasUnmodifiedLogsB) {
            return -1;
        }
        if (hasUnmodifiedLogsB && !hasUnmodifiedLogsA) {
            return 1;
        }
        if (hasModifiedLogsA && !hasModifiedLogsB) {
            return -1;
        }
        if (hasModifiedLogsB && !hasModifiedLogsA) {
            return 1;
        }

        return a.id > b.id ? 1 : -1;
    };

    public render() {
        const { expanded, selectedSite, showFilterSidebar, closeFilterSidebar, openFilterSidebar, filters, setFilters, userRole } = this.props;
        const altText: string = expanded ? "Simplify icon" : "Expand icon";
        const navbarClass = `sitelist-navbar${expanded ? " expanded" : " collapsed"}${selectedSite !== null || showFilterSidebar ? " d-none d-md-flex" : ""}`;

        const listOrder: Site[] = this.props.siteList.slice().sort(this.getCurrentSiteSeverity);
        const schoolSigns: SchoolSign[] = this.props.schoolSignsData;
        const cycleCounterDevices: CycleCounterDevice[] = this.props.cycleCounterDevices;
        const pumps: PumpDevice[] = this.props.pumps;
        const hasPermission = AuthenticationSelectors.checkAuthenticationUsingRole(AuthenticationRole.Operator, userRole);


        return (
            <div className={navbarClass}>
                <div className="nav-header">
                    <div className="expand-sidebar">
                        <button className="nav-toggle btn p-0 m-0 shadow-none" onClick={this.toggleSidebarExpansion}>
                            <img height={24} src={expanded ? iconSimplify : iconExpand} alt={altText} />
                        </button>
                        {expanded && (
                            <div className="nav-header-title-container">
                                <span className="nav-header-title">Alerts</span>
                            </div>
                        )}
                    </div>
                    <div className={"filter-container " + (expanded ? "expanded" : "")}>
                        <button
                            className="icon-container btn p-0 m-0 shadow-none"
                            title="Open Advanced Filters"
                            onClick={() => (showFilterSidebar ? closeFilterSidebar() : openFilterSidebar())}
                        >
                            <img src={iconFilter} alt="filter icon" />
                        </button>
                        {expanded && (
                            <div className="d-flex filter-buttons">
                                <button
                                    type="button"
                                    className={"btn btn-sm ml-auto " + (filters.openLogs ? "active" : "")}
                                    title="Filter to sites that have at least one open log"
                                    onClick={() => setFilters({ ...filters, openLogs: !filters.openLogs })}
                                >
                                    Open
                                </button>
                                {hasPermission && (
                                    <button
                                        type="button"
                                        className={"btn btn-sm ml-auto " + (filters.unreadLogs ? "active" : "")}
                                        title="Filter to sites that have at least one unread log"
                                        onClick={() => setFilters({ ...filters, unreadLogs: !filters.unreadLogs })}
                                    >
                                        Unread
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className={"btn btn-sm ml-auto " + (filters.mutedAlarms ? "active" : "")}
                                    title="Filter to sites that have at least one muted alarm source"
                                    onClick={() => setFilters({ ...filters, mutedAlarms: !filters.mutedAlarms })}
                                >
                                    Muted
                                </button>
                                <button
                                    type="button"
                                    className={"btn btn-sm ml-auto " + (showFilterSidebar || Object.keys(filters.alarms).length > 0 ? "active" : "")}
                                    title="Open advanced filter menu"
                                    onClick={() => (showFilterSidebar ? closeFilterSidebar() : openFilterSidebar())}
                                >
                                    Advanced
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className="nav-search-bar">
                    {expanded ? (
                        <div className="nav-search-form">
                            <input type="text" className="form-control" value={this.props.siteFilterString} onChange={this.handleSiteFilter} placeholder="Search" />
                        </div>
                    ) : (
                        <button type="button" className="nav-search btn p-0 m-0 shadow-none" onClick={this.toggleSidebarExpansion}>
                            <img height={24} src={iconSearch} alt="Search icon" />
                        </button>
                    )}
                </div>
                <div className="nav-alert-container">
                    <div>
                        {
                            /* Sites list */
                            listOrder &&
                            listOrder.map(
                                (site) =>
                                    this.props.backendSettings !== undefined && (
                                        <SiteSideBarAlert
                                            key={site.id}
                                            site={site}
                                            alerts={AlertsSelectors.GetAlertsBySiteId(this.props.alarms, site.id) || []}
                                            logs={this.props.logSiteMap.get(site.id) || []}
                                            expand={expanded}
                                            selectSite={this.props.selectSite}
                                            isSelected={site === this.props.selectedSite}
                                            backendSettings={this.props.backendSettings}
                                        />
                                    )
                            )
                        }
                        {
                            /* School Signs Sidebar list */
                            schoolSigns &&
                            schoolSigns.map((schoolSign) => (
                                <SiteSideBarSchoolSignListItem
                                    key={schoolSign.id}
                                    schoolSign={schoolSign}
                                    expand={expanded}
                                    selectSchoolsSign={this.props.selectSchoolSign}
                                    icon={this.props.getSchoolSignStatusIcon(schoolSign.status)}
                                    isSelected={schoolSign === this.props.selectedSchoolSign}
                                />
                            ))
                        }
                        {
                            /* CycleCounter Device list */
                            cycleCounterDevices &&
                            cycleCounterDevices.map((device: CycleCounterDevice) => (
                                <SideBarCycleCounterItem
                                    key={device.id}
                                    device={device}
                                    expand={expanded}
                                    selectDevice={this.props.selectCycleCounter}
                                    icon={this.props.getCycleCounterDeviceStatusIcon(device)}
                                    isSelected={device.id === this.props.selectedCycleCounterDevice?.id}
                                />
                            ))
                        }
                        {
                            /* Pump Device list */
                            pumps &&
                            pumps.map((device: PumpDevice) => (
                                <SidebarPumpItem
                                    key={device.pumpId}
                                    device={device}
                                    expand={expanded}
                                    selectDevice={this.props.selectPump}
                                    icon={this.props.getPumpStatusIcon(device)}
                                    isSelected={device.pumpId === this.props.selectedPump?.pumpId}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}
