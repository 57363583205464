import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { ApplicationState, AppThunkDispatch, AppThunkAction } from '.';
import * as LogStore from './logs';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { Log } from './logs';
import { HttpError } from '@microsoft/signalr';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface CommentState {
    initialLoadStarted: boolean;
    comments: Map<number, Comment>;
    selectedCommentId?: number;
    commentOperationLoading: boolean;
}
export interface Comment {
    id: number;
    logId: number;
    loading: boolean;
    dateCreated: Date;
    commentType: string;
    lastUpdated: Date;
    content: string;
    userName: string;
    userOid?: string;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state
export class CommentSelectors {
    static GetCommentsByLogId(state: CommentState, logId: number): Comment[] {
        return [...state.comments.values()].filter(comment => comment.logId === logId);
    }
    static GetCommentsByIdentifiers(state: CommentState, ids?: number[]): Comment[] {
        ids = ids || [];
        return ids.map(e => this.GetCommentByIdentifier(state, e));
    }

    static GetCommentByIdentifier(state: CommentState, id: number): Comment {
        return state.comments.get(id) || defaultComment;
    }
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ReceiveCommentsAction extends Action {
    type: 'RECEIVE_COMMENTS';
    //The value of the component that will be rendered
    comments: Map<number, Comment>;
}
interface RequestCommentsAction extends Action {
    type: 'REQUEST_COMMENTS';
}
interface AddCommentAction extends Action {
    type: 'OPEN_COMMENT_EDITOR';
    //The value of the component that will be rendered
    selectedCommentId: number;
}
interface SaveCommentAction extends Action {
    type: 'SAVE_COMMENT';
    comments: Map<number, Comment>;

}
interface DeleteCommentAction extends Action {
    type: 'DELETE_COMMENT';
    comments: Map<number, Comment>;
}

interface CloseCommentAction extends Action {
    type: 'CLOSE_COMMENT_EDITOR';
}

interface ErrorCommentAction extends Action {
    type: 'ERROR_RECEIVING_COMMENTS';
}

interface CommentOperationLoadingAction extends Action {
    type: 'COMMENT_OPERATION_LOADING';
}
interface ErrorCommentOperationAction extends Action {
    type: 'ERROR_COMMENT_OPERATION';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = AddCommentAction | SaveCommentAction | DeleteCommentAction | CloseCommentAction | ReceiveCommentsAction
    | CommentOperationLoadingAction | ErrorCommentOperationAction | RequestCommentsAction | ErrorCommentAction;


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAllComments: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not loading data, and we don't have any loaded yet.
        const { comments: { initialLoadStarted, comments }, authentication: { accessToken } } = getState();
        if (initialLoadStarted) {
            return;
        }
        if (!accessToken) {
            console.error('No access token. Unable to execute actionCreators.requestAllComments()');
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Comment`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<Comment[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    const incomingComments = new Map(data.map(comment => [comment.id, comment] as [number, Comment]));
                    comments.forEach(existingComment => {
                        const incomingComment = incomingComments.get(existingComment.id);
                        if (incomingComment === undefined || existingComment.lastUpdated >= incomingComment.lastUpdated) {
                            incomingComments.set(existingComment.id, existingComment);
                        }
                    });
                    dispatch({ type: 'RECEIVE_COMMENTS', comments: incomingComments });
                    ClearBackendErrorToast(ToastErrorTypes.COMMENTS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_COMMENTS' });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.COMMENTS, "Error retrieving comments");
                });
            dispatch({ type: 'REQUEST_COMMENTS' });
        });
    },
    updateComment: (comment: Comment): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { comments: { comments } } = getState();
            dispatch({ type: 'RECEIVE_COMMENTS', comments: new Map([...comments.set(comment.id, { ...comments.get(comment.id), ...comment })]) });
        });
    },
    receiveUpdatedComments: (comments: Comment[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { comments: existingComments } = getState().comments;
            comments.forEach(comment => existingComments.set(comment.id, comment));
            dispatch({ type: 'RECEIVE_COMMENTS', comments: new Map([...existingComments]) });
        });
    },
    removeComment: (commentId: number): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { comments } = getState().comments;
            comments.delete(commentId);
            dispatch({ type: 'RECEIVE_COMMENTS', comments: new Map([...comments]) });
        });
    },
    removeComments: (commentIds: number[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const { comments } = getState().comments;
            commentIds.forEach(id => comments.delete(id));
            dispatch({ type: 'RECEIVE_COMMENTS', comments: new Map([...comments]) });
        });
    },
    openCommentEditor: (commentId: number, log: Log): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { logs: { selectedLogId, logs } } = getState();
        if (!selectedLogId) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            if (selectedLogId === -1) {
                const newLog = { ...logs[selectedLogId], ...log };
                dispatch(LogStore.actionCreators.saveLog(newLog, false));
            }
            dispatch({ type: 'OPEN_COMMENT_EDITOR', selectedCommentId: commentId });
        });
    },
    saveComment: (comment?: Comment, content?: string): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { comments: { selectedCommentId, comments }, logs: { selectedLogId, logs }, appSettings: { appSettings }, authentication: { accessToken, userDisplayName, userOid } } = getState();
        if (!selectedLogId || !accessToken || !appSettings) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
            const currentComment = comment ?? comments.get(selectedCommentId ?? -1) ?? { ...defaultComment };
            if (!currentComment) {
                return;
            }

            //set the username / userOid to the current user
            if (currentComment.userOid === null || currentComment.userOid === undefined || currentComment.userOid === '') {
                if (currentComment.userName === null || currentComment.userName === undefined || currentComment.userName === '') {
                    currentComment.userOid = userOid;
                    currentComment.userName = userDisplayName ? userDisplayName : '';
                }
                else {
                    if (userDisplayName?.toLowerCase() === currentComment.userName.toLowerCase()) {
                        currentComment.userOid = userOid;
                    }
                }
            }

            //updates the content with the passed in content
            if (content !== undefined) {
                currentComment.content = content;
            }

            fetch(`/api/Comment`, {
                method: 'PUT',
                body: JSON.stringify({ ...currentComment, logId: selectedLogId }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                })
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<Comment>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    if (selectedCommentId === -1) {
                        comments.delete(selectedCommentId);
                    }
                    dispatch({ type: 'SAVE_COMMENT', comments: new Map([...comments.set(data.id, data)]) });
                    const log = logs[selectedLogId];
                    if (log) {
                        dispatch(LogStore.actionCreators.updateLogs(
                            { ...log, commentIds: (log.commentIds || []).filter(id => id !== selectedCommentId) }
                        ));
                        LogStore.clearOtherUsersLogViews(log.id, accessToken);
                    }
                    ClearBackendErrorToast(ToastErrorTypes.COMMENTS_SAVE);
                }).catch(error => {
                    const res = error.statusCode === 401 ? "You do not have permission to edit this comment..." : "Cannot reach the backend services. Please check your internet connection...";
                    dispatch(<ErrorCommentOperationAction>{ type: 'ERROR_COMMENT_OPERATION' });
                    BackendErrorToast(ToastErrorTypes.COMMENTS_SAVE, "Error saving comment", res);
                    console.error(error);
                });
            dispatch(<CommentOperationLoadingAction>{ type: 'COMMENT_OPERATION_LOADING' });
        });
    },
    deleteComment: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { comments: { selectedCommentId, comments }, authentication: { accessToken } } = getState();
        if (!selectedCommentId || !accessToken) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {

            fetch(`/api/Comment/${selectedCommentId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then(resp => {
                    if (resp.ok) {
                        return resp;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(() => {
                    comments.delete(selectedCommentId);
                    dispatch({ type: 'DELETE_COMMENT', comments: new Map([...comments]) });
                    ClearBackendErrorToast(ToastErrorTypes.COMMENTS_DELETE);
                }).catch(error => {
                    const res = error.statusCode === 401 ? "You do not have permission to delete this comment..." : "Cannot reach the backend services. Please check your internet connection...";
                    dispatch(<ErrorCommentOperationAction>{ type: 'ERROR_COMMENT_OPERATION' });
                    BackendErrorToast(ToastErrorTypes.COMMENTS_DELETE, "Error deleting comment", res);
                    console.error(error);
                });

            dispatch(<CommentOperationLoadingAction>{ type: 'COMMENT_OPERATION_LOADING' });
        });
    },
    closeCommentEditor: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<CloseCommentAction>{ type: 'CLOSE_COMMENT_EDITOR' });
        })
};


export const defaultComment: Comment = {
    dateCreated: new Date(),
    loading: false,
    id: -1,
    commentType: '',
    content: '',
    userName: ''
} as Comment;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: CommentState = { comments: new Map<number, Comment>(), initialLoadStarted: false, commentOperationLoading: false };

export const reducer: Reducer<CommentState, AnyAction> = (state: CommentState | undefined, action: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }
    switch (action.type) {
        case "OPEN_COMMENT_EDITOR":
            return {
                ...state,
                selectedCommentId: action.selectedCommentId
            };
        case 'SAVE_COMMENT':
            return {
                ...state,
                comments: action.comments,
                selectedCommentId: undefined,
                commentOperationLoading: false
            };
        case 'DELETE_COMMENT':
            return {
                ...state,
                selectedCommentId: undefined,
                comments: action.comments,
                commentOperationLoading: false
            };
        case 'CLOSE_COMMENT_EDITOR':
            return {
                ...state,
                selectedCommentId: undefined,
            };
        case 'RECEIVE_COMMENTS':
            return <CommentState>{
                ...state,
                initialLoadStarted: false,
                comments: action.comments
            };
        case 'REQUEST_COMMENTS':
            return <CommentState>{
                ...state,
                initialLoadStarted: true
            };
        case 'ERROR_RECEIVING_COMMENTS':
            return <CommentState>{
                ...state,
                initialLoadStarted: false,
            };
        case 'ERROR_COMMENT_OPERATION':
            return <CommentState>{
                ...state,
                commentOperationLoading: false,
            };
        case 'COMMENT_OPERATION_LOADING':
            return <CommentState>{
                ...state,
                commentOperationLoading: true
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};