import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, AppThunkDispatch, ApplicationState } from '.';
import { AuthenticationRole, enforceRolePermissions } from './authentication';
import { DivIconOptions } from '../components/map/icons';
import { BackendErrorToast, ToastErrorTypes, ClearBackendErrorToast } from '../utils/toast';
import { HttpError } from '@microsoft/signalr';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// These Alerts are to be used both in combination with the log viewer, and within sites.

export interface BusState {
    isLoading: boolean;
    buses: { [key: number]: Bus };
}

export interface Bus {
    id: string;
    vehicleType: string;
    isScheduledService: boolean;
    isAtStop: boolean;
    distanceAlongTrip: number;
    isCanceled: boolean;
    headway: number;
    loc: Loc;
    routeId: string;
    routeShortName: string;
    routeName: string;
    headsign: string;
    directionId: string;
    schAdh: number | null;
    schAdhSecs: number | null;
    schAdhStr: string;
    blockId: string;
    blockMthd: string;
    tripId: string;
    tripPattern: string;
    nextStopId: string;
    nextStopName: string;
    serviceId: string;
    serviceName: string;
    layover: boolean | null;
    layoverDepTime: number | null;
    layoverDepTimeStr: string;
}

export interface Loc {
    lat: number;
    lon: number;
    time: number;
    heading: number;
    speed: number | null;
}

export enum BusStatus {
    Late,
    OnTime,
    Early,
    AtStop,
    Unknown
}

export interface BusMarker {
    iconOptions: DivIconOptions;
    showHeading: boolean;
    adherenceTime: number | null;
    busInfo: BusInfo;
    busStatus: BusStatus;
    loc: Loc;
}

export interface BusInfo {
    id: string;
    routeName: string;
    nextStopName: string;
    headSign: string;
    tripId: string;
    blockId: string;
    adherence: string | null;
    speed: string | null;
    lastGpsPing: string;
    isAtStop: boolean;
    isScheduledService: boolean;
    isCanceled: boolean;
    distanceAlongTrip: number;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class BusSelectors {


}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ReceiveBusesAction extends Action {
    type: 'RECEIVE_HAMILTON_BUSES';
    buses: Bus[];
}

interface RequestBusesAction extends Action {
    type: 'REQUEST_HAMILTON_BUSES';
}

interface ErrorBusesAction extends Action {
    type: 'ERROR_RECEIVING_HAMILTON_BUSES';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReceiveBusesAction | RequestBusesAction | ErrorBusesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    requestAllBuses: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        // Only load data if we are not already loading data.
        const { buses: { isLoading }, authentication: { accessToken } } = getState();
        if (!accessToken || isLoading) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Bus`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json() as Promise<Bus[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_HAMILTON_BUSES', buses: data });
                    ClearBackendErrorToast(ToastErrorTypes.BUS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_HAMILTON_BUSES' });
                    BackendErrorToast(ToastErrorTypes.BUS, "Error connecting to goswift.ly Bus API", "");
                    console.error(error);
                });

            dispatch({ type: 'REQUEST_HAMILTON_BUSES' });
        });
    },
    receiveUpdatedBuses: (incomingBuses: Bus[]): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch({ type: 'RECEIVE_HAMILTON_BUSES', buses: incomingBuses });
        });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: BusState = { buses: {}, isLoading: false };

export const reducer: Reducer<BusState, AnyAction> = (state: BusState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_HAMILTON_BUSES': {
            const incomingBuses = action.buses.reduce<Record<string, Bus>>(function (busMap, bus) {
                busMap[bus.id] = bus;
                return busMap;
            }, {});
            return <BusState>{
                ...state,
                buses: incomingBuses,
                isLoading: false
            };
        }
        case 'REQUEST_HAMILTON_BUSES':
            return <BusState>{
                ...state,
                isLoading: true,
            };
        case 'ERROR_RECEIVING_HAMILTON_BUSES':
            return <BusState>{
                ...state,
                isLoading: false,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }

    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};