import * as React from "react";
import "./controls.scss";
import { MobileAlertGroup, MobileAlertRule, SitePriorityGroup } from "store/adminSettings";
import { iconAdd, iconDelete } from "../../images";
import { CheckBox } from "../../controls/checkBox";
import { AlertSource, AlarmCode } from "store/alarm";
import { groupBy } from "lodash";

interface MobileRulesTableSettingProps {
    updateMobileAlertRules: (rules: MobileAlertRule[]) => void;
    alarmCodes: AlarmCode[];
    rules: MobileAlertRule[];
    groups: MobileAlertGroup[];
}

export class MobileRulesTableSetting extends React.Component<MobileRulesTableSettingProps> {
    private defaultNewRule: MobileAlertRule;

    constructor(props: MobileRulesTableSettingProps) {
        super(props);
        this.defaultNewRule = {
            id: 0,
            alarmCode: this.props.alarmCodes.filter(x => x.alarmSourceId === 1)[0].code,
            active: true,
            alarmSourceId: 1,
            priorityGroup: SitePriorityGroup.AnyPriority,
            amount: 1,
            name: "",
            lastUpdated: new Date(),
            groupId: null
        };
    }

    public render() {
        const {
            updateMobileAlertRules,
            rules,
            groups
        } = this.props;

        const handleUpdateRule = (rule: MobileAlertRule) => {
            const r: MobileAlertRule[] = [...this.props.rules];
            const index = r.findIndex((obj => obj.id === rule.id));
            r[index] = rule;
            updateMobileAlertRules(r);
        };

        const handleDeleteRule = (rule: MobileAlertRule) => {
            const r: MobileAlertRule[] = [...this.props.rules];
            const index = r.findIndex((obj => obj.id === rule.id));
            r.splice(index, 1);
            updateMobileAlertRules(r);
        };

        const handleAddRule = () => {
            const r: MobileAlertRule[] = [...this.props.rules];
            this.defaultNewRule.id = -10000000 + this.props.rules.length;  //set temporary id as negative - this will be replaced on save
            r.push(this.defaultNewRule);
            updateMobileAlertRules(r);
        };

        const alarmCodes = (alarmSourceId: number) => this.props.alarmCodes.filter(x => x.alarmSourceId === alarmSourceId);

        const priorityGroups = (r: MobileAlertRule) => {
            return (r.alarmSourceId === AlertSource.ScatsSiteAlarm ||
                r.alarmSourceId === AlertSource.AddInsightSiteAlarm ||
                r.alarmSourceId === AlertSource.ScatsSiteDetectorAlarm ||
                r.alarmSourceId === AlertSource.ScatsSiteDetectorThresholdAlarm ||
                r.alarmSourceId === AlertSource.SharePoint ||
                r.alarmSourceId === AlertSource.PowerSupply
            ) ?
                <div className="mb-2 mr-3">
                    <span className="pr-3 pb-2 pb-md-0">and the Site Priority is</span>
                    <select className="text-setting-input" value={r.priorityGroup} onChange={(e) => handleUpdateRule({
                        ...r,
                        priorityGroup: Number(e.target.value),
                        lastUpdated: new Date()
                    })}
                    >
                        <option value={SitePriorityGroup.AnyPriority}>Any priority</option>
                        <option value={SitePriorityGroup.MediumAndAbove}>Medium and above</option>
                        <option value={SitePriorityGroup.HighAndAbove}>High and above</option>
                        <option value={SitePriorityGroup.Critical}>Critical</option>
                    </select>
                </div> : <></>;
        }

            ;

        const ruleType = (r: MobileAlertRule) =>
            <div className="mb-2 mr-3 ml-3">
                <select className="text-setting-input" value={r.alarmSourceId} onChange={(e) => handleUpdateRule({
                    ...r,
                    alarmSourceId: Number(e.target.value),
                    alarmCode: alarmCodes(Number(e.target.value))[0]?.code ?? "",
                    lastUpdated: new Date()
                })}
                >
                    <option value={AlertSource.ScatsSiteAlarm}>SCATS Alarm</option>
                    <option value={AlertSource.ScatsSiteDetectorThresholdAlarm}>SCATS Volume</option>
                    <option value={AlertSource.ScatsSiteDetectorAlarm}>SCATS Detector</option>
                    <option value={AlertSource.AddInsightSiteAlarm}>AddInsight Site</option>
                    <option value={AlertSource.AddInsightLinkAlarm}>AddInsight Link</option>
                    <option value={AlertSource.SharePoint}>SharePoint</option>
                    <option value={AlertSource.SumpMonitorAlarm}>Sump Monitor</option>
                    <option value={AlertSource.PowerSupply}>Power Supply</option>
                </select>
            </div>;

        const ruleAmount = (r: MobileAlertRule) =>
            <div className="custom-value-input rule-amount mb-2 mr-3">
                <input type="text" value={r.amount} onChange={(e) => handleUpdateRule({ ...r, amount: parseInt(e.target.value) || 0, lastUpdated: new Date() })} />
            </div>;

        const ruleCode = (r: MobileAlertRule) =>
            <div className="mb-2">
                <select className="text-setting-input" value={r.alarmCode} onChange={(e) => handleUpdateRule({ ...r, alarmCode: e.target.value, lastUpdated: new Date() })}  >
                    {
                        alarmCodes(r.alarmSourceId).map(c => <option key={c.code} value={c.code}>{c.code}</option>)
                    }
                </select>
            </div >;

        const sumpRuleCode = (r: MobileAlertRule) =>
            <div className="mb-2">
                <select className="text-setting-input" value={r.alarmCode} onChange={(e) => handleUpdateRule({ ...r, alarmCode: e.target.value, lastUpdated: new Date() })}  >
                    {
                        alarmCodes(r.alarmSourceId).sort((a,b) => a.description.localeCompare(b.description)).map(c => <option key={c.code} value={c.code}>{c.description}</option>)
                    }
                </select>
            </div >;

        const buildRule = (r: MobileAlertRule) => {
            switch (r.alarmSourceId) {
                case AlertSource.AddInsightLinkAlarm:
                    return (
                        <>
                            <span className="pr-3 pb-2 pb-md-0">with an Excess Delay over</span>
                            {ruleAmount(r)}
                            <span className="pr-3 pb-2 pb-md-0">second{r.amount === 1 ? "" : "s"}</span>

                        </>
                    );
                case AlertSource.ScatsSiteDetectorAlarm:
                    return (
                        <>
                            <span className="pr-3 pb-2 pb-md-0">and there{r.amount === 1 ? " is" : " are"}</span>
                            {ruleAmount(r)}
                            {ruleCode(r)}
                            <span className="pr-3 pl-3 pb-2 pb-md-0">alarm{r.amount === 1 ? "" : "s"} on a single site</span>
                        </>
                    );
                case AlertSource.SumpMonitorAlarm:
                        return (
                            <>
                                <span className="pr-3 pb-2 pb-md-0">and</span>
                                {sumpRuleCode(r)}
                                <span className="pr-3 pb-2 pb-md-0 pl-2">is true</span>
                            </>
                        );
                default:
                    return (
                        <>
                            <span className="pr-3 pb-2 pb-md-0">and</span>
                            {ruleAmount(r)}
                            <span className="pr-3 pb-2 pb-md-0">site{r.amount === 1 ? " has" : "s have"} an alarm code</span>
                            {ruleCode(r)}
                        </>
                    );
            }
        };

        return (
            <div className="setting rule-table">
                {rules.length > 0 &&
                    <>
                        {
                            Object.entries(groupBy(rules, x => x.groupId))
                                .sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()))
                                .map((x, i) => {
                                    const g = x[0];
                                    const rul = x[1];
                                    const group = groups.find(grp => grp.id.toString() === g);

                                    return (
                                        <div key={g} className="rule-group">
                                            <div className="settings-table">
                                                {group &&
                                                    <div className="group-name">{group.name}</div>
                                                }
                                                <div className="settings-header-row pl-4 pr-4 d-none d-md-flex">
                                                    <div className="col-10">Rules</div>
                                                    <div className="col-1 col-sm-1">Active</div>
                                                    <div className="col-1">Actions</div>
                                                </div>
                                                {
                                                    rul.map((r, i) => {
                                                        return (
                                                            <div key={r.id} className={"row settings-row ml-1 mr-1" + ((i % 2 === 1) ? " settings-row-alternate" : "")}>
                                                                <div className="col-12 col-md-10 d-flex flex-column mb-3">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="mr-3">Rule Name:</div>
                                                                        <div className="custom-value-input d-inline-block">
                                                                            <input type="text" value={r.name} onChange={(e) => handleUpdateRule({ ...r, name: e.target.value, lastUpdated: new Date() })} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-1 order-md-2 order-5">
                                                                    <div className="d-flex">
                                                                        <span className="d-flex d-md-none pr-3">Active</span>
                                                                        <CheckBox checked={r.active} onChange={() => handleUpdateRule({ ...r, active: !r.active, lastUpdated: new Date() })} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-1 order-md-3 order-6">
                                                                    <div className="delete-icon-container d-flex d-md-block justify-content-end" >
                                                                        <div className="d-inline-block" onClick={() => handleDeleteRule(r)}>
                                                                            <img className="delete-icon" src={iconDelete} alt="Delete recipient" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 flex-wrap d-flex align-items-center mt-2 order-md-4">
                                                                    <span className="pr-3">Alarm type is</span>
                                                                    {ruleType(r)}
                                                                    {buildRule(r)}
                                                                    {priorityGroups(r)}
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="mr-3">for the group</div>
                                                                        <select name="groups" value={r.groupId ?? -1} onChange={(e) => handleUpdateRule({ ...r, groupId: Number(e.target.value) === -1 ? null : Number(e.target.value), lastUpdated: new Date() })} className="text-setting-input">
                                                                            <option value={-1}>None</option>
                                                                            {
                                                                                !!groups.length && groups.map(g => {
                                                                                    return (
                                                                                        <option key={g.id} value={g.id}>{g.name}</option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                        }

                    </>
                }

                <div className="add-icon-container" onClick={() => handleAddRule()}>
                    <span className="d-inline-block">
                        <img className="add-icon" src={iconAdd} alt="Add rule" />
                    </span>Add Rule</div>
            </div >
        );
    }
}

export default MobileRulesTableSetting;
