import { AppMiddlewareAPI, ApplicationState } from "..";
import { AnyAction, Dispatch } from 'redux';
import { IMsalAuthProviderConfig, LoginType, MsalAuthProvider, AuthenticationActions } from "react-aad-msal";
import { Configuration } from "msal";


const _renewalOffset = 5 * 60 // Refresh token every 5 minutes.

export class MsalAuthFactory {
    private static _msalAuthProvider: MsalAuthProvider | null = null;

    static getAuthProvider() {
        return MsalAuthFactory._msalAuthProvider;
    }

    static setAuthProvider(provider: MsalAuthProvider) {
        if (!MsalAuthFactory._msalAuthProvider) {
            MsalAuthFactory._msalAuthProvider = provider;
        }
        else {
            console.warn("Creation of multiple auth providers is not supported");
        }
    }
}

export const middleware = (api: AppMiddlewareAPI) =>
    (next: Dispatch<AnyAction>) =>
        (action: AnyAction) => {
            if (action.type === "RECEIVE_APP_SETTINGS") {

                const appSettings = action.appSettings;
                const authenticationParameters = {
                    scopes: [`${appSettings.applicationIdUri}/user_impersonation`]
                };

                const msalAuthConfig: IMsalAuthProviderConfig = {
                    loginType: LoginType.Popup,
                    //The tokenRefreshUri allows you to set a separate page to load only when tokens are being refreshed. When MSAL attempts to refresh a token, it will reload the page in an iframe. 
                    //This option allows you to inform MSAL of a specific page it can load in the iframe. It is best practice to use a blank HTML file so as to prevent all your site scripts and contents from loading multiple times.
                    //https://www.npmjs.com/package/react-aad-msal
                    tokenRefreshUri: window.location.origin + '/auth.html'
                };

                const config: Configuration = {
                    auth: {
                        authority: `${appSettings.authority}${appSettings.tenantId}`,
                        clientId: appSettings.clientId,
                        navigateToLoginRequestUrl: true,
                        redirectUri: window.location.origin
                    },
                    cache: {
                        cacheLocation: "localStorage",
                        // IE11 and Edge Support
                        storeAuthStateInCookie: true
                    },
                    system: {
                        tokenRenewalOffsetSeconds: _renewalOffset
                    }
                };

                MsalAuthFactory.setAuthProvider(new MsalAuthProvider(config, authenticationParameters, msalAuthConfig));
                next({ type: "AUTH_PROVIDER_FACTORY_READY" });
            }

            if (action.type === AuthenticationActions.LoginSuccess) {
                startAuthLoop(api.getState);
            }
            return next(action);
        }

const _timeInSeconds = (date: Date) => (Math.round(date.getTime() / 1000.0));

const startAuthLoop = (stateFunction: () => ApplicationState) => {
    const updateHandler = () => {
        const state = stateFunction();
        const authProvider = MsalAuthFactory.getAuthProvider();
        if (!authProvider) {
            throw Error("Auth Provider called before initialised");
        }

        const currentTime = _timeInSeconds(new Date());
        const accessTokenExpiryTime = state.authentication.accessTokenExpiry ? _timeInSeconds(state.authentication.accessTokenExpiry) : currentTime;
        const timeToRenew = accessTokenExpiryTime - _renewalOffset;

        if (currentTime >= timeToRenew) {
            authProvider.getAccessToken();
        }
    };

    setInterval(updateHandler, 60000);
    updateHandler();
}