import 'whatwg-fetch';
import { Reducer, AnyAction, Action } from 'redux';
import { AppThunkAction, AppThunkDispatch, ApplicationState } from '.';
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from '../utils/toast';
import { HttpError } from '@microsoft/signalr';
import { enforceRolePermissions, AuthenticationRole } from './authentication';


export interface AppSettingsState {
    isLoading: boolean;
    appSettings: AppSettingsPayload | null;
}

export interface AppSettingsPayload {
    googleApiKey: string;
    clientId: string;
    authority: string;
    tenantId: string;
    applicationIdUri: string;
    applicationInsightsKey: string;
    kmzLayerUrl: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getAppSettings: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const { appSettings: { isLoading } } = getState();
        if (isLoading) {
            return;
        }
        enforceRolePermissions(getState().authentication, AuthenticationRole.None, () => {
            fetch(`/api/PublicSettings`)
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_APP_SETTINGS', appSettings: data });
                    ClearBackendErrorToast(ToastErrorTypes.APP_SETTINGS);
                }).catch(error => {
                    dispatch({ type: 'ERROR_RECEIVING_APP_SETTINGS' });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.APP_SETTINGS, "Error retrieving App Settings");
                });
            dispatch({ type: 'REQUEST_APP_SETTINGS' });
        });
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ReceiveAppSettingsAction extends Action {
    type: 'RECEIVE_APP_SETTINGS';
    //The value of the component that will be rendered
    appSettings: AppSettingsPayload;
    //dateTime
}
interface ErrorAppSettingsAction extends Action {
    type: 'ERROR_RECEIVING_APP_SETTINGS';
}
interface RequestAppSettingsAction extends Action {
    type: 'REQUEST_APP_SETTINGS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ReceiveAppSettingsAction | RequestAppSettingsAction | ErrorAppSettingsAction;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: AppSettingsState = { appSettings: null, isLoading: false, };

export const reducer: Reducer<AppSettingsState, AnyAction> = (state: AppSettingsState | undefined, incomingAction: KnownAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_APP_SETTINGS':
            return <AppSettingsState>{
                ...state,
                isLoading: false,
                appSettings: action.appSettings
            };
        case 'REQUEST_APP_SETTINGS':
            return <AppSettingsState>{
                ...state,
                isLoading: true,
            };
        case 'ERROR_RECEIVING_APP_SETTINGS':
            return <AppSettingsState>{
                ...state,
                isLoading: false,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
}