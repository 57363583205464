import React from "react";
import * as SiteStore from "store/sites";
import moment, { Moment } from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactMoment from "react-moment";
import { SystemLogType } from "../../../constants/logConstants";
import { BackendSettings } from "store/adminSettings";
import * as AlertStore from "store/alarm";
import { LightwireDevice } from "store/lightwireDevicesStore";
import { AuthenticationRole, AuthenticationSelectors } from "store/authentication";
import { defaultLog, Log } from "store/logs";
import { scaleVolumeSeverity } from "../../../utils/volume";
import { CheckBox } from "../../controls/checkBox";
import {
    iconPinAlertNormal,
    iconClose,
    iconPinAlertHigh,
    iconPinAlertMedium,
    iconAdd,
    iconAlertHighCircle,
    iconAlertHigh,
    iconAlertMediumCircle,
    iconAlertMedium,
    iconAlertNormal,
    iconAlertNormalUnmodified,
    iconAlertNormalModified,
} from "../../images";
import Modal from "../../modal/modal";
import MuteRow from "./../muteRow";
import SidebarLogTableBody from "./../sidebarLogTableBody";
import "../sidebar.scss";
import "../infoPanels/sidebarSiteInformation.scss";
import { flowRestrictionAlarmCode } from "../../../constants/alarmConstants";
import { AlertsSelectors } from "store/alarm";
import LightwireRow from "./../lightwireRow";

export interface SiteInformationProps {
    site: SiteStore.Site;
    lightwireDevice: LightwireDevice | null;
    currentAlerts: AlertStore.Alert[];
    closeSitePanel: () => void;
    currentLogs: Log[];
    openLogViewer: (logId: number) => void;
    mute: (alertSource: AlertStore.AlertSource | undefined, muteLength: Moment | undefined) => void;
    isSiteOperationLoading: boolean;
    backendSettings: BackendSettings;
    selectedLogId: number | undefined;
    userRole: AuthenticationRole;
}

export interface SiteInformationState {
    muteAlertSource?: AlertStore.AlertSource;
    muteUntilDate?: Moment;
    filterSystemClosedLogs: boolean;
}

const compareMomentsWithoutSeconds = (m1: Moment | undefined, m2: Moment | undefined) => {
    return moment(m1).seconds(0).milliseconds(0).unix() === moment(m2).seconds(0).milliseconds(0).unix();
};

const validMuteDate = function (current: Moment) {
    return current.isAfter(moment().subtract(1, "day"));
};

export default class SidebarSiteInformation extends React.Component<SiteInformationProps, SiteInformationState> {
    state = {
        muteAlertSource: undefined,
        filterSystemClosedLogs: false,
        muteUntilDate: undefined,
    };

    componentDidUpdate(prevProps: SiteInformationProps) {
        //sets the filterSystemClosedLogs toggle back to false when new site is selected
        if (prevProps.site && this.props.site) {
            if (prevProps.site.id !== this.props.site.id) {
                this.setState({ filterSystemClosedLogs: false });
            }
        }
    }

    private getAlertSeverity = (alerts: AlertStore.Alert[]) => {
        const severity: number = Math.max(...alerts.map((alert) => alert.alertSeverity));
        const sitePriority = this.props.backendSettings.sitePrioritySettings.filter((p) => p.priority === this.props.site.priority)[0];

        if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
            return iconPinAlertHigh;
        } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
            return iconPinAlertMedium;
        } else {
            return iconPinAlertNormal;
        }
    };

    private getDetectorAlertSeverity = (alerts: AlertStore.Alert[]) => {
        const severity: number = alerts.reduce((partialSum, a) => partialSum + a.alertSeverity, 0);
        const sitePriority = this.props.backendSettings.sitePrioritySettings.filter((p) => p.priority === this.props.site.priority)[0];

        if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
            return iconPinAlertHigh;
        } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
            return iconPinAlertMedium;
        } else {
            return iconPinAlertNormal;
        }
    };

    private getVolumeAlertSeverity = (alerts: AlertStore.Alert[]) => {
        const { backendSettings, site } = this.props;
        const severity: number = Math.max(
            ...alerts.map((alert) =>
                scaleVolumeSeverity(
                    alert.alertSeverity,
                    parseFloat(alert.additionalData),
                    backendSettings.minVolumeMultiplierThreshold,
                    backendSettings.scorePerVolumeIncrease,
                    backendSettings.maximumVolumeScore
                )
            )
        );

        const sitePriority = backendSettings.sitePrioritySettings.filter((p) => p.priority === site.priority)[0];

        if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
            return iconPinAlertHigh;
        } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
            return iconPinAlertMedium;
        } else {
            return iconPinAlertNormal;
        }
    };

    private getHeaderAlertSeverity = (alerts: AlertStore.Alert[]) => {
        const { currentLogs, site, backendSettings } = this.props;
        const severity: number = site.currentStatus;
        const unmodified = currentLogs.some((log) => !log.firstModified && !log.dateClosed);
        const modified = currentLogs.some((log) => log.firstModified && !log.dateClosed);
        const sitePriority = backendSettings.sitePrioritySettings.filter((p) => p.priority === site.priority)[0];

        if (sitePriority && severity >= sitePriority.criticalAlertThreshold) {
            if (unmodified) {
                return iconPinAlertHigh;
            } else if (modified) {
                return iconAlertHighCircle;
            } else {
                return iconAlertHigh;
            }
        } else if (sitePriority && severity >= sitePriority.mediumAlertThreshold) {
            if (unmodified) {
                return iconPinAlertMedium;
            } else if (modified) {
                return iconAlertMediumCircle;
            } else {
                return iconAlertMedium;
            }
        } else {
            if (unmodified) {
                return iconAlertNormalUnmodified;
            } else if (modified) {
                return iconAlertNormalModified;
            } else {
                return iconAlertNormal;
            }
        }
    };

    private handleOpenLog = (event: React.MouseEvent<HTMLAnchorElement | HTMLTableRowElement | HTMLButtonElement, MouseEvent>, id: number) => {
        event.preventDefault();
        this.props.openLogViewer(id);
    };

    private toggleMuteControls = (alertSource: AlertStore.AlertSource | undefined) => {
        this.setState({ muteAlertSource: alertSource, muteUntilDate: undefined });
    };

    private muteAlertSource = () => {
        this.props.mute(this.state.muteAlertSource, this.state.muteUntilDate);
        this.setState({ muteAlertSource: undefined, muteUntilDate: undefined });
    };

    private setMuteUntilDate = (muteUntilDate: Moment | undefined) => {
        this.setState({ muteUntilDate });
    };

    public render() {
        const { currentLogs, currentAlerts, site, isSiteOperationLoading, userRole, lightwireDevice } = this.props;
        const { muteAlertSource, muteUntilDate, filterSystemClosedLogs } = this.state;

        const alerts = AlertStore.AlertsSelectors.SortedBySourceSeverityAndTime(currentAlerts.filter((value, index, self) => self.indexOf(value) === index));

        const scatsState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.ScatsSiteAlarm);
        const scatsEventsAll = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteAlarm);
        const scatsEventsNonDuplicate = scatsEventsAll.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj.code).indexOf(obj.code) === pos;
        });
        const scatsEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(scatsEventsAll);

        const scatsDetectorState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorAlarm);
        const scatsDetectorEventsAll = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorAlarm);
        const scatsDetectorEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(scatsDetectorEventsAll);

        const thresholdState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm);
        const thresholdEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm);
        const thresholdEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(thresholdEvents);
        const thresholdEventWithLargestMultiplier = AlertStore.AlertsSelectors.LargestAlertByAdditionalData(thresholdEvents);

        const srmState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.SharePoint);
        const srmEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.SharePoint);
        const srmEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(srmEvents);

        const powerSupplyState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.PowerSupply);
        const powerSupplyEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.PowerSupply);
        const powerSupplyEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(powerSupplyEvents);

        const addInsightState = site.alertSources?.find((source) => source.alarmSourceId === AlertStore.AlertSource.AddInsightSiteAlarm);
        const addInsightEvents = alerts.filter((e) => e.alarmSourceId === AlertStore.AlertSource.AddInsightSiteAlarm);
        const addInsightEventsRecent = AlertStore.AlertsSelectors.ShortestRunningAlert(addInsightEvents);
        const addInsightEventWithHighestExcessDelay = AlertStore.AlertsSelectors.LargestAlertByAdditionalData(addInsightEvents);

        const openCurrentLogs = currentLogs.filter((x) => x.dateClosed === null || x.dateClosed === undefined);
        const closedCurrentLogs = currentLogs
            .filter((x) => x.dateClosed !== null && x.dateClosed !== undefined)
            .filter((x) => (filterSystemClosedLogs ? x.logType.toLocaleUpperCase() !== SystemLogType : true));

        const hasPermission = AuthenticationSelectors.checkAuthenticationUsingRole(AuthenticationRole.Operator, userRole);

        let alertTitle;
        if (this.props.site.addInsightSiteId != null && this.props.site.scatsSiteId != null) {
            alertTitle = <h1>Site {this.props.site.scatsSiteId}</h1>;
        } else if (this.props.site.addInsightSiteId === null && this.props.site.scatsSiteId != null) {
            alertTitle = <h1>Site {this.props.site.scatsSiteId}</h1>;
        } else if (this.props.site.addInsightSiteId != null && this.props.site.scatsSiteId === null) {
            alertTitle = <h1>AddInsight {this.props.site.addInsightSiteId}</h1>;
        }
        return (
            <div className="nav-blade">
                <div className="blade-header">
                    <div className="blade-header-icon">
                        <img src={this.getHeaderAlertSeverity(currentAlerts)} alt="Current alert severity icon" />
                    </div>
                    <div className="blade-header-title">
                        {alertTitle}
                        <h2>{site.description}</h2>
                    </div>
                    <div className="blade-header-close">
                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={() => this.props.closeSitePanel()}>
                            <img src={iconClose} width={15} height={15} alt="Close icon" className="align-top" />
                        </button>
                    </div>
                </div>
                <div className="blade-body">
                    <h3 className="blade-body-header">Alerts</h3>
                    <table>
                        <thead>
                            <tr className="blade-table-header">
                                <th className="pl-4" colSpan={2}>
                                    Details
                                </th>
                                <th>Duration</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!site.scatsSiteId && (
                                <MuteRow
                                    alertSeverityIcon={this.getAlertSeverity(scatsEventsAll)}
                                    alertSourceState={scatsState}
                                    subText={scatsEventsNonDuplicate.map((alert) => (
                                        <small key={alert.alarmId} title={alert.description}>
                                            {alert.code}{" "}
                                        </small>
                                    ))}
                                    mostRecentAlert={scatsEventsRecent}
                                    title="SCATS Alarms"
                                    userRole={userRole}
                                    mute={() => this.props.mute(AlertStore.AlertSource.ScatsSiteAlarm, undefined)}
                                    toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.ScatsSiteAlarm)}
                                />
                            )}
                            {!!site.scatsSiteId && (
                                <MuteRow
                                    alertSeverityIcon={this.getDetectorAlertSeverity(scatsDetectorEventsAll)}
                                    alertSourceState={scatsDetectorState}
                                    subText={scatsDetectorEventsAll
                                        .filter((x) => x.detectorNumber)
                                        .map((alert, i) => (
                                            <small key={alert.alarmId} title={alert.description}>
                                                {alert.additionalData === "PED" ? "PB" : ""}
                                                {alert.detectorNumber}
                                                {i !== scatsDetectorEventsAll.length - 1 && ", "}
                                            </small>
                                        ))}
                                    mostRecentAlert={scatsDetectorEventsRecent}
                                    title="SCATS Detectors"
                                    userRole={userRole}
                                    mute={() => this.props.mute(AlertStore.AlertSource.ScatsSiteDetectorAlarm, undefined)}
                                    toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.ScatsSiteDetectorAlarm)}
                                />
                            )}
                            {!!site.scatsSiteId && (
                                <MuteRow
                                    alertSeverityIcon={this.getVolumeAlertSeverity(thresholdEvents)}
                                    alertSourceState={thresholdState}
                                    subText={
                                        thresholdEventWithLargestMultiplier && (
                                            <small title={thresholdEventWithLargestMultiplier.description}>
                                                {`${Math.round(Number(thresholdEventWithLargestMultiplier.additionalData) * 100)}% (detector ${thresholdEventWithLargestMultiplier.detectorNumber
                                                    })`}{" "}
                                            </small>
                                        )
                                    }
                                    mostRecentAlert={thresholdEventsRecent}
                                    title="SCATS Volumes"
                                    userRole={userRole}
                                    mute={() => this.props.mute(AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm, undefined)}
                                    toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.ScatsSiteDetectorThresholdAlarm)}
                                />
                            )}
                            <MuteRow
                                alertSeverityIcon={this.getAlertSeverity(srmEvents)}
                                alertSourceState={srmState}
                                mostRecentAlert={srmEventsRecent}
                                title={`SRM ${typeof srmEventsRecent === "undefined" ? "" : AlertsSelectors.GetCSRNumberAdditionalItemString(srmEventsRecent.additionalData)}`}
                                userRole={userRole}
                                mute={() => this.props.mute(AlertStore.AlertSource.SharePoint, undefined)}
                                toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.SharePoint)}
                            />
                            {!!site.addInsightSiteId && (
                                <MuteRow
                                    alertSeverityIcon={this.getAlertSeverity(addInsightEvents)}
                                    alertSourceState={addInsightState}
                                    subText={
                                        //handles the case where there is only Flow Restriction alarms
                                        !!addInsightEvents.filter((a) => a.code === flowRestrictionAlarmCode).length &&
                                            addInsightEvents.every((a) => a.code === flowRestrictionAlarmCode) ? (
                                            <small title={addInsightEvents[0].description}>Flow Restricted</small>
                                        ) : (
                                            //handles the case where AddInsight alarms exist
                                            addInsightEventWithHighestExcessDelay && (
                                                <small title={addInsightEventWithHighestExcessDelay.description}>
                                                    <ReactMoment durationFromNow subtract={{ seconds: Number(addInsightEventWithHighestExcessDelay.additionalData) }}>
                                                        {Date.now()}
                                                    </ReactMoment>
                                                </small>
                                            )
                                        )
                                    }
                                    mostRecentAlert={addInsightEventsRecent}
                                    title="AddInsight"
                                    userRole={userRole}
                                    mute={() => this.props.mute(AlertStore.AlertSource.AddInsightSiteAlarm, undefined)}
                                    toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.AddInsightSiteAlarm)}
                                />
                            )}
                            {lightwireDevice && <LightwireRow title="Lightwire" device={lightwireDevice} />}
                            {!!site.scatsSiteId && (
                                <MuteRow
                                    alertSeverityIcon={this.getAlertSeverity(powerSupplyEvents)}
                                    alertSourceState={powerSupplyState}
                                    subText={powerSupplyEvents
                                        .map((alert, i) => (
                                            <small key={alert.alarmId} title={alert.description}>
                                                {alert.additionalData}
                                                {i !== powerSupplyEvents.length - 1 && ", "}
                                            </small>
                                        ))}
                                    mostRecentAlert={powerSupplyEventsRecent}
                                    title="Power Supply Failure"
                                    userRole={userRole}
                                    mute={() => this.props.mute(AlertStore.AlertSource.PowerSupply, undefined)}
                                    toggleMuteControls={() => this.toggleMuteControls(AlertStore.AlertSource.PowerSupply)}
                                />
                            )}
                        </tbody>
                    </table>

                    <div className="blade-section-header pt-4 px-2 pb-2">
                        <h3 className="">Open Logs</h3>
                        <button
                            type="button"
                            disabled={!hasPermission}
                            className="section-icon px-2 btn m-0 shadow-none "
                            onClick={(e) => this.handleOpenLog(e, defaultLog.id)}
                        >
                            <img src={iconAdd} width={15} height={15} alt="Add log icon" />
                        </button>
                    </div>
                    {openCurrentLogs.length > 0 ? (
                        <table>
                            <thead>
                                <tr className="blade-table-header">
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody className="table-body-logs">
                                <SidebarLogTableBody logs={openCurrentLogs} handleOpenLog={this.handleOpenLog} selectedLogId={this.props.selectedLogId} />
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-logs">No open logs found...</div>
                    )}

                    <div className="blade-section-header pt-4 px-2 pb-2">
                        <h3 className="">Closed Logs</h3>
                        <div
                            className="section-icon px-2"
                            onClick={() =>
                                this.setState((e) => ({
                                    filterSystemClosedLogs: !e.filterSystemClosedLogs,
                                }))
                            }
                        >
                            <span className="px-3">User Only</span>
                            <CheckBox checked={filterSystemClosedLogs} />
                        </div>
                    </div>
                    {closedCurrentLogs.length > 0 ? (
                        <table>
                            <thead>
                                <tr className="blade-table-header">
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody className="table-body-logs">
                                <SidebarLogTableBody logs={closedCurrentLogs} handleOpenLog={this.handleOpenLog} selectedLogId={this.props.selectedLogId} />
                            </tbody>
                        </table>
                    ) : filterSystemClosedLogs ? (
                        <div className="no-logs">No closed user logs found...</div>
                    ) : (
                        <div className="no-logs">No closed logs found...</div>
                    )}
                </div>
                {(muteAlertSource || isSiteOperationLoading) && (
                    <Modal closeModal={() => this.toggleMuteControls(undefined)} title="Mute Alert Source" isLoading={isSiteOperationLoading}>
                        <div className="modal-content">
                            {
                                // Time should default to 12AM on the selected day, as per email with John 18/02/2020
                                // i.e. By default, the selected mute date will include up to but not including the mute date
                            }
                            <div className="radio-group" id="mutelength">
                                <div className="checkboxes-container">
                                    <div className="mute-title">Quick Settings</div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment().add(30, "minutes"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment().add(30, "minutes"))} />
                                        <span className="checkbox-label">30 Minutes</span>
                                    </div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment().add(60, "minutes"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment().add(60, "minutes"))} />
                                        <span className="checkbox-label">1 Hour</span>
                                    </div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment().add(1, "day").startOf("day"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment().add(1, "day").startOf("day"))} />
                                        <span className="checkbox-label">1 Day</span>
                                    </div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment().add(3, "days").startOf("day"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment().add(3, "days").startOf("day"))} />
                                        <span className="checkbox-label">3 Days</span>
                                    </div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment().add(7, "days").startOf("day"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment().add(7, "days").startOf("day"))} />
                                        <span className="checkbox-label">7 Days</span>
                                    </div>
                                    <div className="mute-checkbox" onClick={() => this.setMuteUntilDate(moment("9999/12/31", "YYYY/MM/DD"))}>
                                        <CheckBox checked={compareMomentsWithoutSeconds(this.state.muteUntilDate, moment("9999/12/31", "YYYY/MM/DD"))} />
                                        <span className="checkbox-label">Permanently</span>
                                    </div>
                                </div>
                                <div className="datetime-picker-container">
                                    <div className="mute-title">Custom Date Time Picker</div>
                                    <Datetime
                                        open
                                        defaultValue={moment().startOf("day")}
                                        isValidDate={validMuteDate}
                                        className="mute-picker"
                                        value={muteUntilDate}
                                        input={false}
                                        dateFormat={"DD/MM/YYYY"}
                                        onChange={(e) => this.setMuteUntilDate(moment(e))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-actions">
                            <button type="button" className="btn btn-cancel" onClick={() => this.toggleMuteControls(undefined)}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-save" disabled={!muteUntilDate} onClick={this.muteAlertSource}>
                                Save
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}
