import * as React from 'react';
import './controls.scss';

interface TextSettingControlProps {
    settingTitle: string;
    explanation?: string;
}

interface TextSettingControlState {
    showExplanation?: boolean;
}

export class SettingControlWrapper extends React.Component<TextSettingControlProps, TextSettingControlState> {

    constructor(props: TextSettingControlProps) {
        super(props);
        this.state = { showExplanation: true }
    }

    public render() {
        const { settingTitle, explanation } = this.props;
        const { showExplanation } = this.state;
        return (
            <div className="setting-container">
                <div className="setting">
                    <div className={"setting-title" + (explanation ? " has-explanation" : "")}>
                        <div>{settingTitle}</div>
                        {
                            explanation && showExplanation &&
                            <div className="explanation">
                                {explanation}
                            </div>
                        }
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default SettingControlWrapper;