import "whatwg-fetch";
import { Reducer, AnyAction, Action } from "redux";
import { LatLng } from "leaflet";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { AppThunkDispatch, ApplicationState } from ".";
import { CloseFilterPanelAction, CloseSitePanelAction } from "./sites";
import { CloseSchoolSignPanelAction } from "./schoolSigns";
import { CloseLinkPanelAction } from "./addInsight";
import { CloseCycleCounterDevicePanelAction } from "./cycleCounterDevices";
import { ClosePumpPanel } from "./Pumps/pumpsDatatypes";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MapState {
    //Lat/Long
    center?: [number, number];
    zoom: number;
    maxZoom: number;
}

export interface TDAPMap {
    id: number;
    description: string;
    latitude: number;
    longitude: number;
    isLoading: boolean;
    openLogIds: number[];
    currentAlertIds: number[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetZoomAction extends Action {
    type: "SET_ZOOM";
    zoom: number;
}

export interface SetCenterAction extends Action {
    type: "SET_CENTER";
    center: [number, number];
}

export interface FlyToAction extends Action {
    type: "FLY_TO";
    latLng: LatLng;
}

export interface CloseSidePanelsAction extends Action {
    type: "CLOSE_ALL_SIDE_PANELS";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetZoomAction | SetCenterAction | FlyToAction | CloseSidePanelsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setZoom: (zoom: number) => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: zoom });
        }),
    setCenter: (center: [number, number]) => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<SetCenterAction>{ type: "SET_CENTER", center: center });
        }),
    flyTo: (latLng: LatLng) => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<FlyToAction>{ type: "FLY_TO", latLng: latLng });
        }),
    closeAllSidePanelsAction: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const {
                addInsight: { selectedLinkId },
                sites: { showFilterSidebar, selectedSiteId },
                schoolSigns: { selectedSchoolSignId },
                cycleCounterDevices: { cycleCounterDevices },
                pumpDevices: { selectedPumpId }
            } = getState();

            if (selectedSiteId) {
                dispatch(<CloseSitePanelAction>{ type: "CLOSE_SITE_PANEL" });
            }
            if (showFilterSidebar) {
                dispatch(<CloseFilterPanelAction>{ type: "CLOSE_FILTER_PANEL" });
            }
            if (selectedLinkId) {
                dispatch(<CloseLinkPanelAction>{ type: "CLOSE_LINK_PANEL" });
            }
            if (selectedSchoolSignId) {
                dispatch(<CloseSchoolSignPanelAction>{ type: "CLOSE_SCHOOL_SIGN_PANEL" });
            }
            if (cycleCounterDevices) {
                dispatch(<CloseCycleCounterDevicePanelAction>{ type: "CLOSE_CYCLE_COUNTER_PANEL" });
            }
            if (selectedPumpId) {
                dispatch(<ClosePumpPanel>{ type: "CLOSE_PUMP_PANEL" });
            }
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MapState = { maxZoom: 18, zoom: 12 };

export const reducer: Reducer<MapState, AnyAction> = (state: MapState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_ZOOM":
            return {
                ...state,
                zoom: action.zoom,
            };
        case "SET_CENTER":
            return {
                ...state,
                center: action.center,
            };
        case "FLY_TO":
            return {
                ...state,
                center: [action.latLng.lat, action.latLng.lng],
            };
        case "CLOSE_ALL_SIDE_PANELS":
            return {
                ...state,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
