import React, { TextareaHTMLAttributes, useState } from "react";
import { Comment } from "store/comments";
import "./commentEditor.scss";
import Modal from "../modal/modal";
import { AuthState } from "store/authentication";

const MAX_CHAR_LIMIT = 1000;

export interface CommentEditorProps {
    commentToEdit: Comment;
    closeCommentEditor: () => void;
    deleteComment: () => void;
    saveComment: (content: string) => void;
    isLoading: boolean;
    authentication: AuthState;
}

const CommentEditor: React.FC<CommentEditorProps> = (props) => {
    const { commentToEdit, isLoading } = props;
    const [content, setContent] = useState(commentToEdit.content ?? "");

    const checkEditingRights = () => {
        //userOid is not null, so use userOid to check permissions
        if (commentToEdit.userOid) {
            if (props.authentication.userOid?.toLowerCase() === commentToEdit.userOid?.toLowerCase()) {
                return true;
            }
            else {
                return false;
            }
        }
        //userOid is empty and username isn't, so use username to check permissions
        else if (commentToEdit.userName) {
            if (props.authentication.userDisplayName?.toLowerCase() === commentToEdit.userName.toLowerCase()) {
                return true;
            }
            else {
                return false;
            }
        }
        //userOid and userName are empty, so comment must be new - old (before the schema change)
        else {
            return true;
        }
    };

    const canEdit = checkEditingRights();
    const isOverCharLimit = content.length > MAX_CHAR_LIMIT;

    const handleCommentClose = () => {
        props.closeCommentEditor();
        setContent("");
    };

    const handleCommentUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
    };

    const handleCommentSave = () => {
        props.saveComment(content);
        setContent("");
    };

    const handleCommentDelete = () => {
        props.deleteComment();
        setContent("");
    };

    return (
        <Modal
            closeModal={handleCommentClose}
            title="Edit Comment"
            isLoading={isLoading}
        >
            <div className="modal-content">
                {props.commentToEdit.userName && <div className="text-light"><strong>Created by: {props.commentToEdit.userName}</strong></div>}
                <textarea
                    value={content}
                    onChange={handleCommentUpdate}
                    placeholder="Comments"
                    className="form-control my-2"
                    rows={10}
                    disabled={!canEdit}
                ></textarea>
                <div className="comment-lower">
                    <span className="comment-error text-danger">
                        {!canEdit && "You do not have access to edit or delete this comment..."}
                        {isOverCharLimit && `You are over the character limit. Please use less than ${MAX_CHAR_LIMIT} characters...`}
                    </span>
                    <span className={"char-count" + (isOverCharLimit ? " text-danger" : "")}>{content.length} / {MAX_CHAR_LIMIT}</span>
                </div>
            </div>
            <div className="modal-actions">
                <button type="button" className="btn btn-cancel" onClick={handleCommentClose} >Cancel</button>
                {commentToEdit.id !== -1 && (
                    <button type="button" className="btn btn-danger" onClick={handleCommentDelete} disabled={!canEdit}>Delete</button>
                )}
                <button type="button" disabled={!canEdit || isOverCharLimit || !content.length} className="btn btn-save" onClick={handleCommentSave}>Save</button>
            </div>
        </Modal>
    );
};
export default CommentEditor;