//Adapted from https://github.com/produtoreativo/react-leaflet-googlemutant to support React 16
//https://github.com/produtoreativo/react-leaflet-googlemutant/blob/master/LICENSE

import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";
import { MapLayer, withLeaflet, MapLayerProps } from "react-leaflet";

export type GoogleMutantProps = MapLayerProps &
    L.gridLayer.GoogleMutantOptions & {
        trafficLayer?: boolean;
    };

export class GoogleMutant extends MapLayer<GoogleMutantProps> {
    createLeafletElement(props: GoogleMutantProps) {
        const googleMutant = L.gridLayer.googleMutant(props);
        if (this.props.trafficLayer) {
            googleMutant.addGoogleLayer("TrafficLayer");
        }
        return googleMutant;
    }
}

export default withLeaflet(GoogleMutant);
