import "whatwg-fetch";
import { Reducer, AnyAction, Action } from "redux";
import { AppThunkAction, AppThunkDispatch, ApplicationState } from ".";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import moment from "moment";
import { HttpError } from "@microsoft/signalr";
import { Site } from "./sites";

export interface UserSettingsState {
    isLoading: boolean;
    hasLoaded: boolean;
    userSettings: UserSettingsPayload;
}

export interface UserSettingsPayload {
    scatsEnabled?: boolean;
    schoolSignsEnabled?: boolean;
    cycleCountersEnabled?: boolean;
    addInsightEnabled?: boolean;
    busesEnabled?: boolean;
    kmzEnabled?: boolean;
    pumpsEnabled?: boolean;
    hideUnreadLogsBefore?: moment.Moment;
    mapIndex: number;
    cycleCounterDevicesEnabled: boolean;
}

const hideSitePanelIfRequired = (dispatch: AppThunkDispatch, site: Site | null, newScatsToggleState: boolean, newAddInsightToggleState: boolean) => {
    if (site) {
        //Is both SCATS & AddInsight site
        if (!!site.scatsSiteId && !!site.addInsightSiteId) {
            //both are set to off
            if (!newAddInsightToggleState && !newScatsToggleState) {
                dispatch({ type: "CLOSE_SITE_PANEL" });
            }
        }
        //Is just SCATS site
        else if (!!site.scatsSiteId && !site.addInsightSiteId) {
            if (!newScatsToggleState) {
                dispatch({ type: "CLOSE_SITE_PANEL" });
            }
        }
        //Is just AddInsight site
        else if (!!site.addInsightSiteId && !site.scatsSiteId) {
            if (!newAddInsightToggleState) {
                dispatch({ type: "CLOSE_SITE_PANEL" });
            }
        }
    }
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getUserSettings: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/UserSettings`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data: UserSettingsPayload) => {
                    dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: data });
                    dispatch({ type: "UPDATE_SCATS_FILTER" });
                    dispatch({ type: "UPDATE_ADDINSIGHT_FILTER" });
                    dispatch({ type: "UPDATE_BUSNETWORK_FILTER" });
                    dispatch({ type: "UPDATE_SCHOOLSIGN_FILTER" });
                    dispatch({ type: "UPDATE_KMZ_FILTER" });
                    dispatch({ type: "UPDATE_CYCLE_COUNTER_FILTER" });
                    dispatch({ type: "UPDATE_HIDE_UNREAD_LOGS_BEFORE" });
                    ClearBackendErrorToast(ToastErrorTypes.USER_SETTINGS);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_USERS_SETTINGS" });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.USER_SETTINGS, "Error retrieving user settings");
                });
            dispatch({ type: "REQUEST_USER_SETTINGS" });
        });
    },
    toggleSCATS: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
            sites: { sites, selectedSiteId },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, scatsEnabled: !userSettings.scatsEnabled };
            const selectedSite = selectedSiteId ? sites[selectedSiteId] : null;
            hideSitePanelIfRequired(dispatch, selectedSite, updatedUserSettings.scatsEnabled, !!updatedUserSettings.addInsightEnabled);
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_SCATS_FILTER", userSettings: updatedUserSettings });
        });
    },
    toggleAddInsight: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
            sites: { sites, selectedSiteId },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, addInsightEnabled: !userSettings.addInsightEnabled };
            const selectedSite = selectedSiteId ? sites[selectedSiteId] : null;
            hideSitePanelIfRequired(dispatch, selectedSite, !!updatedUserSettings.scatsEnabled, updatedUserSettings.addInsightEnabled);
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_ADDINSIGHT_FILTER", userSettings: updatedUserSettings });
        });
    },
    toggleCycleCounters: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, cycleCountersEnabled: !userSettings.cycleCountersEnabled };

            // dispatch({ type: "CLOSE_ALL_SIDE_PANELS" });
            dispatch({ type: "CLOSE_CYCLE_COUNTER_PANEL" });
            dispatch({ type: "CLOSE_SCHOOL_SIGN_PANEL" });
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_CYCLECOUNTER_FILTER", userSettings: updatedUserSettings });
        });
    },
    toggleBusNetwork: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, busesEnabled: !userSettings.busesEnabled };
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_BUSNETWORK_FILTER", userSettings: updatedUserSettings });
        });
    },
    toggleMapIndex:
        (index: number): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    userSettings: { isLoading, userSettings },
                    authentication: { accessToken },
                } = getState();
                if (isLoading || !accessToken) return;

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    const updatedUserSettings = { ...userSettings, mapIndex: index };
                    dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
                    saveUserSettings(updatedUserSettings, accessToken);
                });
            },
    toggleSchoolSigns: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, schoolSignsEnabled: !userSettings.schoolSignsEnabled };
            dispatch({ type: "CLOSE_ALL_SIDE_PANELS" });
            dispatch({ type: "CLOSE_CYCLE_COUNTER_PANEL" });
            dispatch({ type: "CLOSE_SCHOOL_SIGN_PANEL" });
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_SCHOOLSIGN_FILTER", userSettings: updatedUserSettings });
        });
    },
    toggleKmz: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, kmzEnabled: !userSettings.kmzEnabled };
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_KMZ_FILTER", userSettings: updatedUserSettings });
        });
    },
    togglePumps: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, pumpsEnabled: !userSettings.pumpsEnabled };
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_PUMP_FILTER", userSettings: updatedUserSettings });
        });
    },
    updatehideUnreadLogsBefore: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            userSettings: { isLoading, userSettings },
            authentication: { accessToken },
        } = getState();
        if (isLoading || !accessToken) return;

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            const updatedUserSettings = { ...userSettings, hideUnreadLogsBefore: moment() };
            dispatch({ type: "RECEIVE_USER_SETTINGS", userSettings: updatedUserSettings });
            saveUserSettings(updatedUserSettings, accessToken);
            dispatch({ type: "UPDATE_HIDE_UNREAD_LOGS_BEFORE", userSettings: updatedUserSettings });
        });
    },
};

const saveUserSettings: (userSettings: UserSettingsPayload, jwtIdToken: string) => Promise<UserSettingsPayload> = (
    userSettings: UserSettingsPayload,
    jwtIdToken: string
) =>
    new Promise((resolve, reject) => {
        fetch(`/api/UserSettings`, {
            method: "PUT",
            body: JSON.stringify(userSettings),
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtIdToken}`,
            }),
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                }
            })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface ReceiveSettingsAction extends Action {
    type: "RECEIVE_USER_SETTINGS";
    //The value of the component that will be rendered
    userSettings: UserSettingsPayload;
    //dateTime
}
interface RequestSettingsAction extends Action {
    type: "REQUEST_USER_SETTINGS";
}


interface UpdateSCATSFilterAction extends Action {
    type: "UPDATE_SCATS_FILTER";
}

interface UpdateAddInsightFilterAction extends Action {
    type: "UPDATE_ADDINSIGHT_FILTER";
}

interface UpdateCycleCounterFilterAction extends Action {
    type: "UPDATE_CYCLECOUNTER_FILTER";
}

interface UpdateBusNetworkFilterAction extends Action {
    type: "UPDATE_BUSNETWORK_FILTER";
}

interface UpdateSchoolSignFilterAction extends Action {
    type: "UPDATE_SCHOOLSIGN_FILTER";
}

interface UpdateKmzFilterAction extends Action {
    type: "UPDATE_KMZ_FILTER";
}

interface UpdatePumpFilterAction extends Action {
    type: "UPDATE_PUMP_FILTER";
}
interface UpdateHideUnreadLogsBeforeAction extends Action {
    type: "UPDATE_HIDE_UNREAD_LOGS_BEFORE";
}

interface ErrorUserSettingsAction extends Action {
    type: "ERROR_RECEIVING_USERS_SETTINGS";
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | ReceiveSettingsAction
    | RequestSettingsAction
    | UpdateSCATSFilterAction
    | UpdateAddInsightFilterAction
    | UpdateCycleCounterFilterAction
    | UpdateBusNetworkFilterAction
    | ErrorUserSettingsAction
    | UpdateHideUnreadLogsBeforeAction
    | UpdateSchoolSignFilterAction
    | UpdateKmzFilterAction
    | UpdatePumpFilterAction;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: UserSettingsState = {
    isLoading: false,
    hasLoaded: false,
    userSettings: {
        scatsEnabled: true,
        addInsightEnabled: true,
        busesEnabled: false,
        schoolSignsEnabled: false,
        pumpsEnabled: false,
        kmzEnabled: false,
        mapIndex: 3,
        cycleCountersEnabled: false,
        cycleCounterDevicesEnabled: false,
    },
};

export const reducer: Reducer<UserSettingsState, AnyAction> = (state: UserSettingsState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_USER_SETTINGS":
            return <UserSettingsState>{
                ...state,
                hasLoaded: true,
                isLoading: false,
                userSettings: action.userSettings,
            };
        case "REQUEST_USER_SETTINGS":
            return <UserSettingsState>{
                ...state,
                hasLoaded: false,
                isLoading: true,
            };
        case "UPDATE_SCATS_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };
        case "UPDATE_ADDINSIGHT_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };
        case "UPDATE_BUSNETWORK_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };
        case "UPDATE_SCHOOLSIGN_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };
        case "UPDATE_CYCLECOUNTER_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };
        case "UPDATE_KMZ_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };

        case "UPDATE_PUMP_FILTER":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };

        case "ERROR_RECEIVING_USERS_SETTINGS":
            return <UserSettingsState>{
                ...state,
                hasLoaded: true,
                isLoading: false,
            };
        case "UPDATE_HIDE_UNREAD_LOGS_BEFORE":
            if (!state.hasLoaded) {
                break;
            }
            return <UserSettingsState>{
                ...state,
            };

        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
