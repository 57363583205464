import React from "react";
import ReactMoment from "react-moment";
import {
    addInsightLinkColor0, addInsightLinkColor1, addInsightLinkColor2, addInsightLinkColor3, addInsightLinkColor4, addInsightLinkColor5, addInsightLinkColor6, addInsightLinkColor7
} from "../../../constants/addInsightLinkConstants";
import * as AddInsightStore from "store/addInsight";
import { iconClose } from "../../images";
import GraphWithLegend from "../../map/graph/graphWithLegend";
import Spinner from "../../spinner/spinner";
import "../sidebar.scss";
import "../infoPanels/sidebarLinkInformation.scss";
const reloadIntervalInMilliseconds = 30000;

export interface LinkInformationProps {
    link: AddInsightStore.AddInsightLink;
    graphData?: AddInsightStore.AddInsightGraphData;
    refreshGraphData: (linkId: number) => void;
    closeLinkPanel: () => void;
}

export default class SidebarLinkInformation extends React.Component<LinkInformationProps> {
    private timerRefreshGraph?: NodeJS.Timer;

    private GetColorStyle = (score: number) => {
        const style = { color: addInsightLinkColor0, borderColor: addInsightLinkColor0 };

        switch (score) {
            case 0:
                return { ...style, color: addInsightLinkColor0, borderColor: addInsightLinkColor0 };
            case 1:
                return { ...style, color: addInsightLinkColor1, borderColor: addInsightLinkColor1 };
            case 2:
                return { ...style, color: addInsightLinkColor2, borderColor: addInsightLinkColor2 };
            case 3:
                return { ...style, color: addInsightLinkColor3, borderColor: addInsightLinkColor3 };
            case 4:
                return { ...style, color: addInsightLinkColor4, borderColor: addInsightLinkColor4 };
            case 5:
                return { ...style, color: addInsightLinkColor5, borderColor: addInsightLinkColor5 };
            case 6:
                return { ...style, color: addInsightLinkColor6, borderColor: addInsightLinkColor6 };
            case 7:
                return { ...style, color: addInsightLinkColor7, borderColor: addInsightLinkColor7 };
        }
        return style;
    };

    componentDidMount() {
        this.timerRefreshGraph = setInterval(() => {
            if (this.props.link) {
                this.props.refreshGraphData(this.props.link.linkId);
            }
        }, reloadIntervalInMilliseconds);
    }

    componentWillUnmount() {
        if (this.timerRefreshGraph) {
            clearInterval(this.timerRefreshGraph);
        }
    }

    public render() {
        const { link, graphData } = this.props;

        const score = link.enoughData ? link.score : 0;

        return (
            <div className="nav-blade">
                <div className="blade-header">
                    <div className="blade-header-icon">
                        {link.score >= 0 && (
                            <div
                                className="add-insight-score"
                                title={"AddInsight link score of " + score}
                                style={this.GetColorStyle(score)}
                            >
                                {score}
                            </div>
                        )}
                    </div>
                    <div className="blade-header-title">
                        <h1>AddInsight Link {link.linkId}</h1>
                        <h2>{link.name}</h2>
                    </div>
                    <div className="blade-header-close">
                        <button
                            type="button"
                            className="btn p-0 m-0"
                            onClick={() => this.props.closeLinkPanel()}
                        >
                            <img
                                src={iconClose}
                                className="align-top"
                                width={15}
                                height={15}
                                alt="Close icon"
                            />
                        </button>
                    </div>
                </div>
                <div className="blade-body">
                    <h3 className="blade-body-header">Link Statistics Chart</h3>
                    <div className="graph-container">
                        {graphData ? (
                            <GraphWithLegend
                                actual={graphData.actual}
                                expected={graphData.expected}
                                bt={graphData.bt}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </div>

                    <h3 className="blade-body-header">Link Statistics</h3>
                    <table className="w-100">
                        <thead>
                            <tr className="blade-table-header">
                                <th>Details</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {link.excessDelay != null && <tr className="blade-table-body"><td>Excess Delay</td><td>{link.excessDelay >= 1 ? <ReactMoment durationFromNow subtract={{ seconds: Number(link.excessDelay) }}>{Date.now()}</ReactMoment> : "0:00"} </td></tr>}
                            {link.score != null && <tr className="blade-table-body"><td>Score</td><td>{link.score}</td></tr>}
                            {link.congestion != null && <tr className="blade-table-body"><td>Congestion</td><td>{link.congestion}</td></tr>}
                            {link.travelTime != null && <tr className="blade-table-body"><td>Travel Time</td><td><ReactMoment durationFromNow subtract={{ seconds: Number(link.travelTime) }}>{Date.now()}</ReactMoment></td></tr>}
                            {link.delay != null && <tr className="blade-table-body"><td>Delay</td><td><ReactMoment durationFromNow subtract={{ seconds: Number(link.delay) }}>{Date.now()}</ReactMoment></td></tr>}
                            {link.speed != null && <tr className="blade-table-body"><td>Speed</td><td>{link.speed} km/h</td></tr>}
                            {link.flowRestrictionScore != null && <tr className="blade-table-body"><td>Flow Restriction Score</td><td>{link.flowRestrictionScore}</td></tr>}
                            {link.lastUpdated != null && <tr className="blade-table-body"><td>Last Updated</td><td><ReactMoment date={link.lastUpdated} format="DD-MM-YYYY" /><br /><ReactMoment date={link.lastUpdated} format="hh:mm:ss A" /></td></tr>}
                            {link.enoughData != null && <tr className="blade-table-body"><td>Enough Data</td><td>{link.enoughData.toString()}</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
