import * as React from "react";
import { useSelector, useStore, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import { actionCreators as appSettingActionCreators } from "store/appSettings";
import { useEffect } from "react";
import { AuthenticationState } from 'react-aad-msal';
import { MsalAuthFactory } from "store/middleware/msal";
import Login from "../components/login/login";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { history } from '../index'
import { BackendErrorToast, ToastErrorTypes, ClearBackendErrorToast } from "../utils/toast";

const reactPlugin = new ReactPlugin();

const SecureApp: React.FC = (props) => {
    const store = useStore();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appSettingActionCreators.getAppSettings())
    }, [store, dispatch]);

    const applicationInsightKey = useSelector<ApplicationState, string | undefined>(state => state.appSettings?.appSettings?.applicationInsightsKey);

    useEffect(() => {
        if (applicationInsightKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: applicationInsightKey,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: history }
                    }
                }
            });
            appInsights.loadAppInsights();
            console.log("Loaded application insights...")
        }
    }, [applicationInsightKey]);

    const authProviderReady = useSelector<ApplicationState, boolean>(state => state.authentication.authProviderFactoryReady);
    const authState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication.authState);

    if (authProviderReady) {
        const authProvider = MsalAuthFactory.getAuthProvider();

        if (!authProvider) {
            throw Error("Auth Provider in ready state should not return null");
        }

        authProvider.registerReduxStore(store);

        switch (authState) {
            case AuthenticationState.Authenticated:
                ClearBackendErrorToast(ToastErrorTypes.UNAUTHENTICATED)
                return (
                    <div className="secure-app">
                        {props.children}
                    </div>);
            case AuthenticationState.Unauthenticated:
                if (authProvider.getError()?.errorMessage?.startsWith("AADSTS50105")) {
                    BackendErrorToast(ToastErrorTypes.UNAUTHENTICATED, "Unauthorised", "The user you are trying to sign in with does not have permission to use this application...")
                }
                return (
                    <div className="secure-app">
                        <Login showLogin={true} showFooter={false} login={authProvider.login} />
                    </div>
                )
            case AuthenticationState.InProgress:
                return (
                    <div className="secure-app">
                        <Login showLogin={false} showFooter={true} login={authProvider.login} />
                    </div>)
        }
    }
    return (<div className="secure-app">
        <Login showLogin={false} showFooter={true} />
    </div>)
}

export default withAITracking(reactPlugin, SecureApp);