import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { actionCreators as adminSettingActionCreators, AdminSettingsPayload } from "store/adminSettings";
import { actionCreators as sitesActionCreators } from "store/sites";
import { useEffect } from "react";
import IconGuide from "../components/iconGuide/iconGuide";
import Spinner from "../components/spinner/spinner";

const IconGuideContainer: React.FC = () => {
    const dispatch = useDispatch();
    const authApiReady = useSelector<ApplicationState, boolean>(state => state.authentication.authApiReady);
    const adminSettings = useSelector<ApplicationState, AdminSettingsPayload | undefined>(state => state.adminSettings.adminSettings);

    useEffect(() => {
        if (authApiReady) {
            dispatch(adminSettingActionCreators.getAdminSettings());
            dispatch(sitesActionCreators.requestAllSites());
        }
    }, [authApiReady, dispatch]);

    return (adminSettings ?
        <IconGuide
            portalSettings={adminSettings.portalSettings}
        /> :
        <Spinner dim />
    );
}

export default IconGuideContainer;