// Signal R middleware based on https://medium.com/@lucavgobbi/signalr-react-redux-5a100a226871
import { AppMiddlewareAPI, StoreActions, AppThunkDispatch } from "..";
import { AnyAction, Dispatch } from "redux";

import * as SiteStore from "../sites";
import * as AlertStore from "../alarm";
import * as AddInsightStore from "../addInsight";
import * as BusStore from "../buses";
import * as SchoolSignStore from "../schoolSigns";
import { setInterval, clearInterval } from "timers";
import debounce from "lodash/debounce";
import { startCycleCounters } from "./signalR";
import { fetchAllData } from "containers/home";

let _busInterval: NodeJS.Timer;
let _linkInterval: NodeJS.Timer;
let _schoolSignInterval: NodeJS.Timer;
let _otherInterval: NodeJS.Timer;
let _fallBackActive: boolean = false;

export const middleware = (api: AppMiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: StoreActions) => {
  switch (action.type) {
    case "UPDATE_BUSNETWORK_FILTER":
      startBuses(api.dispatch, api.getState);
      break;
    case "UPDATE_ADDINSIGHT_FILTER":
      startAddInsightLinks(api.dispatch, api.getState);
      break;
    case "UPDATE_SCHOOLSIGN_FILTER":
      startSchoolSigns(api.dispatch, api.getState);
      break;
    case "UPDATE_CYCLECOUNTER_FILTER":
      startCycleCounters(api.dispatch, api.getState);
      break;
  }
  return next(action);
};

export const startBuses = (dispatch: AppThunkDispatch, stateFunction: any) => {
  if (_fallBackActive) {
    const state = stateFunction();
    if (state.userSettings.userSettings.busesEnabled) {
      const updateHandler = debounce(() => {
        dispatch(BusStore.actionCreators.requestAllBuses());
      }, 1500);

      _busInterval = setInterval(updateHandler, 6000);
      updateHandler();
    } else {
      clearInterval(_busInterval);
    }
  }
};

export const startAddInsightLinks = (dispatch: AppThunkDispatch, stateFunction: any) => {
  if (_fallBackActive) {
    const state = stateFunction();
    if (state.userSettings.userSettings.addInsightEnabled) {
      const updateHandler = debounce(() => {
        dispatch(AddInsightStore.actionCreators.requestAllLinks());
        dispatch(SiteStore.actionCreators.requestAllSites());
        dispatch(AlertStore.actionCreators.requestAllAlerts());
      }, 1500);

      _linkInterval = setInterval(updateHandler, 30000);
      updateHandler();
    } else {
      clearInterval(_linkInterval);
    }
  }
};

export const startSchoolSigns = (dispatch: AppThunkDispatch, stateFunction: any) => {
  if (_fallBackActive) {
    const state = stateFunction();
    if (state.userSettings.userSettings.schoolSignsEnabled) {
      const updateHandler = debounce(() => {
        dispatch(SchoolSignStore.actionCreators.requestAllSchoolSigns());
      }, 1500);

      _schoolSignInterval = setInterval(updateHandler, 30000);

      updateHandler();
    } else {
      clearInterval(_schoolSignInterval);
    }
  }
};

export const startTimerUpdates = (dispatch: AppThunkDispatch, stateFunction: any, callback?: () => void) => {
  _fallBackActive = true;
  startBuses(dispatch, stateFunction);
  startAddInsightLinks(dispatch, stateFunction);

  const updateHandler = debounce(() => {
    fetchAllData(dispatch);
  }, 1500);

  _otherInterval = setInterval(updateHandler, 60000);
  updateHandler();

  //calls callback if one exists
  callback && callback();
};

export const stopTimerUpdates = () => {
  clearInterval(_busInterval);
  clearInterval(_linkInterval);
  clearInterval(_otherInterval);
  clearInterval(_schoolSignInterval);
};
