import "./cycleCounterGraph.scss";
import React, { useEffect, useState } from "react";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import { ApplicationState } from "store";
import moment from 'moment'
import { useSelector } from "react-redux";
import { CycleCounterDetectionsState } from "store/cycleCounterDetections";
import _ from "lodash";
import { cycleCounterHelper, GraphData } from "./../../sidebar/cycleCounterHelper";

export interface GraphProps {
    deviceId: string | null | undefined;
}

const getStyle = () => {
    return {
        parent: {
            background: "#fffff",
            boxSizing: "border-box",
            display: "inline",
            padding: 0,
            fontFamily: "'Fira Sans', sans-serif",
        },
        data: { fill: "#5a278c" },
        labels: {
            fontSize: 15,
            fill: "#FFF"
        }
    };
};

export const CycleCounterGraph = (props: GraphProps) => {
    const [data, setData] = useState<GraphData[]>([]);
    const detections = useSelector<ApplicationState, CycleCounterDetectionsState>((state) => state.cycleCounterDetections);
    const selectedId = useSelector<ApplicationState, string | undefined>((state) => state.cycleCounterDevices.selectedCycleCounterDeviceId);

    const xAxisDates = _.range(7)
        .map(x => moment(new Date().setDate(new Date().getDate() - x)).format('yyyy-MM-DD'))
        .reverse();

    useEffect(() => {
        if (selectedId) {
            const summary: GraphData[] = cycleCounterHelper.getSummary(detections, selectedId);
            setData(summary.filter(x => xAxisDates.includes(x.x)));
        }
    }, [selectedId, detections, xAxisDates]);

    return (
        <>
            <div className="cycleCounterGraph">
                <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={{ x: 10, y: 30 }}
                    height={300}
                    padding={{ top: 20, bottom: 80, left: 40, right: 20 }}
                    containerComponent={
                        <VictoryVoronoiContainer voronoiDimension="x"
                            labels={({ datum }) => `${datum.y}`}
                            labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "grey" }} />
                            }
                        />
                    }
                >
                    {/*Y axis*/}
                    <VictoryAxis
                        dependentAxis
                        orientation="left"
                        standalone={false}
                        style={{
                            axisLabel: { padding: 50, fill: "#FFF" },
                            tickLabels: { fill: "#FFF" },
                        }}
                        label={"Count"}
                    />

                    {/*X axis*/}
                    <VictoryAxis
                        standalone={false}
                        tickValues={xAxisDates}
                        tickFormat={(x: string) => `${new Date(x).toString().split(" ").splice(0, 3).join("-")}`}
                        tickLabelComponent={<VictoryLabel angle={90} verticalAnchor="middle" textAnchor="start" />}
                        style={{
                            tickLabels: { fill: "#FFF" },
                            axisLabel: { padding: 50, fill: "#FFF" },
                        }}
                        fixLabelOverlap
                    />
                    <VictoryBar
                        style={getStyle()}
                        data={data}
                        width={280}
                        height={350}
                        
                    />
                </VictoryChart>
            </div>
        </>
    );
};
