import React, { CSSProperties } from "react";
import ReactMoment from "react-moment";
import { Log } from "store/logs";
import "./sidebar.scss";
import "./infoPanels/sidebarSiteInformation.scss";

export interface SidebarLogTableBodyProps {
    logs: Log[];
    handleOpenLog: (
        event: React.MouseEvent<
            HTMLAnchorElement | HTMLTableRowElement | HTMLButtonElement,
            MouseEvent
        >,
        id: number
    ) => void;
    selectedLogId?: number;
}

const hasTitleStyleTr: CSSProperties = {
    borderBottom: "none",
    paddingBottom: "0",
};

export default class SidebarLogTableBody extends React.Component<SidebarLogTableBodyProps> {
    public render() {
        const { logs, handleOpenLog, selectedLogId } = this.props;
        return (
            logs &&
            logs
                .sort(function compare(a: Log, b: Log) {
                    const dateA = new Date(a.dateCreated);
                    const dateB = new Date(b.dateCreated);
                    return dateB.getTime() - dateA.getTime();
                })
                .map((element, index) => {
                    const tableRows: JSX.Element[] = [];
                    tableRows.push(
                        <tr
                            key={element.id}
                            className={
                                "blade-table-body blade-log-row" +
                                (element.unread ? " unread" : "") +
                                (element.id === selectedLogId ? " selected-log" : "")
                            }
                            onClick={(
                                e: React.MouseEvent<
                                    HTMLAnchorElement | HTMLTableRowElement | HTMLButtonElement,
                                    MouseEvent
                                >
                            ) => handleOpenLog(e, element.id)}
                            style={element.title ? hasTitleStyleTr : {}}
                        >
                            <td style={element.title ? hasTitleStyleTr : {}}>{element.logType}</td>
                            <td style={element.title ? hasTitleStyleTr : {}}>
                                <ReactMoment format="DD/MM/YY hh:mm A" date={element.dateCreated} />
                            </td>
                            <td style={element.title ? hasTitleStyleTr : {}}>
                                <ReactMoment from={element.dateCreated} ago to={new Date()} />
                            </td>
                        </tr>
                    );
                    if (element.title) {
                        tableRows.push(
                            <tr
                                key={element.id + "_title"}
                                className={
                                    "blade-table-body blade-log-row log-title" +
                                    (element.unread ? " unread" : "") +
                                    (element.id === selectedLogId ? " selected-log" : "")
                                }
                                onClick={(
                                    e: React.MouseEvent<
                                        HTMLAnchorElement | HTMLTableRowElement | HTMLButtonElement,
                                        MouseEvent
                                    >
                                ) => handleOpenLog(e, element.id)}
                            >
                                <td colSpan={3}>
                                    <div>
                                        <strong>{element.title}</strong>
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                    return tableRows;
                })
        );
    }
}
