import { toast, ToastOptions } from "react-toastify";
import { debounce } from "lodash";
import React from "react";
import "../css/toast.scss";

const sendDebouncedToast = debounce(
  (heading: string, body?: string, options?: ToastOptions) => {
    sendToast(heading, body, options);
  },
  5000,
  { leading: true, trailing: false }
);

const sendToast = (heading: string, body?: string, options?: ToastOptions) => {
  toast(
    <div>
      <b>{heading}</b>
      {body && <div>{body}</div>}
    </div>,
    options
  );
};

export function BackendErrorToast(toastId: ToastErrorTypes, heading?: string, body?: string) {
  sendToast(heading || "Error connecting to TDAP Backend Services", body || "Please try refreshing your browser...", {
    autoClose: false,
    toastId: toastId,
    type: "error",
  });
}

export function ClearBackendErrorToast(toastId: ToastErrorTypes) {
  toast.dismiss(toastId);
}

export function SendToast(heading: string, body?: string, options?: ToastOptions) {
  sendToast(heading, body, options);
}

export function SendDebouncedToast(heading: string, body?: string, options?: ToastOptions) {
  sendDebouncedToast(heading, body, options);
}

export enum ToastErrorTypes {
  "ADDINSIGHT_GRAPH",
  "ADDINSIGHT_LINKS",
  "ADMIN_SETTINGS",
  "ADMIN_SETTINGS_SAVE",
  "ALERTS",
  "APP_SETTINGS",
  "COMMENTS",
  "COMMENTS_SAVE",
  "COMMENTS_DELETE",
  "LOGS",
  "LOGS_SAVE",
  "LOGS_DELETE",
  "LOGS_REOPEN",
  "DISCONNECTED",
  "SITES",
  "STATUS",
  "MUTE",
  "USER_SETTINGS",
  "BUS",
  "UNAUTHENTICATED",
  "INSUFFICIENT_PERMISSIONS",
  "SCHOOL_SIGNS",
  "CYCLE_COUNTER_DEVICES",
  "CYCLE_COUNTER_DETECTIONS",
  "LIGHTWIRE_DEVICES",
  "PUMP_DEVICES",
}
