import * as React from 'react';
import { iconOpen, iconCollapse } from '../images/index';
import { maxMobileWidth } from '../../constants/mobileConstants';

interface AdminSettingSectionProps {
    sectionTitle: string;
    sectionSummary: string;
}
interface AdminSettingSectionState {
    showSection: boolean;
}

export class AdminSettingSection extends React.Component<AdminSettingSectionProps, AdminSettingSectionState> {


    constructor(props: AdminSettingSectionProps) {
        super(props);
        this.state = { showSection: window.innerWidth >= maxMobileWidth }
    }



    public render() {
        const { sectionTitle, sectionSummary } = this.props;
        const { showSection } = this.state;
        const handleToggleSection = () => this.setState((e) => ({
            showSection: !e.showSection
        }));

        return (
            <div className="settings-section">
                <div className="toggle-section">
                    <div onClick={handleToggleSection}>
                        <h3 className="settings-section-title">{sectionTitle}</h3>
                    </div>
                    <div className="toggle-container" onClick={handleToggleSection}>
                        {
                            showSection
                                ? <span className="collapse-all">Collapse All<img className="collapse-icon" src={iconCollapse} alt="Collapse All" /></span>
                                : <span className="expand-all">Expand All<img className="open-icon" src={iconOpen} alt="Expand All" /></span>
                        }
                    </div>
                </div>
                <p className="settings-section-summary">{sectionSummary}</p>
                {showSection && this.props.children}
            </div >
        );
    }
}

export default AdminSettingSection;