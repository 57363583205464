import * as React from "react";
import { Link } from "react-router-dom";

export interface ToggleProps {
    toggle: (() => void) | undefined;
    className: string | undefined;
    active?: boolean;
}

export class Toggle extends React.Component<ToggleProps> {
    public render() {
        const toggleActiveClass = this.props.active ? "active" : "";
        return (
            <Link to="#" className={this.props.className + " " + toggleActiveClass} onClick={this.props.toggle}>
                {this.props.children}
            </Link>
        );
    }
}
