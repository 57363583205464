import * as React from "react";

export interface IconGuideTableRowNoteProps {
    text: string | JSX.Element;
}

export class IconGuideTableRowNote extends React.Component<IconGuideTableRowNoteProps> {
    public render() {
        return (
            <tr>
                <td className="col-12">
                    <i>{this.props.text}</i>
                </td>
            </tr>
        );
    }
}

export default IconGuideTableRowNote;
