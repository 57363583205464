import React from "react";
import "./sidebarPumpInformation.scss";
import { iconClose, iconPump, } from "../../images/";
import { PumpDevice } from "../../../store/Pumps/pumpsDatatypes";
import ReactMoment from 'react-moment';
export interface SidebarPumpInformationProps {
    device?: PumpDevice | null;
    closePanel: () => void;
}

const SidebarPumpInformation = (props: SidebarPumpInformationProps) => {
    const { device } = props;

    return (
        <>
            <div className="sideBarCycleCounterInformation nav-blade">
                <div className="blade-header">
                    <div className="blade-header-icon">
                        <img src={iconPump} alt="CycleCounter Device" />
                    </div>
                    <div className="blade-header-title">
                        <h1>Pump Device</h1>
                        <h2>{device?.name}</h2>
                    </div>
                    <div className="blade-header-close">
                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={props.closePanel}>
                            <img src={iconClose} width={15} height={15} alt="Close icon" className="align-top" />
                        </button>
                    </div>
                </div>
                <div className="blade-body">
                    <h3 className="blade-body-header">Information</h3>
                    <table className="w-100">
                        <thead>
                            <tr className="blade-table-header">
                                <th>Details</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="blade-table-body">
                                <td>Status</td>
                                <td>{device?.fault === 0 ? "OK" : "Fault"}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Pump Running</td>
                                <td>{device?.pumpRunning}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Circuit Break Tripped</td>
                                <td>{device?.circuitBreakTripped}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>High Float</td>
                                <td>{device?.highFloat}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Mid Float</td>
                                <td>{device?.midFloat}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Low Float</td>
                                <td>{device?.lowFloat}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Fault</td>
                                <td>{device?.fault}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Last Updated</td>
                                <td><ReactMoment date={device?.updatedDate} format="DD/MM/YY hh:mm a" /></td>
                            </tr>

                            {/*<tr className="blade-table-body">*/}
                            {/*    <td>Has low battery</td>*/}
                            {/*    <td>{device?.isLowBattery ? "true" : "false"}</td>*/}
                            {/*</tr>*/}
                            {/*<tr className="blade-table-body">*/}
                            {/*    <td>Last Seen</td>*/}
                            {/*    <td>*/}
                            {/*        <ReactMoment date={device?.lastHeartBeat} />*/}
                            {/*        <br />*/}
                            {/*        (<ReactMoment date={device?.lastHeartBeat} fromNow />)*/}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            {/*<tr className="blade-table-body">*/}
                            {/*    <td>Latitude</td>*/}
                            {/*    <td>{device?.latitude}</td>*/}
                            {/*</tr>*/}
                            {/*<tr className="blade-table-body">*/}
                            {/*    <td>Longitude</td>*/}
                            {/*    <td>{device?.longitude}</td>*/}
                            {/*</tr>*/}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default SidebarPumpInformation;
