import React from "react";
import { GeoJSON, useLeaflet } from "react-leaflet";
import "leaflet-polylinedecorator"
import * as L from 'leaflet';
import { length } from "@turf/turf";

function PolylineLink(props) {
    const ctx = useLeaflet()
    const { polylineId, coords, data, setStyle, zoom, onEachFeature } = props;
    const { map } = ctx;
    const style = setStyle(data.features[0]);
    const arrowPixelSize = (zoom <= 15 ? 1.75 : zoom / 4) * 5;
    const linkLength = length(coords, { units: 'meters' });

    const patternSettings = () => {
        if (linkLength >= 1500)
            return {
                offset: '12.5%',
                repeat: '25%'
            }
        else if (linkLength >= 1000)
            return {
                offset: '16.5%',
                repeat: '33%'
            }
        else if (linkLength >= 400)
            return {
                offset: '25%',
                repeat: '50%'
            }
        else {
            return {
                offset: '50%',
                repeat: '100%'
            }
        }
    }
    const patternWithArrow = {
        ...patternSettings(),
        symbol: L.Symbol.arrowHead({
            pixelSize: arrowPixelSize,
            polygon: false,
            pathOptions:
            {
                color: "rgba(41, 24, 66, 0.9)",
                weight: 3
            }
        })
    };

    function handleEachFeature(feature, layer) {
        //set click event
        onEachFeature(data.features[0], layer); 


        //add new lines 
        L.polylineDecorator(layer, {
            id: polylineId,
            patterns: [
                patternWithArrow
            ]
        }).addTo(map);

        //bring polyline layer to the front
        map.eachLayer((layer) => {
            if (polylineId === layer.options.id) {
                setTimeout(() => {
                    layer.bringToFront();
                }, 0);
            }
        });
    }

    return <GeoJSON data={coords} onEachFeature={handleEachFeature} style={style} />;
}

export default PolylineLink;