/* eslint-disable  @typescript-eslint/triple-slash-reference */
/// <reference path="leafletKmlDefinition.d.ts" />
import { Feature, GeometryObject } from "geojson";
import L, { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import IdleTimer from "react-idle-timer";
import { GeoJSON, LayersControl, Map, Marker, TileLayer, Pane } from "react-leaflet";
import { clearInterval, setInterval, setTimeout } from "timers";
import {
    addInsightLinkColor0,
    addInsightLinkColor1,
    addInsightLinkColor2,
    addInsightLinkColor3,
    addInsightLinkColor4,
    addInsightLinkColor5,
    addInsightLinkColor6,
    addInsightLinkColor7,
    addInsightLinkColorFlowRestriction,
} from "../../constants/addInsightLinkConstants";
import { polylineIdPrefix } from "../../constants/mapConstants";
import { AddInsightLink } from "store/addInsight";
import { AppSettingsPayload } from "store/appSettings";
import { BusMarker } from "store/buses";
import { SchoolSignMarker } from "store/schoolSigns";
import { CycleCounterDevice } from "store/cycleCounterDevices";

import { iconLocation } from "../images";
import PolylineLink from "../map/Polyline/PolylineLink";
import BusLeafletMarker from "./bus/busMarker";
import GoogleApiLoader from "./google-mutant/GoogleApiLoader";
import GoogleLayer from "./google-mutant/GoogleLayer";
import GoogleTheme from "./google-mutant/GoogleTheme";
import { defaultIcon, TDAPIconOptions } from "./icons";
import "./map.scss";
import "leaflet-kml";
import { SendToast } from "../../utils/toast";
import { CycleCounterDeviceMarker } from "./cycleCounters/cycleCounterDeviceMarker";
import { CycleCounterDetection } from "store/cycleCounterDetections";
import { PumpDevice } from "store/Pumps/pumpsDatatypes";
import PumpDeviceMarker from "./pump/pumpMarker";

const { BaseLayer } = LayersControl;
const reloadIntervalInMilliseconds = 300000;
const resetViewIntervalInMilliseconds = 300000;
L.Marker.prototype.options.icon = L.icon(defaultIcon);

export interface SiteMarker {
    id: number;
    description: string;
    position: LatLng;
    icon?: TDAPIconOptions;
    clickCallback: () => void;
}

export interface TDAPMapProps {
    markers: SiteMarker[];
    addInsightLinks: AddInsightLink[];
    buses: BusMarker[];
    schoolSigns: SchoolSignMarker[];
    cycleCounterDevices: CycleCounterDevice[];
    cycleCounterDetections: CycleCounterDetection[];
    pumpDevices: PumpDevice[];
    kmzEnabled?: boolean;
    zoom: number;
    maxZoom: number;
    center: [number, number];
    setZoom: (zoom: number) => void;
    setCenter: (center: [number, number]) => void;
    closeSitePanel: () => void;
    closeLinkPanel: () => void;
    closeFilterPanel: () => void;
    closeSchoolSignPanel: () => void;
    closeCycleCounterPanel: () => void;
    toggleMapIndex: (index: number) => void;
    appSettings: AppSettingsPayload | null;
    mapIndex: number;
    adaptiveZoomRadius: number;
    selectLinkOnMap: (linkId: number) => void;
    initialCenter: [number, number];
    selectedLinkId?: number;
    selectedSchoolSignId?: string;
    addInsightEnabled?: boolean;
    selectedPumpId?: string;
    selectedCycleCounterDeviceId?: string;
}

export interface TDAPMapState {
    reloading: boolean;
    firstLoad: boolean;
    kmlLayer?: L.Layer;
}
export const getScaledIcon = (currentZoom: number, iconOptions?: TDAPIconOptions) => {
    const iconOpt = {
        ...defaultIcon,
        ...iconOptions,
    };
    const currentZoomLevel = currentZoom;
    const scaleFunc = (iconSize: number) => Math.floor(iconSize * (1 / Math.max(1, 17 - currentZoomLevel)));
    iconOpt.iconAnchor = [scaleFunc(iconOpt.iconAnchor[0]), scaleFunc(iconOpt.iconAnchor[1])];
    iconOpt.iconSize = [scaleFunc(iconOpt.iconSize[0]), scaleFunc(iconOpt.iconSize[1])];
    return L.icon(iconOpt);
};

export const getMapZoom = (mapRef: Map, fallbackZoom: number) => {
    return (mapRef && mapRef.leafletElement.getZoom()) || fallbackZoom;
};

export const getMapCenter = (mapRef: Map, fallbackCenter: [number, number]) => {
    if (mapRef) {
        const centerLatLng = mapRef.leafletElement.getCenter();
        const convertedValue: [number, number] = [centerLatLng.lat, centerLatLng.lng];
        return convertedValue;
    }
    return fallbackCenter;
};

const baseLayers = ["OpenStreetMap", "AddInsight Style", "CartoDB Positron", "Google Maps", "Google Maps Traffic", "Google Maps Hybrid", "KMZ"];

const buildPolylineLinkLayerId = (linkId: number) => {
    return polylineIdPrefix + linkId;
};

export default class TDAPMap extends React.Component<TDAPMapProps, TDAPMapState> {
    //TODO: Figure out why typing this as a "Map" seems to still require an object with a valid ref prop?
    private mapRef: React.RefObject<any>;
    private timerRefreshLayer?: NodeJS.Timer;
    private timerRefreshResize?: NodeJS.Timer;

    constructor(props: TDAPMapProps) {
        super(props);
        // create a ref to store the textInput DOM element
        this.mapRef = React.createRef<Map>();
        this.handleResetView = this.handleResetView.bind(this);
        this.fitBounds = this.fitBounds.bind(this);
        this.onEachGeoJsonFeature = this.onEachGeoJsonFeature.bind(this);
        this.addKmlLayer = this.addKmlLayer.bind(this);
        this.removeKmlLayer = this.removeKmlLayer.bind(this);

        this.state = {
            reloading: false,
            firstLoad: true,
        };
    }

    componentDidMount() {
        this.timerRefreshLayer = setInterval(() => {
            //only refresh map on google traffic layer
            if (this.props.mapIndex === 4) {
                this.setState({ reloading: true }, () => {
                    setTimeout(() => {
                        this.setState({ reloading: false });
                    }, 1);
                });
            }
        }, reloadIntervalInMilliseconds);
    }

    //this method is allowed to make side effects and can set state immediately
    //https://reactjs.org/docs/react-component.html#componentdidupdate
    componentDidUpdate(prevProps: TDAPMapProps) {
        const map: Map = this.mapRef.current;

        //fits map to coordinate on first load where markers and map are available
        if (this.state.firstLoad && prevProps.markers.length > 0 && map) {
            this.setState({ firstLoad: false });
            map.leafletElement.whenReady(() => {
                this.fitBounds(prevProps.markers);
            });
        }

        if (prevProps.kmzEnabled !== this.props.kmzEnabled && this.props.kmzEnabled === true && this.state.kmlLayer === undefined) {
            this.addKmlLayer(this.mapRef);
        } else if (prevProps.kmzEnabled !== this.props.kmzEnabled && this.props.kmzEnabled === false && this.state.kmlLayer !== undefined) {
            this.removeKmlLayer(this.mapRef);
        }

        if (prevProps.addInsightEnabled !== this.props.addInsightEnabled && this.props.addInsightEnabled === false) {
            if (map) {
                map.leafletElement.eachLayer((layer) => this.removePolyLines(map, layer));
            }
        }
    }

    componentWillUnmount() {
        if (this.timerRefreshLayer) {
            clearInterval(this.timerRefreshLayer);
        }
        if (this.timerRefreshResize) {
            clearInterval(this.timerRefreshResize);
        }
    }

    removePolyLines = (map: Map, layer: any) => {
        const contains = layer?.options?.id?.toString().indexOf(polylineIdPrefix) > -1;
        if (contains) {
            map.leafletElement.removeLayer(layer);
        }
    };

    fitBounds = (markers: SiteMarker[]) => {
        const map: Map = this.mapRef.current;
        if (map) {
            setTimeout(() => {
                const center = new LatLng(this.props.initialCenter[0], this.props.initialCenter[1]);
                const radius = this.props.adaptiveZoomRadius;
                const markersWithinRadius: LatLng[] = markers.reduce(function (result: LatLng[], m: SiteMarker) {
                    const distance = center.distanceTo(m.position) / 1000;
                    if (distance <= radius) {
                        result.push(m.position);
                    }
                    return result;
                }, []);
                //handles case where center is not near markers
                if (!markersWithinRadius.length) {
                    map.leafletElement.flyTo(center, 12);
                } else {
                    const bounds = L.latLngBounds(markersWithinRadius);
                    map.leafletElement.fitBounds(bounds.pad(0.01), { padding: [0, 0] });
                }
            }, 100);
        }
    };

    //resets view by closing site panels and fitting map bounds to markers
    handleResetView = () => {
        const { closeSitePanel, closeFilterPanel, closeLinkPanel, closeSchoolSignPanel, closeCycleCounterPanel, markers } = this.props;

        closeSitePanel();
        closeLinkPanel();
        closeFilterPanel();
        closeSchoolSignPanel();
        closeCycleCounterPanel();

        this.fitBounds(markers);
    };

    scoreStyle = (color: string, weight: number, opacity: number = 0.8) => {
        return { color: color, weight: weight, opacity: opacity };
    };

    getAddInsightStyle = (feature: Feature<GeometryObject, any> | undefined) => {
        const currentZoomLevel = getMapZoom(this.mapRef.current, this.props.zoom);
        const scale = currentZoomLevel <= 15 ? 1.75 : currentZoomLevel / 4;
        const defaultStyle = this.scoreStyle(addInsightLinkColor0, scale, 0.5);

        if (feature) {
            const score: number = feature.properties.score;
            const flowRestrictionScore: number = feature.properties.flowRestrictionScore;

            if (score === 0 && flowRestrictionScore >= 1) {
                return this.scoreStyle(addInsightLinkColorFlowRestriction, 2 * scale);
            }
            if (feature.properties.enoughData === true) {
                if (score === 0) {
                    return defaultStyle;
                } else if (score === 1) {
                    return this.scoreStyle(addInsightLinkColor1, 2 * scale);
                } else if (score === 2) {
                    return this.scoreStyle(addInsightLinkColor2, 2 * scale);
                } else if (score === 3) {
                    return this.scoreStyle(addInsightLinkColor3, 2 * scale);
                } else if (score === 4) {
                    return this.scoreStyle(addInsightLinkColor4, 2 * scale);
                } else if (score === 5) {
                    return this.scoreStyle(addInsightLinkColor5, 2.25 * scale);
                } else if (score === 6) {
                    return this.scoreStyle(addInsightLinkColor6, 2.25 * scale);
                } else if (score >= 7) {
                    return this.scoreStyle(addInsightLinkColor7, 2.25 * scale);
                }
            }
        }
        return defaultStyle;
    };

    getGeoJSON = (data: AddInsightLink) => {
        const collection = [];
        //data is the JSON string
        const score = data.score;
        const feature: any = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: data.coordinates.map((x) => [x.long, x.lat]),
            },
            properties: {
                linkId: data.linkId,
                score: score != null ? score : 0,
                enoughData: score != null ? data.enoughData : true,
                flowRestrictionScore: data.flowRestrictionScore,
            },
        };

        if (data.coordinates) collection.push(feature);
        const geo: any = {
            type: "FeatureCollection",
            features: collection,
        };

        return geo;
    };

    onEachGeoJsonFeature(feature: any, layer: any) {
        const featureProperties: AddInsightLink = feature.properties;
        const id: number = featureProperties.linkId;

        layer.on({
            click: (event: L.LeafletMouseEvent) => {
                this.props.selectLinkOnMap(id);
            },
        });
        const map: Map = this.mapRef.current;

        if (map) {
            //remove polylines from deselected links
            map.leafletElement.eachLayer(function (layer: any) {
                if (buildPolylineLinkLayerId(id) === layer.options.id) {
                    map.leafletElement.removeLayer(layer);
                }
            });
        }
    }

    onBaseLayerChange = (e: any) => {
        this.props.toggleMapIndex(baseLayers.indexOf(e.name));
    };

    resizeMap = (mapRef: React.RefObject<any>) => {
        if (this.timerRefreshResize === undefined) {
            //leaflet runs into sizing issues when the container size changes due to other components e.g. sidebar expanding
            //calling invalidateSize() fixes this
            const map: Map = mapRef.current;
            this.timerRefreshResize = setInterval(() => {
                if (map) {
                    map.leafletElement.invalidateSize({
                        debounceMoveend: true,
                        animate: false,
                        noMoveStart: true,
                        pan: true,
                    });
                }
            }, 100);
        }
    };

    addKmlLayer = (mapRef: React.RefObject<any>) => {
        const map: Map = mapRef.current;
        if (map && this.props.appSettings) {
            if (this.props.appSettings.kmzLayerUrl) {
                fetch(this.props.appSettings.kmzLayerUrl)
                    .then((res) => res.text())
                    .then((kmlText) => {
                        //parses the xml
                        const parser = new DOMParser();
                        const kmlResponse: any = parser.parseFromString(kmlText, "text/xml");
                        const track: any = new L.KML(kmlResponse);

                        //adds the layer to the map
                        map.leafletElement.addLayer(track);

                        //adds the layer to state (so it can be removed on toggle)
                        this.setState({ kmlLayer: track });
                    })
                    .catch((ex) => {
                        SendToast("Error loading KMZ file...", "", { type: "error" });
                        console.log(ex);
                    });
            } else {
                SendToast("Error loading KMZ file...", "KmzLayerUrl Environment variable hasn't been set...", { type: "warning" });
            }
        }
    };

    removeKmlLayer = (mapRef: React.RefObject<any>) => {
        const map: Map = mapRef.current;
        if (map && this.state.kmlLayer) {
            //removes the kml layer from the map
            map.leafletElement.removeLayer(this.state.kmlLayer);

            //removes the kml layer from state
            this.setState({ kmlLayer: undefined });
        }
    };

    public render() {
        const { reloading } = this.state;
        const {
            appSettings,
            mapIndex,
            zoom,
            center,
            maxZoom,
            addInsightLinks,
            buses,
            schoolSigns,
            cycleCounterDevices,
            pumpDevices,
            markers,
            closeSitePanel,
            closeFilterPanel,
            closeLinkPanel,
            closeSchoolSignPanel,
            closeCycleCounterPanel,
            setCenter,
            setZoom,
            kmzEnabled,
        } = this.props;

        return (
            <div className="map-container flex-grow-1">
                <IdleTimer
                    timeout={resetViewIntervalInMilliseconds}
                    onIdle={() => {
                        closeSitePanel();
                        closeLinkPanel();
                        closeFilterPanel();
                        closeSchoolSignPanel();
                        closeCycleCounterPanel();

                        if (markers) {
                            this.fitBounds(markers);
                        }
                    }}
                />
                {appSettings && (
                    <GoogleApiLoader apiKey={appSettings.googleApiKey}>
                        {!reloading && (
                            <Map
                                ref={this.mapRef}
                                whenReady={() => {
                                    this.resizeMap(this.mapRef);
                                    if (kmzEnabled) {
                                        this.addKmlLayer(this.mapRef);
                                    }
                                }}
                                zoomSnap={1}
                                onBaseLayerChange={this.onBaseLayerChange}
                                center={center}
                                zoom={zoom}
                                maxZoom={maxZoom}
                                onDragEnd={() => {
                                    setCenter(getMapCenter(this.mapRef.current, center));
                                }}
                                onZoomEnd={() => {
                                    setZoom(getMapZoom(this.mapRef.current, zoom));
                                    setCenter(getMapCenter(this.mapRef.current, center));

                                    if (this.props.addInsightEnabled === false && this.mapRef) {
                                        const map = this.mapRef.current;
                                        map.leafletElement.eachLayer((layer: any) => this.removePolyLines(map, layer));
                                    }
                                }}
                            >
                                <LayersControl position="topright">
                                    <BaseLayer checked={mapIndex === 0} index={0} name={baseLayers[0]}>
                                        <TileLayer
                                            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 1} name={baseLayers[1]}>
                                        <TileLayer
                                            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                                            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                            subdomains="abcd"
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 2} name={baseLayers[2]}>
                                        <TileLayer
                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                            subdomains="abcd"
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 3} name={baseLayers[3]}>
                                        <GoogleLayer type="roadmap" styles={GoogleTheme} />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 4} name={baseLayers[4]}>
                                        <GoogleLayer type="roadmap" trafficLayer={true} />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 5} name={baseLayers[5]}>
                                        <GoogleLayer type="hybrid" />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 6} name={baseLayers[6]}>
                                        <GoogleLayer type="hybrid" />
                                    </BaseLayer>
                                    <BaseLayer checked={mapIndex === 7} name={baseLayers[6]}>
                                        <GoogleLayer type="hybrid" />
                                    </BaseLayer>
                                </LayersControl>
                                <Pane className="site-pane">
                                    {markers.length > 0 &&
                                        markers.map((m) => (
                                            <Marker
                                                key={m.id}
                                                position={m.position}
                                                icon={getScaledIcon(getMapZoom(this.mapRef.current, zoom), m.icon)}
                                                onClick={m.clickCallback}
                                            />
                                        ))}
                                </Pane>
                                <Pane className="school-sign-pane">
                                    {schoolSigns.length > 0 &&
                                        schoolSigns.map((s) => (
                                            <Marker
                                                key={s.id}
                                                position={s.position}
                                                icon={getScaledIcon(getMapZoom(this.mapRef.current, zoom), s.icon)}
                                                onClick={s.clickCallback}
                                            />
                                        ))}
                                </Pane>
                                <Pane className="bus-pane">
                                    {buses.length > 0 &&
                                        buses.map(
                                            (b) =>
                                                b.busInfo.routeName && <BusLeafletMarker key={b.busInfo.id} bus={b} zoom={getMapZoom(this.mapRef.current, zoom)} />
                                        )}
                                </Pane>
                                <Pane className="links-pane">
                                    {addInsightLinks.length > 0 &&
                                        addInsightLinks.map((link) =>
                                            (this.props.selectedLinkId ? this.props.selectedLinkId === link.linkId : false) ||
                                                (link.score > 0 && link.enoughData) ||
                                                link.flowRestrictionScore >= 1 ? (
                                                <PolylineLink
                                                    key={link.linkId + "_" + link.score + "_" + link.flowRestrictionScore + "_" + zoom}
                                                    polylineId={buildPolylineLinkLayerId(link.linkId)}
                                                    coords={link.lineString}
                                                    data={this.getGeoJSON(link)}
                                                    zoom={zoom}
                                                    onEachFeature={this.onEachGeoJsonFeature}
                                                    setStyle={(feature: Feature<GeometryObject>) => this.getAddInsightStyle(feature)}
                                                />
                                            ) : (
                                                <GeoJSON
                                                    key={link.linkId + "_" + link.score}
                                                    data={this.getGeoJSON(link)}
                                                    style={this.getAddInsightStyle}
                                                    onEachFeature={this.onEachGeoJsonFeature}
                                                />
                                            )
                                        )}
                                </Pane>
                                <Pane className="cyclecounter-pane">
                                    {cycleCounterDevices.length > 0 &&
                                        cycleCounterDevices.map((d) => <CycleCounterDeviceMarker key={d.id} device={d} zoom={zoom} isSelected={d.id === this.props.selectedCycleCounterDeviceId} />)}
                                </Pane>
                                <Pane className="pumps-pane">
                                    {pumpDevices.length > 0 && pumpDevices.map((d) => <PumpDeviceMarker key={d.pumpId} device={d} zoom={zoom} isSelected={d.pumpId === this.props.selectedPumpId} />)}
                                </Pane>
                                <button id="reset-view" title="Reset View" className="leaflet-control-layers leaflet-control" onClick={() => this.handleResetView()}>
                                    <img className="leaflet-control-location-toggle" src={iconLocation} alt="Location icon" />
                                </button>
                            </Map>
                        )}
                    </GoogleApiLoader>
                )}
            </div>
        );
    }
}
