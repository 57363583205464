import "whatwg-fetch";
import { Action, Reducer, AnyAction } from "redux";
import { ApplicationState, AppThunkDispatch, AppThunkAction } from ".";
import { AlertSource, AlarmCode } from "./alarm";
import { AuthenticationRole, enforceRolePermissions } from "./authentication";
import * as moment from "moment";
import { LatLng } from "leaflet";
import { BackendErrorToast, ClearBackendErrorToast, ToastErrorTypes } from "../utils/toast";
import { FlyToAction, SetZoomAction, actionCreators as MapActionCreators } from "./map";
import { HttpError } from "@microsoft/signalr";
import { maxMobileWidth } from "../constants/mobileConstants";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface SiteState {
    sites: {
        [key: number]: Site;
    };
    initialLoadStarted: boolean;
    selectedSiteId?: number;
    sidebarExpanded: boolean;
    siteOperationLoading: boolean;
    showFilterSidebar: boolean;
    filters: Filters;
}

export interface Filters {
    alarms: Record<string, AlarmCode>;
    mutedAlarms: boolean;
    openLogs: boolean;
    unreadLogs: boolean;
    schoolSignOff: boolean;
    schoolSignOn: boolean;
    schoolSignNotTalking: boolean;
}

export interface Site {
    id: number;
    scatsSiteId?: number;
    addInsightSiteId?: number;
    description: string;
    latitude: number;
    longitude: number;
    lastUpdated: Date;
    initialLoadStarted: boolean;
    currentStatus: number;
    alertSources: SiteAlertSourceState[];
    priority: number;
    hidden: boolean;
}

export interface SiteCurrentState {
    id: number;
    lastUpdated: Date;
    currentStatus: number;
}

export interface SiteAlertSourceState {
    siteId?: number; // Used for update Object
    alarmSourceId: AlertSource;
    muteUntil?: string;
}

// -----------------
// SELECTORS - Used to grab data out of state without depending on the structure of the state

export class SiteSelectors {
    //TODO: A selector that returns the current status of site.
    //(This will probably be moved to the site object in the future as the backend will be authoritative on the logic of what a "major" alert is and handle all the flappy logic.)

    //Gets a list of sites in order of the meeting ids specified.
    static GetSitesByIdentifiers(state: SiteState, ids: number[]): Site[] {
        return ids.map((e) => this.GetSiteByIdentifier(state, e));
    }

    //Gets a single site by the site id
    static GetSiteByIdentifier(state: SiteState, id: number): Site {
        return (
            state.sites[id] ||
            <Site>{
                id: id,
                description: "Unknown site",
            }
        );
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestSitesAction extends Action {
    type: "REQUEST_SITES";
}
export interface ReceiveSitesAction extends Action {
    type: "RECEIVE_SITES";
    sites: Site[];
}
interface OpenSitePanelAction extends Action {
    type: "OPEN_SITE_PANEL";
    // The site id that is selected
    siteId: number;
}
interface OpenFilterPanelAction extends Action {
    type: "OPEN_FILTER_PANEL";
}
export interface CloseFilterPanelAction extends Action {
    type: "CLOSE_FILTER_PANEL";
}
interface SaveSiteAction extends Action {
    type: "SAVE_SITE";
    sites: Site[];
}
interface AddSiteLogAction extends Action {
    type: "ADD_SITE_LOG";
    sites: Site[];
}
interface DeleteSiteLogAction extends Action {
    type: "DELETE_SITE_LOG";
    sites: Site[];
}
export interface CloseSitePanelAction extends Action {
    type: "CLOSE_SITE_PANEL";
}
interface UpsertSitesAction extends Action {
    type: "UPSERT_SITES";
    sites: Site[];
}
interface UpsertMutedSitesAction extends Action {
    type: "UPSERT_MUTED_SITES";
    sites: Site[];
}
interface ToggleSidebarExpandedAction extends Action {
    type: "TOGGLE_SIDEBAR_EXPANDED";
}
interface ErrorSitesAction extends Action {
    type: "ERROR_RECEIVING_SITES";
}
interface SiteOperationLoadingAction extends Action {
    type: "SITE_OPERATION_LOADING";
}
interface ErrorSiteOperationAction extends Action {
    type: "ERROR_SITE_OPERATION";
}
interface SetFiltersAction extends Action {
    type: "SET_FILTERS";
    filters: Filters;
}

interface SetFiltersAction extends Action {
    type: "SET_FILTERS";
    filters: Filters;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestSitesAction
    | ReceiveSitesAction
    | OpenSitePanelAction
    | SaveSiteAction
    | AddSiteLogAction
    | DeleteSiteLogAction
    | CloseSitePanelAction
    | UpsertSitesAction
    | ErrorSitesAction
    | ToggleSidebarExpandedAction
    | SiteOperationLoadingAction
    | ErrorSiteOperationAction
    | UpsertMutedSitesAction
    | CloseFilterPanelAction
    | OpenFilterPanelAction
    | SetFiltersAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export async function saveSites(accessToken: string | null, state: SiteState, newSites: Site[]): Promise<Site[]> {
    const oldSites = state.sites;

    // NOTE: This does not support deleting sites. This was introduced for updates only
    // at this stage
    const updatePromises = newSites.map(async (upsertSite) => {
        const existingSite = oldSites[upsertSite.id];

        const existingSiteJson = JSON.stringify(existingSite);
        const upsertSiteJson = JSON.stringify(upsertSite);

        if (existingSiteJson === upsertSiteJson) {
            return existingSite;
        }

        const response = await fetch(`/api/Site`, {
            method: "PUT",
            body: upsertSiteJson,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        const updatedSite = (await response.json()) as Site;

        return updatedSite;
    });

    return await Promise.all(updatePromises);
}

export const actionCreators = {
    requestAllSites: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        const {
            sites: { initialLoadStarted },
            authentication: { accessToken },
        } = getState();

        if (initialLoadStarted || !accessToken) {
            return;
        }

        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            fetch(`/api/Site`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        return resp.json() as Promise<Site[]>;
                    } else {
                        throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                    }
                })
                .then((data) => {
                    dispatch({ type: "RECEIVE_SITES", sites: data });
                    ClearBackendErrorToast(ToastErrorTypes.SITES);
                })
                .catch((error) => {
                    dispatch({ type: "ERROR_RECEIVING_SITES" });
                    console.error(error);
                    BackendErrorToast(ToastErrorTypes.SITES, "Error retrieving sites");
                });

            dispatch({ type: "REQUEST_SITES" });
        });
    },
    receiveUpdatedSites:
        (incomingSites: Site[]): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch({ type: "RECEIVE_SITES", sites: incomingSites });
                });
            },
    updateSites:
        (sites: Site[]): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const { accessToken } = getState().authentication;
                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    saveSites(accessToken, getState().sites, sites).then((updatedSites) => dispatch({ type: "UPSERT_SITES", sites: updatedSites }));
                });
            },
    selectSite:
        (selectedSiteId: number, zoom: boolean): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    sites: { sites },
                } = getState();

                enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
                    dispatch(MapActionCreators.closeAllSidePanelsAction());
                    dispatch(<OpenSitePanelAction>{ type: "OPEN_SITE_PANEL", siteId: selectedSiteId });

                    if (zoom) {
                        const selectedSite = sites[selectedSiteId];
                        if (selectedSite) {
                            dispatch(<FlyToAction>{ type: "FLY_TO", latLng: new LatLng(selectedSite.latitude, selectedSite.longitude) });
                            dispatch(<SetZoomAction>{ type: "SET_ZOOM", zoom: 15 });
                        }
                    }
                });
            },
    toggleSidebarExpanded: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<ToggleSidebarExpandedAction>{ type: "TOGGLE_SIDEBAR_EXPANDED" });
        }),
    muteAlertSource:
        (alarmSourceId: AlertSource | undefined, muteLength: moment.Moment | undefined): AppThunkAction =>
            (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
                const {
                    sites: { sites, selectedSiteId, initialLoadStarted },
                    authentication: { accessToken },
                    appSettings: { appSettings },
                } = getState();
                if (initialLoadStarted || !selectedSiteId || !alarmSourceId || !accessToken || !appSettings) {
                    return;
                }
                const site = sites[selectedSiteId];
                if (site === undefined) {
                    return;
                }

                enforceRolePermissions(getState().authentication, AuthenticationRole.Operator, () => {
                    fetch(`/api/Site/ToggleMuteState`, {
                        method: "PUT",
                        body: JSON.stringify({ alarmSourceId: alarmSourceId, muteUntil: muteLength, siteId: selectedSiteId }),
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                    })
                        .then((resp) => {
                            if (resp.ok) {
                                return resp.json() as Promise<SiteAlertSourceState>;
                            } else {
                                throw new HttpError(`Request rejected with status ${resp.status}`, resp.status);
                            }
                        })
                        .then((data) => {
                            const site = sites[data.siteId || -1];
                            if (site) {
                                site.alertSources = [
                                    ...site.alertSources.filter((alertSource: SiteAlertSourceState) => alertSource.alarmSourceId !== data.alarmSourceId),
                                    {
                                        alarmSourceId: data.alarmSourceId,
                                        muteUntil: data.muteUntil,
                                    },
                                ];

                                dispatch({
                                    type: "UPSERT_MUTED_SITES",
                                    sites: [site],
                                });
                                ClearBackendErrorToast(ToastErrorTypes.MUTE);
                            }
                        })
                        .catch((error) => {
                            dispatch(<ErrorSiteOperationAction>{ type: "ERROR_SITE_OPERATION" });
                            console.error(error);
                            BackendErrorToast(ToastErrorTypes.MUTE, "Error muting alert source");
                        });
                    dispatch(<SiteOperationLoadingAction>{ type: "SITE_OPERATION_LOADING" });
                });
            },
    closeSitePanel: () => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<CloseSitePanelAction>{ type: "CLOSE_SITE_PANEL" });
        }),
    closeFilterPanel: () => <CloseFilterPanelAction>{ type: "CLOSE_FILTER_PANEL" },
    openFilterPanel: (): AppThunkAction => (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(MapActionCreators.closeAllSidePanelsAction());
            dispatch(<OpenFilterPanelAction>{ type: "OPEN_FILTER_PANEL" });
        });
    },
    setFilters: (filters: Filters) => (dispatch: AppThunkDispatch, getState: () => ApplicationState) =>
        enforceRolePermissions(getState().authentication, AuthenticationRole.Viewer, () => {
            dispatch(<SetFiltersAction>{ type: "SET_FILTERS", filters: filters });
        }),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SiteState = {
    sites: {},
    initialLoadStarted: false,
    sidebarExpanded: window.innerWidth >= maxMobileWidth,
    siteOperationLoading: false,
    showFilterSidebar: false,
    filters: {
        alarms: {},
        mutedAlarms: false,
        openLogs: false,
        unreadLogs: false,
        schoolSignNotTalking: false,
        schoolSignOff: false,
        schoolSignOn: false,
    },
};

export const reducer: Reducer<SiteState, AnyAction> = (state: SiteState | undefined, incomingAction: AnyAction) => {
    if (!state) {
        //Redux throws initialises the state with a dummy action on load. Return an initial state.
        state = unloadedState;
    }

    const action = incomingAction as KnownAction;
    const newSites = { ...state.sites };

    switch (action.type) {
        case "REQUEST_SITES":
            return <SiteState>{
                ...state,
                initialLoadStarted: true,
            };
        case "RECEIVE_SITES": {
            const incomingSites = action.sites.reduce<Record<number, Site>>(function (siteMap, site) {
                const current = siteMap[site.id];

                if (current == null) {
                    siteMap[site.id] = site;
                } else if (current && site.lastUpdated >= current.lastUpdated) {
                    siteMap[site.id] = site;
                }

                return siteMap;
            }, {});

            return <SiteState>{
                ...state,
                initialLoadStarted: false,
                sites: incomingSites,
            };
        }
        case "UPSERT_SITES":
            action.sites.forEach((upsertSite) => {
                const existingSite = newSites[upsertSite.id];
                if (!existingSite || upsertSite.lastUpdated >= existingSite.lastUpdated) {
                    newSites[upsertSite.id] = upsertSite;
                }
            });
            return <SiteState>{
                ...state,
                sites: newSites,
            };
        case "UPSERT_MUTED_SITES":
            action.sites.forEach((upsertSite) => {
                const existingSite = newSites[upsertSite.id];
                if (!existingSite || upsertSite.lastUpdated >= existingSite.lastUpdated) {
                    newSites[upsertSite.id] = upsertSite;
                }
            });
            return <SiteState>{
                ...state,
                sites: newSites,
                siteOperationLoading: false,
            };
        case "OPEN_SITE_PANEL":
            return <SiteState>{
                ...state,
                selectedSiteId: action.siteId,
            };
        case "SAVE_SITE":
            return <SiteState>{
                ...state,
                sites: action.sites,
            };
        case "CLOSE_SITE_PANEL":
            return <SiteState>{
                ...state,
                selectedSiteId: undefined,
            };
        case "CLOSE_FILTER_PANEL":
            return <SiteState>{
                ...state,
                showFilterSidebar: false,
            };
        case "OPEN_FILTER_PANEL":
            return <SiteState>{
                ...state,
                showFilterSidebar: true,
            };
        case "TOGGLE_SIDEBAR_EXPANDED":
            return <SiteState>{
                ...state,
                sidebarExpanded: !state.sidebarExpanded,
            };
        case "ERROR_RECEIVING_SITES":
            return <SiteState>{
                ...state,
                initialLoadStarted: false,
            };
        case "SITE_OPERATION_LOADING":
            return <SiteState>{
                ...state,
                siteOperationLoading: true,
            };
        case "ERROR_SITE_OPERATION":
            return <SiteState>{
                ...state,
                siteOperationLoading: false,
            };
        case "SET_FILTERS":
            return <SiteState>{
                ...state,
                filters: action.filters,
            };
        default: {
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action as never;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    return state;
};
