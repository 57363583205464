import * as React from "react";
import { FC } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Layout from "./containers/layout";
import HomeContainer from "./containers/home";
import IconGuideContainer from "./containers/iconGuide";
import AdminSettingsContainer from "./containers/adminSettings";
import { withTitle } from "../src/components/title/title";
import DashBoardContainer from "./containers/dashboard";

const Routes: FC = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={withTitle({ Component: HomeContainer, title: "TDAP - Home Page" })} />
                <Route exact path="/" component={withTitle({ Component: HomeContainer, title: "TDAP - Home Page" })} />
                <Route exact path="/icon-guide" component={withTitle({ Component: IconGuideContainer, title: "TDAP - Icon Guide" })} />
                <Route exact path="/admin" component={withTitle({ Component: AdminSettingsContainer, title: "TDAP - Admin Settings" })} />
                <Route exact path="/dashboard" component={DashBoardContainer} />
                <Redirect to="/" />
            </Switch>
        </Layout>
    );
};

export default Routes;
