import * as React from 'react';
import './controls.scss';

interface SliderIndicatorProps {
    iconSrc: string;
    altText: string;
}

export class SliderIndicator extends React.Component<SliderIndicatorProps> {

    public render() {
        const { iconSrc, altText } = this.props;
        return (
            <img src={iconSrc} style={{ height: "100%", width: "100%" }} alt={altText} />
        );
    }
}

export default SliderIndicator;