import React from "react";
import "./sidebarCycleCounterInformation.scss";
import { CycleCounterDevice } from "store/cycleCounterDevices";
import ReactMoment from "react-moment";
import { iconClose, iconBicycle, } from "../../images/";
import { CycleCounterDetectionsState } from "store/cycleCounterDetections";
import { CycleCounterGraph } from "components/map/graph/cycleCounterGraph";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { cycleCounterHelper } from "./../cycleCounterHelper";

export interface SideBarCycleCounterInformationItemProps {
    device?: CycleCounterDevice | null;
    closeCycleCounterPanel: () => void;
}

export const SideBarCycleCounterInformation = (props: SideBarCycleCounterInformationItemProps) => {
    const { device } = props;

    const detections = useSelector<ApplicationState, CycleCounterDetectionsState>((state) => state.cycleCounterDetections);
    const selectedId = useSelector<ApplicationState, string | undefined>((state) => state.cycleCounterDevices.selectedCycleCounterDeviceId);
    const total = cycleCounterHelper.getTodaysTotalForDevice(detections, selectedId);

    return (
        <>
            <div className="sideBarCycleCounterInformation nav-blade">
                <div className="blade-header">
                    <div className="blade-header-icon">
                        <img src={iconBicycle} alt="CycleCounter Device" />
                    </div>
                    <div className="blade-header-title">
                        <h1>CycleCounter Device</h1>
                        <h2>{device?.id}</h2>
                    </div>
                    <div className="blade-header-close">
                        <button type="button" className="btn p-0 m-0 border-0 shadow-none" onClick={props.closeCycleCounterPanel}>
                            <img src={iconClose} width={15} height={15} alt="Close icon" className="align-top" />
                        </button>
                    </div>
                </div>
                <div className="blade-body">
                    <h3 className="blade-body-header">
                        <div className="largeDisplay">
                            <div className="label">Bikes Detected Today</div>
                            <div className="number">{total}</div>
                        </div>
                    </h3>
                    <div className="graph-container">
                        <CycleCounterGraph deviceId={device?.id}></CycleCounterGraph>
                    </div>
                    <h3 className="blade-body-header">Information</h3>
                    <table className="w-100">
                        <thead>
                            <tr className="blade-table-header">
                                <th>Details</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="blade-table-body">
                                <td>Status</td>
                                <td>{device?.state}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Has low battery</td>
                                <td>{device?.isLowBattery ? "true" : "false"}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Last Seen</td>
                                <td>
                                    <ReactMoment date={device?.lastHeartBeat} format="DD/MM/YY hh:mm a" />
                                    <br />
                                    (<ReactMoment date={device?.lastHeartBeat} fromNow />)
                                </td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Latitude</td>
                                <td>{device?.latitude}</td>
                            </tr>
                            <tr className="blade-table-body">
                                <td>Longitude</td>
                                <td>{device?.longitude}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
