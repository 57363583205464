import * as React from 'react';
import './filterGroup.scss';

interface FilterGroupProps {
    title: string;
    children: React.ReactNode;
}

const FilterGroup = (props: FilterGroupProps) => {
    return (
        <div className="filter-group">
            <div className="filter-group-title-container">
                <h3 className="filter-group-title">{props.title}</h3>
            </div>
            {props.children && props.children}
        </div>
    );
}

export default FilterGroup;